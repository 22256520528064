import React from 'react';
import $ from 'jquery';
import * as d3Module from 'd3';
import d3Tip from 'd3-tip';
import './lineage.css';
import {event as currentEvent} from 'd3-selection';
// import {event as currentEvent} from 'd3-selection';

const d3 = {
  ...d3Module,
  tip: d3Tip
};

class PipelineLineage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          height:this.props.height,
          data:this.props.data
        }
        this.showGraph = this.showGraph.bind(this);
    }


  // zoomOut() {
  //     svg.transition().call(zoom.scaleBy, 0.8);
  // }

  //  resetZoom() {
  //     svg.transition().call(zoom.transform, d3.zoomIdentity);
  // }
  showGraph(){
        $(".lg-tooltip").hide(); // On very first line of scripts.js file

  var icon_job = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_job" transform="translate(-8449 726)">
    <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
    <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
  </g>
</svg>
`

  var icon_task = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
    <path id="Path_8121" data-name="Path 8121" d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z" transform="translate(1 1)" fill="currentColor"/>
  </g>
</svg>
`

/*
  var icon_dataset = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_datasouce" transform="translate(-8523 726)">
    <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24" transform="translate(8523 -726)" fill="none"/>
    <path id="database-solid" d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z" transform="translate(8527 -723)" fill="currentColor"/>
  </g>
</svg>`
*/



  var icon_dataset_icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="icon_dataset" transform="translate(-8487 726)">
    <rect id="Rectangle_1920" data-name="Rectangle 1920" width="24" height="24" transform="translate(8487 -726)" fill="none"/>
    <path id="layer-group-solid" d="M.434,5.2,8.623,8.911a.9.9,0,0,0,.748,0L17.561,5.2a.785.785,0,0,0,0-1.407L9.372.074a.9.9,0,0,0-.748,0L.434,3.789A.786.786,0,0,0,.434,5.2ZM17.561,8.3l-2.042-.926L9.836,9.95a2.026,2.026,0,0,1-1.678,0L2.476,7.374.434,8.3a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712a.785.785,0,0,0,0-1.406Zm0,4.493-2.034-.922L9.836,14.45a2.026,2.026,0,0,1-1.678,0l-5.69-2.579-2.035.922a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712A.785.785,0,0,0,17.561,12.793Z" transform="translate(8490.003 -722.993)" fill="currentColor"/>
  </g>
</svg>`

var icon_external_link = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_file_open" transform="translate(-254 -412)">
  <rect id="Rectangle_1" data-name="Rectangle 1" width="16" height="16" transform="translate(254 412)" fill="none"/>
  <path id="file_open_FILL0_wght400_GRAD0_opsz24" d="M161.178-868.221a1.135,1.135,0,0,1-.832-.346A1.134,1.134,0,0,1,160-869.4v-9.423a1.134,1.134,0,0,1,.346-.832,1.134,1.134,0,0,1,.832-.346h4.712l3.534,3.534v3.534h-1.178v-2.945H165.3v-2.945h-4.123v9.423h5.3v1.178Zm9.394.221-1.737-1.737v1.31h-1.178v-3.328h3.328v1.178h-1.325l1.737,1.737Zm-9.394-1.4v0Z" transform="translate(96.3 1294)" fill="currentColor"/>
</g>
</svg>`

var icon_feature_search = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_feature_search" transform="translate(-312 -412)">
  <rect id="Rectangle_3" data-name="Rectangle 3" width="16" height="16" transform="translate(312 412)" fill="none"/>
  <path id="feature_search_FILL0_wght400_GRAD0_opsz24" d="M89.366-873.415l1.171,1.171v2.78a1.127,1.127,0,0,1-.344.827,1.127,1.127,0,0,1-.827.344h-8.2a1.127,1.127,0,0,1-.827-.344,1.127,1.127,0,0,1-.344-.827v-8.2a1.127,1.127,0,0,1,.344-.827,1.127,1.127,0,0,1,.827-.344h3.22a2.616,2.616,0,0,0-.176.563q-.059.3-.088.607H81.171v8.2h8.2Zm.761-2.546L92-874.088l-.82.82-1.873-1.873a3.646,3.646,0,0,1-.659.293,2.343,2.343,0,0,1-.746.117,2.54,2.54,0,0,1-1.866-.768,2.54,2.54,0,0,1-.768-1.866,2.54,2.54,0,0,1,.768-1.866A2.54,2.54,0,0,1,87.9-880a2.54,2.54,0,0,1,1.866.768,2.54,2.54,0,0,1,.768,1.866,2.345,2.345,0,0,1-.117.746A3.647,3.647,0,0,1,90.127-875.961ZM87.9-875.9a1.413,1.413,0,0,0,1.039-.424,1.413,1.413,0,0,0,.424-1.039,1.413,1.413,0,0,0-.424-1.039,1.413,1.413,0,0,0-1.039-.424,1.413,1.413,0,0,0-1.039.424,1.413,1.413,0,0,0-.424,1.039,1.413,1.413,0,0,0,.424,1.039A1.413,1.413,0,0,0,87.9-875.9Zm-6.732,2.488v0Z" transform="translate(234 1294.133)" fill="currentColor"/>
</g>
</svg>`

var icon_troubleshoot = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g id="icon_troubleshoot" transform="translate(-283 -412)">
  <rect id="Rectangle_2" data-name="Rectangle 2" width="16" height="16" transform="translate(283 412)" fill="none"/>
  <path id="troubleshoot_FILL1_wght400_GRAD0_opsz24" d="M91.16-868.6l-2.82-2.82a4.933,4.933,0,0,1-1.357.75,4.594,4.594,0,0,1-1.582.27,4.6,4.6,0,0,1-2.437-.66,4.893,4.893,0,0,1-1.717-1.74h1.47a3.821,3.821,0,0,0,1.192.877,3.448,3.448,0,0,0,1.493.323,3.472,3.472,0,0,0,2.55-1.05A3.472,3.472,0,0,0,89-875.2a3.471,3.471,0,0,0-1.05-2.55,3.471,3.471,0,0,0-2.55-1.05,3.465,3.465,0,0,0-2.437.953,3.507,3.507,0,0,0-1.147,2.348h-1.2a4.658,4.658,0,0,1,1.493-3.2A4.624,4.624,0,0,1,85.4-880a4.633,4.633,0,0,1,3.4,1.395A4.633,4.633,0,0,1,90.2-875.2a4.6,4.6,0,0,1-.27,1.583,4.931,4.931,0,0,1-.75,1.358L92-869.44Zm-6.4-4.2-.945-3.12-.78,2.22H80v-.9h2.4l.99-2.85h.9l.915,3.06.645-2.01h.9l.9,1.8h.45v.9h-1l-.7-1.41-.75,2.31Z" transform="translate(205 1294.333)" fill="currentColor"/>
</g>
</svg>`

console.log("this.props.data",this.props.data);

var graph=this.props.data
if (!graph || !graph.tasks || !graph.links || !graph.datasets) {
  console.error("Invalid graph data:", graph);
  return;
}
let getalllinks = graph.links;

const graphTree = (graph) => {
  const nodesMap = new Map();
  for (const [key, value] of Object.entries(graph.datasets)) {
    const newNode = {
      name: key,
      content: generateNodeContent(key, value, graph.tasks[key] || []),
      children: []
    };
    nodesMap.set(key, newNode);
  }

  graph.links.forEach(link => {
    const sourceNode = nodesMap.get(link.source);
    const targetNode = nodesMap.get(link.destination);

    if (!sourceNode || !targetNode) {
      console.error(`Link has undefined node: ${JSON.stringify(link)}`);
      return;
    }

    sourceNode.children.push(targetNode);
  });
  

 
  // const destinationSet = new Set(graph.links.map(link => link.destination));
  const forest = [...nodesMap.values()].filter(node => (node.name));

  const allNodes = [...nodesMap.values()];
  const includedNodes = new Set(forest.flatMap(node => [node, ...node.children]));
  const disconnectedNodes = allNodes.filter(node => !includedNodes.has(node));

  // Combine the forest and disconnected nodes
  const completeTree = [...forest, ...disconnectedNodes];

  return completeTree;
};

const generateNodeContent = (nodeName, datasets, tasks) => {
  let content = "";

  // Header section
  content += "<div class='lg-panel-header tltip tooltipHeading'>";
  content += `<a data-type='tooltip' data-tagname='${nodeName}' id='${nodeName}_jobs' href='#' class='
   jobstooltiptrigger'>`;
  content += `<span class='lg-panel-title'>${icon_job} ${nodeName}</span>`;
  content += "</a></div>";

  // Check if node has tasks
  if (tasks && tasks.length > 0) {
    // Body section
    content += "<div class='lg-panel-body'>";
    content += "<div class='lg-list'>";
    content += "<div class='lg-list__heading'>Tasks</div>";

    // Iterate over each task and generate HTML content
    tasks.forEach((task) => {
      content += `<div class="lg-list__item tltip highlights ${task.task_name}">`;
      content += `<a href="#" data-type="task" data-tagname='${nodeName}' id="${task.task_id}_task" data-classname="${task.task_name}" class="highlights jobstooltiptrigger ${task.task_name}">`;
      content += `<div class="lg-list__label tooltipTask">${icon_task} <span>${task.task_name}</span></div>`;
      content += `<div class="lg-list__actions"><div class="lg-list__actions-left "><i class="upstream" data-name="${nodeName}">${icon_troubleshoot}</i><i class="downstream" data-name="${nodeName}">${icon_feature_search}</i></div><div class="lg-list__actions-right"><i class="rightpaneldetail">${icon_external_link}</i></div></div>`;
      content += `</a></div>`;
    });

    content += "</div>";
  

  // Check if node has datasets
  if (datasets && datasets.length > 0) {
    content += "<div class='lg-list'>";
    content += "<div class='lg-list__heading'>Datasets</div>";

    // Iterate over each dataset and generate HTML content
    datasets.forEach((dataset) => {
      if (dataset) {
        content += `<a href="#" data-type="dataset" data-tagname='${nodeName}' id="${dataset.pipeline_data_set_id}_dataset" data-classname="${dataset.pipeline_data_set_name}" class="highlights jobstooltiptrigger ${dataset.pipeline_data_set_name}">`;
        content += `<div class="lg-list__item tltip highlights ${dataset.pipeline_data_set_name}">`;
        content += `<div class="lg-list__label tooltipDataset">${icon_dataset_icon}&nbsp;${dataset.pipeline_data_set_name}</div>`;
        content += `</a></div>`;
      }
    });

    content += "</div>";
  }

  content += "</div>";
 }
  // Close the panel container
  // content += "</div>";

  return content;
};


const dataFromGraph = graphTree(graph);
console.log("dataFromGraph",dataFromGraph);
// const svg = d3.select("#outputContainerFlowchart svg");
// alert(JSON.stringify(graph.links))

 


// Count all jobs, tasks, and datasets
let jobCount = 0;
let taskCount = 0;
let datasetCount = Object.keys(graph.datasets).length;

for (const tasks of Object.values(graph.tasks)) {
  taskCount = Math.max(taskCount, tasks.length);
  jobCount++;
}

const nodeWidth = 200 + (jobCount * 10); 
const nodeHeight = 150 + (taskCount + datasetCount) * 100; 

// SVG dimensions based on node dimensions
const svgWidth = (nodeWidth + 50) * jobCount; 
const svgHeight = (nodeHeight + 150) * jobCount; 
// svg.attr("width", svgWidth).attr("height", svgHeight);

const width = 1200;
const height = 600;
const margin = { top: 20, right: 90, bottom: 30, left: 90 };

const svg = d3.select("#outputContainerFlowchart svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .call(d3.zoom().on("zoom", function () {
        svg.attr("transform", currentEvent.transform)
    }))
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

// const g = svg.append("g");


// Set up the tree layout with a vertical orientation
const treeLayout = d3.tree().size([svgWidth, svgHeight]);
// const treeLayout = d3.tree().size([width, height]);

// Create the lineage graph
// const lineageGraph = svg.append("g").attr("transform", "translate(50, 50)");
const lineageGraph = svg.append("g").attr("transform", "translate(50, 50)");

lineageGraph.append("defs").append("marker")
                .attr("id", "arrow")
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                        .attr("d", "M0,-5L10,0L0,5")
                        .style("fill","#CED4DA");

                        lineageGraph.append("defs").append("marker")
                        .attr("id", "upstreamarrow")
                        .attr("viewBox", "0 -5 10 10")
                        .attr("refX", 0)
                        .attr("refY", 0)
                        .attr("markerWidth", 5)
                        .attr("markerHeight", 5)
                        .attr("orient", "auto")
                        .append("svg:path")
                                .attr("d", "M0,-5L10,0L0,5")
                                .style("fill","#007BFF");

                        lineageGraph.append("defs").append("marker")
                                .attr("id", "downstreamarrow")
                                .attr("viewBox", "0 -5 10 10")
                                .attr("refX", 0)
                                .attr("refY", 0)
                                .attr("markerWidth", 5)
                                .attr("markerHeight", 5)
                                .attr("orient", "auto")
                                .append("svg:path")
                                        .attr("d", "M0,-5L10,0L0,5")
                                        .style("fill","#F64E60");
                
        

// Create a hierarchy from the data
let root
if (dataFromGraph) {
  root = d3.hierarchy(dataFromGraph);
} else {
  console.error("dataFromGraph is undefined or null.");
  return;
}

// Generate the tree structure
const treeData = treeLayout(root);
console.log("treeData",treeData);

// Define a variable to indicate whether the current node should be positioned above or below
let isAbove = true; 

// Render nodes with HTML content
const nodes = lineageGraph.selectAll(".node")
.data(treeData.descendants())
.enter()
.append("g")
.attr("class", (d) => {return d.data.name})
.attr("transform", (d) => {
// Adjust the y-coordinate based on whether the node should be above or below
const y = isAbove ? svgHeight * 0.075 : svgHeight * 0.025; 
// const y = isAbove ? height * 0.075 : height * 0.025; 
isAbove = !isAbove; 
return `translate(${d.y}, ${y})`;
});

const horizontalSpacing = 200; 
const verticalSpacing = 100;   

nodes.append("foreignObject")
  .attr("class", "nodegroup")
  .attr("width", nodeWidth)
  .attr("height", nodeHeight)
  .attr("tooltipx", (d, i) => d.x + (i % 2) * horizontalSpacing) 
  .attr("tooltipy", (d, i) => d.y + Math.floor(i / 2) * (nodeHeight + verticalSpacing)) 
  .attr("x", (d, i) => d.x + (i % 2) * horizontalSpacing)
  .attr("y", (d, i) => d.y + Math.floor(i / 2) * (nodeHeight + verticalSpacing)) 
  .html(d => {
    return `
      ${d.data.map(item => `<div class='lg-panel' style="margin-bottom: 10px;">${item.content}</div>`).join('')}
    `;
});

nodes.append("foreignObject")
        .attr("id", "jobstooltip")
       // .attr("width", 480)
       // .attr("height", 500)
       .append("xhtml:div")
       .style("font", "14px 'Helvetica Neue'")
       .html(`<span  class="lg-tooltip">
       <div class="lg-tooltip__header">
         <p><label>Pipeline:</label>Job2</p>
         <p><label>Run Status:</label>
           <i class="status-icon">
             <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
               <path id="Path_8127" data-name="Path 8127" d="M0,0H24V24H0Z" fill="none" />
               <path id="Path_8128" data-name="Path 8128"
                 d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
                 fill="#ff4934" />
             </svg>
           </i>
           Failed
         </p>
         <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
       </div>
     </span>`);
   
   
     nodes.append("foreignObject")
        .attr("id", "tasktooltip")
       // .attr("width", 480)
       // .attr("height", 500)
       .append("xhtml:div")
       .style("font", "14px 'Helvetica Neue'")
       .html(`  <div id="tooltipTask" class="lg-tooltip">
       <div class="lg-tooltip__header">
         <p><label>Task:</label>taskname1</p>
         <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
       </div>
       <div class="lg-tooltip__body">
         <div class="lg-tooltip__section">
           <dl>
             <dt>Read from</dt>
             <dd>
               <i>
                 <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                   <g id="icon_datasouce" transform="translate(-8523 726)">
                     <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                       transform="translate(8523 -726)" fill="none" />
                     <path id="database-solid"
                       d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                       transform="translate(8527 -723)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               SAP - Daily Global Sales Extract
             </dd>
           </dl>
           <dl>
             <dt>Write to</dt>
             <dd>
               <i>
                 <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                   <g id="icon_datasouce" transform="translate(-8523 726)">
                     <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                       transform="translate(8523 -726)" fill="none" />
                     <path id="database-solid"
                       d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                       transform="translate(8527 -723)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               S3 - Raw Daily Global Sales Extract
             </dd>
           </dl>
         </div>
       </div>
     </div>`);
   
   
     nodes.append("foreignObject")
        .attr("id", "datsettooltip")
       // .attr("width", 480)
       // .attr("height", 500)
       .append("xhtml:div")
       .style("font", "14px 'Helvetica Neue'")
       .html(` <span id="tooltipDataset" class="lg-tooltip">
       <div class="lg-tooltip__header">
         <p><label>Dataset:</label>Dataset1</p>
       </div>
       <div class="lg-tooltip__body">
         <div class="lg-tooltip__section">
           <dl>
             <dt>Read tasks</dt>
             <dd>
               <i>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                   viewBox="0 0 24 24">
                   <defs>
                     <clipPath id="clip-path">
                       <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                         fill="#fff" />
                     </clipPath>
                   </defs>
                   <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                     <path id="Path_8121" data-name="Path 8121"
                       d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                       transform="translate(1 1)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               load_prior_day_na_sales_to_databricks_bronze
             </dd>
           </dl>
           <dl>
             <dt>Written Tasks</dt>
             <dd>
               <i>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                   viewBox="0 0 24 24">
                   <defs>
                     <clipPath id="clip-path">
                       <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                         fill="#fff" />
                     </clipPath>
                   </defs>
                   <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                     <path id="Path_8121" data-name="Path 8121"
                       d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                       transform="translate(1 1)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               extract_regional_sales_to_s3
             </dd>
           </dl>
         </div>
         <div class="lg-tooltip__section">
           <dl>
             <dt>1 Missing Operation</dt>
             <dd>
               <i>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
                   viewBox="0 0 24 24">
                   <defs>
                     <clipPath id="clip-path">
                       <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                         fill="#fff" />
                     </clipPath>
                   </defs>
                   <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
                     <path id="Path_8121" data-name="Path 8121"
                       d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                       transform="translate(1 1)" fill="#898989" />
                   </g>
                 </svg>
               </i>
               Data wasn't written by task <strong>extract_regional_sales_to_s3</strong>
             </dd>
           </dl>
         </div>
       </div>
     </span>`);

  $(document).on('click', '.rightpaneldetail', function () {
   $(".lineage-graph-sidebar").addClass("show")
  });

  
  $(document).on('click', '.upstream', function () {

    let  flag = 0;
    var $paths = $('path.lineage'); // Get all paths
    for (var i=0;i<$paths.length;i++){ // Iterate through each one
        var $path =$($paths[i]); // This gets a single path
        if($path.attr("source") === $(this).attr("data-name")){
          flag = 1;
        }
        if(flag ===0) {
          $path.attr("stroke","#007BFF")
        }else {
          $path.attr("stroke","#CED4DA")
        }
    }
   });

   $(document).on('click', '.downstream', function () {
    let  flag = 0;
    var $paths = $('path.lineage'); // Get all paths
    for (var i=0;i<$paths.length;i++){ // Iterate through each one
        var $path =$($paths[i]); // This gets a single path
        if($path.attr("source") === $(this).attr("data-name") && flag!==0 ){
          flag = 1;
        }
        if(flag ===1) {
          $path.attr("stroke","#F64E60")
        }
      }
   }); 

$(document).on('mouseout', 'a.jobstooltiptrigger', function () {
   $("#jobstooltip,#tasktooltip,#datsettooltip").hide();
});




  $(document).on('mouseover', 'a.jobstooltiptrigger', function (event) {
    var elems = this;
    var tooltip = $("#" + elems.id).attr("data-type"); // will return the string "123"

    var tagname = $("#" + elems.id).attr("data-tagname"); // will return the string "123"

    let rect1 = lineageGraph.selectAll("."+tagname)
    let rect1x,rect1y;
    if (!rect1.empty()) {
      let rect1conordinates = rect1.attr("transform").replace("translate(", "").replace(")", "");
      rect1conordinates = rect1conordinates.split(",");
       rect1x = rect1conordinates[0];
       rect1y = rect1conordinates[1];
  } else {
      console.error('No elements found with class:', tagname);
  }

    //alert($("#" + elems.id).closest(".node"))

    let rect1height = $("."+tagname+" .nodegroup").attr("height");
    let rect1width = $("."+tagname+" .nodegroup").attr("width");

    var newposx = parseInt(rect1x)+parseInt(rect1width);
    var newposy = parseInt(rect1y)-(parseInt(rect1height)+parseInt(20));

    if(tooltip === "tooltip") {
           $("#tooltipHeader").show();
           $("#jobstooltip").show();
           $("#jobstooltip").attr("y", newposy);
           $("#jobstooltip").attr("x", newposx);
           $("#jobstooltip").attr("width", "200");
           $("#jobstooltip").attr("height", "200");
         }
         else if(tooltip === "task") {

          $("#tasktooltip").show();
           $("#tasktooltip").attr("y", newposy);
           $("#tasktooltip").attr("x", newposx);
           $("#tasktooltip").attr("width", "300");
           $("#tasktooltip").attr("height", "300");
          
         }
         else if(tooltip === "dataset") {
           $("#datsettooltip").show();
           $("#datsettooltip").attr("y", newposy);
           $("#datsettooltip").attr("x", newposx);
           $("#datsettooltip").attr("width", "300");
           $("#datsettooltip").attr("height", "300");
         }
         
       });
     
       for(let loop=0;loop<getalllinks.length;loop++){

        let rect1 = lineageGraph.selectAll("."+getalllinks[loop]['source'])
        let rect2 = lineageGraph.selectAll("."+getalllinks[loop]['destination'])
        let rect1x, rect1y
        if (!rect1.empty()) {
          let rect1conordinates = rect1.attr("transform").replace("translate(", "").replace(")", "");
          rect1conordinates = rect1conordinates.split(",");
          rect1x = rect1conordinates[0];
          rect1y = rect1conordinates[1];
      } else {
          console.error('No elements found for source:', getalllinks[loop]['source']);
      }
        let rect1height = $("."+getalllinks[loop]['source']+" .nodegroup").attr("height");
        let rect1width = $("."+getalllinks[loop]['source']+" .nodegroup").attr("width");
        let rect2x,rect2y
        if (!rect2.empty()) {
          let rect2conordinates = rect2.attr("transform").replace("translate(", "").replace(")", "");
          rect2conordinates = rect2conordinates.split(",");
          rect2x = rect2conordinates[0];
          rect2y = rect2conordinates[1];
      } else {
          console.error('No elements found for destination:', getalllinks[loop]['destination']);
      }
      
        let rect2height = $("."+getalllinks[loop]['source']+" .nodegroup").attr("height");

      let x1 = parseInt(rect1x) + parseInt(rect1width);
      let y1 =parseInt(rect1y) + parseInt(rect1height) / 2;
      let x2 = parseInt(rect2x);
      let y2 = parseInt(rect2y) + parseInt(rect2height) / 2;
      let midx  = ((x2 - x1) / 2) + x1
      //The data for our line
      let lineData = [{ "x": x1,   "y": y1},  
                      { "x": midx,  "y": y1}, 
                      { "x": midx,  "y": y2}, 
                      { "x": x2-10,  "y": y2}];
        let lineFunction =  d3.line()
        .x(function(d) { return d.x; })
        .y(function(d) { return d.y; })
        .curve(d3.curveBasis);  
      //The line SVG Path we draw
      lineageGraph.append("path")
                                  .attr("d", lineFunction(lineData))
                                  .attr("source",getalllinks[loop]['source'])
                                  .attr("destination",getalllinks[loop]['destination'])
                                  .attr("stroke", "#CED4DA")
                                  .attr("class", "lineage")
                                  .attr("stroke-width", 2)
                                  .attr("fill", "none")
                                  .attr("marker-end","url(#arrow)");  
      }
      }

    componentDidMount() {
      this.showGraph();
    }

    render() {
        return (<div id="outputContainerFlowchart" class="lineage-graph-container"><svg></svg></div>)
    }
}
export default PipelineLineage;