import _ from 'lodash';
import moment from 'moment';
import {iam_ip} from "./ip_details";
import CryptoJS from 'crypto-js';
import {Parser} from 'node-sql-parser';

import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {
    ATTRIBUTE,
    ATTRIBUTE_LEVEL_ALLOWED_ML_METRICS,
    ATTRIBUTE_LEVEL_MODEL_ERROR_DESCRIPTIONS,
    CHART_TITLE_METRIC_SEPARATOR,
    CHART_TITLE_SEPARATOR,
    chartTypes,
    CONTRIBUTION_KEY,
    DATA_CONSISTENCY,
    DATA_DRIFT,
    DATA_SET,
    DATA_SET_LEVEL_MODEL_ERROR_DESCRIPTIONS,
    DATA_TYPE_CONSISTENCY,
    DATA_TYPE_CONSISTENCY_HEADERS,
    DATASET_LEVEL_ALLOWED_ML_METRICS,
    DateParts,
    DEFAULT_CURRENCY_SYMBOL,
    DEFAULT_ERROR_PRIORITY,
    DEFAULT_METRIC_PRIORITY,
    DEFAULT_ML_METRIC_PRIORITY,
    DQ_CHART_POINTS,
    DQ_MONITOR_SECTIONS,
    DRIFT_PATTERN_TYPES,
    FEATURE_DRIFT,
    FEATURE_DRIFT_ALIAS_GREEN_TEXT,
    FEATURE_DRIFT_ALIAS_RED_TEXT,
    FEATURE_DRIFT_TEMPORAL,
    GENERIC_CONSTANTS,
    getMetaInformationAboutChart,
    IS_NONE_CHECK,
    LIGHT_COLOR_MAP,
    MAX_DATA_POINTS_FOR_LIST_VIEW,
    MEMORY_UNITS,
    METRIC_ALIAS_NAMES,
    metricType,
    MIN_MAX_SCALE,
    ML_ACCURACY_DRIFT,
    ML_BOX_PLOT_LIST,
    ML_DATA_CONSISTENCY,
    ML_FEATURE_KEYS,
    ML_METRIC,
    ML_METRIC_COLOR,
    ML_METRIC_ORDER,
    ML_REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART, MODEL_AB_COMPARE_SRC,
    MODEL_ACCURACY,
    MODEL_DRIFT,
    MODEL_EVALUATION,
    MODEL_MONITOR_PREVIEW_PAGE, MODEL_MONITORING,
    MODEL_PERFORMANCE_AS_MODEL_ERRORS,
    MODEL_PERFORMANCE_COMBINATION_CHART,
    MODEL_PERFORMANCE_DEFAULT_CHART_MAPPING,
    MODEL_PERFORMANCE_GROUPS,
    MODEL_PERFORMANCE_PREVIEW_PAGE,
    MODEL_PERFORMANCE_RESULT_GROUP_MAP,
    modelPerformance,
    MONITOR_PERF_LIST_VIEW,
    NO_DATA_SET_FOUND_TEXT,
    NO_ERROR_CHART_COLORS,
    NON_ERROR_DRIFT_PATTERNS,
    PROTOCOL,
    QualdoPlanCycle,
    QualdoPlanVersions,
    REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART,
    REQUIRES_FILTER,
    SERVING_DATA_DRIFT,
    STATIC_CARDS,
    K_RESULTS,
    COEFFICIENT_VALUES,
    OTHER_ACCURACY_METRIC,
    SUFFIXED_DATE, IAM_SECRET_KEY, DATA_SOURCE_OPTIONS,
    DOCUMENT_LINK_MAPPING,
    CUSTOM_ML_METRIC,
    DEFAULT_ML_METRICS,
    MULTI_LINE_COLORS,
    MODEL_RUNTIME_PROFILE,
    MODEL_EXPLAINABILITY,
    FEATURE_IMPORTANCE,
    MANDATE_RESET_PASSWORD,
    MODEL_TYPES,
    DOUBLE_COLON,
    LEFT_BRACKET,
    DOUBLE_QUOTES,
    RIGHT_BRACKET,
    PERMISSIONS_LOCAL_STORE_KEY
    } from './constant';
import store from "../redux/store";
import {setInitialValues, setMonitorInitialValues} from "../redux/actions";
import React from "react";
import NoErrorComponent from "../monitor/components/monitorNoResult";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import Load from "../components/loadAction";
import ChartFilterHeader from "../monitor/components/chartFilterHeader";
import {getDateObject} from "../charts/browser_utils";
import {
    attributeNameFromMappedValue,
    normalizeAttributeName,
    datasetNameFromMappedValue,
    METADATA_MAP_VALUE_SEPARATOR,
    METADATA_MAP_KEY_SEPARATOR,
    STRUCT_SEPARATOR,
    QUALDO_ATTRIBUTE_SEPARATOR,
    FQ_TOOLTIP_PLACEHOLDER,
    normalizeChildDatasetName,
    QUALDO_CHILD_DATASET_SEPARATOR
} from "./attribute_name_utils";
import { DATA_CONSISTENCY_METRICS } from '../metrics/metric_utils/constants';

const metricsNameKeyObj = {
    "completeness": "Data Completeness",
    "uniqueness": "Data Uniqueness",
    "timeliness": "Data Timeliness",
    "accuracy": "Data Accuracy",
    "integrity": "Data Integrity",
    "standardisation": "Data Consistency",
    "conformity": "Data Conformity",
    "feature": "Model Feature",
    "modelPrediction": "Model Prediction",
    "businessResponse": "Business response",
    "modelAccuracy": "Model Accuracy",
    "ml_model": "ML Model"
};

const cachedLocalData = {};

export const helpContent = {
"aws": ['Sign in to the AWS Management Console \n as an IAM user. ',
        'In the navigation bar on the upper \n right, choose your user name \n and then choose My Security Credentials.',
        'Choose AWS IAM credentials, Create\n access key and provide the same for \n Access Key.',
        "If configuring the files from the root folder, \n leave ‘Sub Path’ as empty. Don’t specify \n ‘/' or ‘*' in the 'Sub Path’.",
        'When prompted, choose Download .csv\n file or Show secret access key. This \n is your only opportunity to save your \n secret access key. Copy the secret access\n key somewhere handy. Provide the same for \n Secret Access Key.',"Choose appropriate Refresh Type.","In the Refresh Format field, provide the\n format string that will be used to identify the\n refreshed dataset. This is applicable for \n 'File/Folder Version' Refresh type.",
                "In Refresh Format Occurrence field, provide the\n occurrence of the refresh format string in the filename. \nThe default value is 'prefix'. "],
"athena":['Sign in to the AWS Management Console as an IAM user.',
            'Keep the required inputs like Athena catalog name, database, region, etc., ready.',
            'In the navigation bar on the upper right, choose your user name and then select "My Security Credentials".',
            'Choose AWS IAM credentials, create an access key, and provide the same for Access Key.',
            'When prompted, choose "Download .csv file" or "Show secret access key." This is your only opportunity to save your secret access key. Copy the secret access key somewhere secure. Provide this for the Secret Access Key.',
            'Ensure that the provided Secret Access Key and Access Key have valid permissions to access Athena as well as the S3 Output Location.',
            'In S3 Output Location, provide a valid S3 bucket URL. This will be used to store the query outputs.'],
"redshift": ["Ensure you have a running Redshift instance.",
                "Keep the required inputs like Redshift host URL/IP, Username, Password etc ready",
                "Please note that while entering URL/IP do not enter the port number.",
                "In the Incremental Data Identifier field, enter the attribute name which has to be tracked to identify the refreshed data. The attribute has to be in *timestamp* format.",
                "In Refresh Format Occurrence field, provide the\n occurrence of the refresh format string in the filename. \nThe default value is 'prefix'. "],
"big_query": ["Login to Google Cloud Platform console. In the left side navigation panel, click Service Accounts from IAM & Admin.",
                "Look for the service account you want to generate JSON key, click *three vertical dots under Actions in that row.",
                "Click Create Key and choose JSON in Key type.","Click Create to generate and download the JSON key file.","Provide BigQuery Admin role to the service account.",
                "In the \"Schema\" field enter the name of a Dataset in BigQuery.",
                "In the \"Dataset\" field enter the name of a table in BigQuery.",
                "In the Incremental Data Identifier field, enter the attribute name which has to be tracked to identify the refreshed data. The attribute has to be in *timestamp* format."],
"azure_blob": ["Navigate to the Azure portal","Locate your storage account",
                "Under Settings, select Access keys", "Find the Key value under key1",
                "Copy button to copy the account key", "Paste the key into blob secret. ",
                "If configuring the files from the root folder, \n leave ‘Sub Path’ as empty. Don’t specify \n ‘/' or ‘*' in the 'Sub Path’.",
                "Choose appropriate Refresh Type.","In the Refresh Format field, provide the\n format string that will be used to identify the\n refreshed dataset. This is applicable for \n 'File/Folder Version' Refresh type.",
                "In Refresh Format Occurrence field, provide the\n occurrence of the refresh format string in the filename. \nThe default value is 'prefix'. "],
"azure_synapse_ds": ["Sign in to the Azure portal and go to the Synapse service.",
                "For host url, go to your azure synapse analytics in azure portal. In the overview you can see “Dedicated SQL endpoint” copy that url.",
                "Keep the required inputs like \n host URL/IP, Username, \n Password etc ready.",
                "Please note that while entering URL/IP \n do not enter the port number.",
                "Also note that the given user has select permission to the table. If not, run this in your database: “grant select to Username”.",
                "In the Incremental Data Identifier field,\n enter the attribute name which has to be tracked\n to identify the refreshed data.\n The attribute has to be in *timestamp* format.\n"],
"azure_data_lake": ["Login to Azure Portal. In the sidenav,\n click Azure Active Directory",
              "From App registrations in Azure AD,\n select your application.",
              "Copy the Directory (tenant) ID and\n store it somewhere handy to input for ADL\n Tenant ID.",
              "Copy the Application ID and store \n it somewhere handy to input for ADL\n client ID.",
              "Select Certificates & secrets.",
              "If configuring the files from the root folder, \n leave ‘Sub Path’ as empty. Don’t specify \n ‘/' or ‘*' in the 'Sub Path’.",
              "Select Client secrets -> New client\n secret.",
              "Provide a description of the secret,\n and a duration.When done, select Add.",
              "After saving the client secret,\n the value of the client secret is \n displayed. Copy this value because you\n won’t be able to retrieve the key later.\n Provide the same for ADL Secret key. ",
              "Choose appropriate Refresh Type.","In the Refresh Format field, provide the\n format string that will be used to identify the\n refreshed dataset. This is applicable for \n 'File/Folder Version' Refresh type.",
                "In Refresh Format Occurrence field, provide the\n occurrence of the refresh format string in the filename. \nThe default value is 'prefix'. "],
"snowflake": ["Ensure you have ACCOUNTADMIN role \n privileges in Snowflake, OR privileges\n equivalent to the SECURITYADMIN \n and SYSADMIN roles.",
               "Keep the required inputs like \n Snowflake account URL, Username, \n Password etc ready.",
               'Please note that while entering URL,\n entering the http:// or https:// \n portion of the URL will prevent \n a successful connection.\n Example value is \n "xy12345.east-us-2.azure.snowflakecomputing.com".',
               "In the Incremental Data Identifier field,\n enter the attribute name which has to be tracked\n to identify the refreshed data.\n The attribute has to be in *timestamp* format.\n"],
"postgresql": ["Ensure you have a running Postgres \n instance.",
               "Keep the required inputs like \n Postgres host URL/IP, Username, \n Password etc ready",
               "Please note that while entering URL/IP \n do not enter the port number.",
               "In the Incremental Data Identifier field,\n enter the attribute name which has to be tracked\n to identify the refreshed data.\n The attribute has to be in *timestamp* format.\n"],
 "mysql": ["Ensure you have a running MySQL \n instance.",
           "Keep the required inputs like \n MySQL host URL/IP, Username, \n Password etc ready.",
           "Please note that while entering URL/IP \n do not enter the port number.",
           "In the Incremental Data Identifier field,\n enter the attribute name which has to be tracked\n to identify the refreshed data.\n The attribute has to be in *timestamp* format.\n"],
 "azure_sql": ["Ensure you have a running Azure \n SQLServer instance.",
               "Keep the required inputs like \n SQL Server host URL/IP, Username, \n Password etc ready.",
               "Please note that while entering URL/IP \n do not enter the port number.\n Example value is \n xy12345.database.windows.net",
               "In the Incremental Data Identifier field,\n enter the attribute name which has to be tracked\n to identify the refreshed data.\n The attribute has to be in *timestamp* format.\n"],
 "gcp_sql": ["Login to Google Cloud Platform console. In the left side navigation panel, click Service Accounts from IAM & Admin.",
                "Look for the service account you want to generate JSON key, click *three vertical dots under Actions in that row.",
                "Click Create Key and choose JSON in Key type.","Click Create to generate and download the JSON key file.",
                "Provide Storage Object Admin role to the service account.","If configuring the files from the root folder, leave ‘Sub Path’ as empty. Don’t specify ‘/' or ‘*' in the 'Sub Path’.",
                "Choose appropriate Refresh Type.","In the Refresh Format field, provide the format string that will be used to identify the refreshed dataset. This is applicable for 'File/Folder Version' Refresh type.",
                "In Refresh Format Occurrence field, provide the occurrence of the refresh format string in the filename. The default value is 'prefix'."
            ],
"gcs": ["Login to Google Cloud Platform console. In the left side navigation panel, click Service Accounts from IAM & Admin.",
        "Look for the service account you want to generate JSON key, click *three vertical dots under Actions in that row.",
        "Click Create Key and choose JSON in Key type.","Click Create to generate and download the JSON key file.",
        "Provide BigQuery Admin role to the service account.",
        "If configuring the files from the root folder, leave ‘Sub Path’ as empty. Don’t specify ‘/' or ‘*' in the 'Sub Path’.",
        "Choose appropriate Refresh Type.",
        "In the Refresh Format field, provide the format string that will be used to identify the refreshed dataset.This is applicable for 'File/Folder Version' Refresh type.",
        "In the Refresh Format Occurrence field, provide the occurrence of the refresh format string in the filename. The default value is 'prefix'. ",
],
"azure_databricks_table": [
                            'Login to Databricks Workspace.',
                            'To generate access token, go to "User Settings".  Click on "Access tokens". Click on "GENERATE NEW TOKEN".    Copy and save the token as this will not be visible again.  Use this token as input for "Databricks Access token".  You can also use a previously generated access token.',
                            'To add Databricks Cluster as a Datasource:',
                            ' a. Click on "Compute" in the sidebar.  Select the required cluster.',
                            ' b. Click on "Advanced options".  Select JDBC/ODBC.',
                            ' c. Copy and save the details in "Server Hostname".  This will be used as the input for "Databricks URL".',
                            ' d. Copy and save the details in "HTTP Path" .  This will be used as input for "Databricks HTTP Path".',
                            'To add SQL Warehouse as a Datasource:',
                            ' a. Click on "SQL Warehouses" in the sidebar.  Select the required warehouse.',
                            ' b. Click on "Connection details".',
                            ' c. Copy and save the details in "Server Hostname".  This will be used as the input for "Databricks URL".',
                            ' d. Copy and save the details in "HTTP Path" .  This will be used as input for "Databricks HTTP Path".',
                            'Click on Data in sidebar to get Catalog/Schema/Table details.',
                            ''
                        ]
}
export const helpContentPipelineSources = {
    "synapse": ["Sign in to the Azure portal and go to the Azure Active Directory.",
        "From Manage, select App registrations > New registration else If you already have an application , select your application.",
        "From the Overview of your app, copy the Application (client) ID, Directory (tenant) ID and save it for later use.",
        "select Certificates & secrets.",
        "Under Client secrets, click on New client secret and then on Add. Choose the secret expiration date which can be up to 2 years",
        "Copy the value of Client Secret and save it for later use.",
        "Go to the synapse workspace. Left side  you can see “Manage” click on that.",
        "Under the security you can see “Access control” click on that. There you can see Add click on that.",
        "Select role “Synapse Monitoring Operator” and in Select user type your application name and click on that. And click the Apply button.",
        "For workspace url, go to your azure synapse analytics in azure portal. In the overview you can see “Development endpoint” copy that url."],
    // "airflow":["Provide valid apache airflow url , username & password.Make sure that your airflow url is in running state.",
    //             "For Airflow username give ADMIN or USER role username.",
    //             "Change airflow configuration files content for airflow api access",
    //             "Under airflow directory change,  Inside webserver_config.py change AUTH_ROLE_PUBLIC = 'Admin'",
    //             "For authenticating airflow api's , need airflow configuration file auth_backend changes. Change auth_backend=airflow.api.auth.backend.default to auth_backend=airflow.api.auth.backend.basic_auth in airflow.cfg file.",
    //     ],
    "airflow":[
       'Prerequisites to connect Apache Airflow:\n 1.Provide valid apache airflow url , username & password. Make sure that your airflow url is in running state.',
       'For Airflow username give ADMIN or USER role username.',
       `Make sure these configurations are set as the following:\n
       Change airflow configuration files content for airflow api access\n
       1. Under airflow directory change,  Inside webserver_config.py change AUTH_ROLE_PUBLIC = 'Admin'
       2. For authenticating airflow api's , need airflow configuration file auth_backend changes. In airflow.cfg file change [api]
       <b>auth_backend= airflow.api.auth.backend.default to auth_backend= airflow.api.auth.backend.basic_auth</b>
       3. For Airflow version 2.3.0 and above ,in airflow.cfg file change [api]
       auth_backends= airflow.api.auth.backend.default to auth_backends = airflow.api.auth.backend.basic_auth,airflow.api.auth.backend.session
       \n Note: In Airflow version < 2.3.0 this setting was auth_backend and allowed only one value. In airflow versions 2.3.0 and above it was changed to auth_backends to support multiple backends that are tried in turn.
       \n Note : In airflow.cfg file change [core]
       \n load_examples = True to load_examples = False
       \nTo avoid default dags inside airflow.
       `
    ],
    "glue":[
    "Sign in to the AWS Management Console as an IAM user.",
    "In the navigation bar on the upper right, choose your user name and then choose My Security Credentials.",
    "Choose AWS IAM credentials, Create access key and provide the same for Access Key.",
    "When prompted, choose Download .csv file or Show secret access key. This is your only opportunity to save your secret access key. Copy the secret access key somewhere handy. Provide the same for Secret Access Key.",
    "Provide the access key and secret access key, and specify the appropriate AWS region.",
    "Make sure Qualdo's IP: 172.190.224.58 is added to your authorized network(s)."
    ],

    "adf":[
    'Sign in to the Azure portal and go to the Azure Active Directory.',
    'Under Manage in the left hand side panel, select App registrations.  Select your application from the list displayed.  You can also create a "New registration" and select the newly registered application.  Make sure that the selected application has "Supported Account Type" enabled for "Accounts in any organizational directory - Multi-tenant"',
    'Copy the Application (client) ID and store it somewhere handy to input for<client_id>',
    'Copy the Directory (tenant) ID and store it somewhere handy to input for <tenant_id>',
    'Under Manage, in the left hand side panel, Select Certificates & secrets.',
    'Under Client secrets, click on New client secret to add a new secret.  The secret can have a validity period of up to two years.  Click on "Add"',
    'The value of Client Secret is displayed.  Copy the value of the Client Secret and save it for later use.  The client secret will not be retrievable later.  Provide the same as input for <client_secret>',
    'Search data factories in the azure portal search engine and select your data factory.',
    'Select Access control (IAM).Select Add > Add role assignment.Select Data Factory Contributor role then click next. in that click select member, then select application which is created above.then click review and assign.',
    'Go to overview copy the subscription id to input for <subscription_id></subscription_id>'
    ]
    }

export function list_comparison_without_order(set_1, set_2) {
    if (set_1.length !== set_2.length) {
        return false;
    }
    for (const a of set_1) if (!set_2.includes(a)) return false;

    return true;
}


export function roundOffResult(inputVal, decimalPoints=4){
    if (IS_NONE_CHECK.includes(inputVal) || inputVal === "") {
        return inputVal
    }

    try{
        if(inputVal % 1 !== 0) {
            return parseFloat(String(inputVal)).toFixed(decimalPoints);
        }
        else {
            return parseFloat(String(inputVal));
        }
    }
    catch {
        return inputVal
    }
}

export const toastObj = {
    "alert-success": {
        "className": "toast floating-top-toast toast-success",
        "icon": faCheckCircle,
        "heading": "Success!"
    },
    "alert-danger": {"className": "toast floating-top-toast toast-danger", "icon": faTimesCircle, "heading": "Error!"},
    "alert-info": {"className": "toast floating-top-toast toast-info", "icon": faTimesCircle, "heading": "Info!"},
    "alert-warning": {"className": "toast floating-top-toast toast-warning", "icon": faTimesCircle, "heading": "Warning!"}
};

export function getSelectedModel(dataSource, models) {
    let mlModels = [];
    for(let m in models) {
        let model = models[m];
        if(model.integration_id === dataSource.value) {
            mlModels.push({"label": model.model_name, "value": model.ml_model_id});
        }
    }
    if (mlModels.length === 0) {
        return null;
    }
    return mlModels;
}

export function getTrimmedData(data) {
    if (data === undefined || data === null) {
        return data;
    }

    if (typeof data !== "string"){
        return data;
    }

    return String(data).trim();
}

export function profileTableData(inputData, key) {
    let headers = ["Dataset Name", "Attribute Name", "Metric Name", "Date", "Check Name", "Value"];
    let finalOp = [];
    let selectedTabName = metricsNameKeyObj[key];
    for (let i of inputData) {
        let d = inputData[i];
        if (key === "relationship") {
            let metricsList = d['payload']['computed_measures']
            for (let j of metricsList) {
                let attribute = metricsList[j];
                let row = [];
                row.push({"value": d["data_set_name"], "type": "td"})
                row.push({"value": attribute["attribute_name"], "type": "td"});
                row.push({"value": attribute["measure_name"], "type": "td"});
                row.push({"value": d["payload_created_time"], "type": "td"});
                row.push({"value": attribute["check"], "type": "td"});
                row.push({"value": attribute["value"], "type": "td"});
                finalOp.push(row);
            }
        } else if (d["mas_name"] === selectedTabName) {
            let metricsList = d['payload']['computed_measures']
            for (let j in metricsList) {
                let attribute = metricsList[j];
                let row = [];
                row.push({"value": d["data_set_name"], "type": "td"})
                row.push({"value": attribute["attribute_name"], "type": "td"});
                row.push({"value": attribute["measure_name"], "type": "td"});
                row.push({"value": d["payload_created_time"], "type": "td"});
                row.push({"value": attribute["check"], "type": "td"});
                row.push({"value": attribute["value"], "type": "td"});
                finalOp.push(row);
            }
        }
    }

    let tableData = {"headers": headers, "data": finalOp};
    return tableData;
}

//function getRecentData(i, contribution) {
//    let d = contribution[i];
//    if(d.length > 0 || i === 0 ) {
//        return d;
//    } else {
//        let ans = getRecentData(i-1, contribution);
//        return ans;
//     }
// }


export function profileMlTableData(inputData, key) {
    let headers = ["Model Name","Dataset Name", "Attribute Name", "Date", "Value"];
    let finalOp = [];
    for (let i in inputData) {
        let d = inputData[i];
        let metricsList = d["payload"]["computed_measures"];
        for (let j in metricsList) {
            let attribute = metricsList[j];
            let row = [];
            row.push({"value": d["model_name"], "type": "td"});
            row.push({"value": d["data_set_name"], "type": "td"});
            row.push({"value": attribute["attribute_name"], "type": "td"});
            row.push({"value": d["payload_created_time"], "type": "td"});
            //row.push({"value": attribute["check"], "type": "td"});
            row.push({"value": attribute["value"], "type": "td"});
            finalOp.push(row);
        }
    }

    let tableData = {"headers": headers, "data": finalOp};
    return tableData;
}

export function chartFilterTable(inputData) {
    let headers = ["Date", "Attribute Name", " Contribution"];
    let finalOp = [];
    let time = inputData["time"];
    let contribution = inputData["contribution"];
    if (time === undefined || contribution === undefined) {
        return {"headers": headers, "data": finalOp};
    }

    let contributionCount = contribution.length;
    if (time.length === 0 || contributionCount === 0) {
        return {"headers": headers, "data": finalOp};
    }

    let metricName = inputData.name;
    let showDataTypeDifferences = (metricName === DATA_TYPE_CONSISTENCY ||
        metricName === ML_DATA_CONSISTENCY);
    if (showDataTypeDifferences){
        headers = DATA_TYPE_CONSISTENCY_HEADERS[metricName];
    }

    let recent_time = time[time.length - 1];

    for (const contribute_data of contribution) {
        if (contribute_data === undefined || contribute_data === null) {
            continue;
        }

        let timeValue;
        if (contribute_data["time"] === undefined) {
            timeValue = recent_time;
        } else {
            timeValue = contribute_data["time"];
        }

        let reqAttributeName = contribute_data["attribute_name"];
        reqAttributeName = normalizeAttributeName(reqAttributeName);

        let row = [];
        if (showDataTypeDifferences) {
            let targetDS = contribute_data.target_data_set_name;
            if (targetDS === undefined || targetDS === null) {
                continue;
            }

            let matchColumn = contribute_data.match_column;
            if (matchColumn === undefined || matchColumn === null) {
                matchColumn = `<Not Found>`;
            }

            row.push({"value": timeValue, "type": "td"});

            row.push({"value": reqAttributeName, "type": "td"});
            row.push({"value": contribute_data.data_type, "type": "td"});
            let referenceDataType = contribute_data.reference_data_type;
            if (referenceDataType === undefined || referenceDataType === null){
                referenceDataType = `<NOT_FOUND>`;
            }

            row.push({"value": referenceDataType, "type": "td"});
        } else {
            row.push({"value": timeValue, "type": "td"});
            row.push({"value": reqAttributeName, "type": "td"});
            row.push({"value": contribute_data["value"].toFixed(2), "type": "td"});
        }

        finalOp.push(row);
    }

    let tableData = {"headers": headers, "data": finalOp};
    return tableData;
}

export function updateChartDataErrorType(r) {
    let driftLevels = r["drift_level"];
    let type = false;
    if (driftLevels !== undefined) {
        type = driftLevels.every(v => v === "NO_DRIFT");
    }

    if (type) {
        r["chart_head"] = "No Errors";
        r["type"] = "success";
    }
    else {
        let driftPatterns = r["drift_pattern"];
        let charHeadText;
        if (driftPatterns !== undefined) {
            // let drifts = driftPatterns.filter(v => v !== "not_computed");
            let drifts = driftPatterns.filter(v => v === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT);
            let driftsFound = drifts.length > 0;
            if (driftsFound) {
                charHeadText = "Found " + drifts[drifts.length - 1] + " Drift";
            }
            else {
                charHeadText = "Found Data Errors";
                r["chart_head"] = "No Errors";
                r["type"] = "success";
                return;
            }
        } else {
            charHeadText = "Found Model Errors";
        }

        r["chart_head"] = charHeadText;
        r["type"] = "error";
    }
}

export function updateChartData(data, dataSource=null, computeTitle=true) {
    let values = data.map(function (r) {
        if (computeTitle) {
            r["chartTitle"] = getChartTitleBasedOnData(r, dataSource);
        }
        updateChartDataErrorType(r);
        return r;
    });
    return values;
}

export function getSortedBasedOnError(data) {
    return data.sort(function (a, b) {
        const chartTypeA = a["type"];
        const chartTypeB = b["type"];
        if (chartTypeA === "success" && chartTypeB !== "success") {
            // B result should be listed first . BEFORE A. So return 1
            return 1;
        }
        else if (chartTypeA !== "success" && chartTypeB === "success") {
            // A result should be listed first. BEFORE B. So return -1
            return -1;
        }
        return 0;
    });
}

export function getModelPerformanceCharts(data) {
    let timeList = [];
    let valueList = [];
    let drift_patterns = [];

    // Error list contains error history for the particular model performance chart
    let errorList = [];

    let dataLength = data.length;
    if (dataLength === 0) {
        return {
            "time": timeList,
            "drift": valueList,
            "drift_patterns": drift_patterns
        };
    }

    let firstData = data[0];

    let multipleComponents = [];
    let componentsLength = firstData.components;
    if (componentsLength !== undefined) {
        for (let i = 1; i < componentsLength; i++) {
            multipleComponents.push([]);
        }
    }

    for (let i = 0; i < dataLength; i++) {
        let datum = data[i];

        let x_value;
        x_value = datum.payload_created_time;
        let _payload = datum.payload;
        if (_payload.feature_imp_val !== undefined){
            valueList.push(_payload.feature_imp_val);
        }else{
            valueList.push(_payload.value);
        }
        let current_drift_pattern = _payload.drift_pattern;

        if (current_drift_pattern === undefined || current_drift_pattern === null){
            current_drift_pattern = DRIFT_PATTERN_TYPES.NO_DRIFT;
        }

        let thresholdErrorData = _payload.error;
        if (thresholdErrorData !== undefined && thresholdErrorData !== null) {
            current_drift_pattern = DRIFT_PATTERN_TYPES.THRESHOLD_ALERT;
        }

        drift_patterns.push(current_drift_pattern);
        errorList.push(_payload.error);
        timeList.push(x_value);

        if (componentsLength !== undefined) {
            for (let i = 0; i < multipleComponents.length; i++) {
                let y_val_i = _payload[`value_${i + 1}`]
                multipleComponents[i].push(y_val_i);
            }
        }
    }

    let pointsData = {
        "time": timeList,
        "drift": valueList,
        "errorList": errorList,
        "drift_patterns": drift_patterns
    };

    if (componentsLength !== undefined) {
         for (let i = 0; i < multipleComponents.length; i++) {
            pointsData[`y${i + 1}`] = multipleComponents[i];
        }
    }
    return pointsData;
}
export function getModelMultipleLineData(chartData){
    let data = chartData.drift;
    let line1 = [];
    let line2 = [];
    let line3 = [];

    for (let i = 0; i < data.length; i++){
        line1.push(data[i][0]);
        line2.push(data[i][1]);
        line3.push(data[i][2]);
    }
    let lineData = {"line1": line1, "line2": line2, "line3": line3};
    return lineData;
}

export function getModelPerformanceChartType(chartData, chartName) {
    let chartType = undefined;
    let values = chartData.drift;
    if (values === undefined || values === null) {
        return chartType;
    }

    let totalValues = values.length;
    let totalZeroValues = values.filter(x => x === 0).length;
    if (totalValues === totalZeroValues) {
        chartType = `areaChart`;
    } else{
        chartType = MODEL_PERFORMANCE_DEFAULT_CHART_MAPPING[chartName];
    }

    return chartType;
}


export function getGroupedData(fullScreenData, selectedMLModel, ignoreModelIDFilter=true) {
    const emptyGroupedData = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
    let selectedMLModelId = null;
    if (selectedMLModel !== undefined && selectedMLModel !== null) {
        selectedMLModelId = selectedMLModel['value'];
    }

    if (ignoreModelIDFilter && selectedMLModelId === null) {
        return emptyGroupedData;
    }

    if (fullScreenData === undefined || fullScreenData === null) {
        return emptyGroupedData;
    }

    let groupedData = {};
    let mappedData = {};
    for (let data of fullScreenData) {
        let mlModelId = data.ml_model_id;
        if (ignoreModelIDFilter && String(selectedMLModelId) !== String(mlModelId)) {
            continue;
        }

        // let modelName = this.props.monitorModule.mlModelMapping[mlModelId];
        let reqGroup = mappedData[mlModelId];
        if (reqGroup === undefined) {
            // Create a new group for the current ML Model
            reqGroup = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
            for (let _key of Object.keys(reqGroup)) {
                let value = reqGroup[_key];
                groupedData[`${_key}_${mlModelId}`] = value;
                // value['header'] = `${value['header']} - ${modelName}`;
                value['header'] = `${value['header']}`;
            }

            mappedData[mlModelId] = reqGroup;
        }

        let performanceMetricName = data.name;
        let groupName = MODEL_PERFORMANCE_RESULT_GROUP_MAP[performanceMetricName];
        if (groupName === undefined) {
            groupName = MODEL_ACCURACY
        }

        let group = reqGroup[groupName];
        if (group === undefined) {
            continue;
        }

        group.data.push(data);
    }

    if (Object.keys(groupedData).length === 0) {
        return emptyGroupedData;
    }

    return groupedData;
}

export function reorderModelPerformanceResults(accuracyResults){
     if (accuracyResults.length > 0) {
         let k_results = accuracyResults.filter(chart =>  K_RESULTS.includes(chart.name))
         let cc_charts = accuracyResults.filter(chart =>  COEFFICIENT_VALUES.includes(chart.name))
         let other_charts = accuracyResults.filter(chart =>  OTHER_ACCURACY_METRIC.includes(chart.name))
         let result = k_results.concat(other_charts).concat(cc_charts);

         if (accuracyResults.length > result.length){
             let recommendation_charts = K_RESULTS.concat(COEFFICIENT_VALUES).concat(OTHER_ACCURACY_METRIC);
             let otherModelMetrics = accuracyResults.filter(chart =>  !recommendation_charts.includes(chart.name))
              result = result.concat(otherModelMetrics)
         }
         return result;
     } else {
        return accuracyResults
     }
}

export function getGroupedDataListView(selectedMetrics, fullScreenData, selectedMLModel, ignoreModelIDFilter=true) {
    const emptyGroupedData = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
    if (fullScreenData === undefined || fullScreenData === null) {
        return emptyGroupedData;
    }

    let groupedData = {};
    let mappedData = {};
    let reqGroup = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
    for (let _key of Object.keys(reqGroup)) {
                let value = reqGroup[_key];
                groupedData[`${_key}`] = value;
                value['header'] = `${value['header']}`;
            }
    for (let data of fullScreenData) {
        let mlModelId = data.ml_model_id;

        let filtered = selectedMLModel.filter(x=>Number(x.value) === Number(mlModelId))
        if (filtered.length === 0) {
            continue;
        }


        if(selectedMetrics.length !== 0){
          let filteredMetrics = selectedMetrics.filter(x=>(x.label.toLowerCase() === data.name.toLowerCase() || x.value === data.name || x.value === data.filter_name))
          if(filteredMetrics.length === 0) {
            continue;
          }
        }

        mappedData[mlModelId] = reqGroup;

        let performanceMetricName = data.name;
        let groupName = MODEL_PERFORMANCE_RESULT_GROUP_MAP[performanceMetricName];
        if (groupName === undefined) {
            groupName = MODEL_ACCURACY
        }

        let group = reqGroup[groupName];
        if (group === undefined) {
            continue;
        }

        group.data.push(data);
    }

    if (Object.keys(groupedData).length === 0) {
        return emptyGroupedData;
    }

    if (groupedData[MODEL_ACCURACY] !== undefined &&
        groupedData[MODEL_ACCURACY].data !== undefined  &&
        groupedData[MODEL_ACCURACY].data.length > 0 ){
        // reorder charts
        let accuracyResults = reorderModelPerformanceResults(groupedData[MODEL_ACCURACY].data);
        groupedData[MODEL_ACCURACY].data = accuracyResults;
    }

    return groupedData;
}


function fill_empty_with_zero(expected_labels, actual_labels, values){
    let final = []
    let empty_rows_index = []
    for (let a of values){
        for (let e of expected_labels){
            if (!actual_labels.includes(e)){
                a.splice(expected_labels.indexOf(e), 0, 0);
            }
        }
        final.push(a);
    }

    for(let e of expected_labels){
        if(!actual_labels.includes(e)){
            let new_row = new Array(expected_labels.length).fill(0);
            if (!empty_rows_index.includes(expected_labels.indexOf(e))) {
                final.splice(expected_labels.indexOf(e), 0, new_row)
                empty_rows_index.push(expected_labels.indexOf(e))
            }
        }
    }
    return final
}

function get_aggregated_confusion_matrix(label_values_final, res){
    let agg_matrix = [];
    let label_count = label_values_final.length;
    for(let i=0; i < label_count; i++) {
        let row = new Array(label_count).fill(0);
        agg_matrix.push(row)
    }

    for(let result of res){
        let values = result["payload"]["value"]
        values = fill_empty_with_zero(label_values_final, result["label_values"], values)
        for(let i=0; i < label_count; i++){
            for(let j=0; j < label_count; j++){
                try{
                    agg_matrix[i][j] = agg_matrix[i][j] + values[i][j]
                }
                catch{
                    agg_matrix[i][j] = agg_matrix[i][j] + 0
                }
            }
        }
    }

    return agg_matrix
}

export function setModelPerformanceTimeFilter(data, startDate, endDate){
    let all_details = data["all_details"];
    let selectedDate = startDate.format('YYYY-MM-DD');
    let selectedDate1 = endDate.format('YYYY-MM-DD');
    let filteredList = [];
    for(let d of all_details){
        let timeObj = new Date(d["payload_created_time"]);
        const parsedDate = moment(timeObj);
        if (parsedDate  >= selectedDate && parsedDate <=selectedDate1 ) {
            filteredList.push(d);
        }
    }
    if (filteredList.length > 0) {
        filteredList = [filteredList[filteredList.length -1]]
    }
    let label_values_final = [];
    for(let result of filteredList){
        let labels = result["label_values"];
        label_values_final.push(...labels);

    }
    let setFinalValue = new Set(label_values_final);
    let sortedList = Array.from(setFinalValue);
//    let sortedList = array.sort();

    if(sortedList.length > 0){
//        let first_confusion_mat = filteredList[0]
        let aggregated_res = get_aggregated_confusion_matrix(sortedList, filteredList)
        data["values"] = aggregated_res
        data["labels"] = sortedList
    } else {
        data = null;
    }
    this.setModelPerformanceConfusionTimeFilterChartData(data)
}


export function setModelPerformanceTimeFilterPreviewPage(data, startDate, endDate){
    let all_details = data["all_details"];
    let selectedDate = startDate.format('YYYY-MM-DD');
    let selectedDate1 = endDate.format('YYYY-MM-DD');
    let filteredList = [];
    for(let d of all_details){
        let timeObj = new Date(d["payload_created_time"]);
        // find latest
        const parsedDate = moment(timeObj).format('YYYY-MM-DD');
        if (parsedDate  >= selectedDate && parsedDate <=selectedDate1 ) {
            filteredList.push(d);
        }
    }

    if (filteredList.length > 0) {
        filteredList = [filteredList[filteredList.length -1]]
    }
    let label_values_final = [];
    for(let result of filteredList){
        let labels = result["label_values"];
        label_values_final.push(...labels);

    }
    let setFinalValue = new Set(label_values_final);
    let sortedList = Array.from(setFinalValue);
//    let sortedList = array.sort();

    if(sortedList.length > 0){
//        let first_confusion_mat = filteredList[0]
        let aggregated_res = get_aggregated_confusion_matrix(sortedList, filteredList)
        data["chartData"]["values"] = aggregated_res
        return data;
    } else {
        data = null;
        return data;
    }
}

export function getDatasetLevelFeatureImportance(allAttributes){
    let datasetLevelChart = {};
    let count = 0
    if (allAttributes.length > 0){
            let data =  allAttributes[0]
            let datasourceName = data["data_source_name"]
            let ml_model_id = data["ml_model_id"]
            let data_set_id = data["data_set_id"] !== undefined ? data["data_set_id"] : null
            datasetLevelChart = {
                    "chartTitle": "mean_shap_value",
                    "chartType": "horizontalBarChart",
                    "data_source_name": datasourceName,
                    "drift": [],
                    "data_set_id": data_set_id,
                    "attribute_list": [],
                    "drift_patterns": [],
                    "errorList": [],
                    "isError": false,
                    "isModelPerformance": true,
                    "key": ["mean_shap_value",ml_model_id, datasourceName].join("_"),
                    "label": "Model",
                    "chart_level":"dataset",
                    "ml_model_id": ml_model_id,
                    "name": "mean_shap_value",
                    "scale": "minMaxScale",
                    "time":[],
                    "dataOf":data.time[data.time.length-1],
                    "toolTipTitle": "mean_shap_value",
                    "toolTiptopHeader": "mean_shap_value",
                    "xValue": "Attributes",
                    "yValue": "mean_shap_value",
        }

        for (let chart of allAttributes){
            if (chart["name"] === "mean_shap_value"){
                count = count+1;
                let feature_imp_val = chart.drift[chart.drift.length -1];
                //let feature_imp_val = chart.drift[0];
                datasetLevelChart["drift"].push(feature_imp_val)
                datasetLevelChart["time"].push(chart["time"])
                datasetLevelChart["attribute_list"].push(chart["attribute_name"])
            }
        }
    }

    if (count > 0){
        return datasetLevelChart;
    } else {
        return {};
    }
}

export function getDatasetLevelShapValues(allAttributes){
    let datasetLevelChart = {};
    let count = 0
    if (allAttributes.length > 0){
            let data =  allAttributes[0]
            let ml_model_id = data["ml_model_id"]
            let data_set_id = data["payload"]["attribute_details"][0]["data_set_id"] !== undefined ? data["payload"]["attribute_details"][0]["data_set_id"] : null
            datasetLevelChart = {
                    "chartTitle": "mean_shap_value",
                    "chartType": "horizontalBarChart",
                    "drift": [],
                    "data_set_id": data_set_id,
                    "attribute_list": [],
                    "drift_patterns": [],
                    "errorList": [],
                    "isError": false,
                    "isModelPerformance": true,
                    "key": ["mean_shap_value",ml_model_id, "datasetLevel", data_set_id].join("_"),
                    "label": "Model",
                    "chart_level":"dataset",
                    "ml_model_id": ml_model_id,
                    "name": "mean_shap_value",
                    "scale": "minMaxScale",
                    "time":[],
                    "chartDataPoints":[],
                    "total":[],
                    "toolTipTitle": "mean_shap_value",
                    "toolTiptopHeader": "mean_shap_value",
                    "xValue": "Attributes",
                    "yValue": "mean_shap_value",
        }

        for (let dsResult of allAttributes){
            if (dsResult["metric_type"] === "mean_shap_value"){
                 let latestData = allAttributes[0]["payload"]["attribute_details"];
                 let dataset_id = allAttributes[0]["payload"]["data_set_id"]
                 let total = latestData.reduce(function (acc, obj) { return acc + obj.feature_imp_val; }, 0);

                count = count+1;
                datasetLevelChart["time"].push(dsResult["payload_created_time"])
                datasetLevelChart["chartDataPoints"].push(dsResult["payload"]["attribute_details"])
                datasetLevelChart["total"].push({"data_set_id":dataset_id, "total":total});
            }
        }
    }

    if (count > 0){
        return datasetLevelChart;
    } else {
        return {};
    }
}

export function getFeatureImportanceChartData(chartData, performanceName, modelName, key, ml_model_id){
    if(chartData["key"] === undefined){
        chartData["key"] = key+"_"+chartData["data_set_id"]+"_"+chartData["attribute_name"];
    }
    chartData["xValue"] = "Time";
    chartData["isError"] = false;
    chartData["yValue"] = performanceName.replaceAll("_"," ");
    chartData["toolTipTitle"] = performanceName;
    chartData["chartTitle"] = performanceName;
    let chartType = getModelPerformanceChartType(chartData, performanceName);
    if (chartType === undefined || chartType === null) {
        chartType = `areaChart`;
    } else {
        chartData["useFixedChart"] = true;
    }

    if(chartData["chartTitle"] === "mean_shap_value" && chartData["chart_level"]==="dataset"){
        chartType = "horizontalBarChart";
    }
    chartData["chartType"] = chartType;
    chartData["ml_model_id"] = ml_model_id;
    chartData["label"] = "Model";
    chartData["name"] = performanceName;
    chartData["scale"] = MIN_MAX_SCALE;
    chartData["isModelPerformance"] = true;
    chartData["data_source_name"] = modelName;
    chartData["toolTiptopHeader"] = METRIC_ALIAS_NAMES[performanceName];
    return chartData
}

export function addTrainingServingData(serving_data, training_data,type){
    let combinedChart =  null
    if (serving_data !== null && training_data !== null){
        let dataSetName = null;
        let data_set_name_2 = null;
        if (type === "serving"){
            let serving_dataset_name = [training_data["data_set_name"], "Latest"].join("-")
            dataSetName = [training_data["data_set_name"], "(" ,"Base dataset vs Latest Refresh", ")"].join(" ")
            data_set_name_2 = serving_dataset_name
        }
        else{
            dataSetName = [serving_data["data_set_name"], "vs",training_data["data_set_name"]].join(" ");
            data_set_name_2 = training_data["data_set_name"]
        }
        let serving_data_points = serving_data["chartDataPoints"]
        let training_data_points = training_data["chartDataPoints"]
        let latest_data_point_1 = serving_data["chartDataPoints"].slice(-1).pop();
        let latest_data_point_2 = training_data["chartDataPoints"].slice(-1).pop();
        let d1 = {"data_set_id":serving_data["data_set_id"], "data_set_name": serving_data["data_set_name"],
                    "data_points": latest_data_point_1}
        let d2 = {"data_set_id": training_data["data_set_id"], "data_set_name": data_set_name_2,
                   "data_points": latest_data_point_2}
        combinedChart = {
            "chartTitle": "mean_shap_value",
            "chartType": "groupedHorizontalBarChart",
            "drift": [],
            "data_source_name": serving_data["data_source_name"],
            "data_set_id_1": serving_data["data_set_id"],
            "data_set_name_1": serving_data["data_set_name"],
            "data_set_id_2": training_data["data_set_id"],
            "data_set_name_2": training_data["data_set_name"],
            "data_set_1_type": serving_data["data_set_type"],
            "data_set_2_type": training_data["data_set_type"],
            "dataSetName": dataSetName,
            "drift_patterns": [],
            "isError": false,
            "isModelPerformance": true,
            "key": ["mean_shap_value",serving_data["ml_model_id"], serving_data["data_set_id"],"vs",training_data["data_set_id"], serving_data["data_source_name"]].join("_"),
            "label": "Model",
            "chart_level": "dataset",
            "ml_model_id": serving_data["ml_model_id"],
            "name": "mean_shap_value",
            "scale": MIN_MAX_SCALE,
            "toolTipTitle": "mean_shap_value",
            "toolTiptopHeader": "Feature Importance",
            "xValue": "Time",
            "yValue": "Feature Contribution Value",
            "useFixedChart": true,
            "time": [],
            "data_set_1_time": serving_data["time"],
            "data_set_2_time": training_data["time"],
            "chartDataPoints_1": serving_data_points,
            "chartDataPoints_2": training_data_points,
            "chartDataPoints": [d1,d2]
            }
   }
    return combinedChart;
}

export function getPerformanceChartData(performanceResults, modelMapping,
                                        ignoreCombinedCharts=false, mappedDatasetInfo=null, fullModelInfo=null) {
    /*
     * Gets performance chart data in usable (for drawing chart) format
     *
     * performanceResults: Result list from API
     * modelMapping: Key value pair in dictionary. Key is model id and Value is model name
     * ignoreCombinedCharts: Boolean. If it is true, we will not create combined chart like
     * 'precision vs recall' or 'sensitivity vs specificity'
     *
     */
    let chartValues = [];
    let tempResult = {};

    if (performanceResults === undefined || performanceResults === null) {
        return chartValues;
    }
    let datasetMapping = null
    if(mappedDatasetInfo !== null){
        datasetMapping = mappedDatasetInfo["idDatasetDetail"]
    }
    // This will have the combined charts of all the models
    let combinedChartOfAllModels = {};


    for (let k = 0; k < performanceResults.length; k++) {
        let row = performanceResults[k];
        const performanceName = row["performance_metric_name"];
        const ml_model_id = row["ml_model_id"];

        let modelName = modelMapping[ml_model_id];
        if(modelName === undefined)
        {
            continue;
        }
        let modelRes = tempResult[ml_model_id];
        if (modelRes === undefined) {
            modelRes = { "needSorting" : false , "charts" : [] };
            tempResult[ml_model_id] = modelRes;
        }

        if (performanceName === modelPerformance.SILHOUETTE_SCORE){
            modelRes["needSorting"] = true;
        }

        let reqCombinedCharts = combinedChartOfAllModels[ml_model_id];

        if (ignoreCombinedCharts) {
            reqCombinedCharts = [];
        } else {
            if (reqCombinedCharts === undefined){
                reqCombinedCharts = _.cloneDeep(MODEL_PERFORMANCE_COMBINATION_CHART);
                combinedChartOfAllModels[ml_model_id] = reqCombinedCharts;
            }
        }

        let key = `${getNormalizedName(performanceName)}_${ml_model_id}`;

        let perfResults = row["results"];

        if (perfResults === undefined || perfResults === null) {
            continue;
        }

        if (perfResults.length === 0) {
            continue;
        }

        if (performanceName === modelPerformance.CONFUSION_MATRIX) {
            let latestConfusionMatrixRes = perfResults[perfResults.length - 1];
            let label_values = latestConfusionMatrixRes["label_values"];
            let confusionMatrix = latestConfusionMatrixRes["payload"]["value"];
            let confusionChartData = {
                "all_details": latestConfusionMatrixRes["all_details"],
                "values": confusionMatrix,
                "labels": label_values,
                "key": key,
                "chartTitle": performanceName,
                "chartType": chartTypes.CONFUSION_MATRIX,
                "ml_model_id": ml_model_id,
                "name": performanceName,
                "metric": performanceName,
                "data_source_name": modelName,
                "isModelPerformance": true,
                "toolTiptopHeader": performanceName,
                "label": "Model"
            }

            modelRes["charts"].push(confusionChartData);
            //chartValues.push(confusionChartData);
        } else if (performanceName === modelPerformance.ROC_CURVE) {
            let latestROC = perfResults[perfResults.length - 1];
            let payload = latestROC["payload"];
            let xAxis = payload["false_positive_rate"];
            let yAxis = payload["true_positive_rate"];

            let confusionChartData = {
                "time": xAxis,
                "drift": yAxis,
                "y1": xAxis,
                "key": key,
                "chartTitle": performanceName,
                "chartType": `areaChart`,
                "ml_model_id": ml_model_id,
                "name": performanceName,
                "isNonTimeSeries": true,
                "data_source_name": modelName,
                "toolTiptopHeader": METRIC_ALIAS_NAMES[performanceName],
                "isModelPerformance": true,
                "xValue": "Time",
                "yValue": "ROC Curve",
                "isError": false,
                "toolTipTitle": performanceName,
                "label": "Model"
            }

            modelRes["charts"].push(confusionChartData);
           //chartValues.push(confusionChartData);
        } else if (performanceName === modelPerformance.HOMOGENEITY_COMPLETENESS_V_MEASURE_SCORE) {
            let chartData = getModelPerformanceCharts(perfResults);
            chartData["lineData"] = getModelMultipleLineData(chartData);
            chartData["line1"] = chartData.lineData.line1;
            chartData["line2"] = chartData.lineData.line2;
            chartData["line3"] = chartData.lineData.line3;
            chartData["key"] = key;
            chartData["xValue"] = "Time";
            chartData["isError"] = false;
            chartData["yValue"] = performanceName;
            chartData["yValue1"] = "Homogeneity";
            chartData["yValue2"] = "Completeness";
            chartData["yValue3"] = "V-measure";
            chartData["toolTipTitle"] = performanceName;
            chartData["chartTitle"] = performanceName;
            let chartType = chartTypes.GROUPED_VERTICAL_BAR_CHART;
            chartData["useFixedChart"] = true;
            chartData["chartType"] = chartType;
            chartData["ml_model_id"] = ml_model_id;
            chartData["label"] = "Model";
            chartData["labels"] = ["Homogeneity","Completeness","V-measure"];
            chartData["name"] = performanceName;
            chartData["scale"] = MIN_MAX_SCALE;
            chartData["colorType"] = MULTI_LINE_COLORS;
            chartData["isModelPerformance"] = true;
            chartData["data_source_name"] = modelName;
            chartData["toolTiptopHeader"] = METRIC_ALIAS_NAMES[performanceName];
            chartData["labelText1Line1"] = chartData.label;
            chartData["labelText1Line2"] = chartData.label;
            chartData["labelText1Line3"] = chartData.label;
            chartData["labelValue1Line1"] = modelName;
            chartData["labelValue1Line2"] = modelName;
            chartData["labelValue1Line3"] = modelName;
            chartData["isMultiLine"] = true;
            chartData["isMultiBar"] = true;
            chartData["multipleLines"] = true;

            modelRes["charts"].push(chartData);
           // chartValues.push(chartData);
        } else if (MODEL_MONITOR_PREVIEW_PAGE.includes(performanceName)) {
            let latestResult = perfResults[perfResults.length - 1];
            let payload = latestResult["payload"];
            let xAxis = payload["time"];
            let yAxis = payload["value"];

            let modelMonitorChartData = {
                "time": xAxis,
                "drift": yAxis,
                "key": key,
                "chartTitle": performanceName,
                "chartType": `areaChart`,
                "ml_model_id": ml_model_id,
                "name": performanceName,
                "data_source_name": modelName,
                "toolTiptopHeader": METRIC_ALIAS_NAMES[performanceName],
                "isModelPerformance": true,
                "xValue": "Time",
                "yValue": "Value",
                "isError": false,
                "toolTipTitle": performanceName,
                "label": "Model"
            }

            modelRes["charts"].push(modelMonitorChartData);
            //chartValues.push(modelMonitorChartData);
        } else if(performanceName === modelPerformance.MEAN_SHAP_VALUE){
          let grouped = [];
          let datasetLevelCharts = perfResults.filter(x => x.payload.attribute_details !== undefined);
          let attributeLevelCharts = perfResults.filter(x => x.payload.metaKey !== undefined);

          let dataset_grouped = []
          datasetLevelCharts.forEach(function (a) {
            this[a.payload.data_set_id] || dataset_grouped.push(this[a.payload.data_set_id] = []);
            this[a.payload.data_set_id].push(a);
          }, Object.create(null));

          attributeLevelCharts.forEach(function (a) {
            this[a.payload.metaKey] || grouped.push(this[a.payload.metaKey] = []);
            this[a.payload.metaKey].push(a);
            }, Object.create(null));
          let total_shap_value = [];
          let combined_chart = []
          let lineCharts = []
          let model_res = []
          for (let res of dataset_grouped){
            if(res[0]["payload"]["attribute_details"] !== undefined){
                let chartData = getDatasetLevelShapValues(res)
                let total = chartData["total"]
                chartData = getFeatureImportanceChartData(chartData, performanceName, modelName, key, ml_model_id)
                chartData["dataSetName"] = datasetMapping[chartData["data_set_id"]]["datasetName"];
                chartData["data_set_name"] = datasetMapping[chartData["data_set_id"]]["datasetName"];
                chartData["filter_name"] = "mean_shap_value_ds_level"
                combined_chart.push(chartData);
                let lineChart = _.cloneDeep(chartData);
                lineChart["chartType"] = "multiLineFeatureChart"
                lineChart["key"] = chartData["key"]+"_line_chart"
                lineCharts.push(lineChart);
                model_res.push(chartData)
                model_res.push(lineChart)
                //chartValues.push(chartData);
                total_shap_value.push(total)
            }
          }
          //chartValues.push(...lineCharts);
          let serving_data_set_id = null;
          let training_data_set_id = null;
          if (combined_chart.length > 0 ){
                let modelFullDetails = fullModelInfo.filter(x => x.ml_model_id === ml_model_id)
                let serving_data_points, training_data_points = null;
                if(modelFullDetails.length > 0){
                      let modelDatasetsDetails =  modelFullDetails[0]["features"].filter(x=> x.type === "feature");
                      if(modelDatasetsDetails.length > 0) {
                            serving_data_set_id = modelDatasetsDetails[0]["model_info_details"]["target_data_set"]
                            serving_data_points = combined_chart.filter(x => parseInt(x.data_set_id)=== parseInt(serving_data_set_id))
                            training_data_set_id = modelDatasetsDetails[0]["model_info_details"]["base_line_data_set_id"]
                            training_data_points = combined_chart.filter(x => parseInt(x.data_set_id)=== parseInt(training_data_set_id))
                      }
                }

                for (let res of model_res){
                    if (res["data_set_id"].toString() === serving_data_set_id){
                        modelRes["charts"].push(res)
                    }
                }

                if (serving_data_points !== null && training_data_points !== null && serving_data_points.length > 0 && training_data_points.length > 0){
                    serving_data_points[0]["data_set_type"] = "serving dataset"
                    training_data_points[0]["data_set_type"] = "training dataset"
                    let combinedChart = addTrainingServingData(serving_data_points[0], training_data_points[0],"training")
                    if (combinedChart !== null){
                        combinedChart["chartVariant"] = "training_vs_serving";
                        combinedChart["filter_name"] = "mean_shap_value_combined_level"
                        modelRes["charts"].push(combinedChart);
                        //chartValues.push(combinedChart);
                   }
                }

                if(serving_data_points !== null && serving_data_points.length > 0){
                    let ServingBase = _.cloneDeep(serving_data_points[0]);
                    if( ServingBase["chartDataPoints"].length > 0){
                        ServingBase["chartDataPoints"]=  [ServingBase["chartDataPoints"][0]];
                        ServingBase["time"]=  ServingBase["time"][0];
                    }
                    ServingBase["data_set_type"] = "Base serving dataset"
                    ServingBase["data_set_name"] = ServingBase["data_set_name"]+"-Base"
                    serving_data_points[0]["data_set_type"] = "serving dataset"
                    let combinedChart = addTrainingServingData(ServingBase, serving_data_points[0],"serving")
                    if (combinedChart !== null){
                        combinedChart["chartVariant"] = "serving_vs_serving";
                        combinedChart["filter_name"] = "mean_shap_value_combined_level"
                        modelRes["charts"].push(combinedChart);
                        //chartValues.push(combinedChart);
                   }
              }

              for (let res of model_res){
                    if (res["data_set_id"].toString() === training_data_set_id){
                        modelRes["charts"].push(res)
                    }
              }

          }

          for (let res of grouped){
            let chartData = getModelPerformanceCharts(res);
            if (res[0]["payload"] !== undefined &&  res[0]["payload"]["attribute_name"] !== undefined){
                chartData["attribute_name"] = res[0]["payload"]["attribute_name"];
                if ( res[0]["payload"]["metaKey"] !== undefined){
                chartData["data_set_id"] = res[0]["payload"]["metaKey"].split(".")[1];
                chartData = getFeatureImportanceChartData(chartData, performanceName, modelName, key, ml_model_id)
                chartData["dataSetName"] = datasetMapping[chartData["data_set_id"]]["datasetName"];
                chartData["data_set_name"] = datasetMapping[chartData["data_set_id"]]["datasetName"];
                chartData["filter_name"] = "mean_shap_value_attribute_level"
                modelRes["charts"].push(chartData);
                //chartValues.push(chartData);
                let dataset_id = chartData["data_set_id"]
                let total_value
                for(let total of total_shap_value){
                        if(total[0]['data_set_id'] === undefined){
                            continue
                        }
                        if(total[0]['data_set_id'].toString() === dataset_id){
                            total_value = total[0]['total']
                            }
                        }
                chartData["total"] = total_value
                }
            }
          }

        }
        else {
            let chartData = getModelPerformanceCharts(perfResults);
            chartData["key"] = key;
            chartData["xValue"] = "Time";
            chartData["isError"] = false;
            chartData["yValue"] = performanceName;
            chartData["toolTipTitle"] = performanceName;
            chartData["chartTitle"] = performanceName;
            let chartType = getModelPerformanceChartType(chartData, performanceName);
            if (chartType === undefined || chartType === null) {
                chartType = `areaChart`;
            } else {
                chartData["useFixedChart"] = true;
            }

            chartData["chartType"] = chartType;
            chartData["ml_model_id"] = ml_model_id;
            chartData["label"] = "Model";
            chartData["name"] = performanceName;
            chartData["scale"] = MIN_MAX_SCALE;
            chartData["isModelPerformance"] = true;
            chartData["data_source_name"] = modelName;
            chartData["toolTiptopHeader"] = METRIC_ALIAS_NAMES[performanceName];

            let found = reqCombinedCharts.find(x => Object.values(x).includes(performanceName));
            if (found === undefined || found === null) {
                // Normal chart does not require additional process
                modelRes["charts"].push(chartData);
                //chartValues.push(chartData);
                continue;
            }

            const combinedName = `${found.y} vs ${found.y1}`;
            chartData["chartTitle"] = combinedName;
            chartData["name"] = combinedName;
            chartData["toolTiptopHeader"] = combinedName;
            chartData["useFixedChart"] = true;

            let combinedChart;
            if (found.added === undefined) {
                chartData["multipleLines"] = true;
                modelRes["charts"].push(chartData);
                //chartValues.push(chartData);
                combinedChart = chartData;
                found["added"] = chartData;
            } else {
                combinedChart = found["added"];
            }

            if (found.y === performanceName) {
                combinedChart["drift"] = chartData["drift"];
                combinedChart["titleY"] = performanceName;
                combinedChart["yValue"] = performanceName;
            } else {
                combinedChart["drift1"] = chartData["drift"];
                combinedChart["titleY1"] = performanceName;
                combinedChart["y1Value"] = performanceName;
                combinedChart["y2Value"] = `${found.y} / ${found.y1}`;
            }
        }
    }

    let allModelIds = Object.keys(tempResult);
    for (let l = 0; l < allModelIds.length; l++){
        let currentModelId = allModelIds[l];
        if (tempResult[currentModelId].needSorting){
            tempResult[currentModelId].charts.sort(sortBasedOnMetrics);
        }
        chartValues.push(...tempResult[currentModelId].charts);
    }
    return chartValues;
}


/**
 * Creates show more data for model error tab in Monitor page
 * @param {*} dataSetData - Object containing model error chart data
 * @param {boolean} isDataSetLevel - Represents whether current chart group formation is for dataset level or
 * at attribute level
 * @param  mlModelDetails - Complete model details for the current tenant
 * @param  mlModelMapping - Key-Value pair where key is model id and value is model name
 * @param {{}} metadataMap - Key-Value pair where
 *                      KEY is "env_id.integration_id.data_set_id.meta_data_id"
 *                      VALUE is "env_name.integration_name.data_set_name.meta_data_name"
 *
 * @param {canIncludeMetric} canIncludeFunction - Function to be called to decide whether
 * to include chart to group or not.
 * @param {[]} modelPerformanceCharts - list contains model performance charts
 * that are to be shown in model error page
 */

export function getCustomMetrics(dataSetData){
        let customMlOptions =[];
        let CustomMetrics = dataSetData.filter(x=>(x.metric === CUSTOM_ML_METRIC))
        if(CustomMetrics.length > 0){
            for(let data of CustomMetrics[0].data){
                customMlOptions.push({"name": data.name, "ml_model_id":data.ml_model_id, "model_name": data.model_name})
            }
        }
        return customMlOptions;

}

export function setShowMoreDataOnGroupingLevel(dataSetData, isDataSetLevel, mlModelDetails,
                                               mlModelMapping, metadataMap, canIncludeFunction,
                                               modelPerformanceCharts) {
    let gridData = [];
    if (dataSetData === undefined) {
        return gridData;
    }


    let modelGroup = {};
    let allowedMetrics = ATTRIBUTE_LEVEL_ALLOWED_ML_METRICS;
    let descriptionInfo = ATTRIBUTE_LEVEL_MODEL_ERROR_DESCRIPTIONS;
    let level = "Attribute";

    if (isDataSetLevel) {
        allowedMetrics = DATASET_LEVEL_ALLOWED_ML_METRICS;
        descriptionInfo = DATA_SET_LEVEL_MODEL_ERROR_DESCRIPTIONS;
        level = "Dataset";
    }

    for (const [key, modelValue] of Object.entries(dataSetData)) {
        let modelName = mlModelMapping[key];
        if (modelName === undefined) {
            continue;
        }

        for (const metricValue of Object.values(modelValue)) {

            let processedKeys = [];
            for (const [attribute, chartData] of Object.entries(metricValue)) {

                // If consolidation is done at attribute level,
                // make sure that there are 4 parts in attribute key (Ex) 22.33.16.2003)
                const attributeKeys = attribute.split(METADATA_MAP_KEY_SEPARATOR);
                let attribute_id = attributeKeys[attributeKeys.length - 1]
                if (!isDataSetLevel && attributeKeys.length !== 4) {
                    continue;
                }

                // if (isDataSetLevel && attributeKeys.length > 3){
                //     continue;
                // }

                let metricName = chartData.name;
                let is_udf_ml_metrics = false;
                if(!DEFAULT_ML_METRICS.includes(metricName) && chartData.ml_model_id !== undefined && chartData.model_name !== undefined && chartData.metric_threshold_error_status !== undefined){
                    is_udf_ml_metrics=true;
                    metricName = CUSTOM_ML_METRIC;
                }
                if (!allowedMetrics.includes(metricName) && !(is_udf_ml_metrics)) {
                    continue;
                }

                if (metricName === ML_ACCURACY_DRIFT) {
                    continue;
                }

                // To avoid repetitive charts for same attribute - metric combination
                let uniqueKey = `${attribute}_${metricName}`;
                if (processedKeys.includes(uniqueKey)) {
                    continue;
                }
                processedKeys.push(uniqueKey);

                let metricGroup = modelGroup[metricName];
                if (metricGroup === undefined) {
                    metricGroup = {};
                    let description = descriptionInfo[metricName];
                    if (description === undefined) {
                        description = `${descriptionInfo["default"]} ${metricName}`;
                    }

                    metricGroup["metric"] = `${metricName}`;
                    metricGroup["level"] = level;
                    metricGroup["description"] = description.desc;
                    let headerTitle = description.title;
                    metricGroup["header"] = headerTitle;
                    metricGroup["data"] = [];
                    metricGroup["key"] = `metricGroup_${metricName}`;
                    modelGroup[metricName] = metricGroup;
                }

                let metaData = metadataMap[attribute];
                if (metaData === undefined) {
                    metaData = metadataMap;
                    // const filtered = Object.keys(metaData)
                    //     .filter(key => key.startsWith(attribute))
                    //     .reduce((obj, key) => {
                    //         obj[key] = metaData[key];
                    //         return obj;
                    //     }, {});
                    metaData = metadataMap[attribute];
                    if (metaData !== undefined) {
                        let metaDataAfterSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR)
                        if(metaDataAfterSplit.length > 4) {
                          metaData = metaDataAfterSplit.slice(0, metaDataAfterSplit.length - 2).join(METADATA_MAP_VALUE_SEPARATOR);

                        }
                    }
                }

                if (metaData !== undefined) {
                    let metaDataSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                    let _attribute_name = null;
                    let datasetName;
                    let toolTiptopHeader;

                    if (isDataSetLevel) {
                        datasetName = metaDataSplit[2];
                    } else {
                        datasetName = metaDataSplit[2];
                        _attribute_name = metaDataSplit[metaDataSplit.length - 1];
                    }

                    if (REQUIRES_FILTER.includes(metricName) &&
                        !canIncludeFunction(key, attribute, chartData,
                            _attribute_name, metricName, mlModelDetails)) {
                        continue;
                    }

                    if (_attribute_name !== null) {
                        chartData["attribute_name"] = _attribute_name;
                        chartData["attribute_id"] = attribute_id;
                    }
                    chartData["datasetName"] = datasetName;
                    chartData["data_set_id"] = attribute.split(METADATA_MAP_KEY_SEPARATOR)[2];
                    let title = `${metaDataSplit.join(" > ")}`;
                    chartData["title"] = title;

                    chartData["name"] = getAliasChartName(chartData["name"], isDataSetLevel);
                    if (ML_BOX_PLOT_LIST.includes(chartData["name"])) {
                        let boxPlotData = {}
                        let statistics = {}
                        let quantile = chartData["boxplot_data"]
                        if (quantile !== undefined && quantile !== null) {
                            statistics["attribute_name"] = _attribute_name
                            statistics["quantile"] = quantile
                            statistics["drift_patterns"] = chartData["drift_patterns"]
                            statistics["time"] = chartData["time"]
                            if (_attribute_name !== null) {
                                boxPlotData["chart_level"] = "Attribute"
                            } else {
                                boxPlotData["chart_level"] = "Dataset"
                            }
                            boxPlotData["statistics"] = [statistics]
                            chartData["boxPlotData"] = boxPlotData
                            chartData["chartType"] = "boxPlotZoomable"
                        }
                    }

                    chartData["key"] = `show_more_${uniqueKey}_${chartData["ml_model_id"]}_${chartData.name}`;
                    chartData["title"] = title;

                    let chartTypeData = getChartTypeForMonitorCharts(chartData);
                    let hasError = chartTypeData.hasError;
                    chartData["type"] = chartTypeData.chartPatternType;
                    chartData["hasDrift"] = hasError;
                    // We are changing scale of all charts to min max scale
                    // Part of fix for issue 1038
                    chartData["scale"] = MIN_MAX_SCALE;

                    chartData["label"] = "Model";
                    chartData["data_source_name"] = modelName;
                    chartData["errorChart"] = true;

                    if (ML_REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART.includes(metricName)) {
                        chartData["settings"] = CONTRIBUTION_KEY;
                        chartData["referenceDataSetLabel"] = "Training Dataset";
                        let contributions = chartData["contribution"];
                        let referenceDataset = "";
                        if (contributions !== undefined && contributions.length > 0) {
                            referenceDataset = contributions[contributions.length - 1].target_data_set_name;
                        }

                        chartData["referenceDataSetName"] = referenceDataset;
                    }

                    // For drawing model error related chart we need to use actual drifts
                    // and not drifts of drifts
                    let valueList = chartData.value;
                    if (valueList !== undefined && valueList !== null) {
                        chartData["drift"] = valueList;
                    }

                    normalizeChartData(chartData);
                    let drift_len = chartData.drift.length;
                    let time_len = chartData["time"].length;
                    if (drift_len > 1) {
                        let recentValue = chartData.drift[(drift_len - 1)];
                        chartData["error_percentage"] = recentValue.toFixed(2);
                        chartData["latest_refresh"] = chartData["time"][(time_len - 1)];
                        chartData["last_error"] = chartData.drift[(drift_len - 2)].toFixed(2);
                    } else if (drift_len === 1) {
                        chartData["error_percentage"] = chartData.drift[(drift_len - 1)].toFixed(2);
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = chartData.drift[(drift_len - 1)].toFixed(2);
                    } else {
                        chartData["error_percentage"] = 0;
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = 0;
                    }

                    if (this !== undefined && this.state !== undefined) {
                        filterChartDataForTime(chartData, this.state.startDate, this.state.endDate);
                    }

                    if (chartData["name"] === FEATURE_DRIFT) {
                        if (chartData["type"] === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT) {
                            chartData["name"] = FEATURE_DRIFT_ALIAS_RED_TEXT;
                        } else {
                            chartData["name"] = FEATURE_DRIFT_ALIAS_GREEN_TEXT;
                        }
                    }
                    toolTiptopHeader = chartData["name"];
                    chartData["toolTiptopHeader"] = toolTiptopHeader;


                    metricGroup.data.push(chartData);
                }
            }
        }
    }

    if(isDataSetLevel && !(allowedMetrics.includes(CUSTOM_ML_METRIC))){
        allowedMetrics.push(CUSTOM_ML_METRIC);
    }

    for (let allowedMetric of allowedMetrics) {

        let groupData = modelGroup[allowedMetric];

        if (groupData !== undefined) {
            if (allowedMetric === ML_ACCURACY_DRIFT) {
                groupData.data = [...groupData.data, ...modelPerformanceCharts];
            }
            groupData.data.sort(sortBasedOnErrors);
            gridData.push(groupData);
            continue;
        }

        // No chart data found for these sections, so add section with empty chart list
        let description = descriptionInfo[allowedMetric];
        if (description === undefined) {
            continue;
        }

        let header = description.title;
        groupData = {
            "metric": allowedMetric,
            "description": description.desc,
            "header": header,
            "level": level,
            "data": [],
        };

        if (allowedMetric === ML_ACCURACY_DRIFT) {
            groupData.data = [...groupData.data, ...modelPerformanceCharts];
        }
        groupData.data.sort(sortBasedOnErrors);
        gridData.push(groupData);
    }
    return gridData;
}



export function canIncludeMetric(ml_model_id, attribute, chartData,
                                 attribute_name, metricName, mlModelDetails) {
    //This function checks the model configuration and
    // decides whether the current chart can be shown or not

    let reqModelDetail = mlModelDetails[ml_model_id];
    if (reqModelDetail === undefined || reqModelDetail === null) {
        return false;
    }

    let featureMap = reqModelDetail["feature_map"];
    if (featureMap === undefined || featureMap === null) {
        return false;
    }

    const mlFeaturekey = ML_FEATURE_KEYS[metricName];
    if (mlFeaturekey === undefined || mlFeaturekey === null) {
        return false;
    }

    let typeKey = mlFeaturekey["key"];
    let featureInfo = featureMap[typeKey];
    if (featureInfo === undefined || featureInfo === null) {
        return false;
    }

    const modelInfoDetails = featureInfo["model_info_details"];
    let target_data_set = modelInfoDetails["target_data_set"];
    const attributeSplits = attribute.split(METADATA_MAP_KEY_SEPARATOR);

    if (String(attributeSplits[2]) !== String(target_data_set)) {
        //Current dataset id vs dataset id present in model configuration
        //Dataset id does not match. So skipping this chart data
        return false;
    }

    let columnKey = mlFeaturekey["columnKey"];
    if (columnKey === undefined || columnKey === null) {
        return false;
    }

    let targetColumns = modelInfoDetails[columnKey];

    if (targetColumns === undefined || targetColumns === null) {
        return false;
    }

    let requiredColumns = [];
    for (let column of targetColumns) {
        requiredColumns.push(...Object.values(column))
    }

    return requiredColumns.includes(attribute_name);
}


export function convertModelDetails(mlModelDetails) {
    let modelDetail = {};
    for (let detail of mlModelDetails) {
        let feature_map = {};
        for (let feat of detail["features"]) {
            feature_map[feat["type"]] = feat;
        }

        detail["feature_map"] = feature_map;
        modelDetail[detail["ml_model_id"]] = detail
    }

    return modelDetail;
}

export function getModelErrorsOfPerformanceCharts(allCharts) {
    /*
     * This function is used in model error page to get only threshold error charts
     * among model performance charts
     */
    let errorCharts = [];

    for (let chart of allCharts) {

        if (!MODEL_PERFORMANCE_AS_MODEL_ERRORS.includes(chart.name)) {
            continue;
        }

        let errorChart = _.cloneDeep(chart);

        errorChart["name"] = capitalize(errorChart["name"]);
        errorChart["attribute_name"] = errorChart.name;
        errorChart["data_set_id"] = "";
        errorChart["title"] = errorChart.name;
        const modelId = errorChart["ml_model_id"];
        errorChart["key"] = `show_more_model_perf_${modelId}_${errorChart.name}`;
        errorChart["hasDrift"] = false;
        errorChart["scale"] = MIN_MAX_SCALE;
        errorChart["label"] = "Model";
        errorChart["isModelPerformance"] = true;
        errorCharts.push(errorChart);

        let driftPatterns = errorChart.drift_patterns;
        let driftType;

        if (driftPatterns === undefined || driftPatterns.length === 0) {
            driftType = DRIFT_PATTERN_TYPES.NO_DRIFT;
        } else {
            driftType = driftPatterns[driftPatterns.length - 1];
        }
        errorChart["type"] = driftType;

        let errorList = chart.errorList;
        if (errorList === undefined || errorList === null) {
            continue;
        }

        let errorCount = errorList.length;
        if (errorCount === 0) {
            continue;
        }

        let recentError = errorList[errorCount - 1];
        if (recentError === undefined || recentError === null) {
            continue;
        }

        errorChart["thresholdError"] = recentError;
        errorChart["hasDrift"] = (driftType === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT);
    }

    return errorCharts;
}

export function setGridData(data, metadataMap, mlModelMapping, modelPerfErrors) {
    /*
     * Creates monitor - model error preview page charts
     */
    let gridData = [];
    let errorChartInfo = {};
    let hasChartData = false;

    // Filter charts that have only threshold errors
    let errorCharts = modelPerfErrors.filter(x => x.hasDrift);

    if (data === undefined || data === null || Object.keys(mlModelMapping).length === 0 ) {
        return gridData;
    }
    let mlModelIds = Object.keys(mlModelMapping);
    for (const [key, value] of Object.entries(data)) {
        let modelData = {};
        let chartDataList = [];
        modelData["title"] = `Model Errors for '${key}'`;
        if ( typeof(value)!=='object' || key === undefined || key === null || key === "null" || typeof(value) === "string" || value === null) {
            continue;
        }

        modelData["key"] = key;
        let ml_model_id = null;
        for (const fullData of Object.values(value)) {
            for (const [attribute, chartData] of Object.entries(fullData)) {

                let metaData = metadataMap[attribute];
                if (metaData === undefined) {
                    metaData = metadataMap;
                    // const filtered = Object.keys(metaData)
                    //     .filter(key => key.startsWith(attribute))
                    //     .reduce((obj, key) => {
                    //         obj[key] = metaData[key];
                    //         return obj;
                    //     }, {});

                    let matchedKeys = metadataMap[attribute];
                    if (matchedKeys.length === 0) {
                        continue;
                    }

                    metaData = matchedKeys;
                    if (metaData === undefined || metaData === null) {
                        continue;
                    }

                    let metaDataAfterSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR)
                    if(metaDataAfterSplit.length > 4) {
                      metaData = metaDataAfterSplit.slice(0, metaDataAfterSplit.length - 2).join(METADATA_MAP_VALUE_SEPARATOR);

                    }
                }
                const metadataSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                const attributeKeySplit = attribute.split(METADATA_MAP_KEY_SEPARATOR);

                let attributeName;
                let datasetName;
                let toolTiptopHeader;
                let isAttributeLevel = false;
                if (attributeKeySplit.length === 4) {
                    datasetName = metadataSplit[2];
                    attributeName = metadataSplit[metadataSplit.length - 1];
                    isAttributeLevel = true;
                } else {
                    datasetName = metadataSplit[2];
                }

                let metricName = chartData['name'];
                chartData["name"] = getAliasChartName(metricName, !isAttributeLevel);

                toolTiptopHeader = chartData["name"];

                if (attributeName !== null) {
                    chartData["attribute_name"] = attributeName;
                }

                chartData["toolTiptopHeader"] = toolTiptopHeader
                chartData["datasetName"] = datasetName;
                chartData["data_set_id"] = attributeKeySplit[2];
                chartData["integration_id"] = attributeKeySplit[1];
                const mlModelId = chartData["ml_model_id"];
                ml_model_id = chartData["ml_model_id"];
                modelData["ml_model_id"] = mlModelId;
                modelData["label"] = "Model";
                chartData["title"] = `${metadataSplit.join(" > ")} - ${chartData.name}`;
                const chartKeyText = `preview_${chartData["data_set_id"]}_${mlModelId}_${chartData.name}`;
                chartData["key"] = getNormalizedName(chartKeyText);
                // We are changing scale of all charts to min max scale
                // Part of fix for issue 1038
                chartData["scale"] = MIN_MAX_SCALE;

                let chartTypeData = getChartTypeForMonitorCharts(chartData);
                let hasError = chartTypeData.hasError;
                chartData["type"] = chartTypeData.chartPatternType;
                chartData["hasDrift"] = hasError;
                if (ML_BOX_PLOT_LIST.includes(chartData["name"])) {
                    let boxPlotData = {}
                    let statistics = {}
                    let quantile = chartData["boxplot_data"]
                    if (quantile !== undefined && quantile !== null) {
                        statistics["attribute_name"] = attributeName
                        statistics["quantile"] = quantile
                        statistics["drift_patterns"] = chartData["drift_patterns"]
                        statistics["time"] = chartData["time"]
                        if (attributeName !== null) {
                            boxPlotData["chart_level"] = "Attribute"
                        } else {
                            boxPlotData["chart_level"] = "Dataset"
                        }
                        boxPlotData["statistics"] = [statistics]
                        chartData["boxPlotData"] = boxPlotData
                        chartData["chartType"] = "boxPlotZoomable"
                    }
                }

                // For drawing model error related chart we need to use actual drifts
                // and not drifts of drifts
                let valueList = chartData.value;
                if (valueList !== undefined && valueList !== null) {
                    chartData["drift"] = valueList;
                }

                chartData["data_source_name"] = key;
                normalizeChartData(chartData);
                let drift_len = chartData.drift.length;
                let time_len = chartData.time.length;
                if (drift_len > 1) {
                    chartData["error_percentage"] = chartData.drift[(drift_len - 1)].toFixed(2);
                    chartData["last_error"] = chartData.drift[(drift_len - 2)].toFixed(2);
                    chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                } else if (drift_len === 1) {
                    chartData["error_percentage"] = chartData.drift[(drift_len - 1)].toFixed(2);
                    chartData["last_error"] = chartData.drift[(drift_len - 1)].toFixed(2);
                    chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                } else {
                    chartData["error_percentage"] = 0;
                    chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                    chartData["last_error"] = 0;
                }

                if (this !== undefined && this.state !== undefined) {
                    filterChartDataForTime(chartData, this.state.startDate, this.state.endDate);
                }

                hasChartData = true;
                if (hasError) {
                    chartData["errorChart"] = true;
                    chartDataList.push(chartData);

                    // Add it to under performing model list
                    let clonedChartData = _.cloneDeep(chartData);
                    let mlModelID = clonedChartData["ml_model_id"];
                    let errorData = errorChartInfo[mlModelID];
                    if (errorData === undefined) {
                        errorData = {
                            "count": 0,
                            "data": [],
                            "ml_model_id": String(mlModelID)
                        }
                        if(mlModelIds.includes(String(mlModelID))){
                            errorChartInfo[mlModelID] = errorData;
                        }
                    }

                    errorData.count = errorData.count + 1;
                    errorData.data.push(clonedChartData);
                }
                if (chartData["name"] === FEATURE_DRIFT) {
                    if (chartData["type"] === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT) {
                        chartData["name"] = FEATURE_DRIFT_ALIAS_RED_TEXT;
                    } else {
                        chartData["name"] = FEATURE_DRIFT_ALIAS_GREEN_TEXT;
                    }
                }
            }
        }
        modelData["data"] = chartDataList;
        if (ml_model_id === null || mlModelIds.includes(String(ml_model_id))) {
           gridData.push(modelData);
        }
    }

    let processedIDs = gridData.map(x => String(x["ml_model_id"]));
    for (const [mlModelId, modelName] of Object.entries(mlModelMapping)) {
        let currentModelPerfErrorCharts = errorCharts.filter(x => String(x["ml_model_id"]) === String(mlModelId));
        let currentPerfErrors = _.cloneDeep(currentModelPerfErrorCharts);

        if (processedIDs.includes(String(mlModelId))) {
            let currModelData = gridData.find(x=>String(x.ml_model_id) === mlModelId);
            currModelData["data"] = [...currentPerfErrors, ...currModelData["data"]];
            continue;
        }

        let modelData = {};
        modelData["title"] = `Model Name - ${modelName}`;
        modelData["label"] = "Model";
        modelData["key"] = modelName;
        // modelData["disableShowMore"] = true;
        modelData["data"] = currentPerfErrors;
        modelData["ml_model_id"] = mlModelId;
        gridData.push(modelData);
    }

    let underPerformingModels = {
        "title": "Top Under-performing Models",
        "data": [],
        "under_performing_model_ids": []
    };

    // Sort the list in descending order to get top under performing models
    const errorChartValues = Object.values(errorChartInfo);
    errorChartValues.sort(function (a, b) {
        return b.count - a.count;
    });
    if (errorChartValues.length === 1) {
        const topErrorContainer = errorChartValues[0];
        underPerformingModels.under_performing_model_ids.push(topErrorContainer.ml_model_id);
        underPerformingModels.data = topErrorContainer.data.slice(0, 3);
    } else if (errorChartValues.length === 2) {
        underPerformingModels.data = errorChartValues[0].data.slice(0, 2);
        underPerformingModels.under_performing_model_ids.push(errorChartValues[0].ml_model_id);

        let chartFromSecondTopError = errorChartValues[1].data[0];
        underPerformingModels.under_performing_model_ids.push(errorChartValues[1].ml_model_id);
        underPerformingModels.data.push(chartFromSecondTopError);
    } else if (errorChartValues.length > 2) {
        let chartFromFirstTopError = errorChartValues[0].data[0];
        underPerformingModels.under_performing_model_ids.push(errorChartValues[0].ml_model_id);

        let chartFromSecondTopError = errorChartValues[1].data[0];
        underPerformingModels.under_performing_model_ids.push(errorChartValues[1].ml_model_id);

        let chartFromThirdTopError = errorChartValues[2].data[0];
        underPerformingModels.under_performing_model_ids.push(errorChartValues[2].ml_model_id);

        underPerformingModels.data.push(chartFromFirstTopError);
        underPerformingModels.data.push(chartFromSecondTopError);
        underPerformingModels.data.push(chartFromThirdTopError);
    }

    underPerformingModels.data.forEach(x => x["key"] = `${x["key"]}_un_perf_models`);
    if (hasChartData && underPerformingModels.data.length > 0) {
        gridData = [underPerformingModels, ...gridData];
    }


    return gridData;
}

export function applyDefaultTimeFilter(gridData, startDate, endDate) {
//
//    let startDate = moment().subtract(10, 'days');
//    let endDate = moment().utc().endOf('day');
    for (let grid of gridData) {
        let timeFilteredData = [];
        for (let data of grid.data) {
            let copiedData = {}
            Object.assign(copiedData, data);
            let result = filterChartDataForTime(data, startDate, endDate);
            let boxPlotDataAvailable = false;
            if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
                boxPlotDataAvailable = true;
                copiedData.boxPlotData = result.boxPlotData;
            }
            let updatedTime = result.time;
            let yAxis = result.drift;
            if ((copiedData.chartType === "boxPlotZoomable" && boxPlotDataAvailable === false) ||
                (copiedData.chartType !== "boxPlotZoomable" && updatedTime.length === 0 )) {
                continue;
            }
            if (updatedTime.length >= 10) {
                updatedTime = updatedTime.slice(Math.max(updatedTime.length - 10, 0));
                yAxis = yAxis.slice(Math.max(yAxis.length - 10, 0));
            }
            copiedData["time"] = updatedTime;
            copiedData["drift"] = yAxis;
            timeFilteredData.push(copiedData);
        }
        grid.data = timeFilteredData;
    }
    return gridData
}

function addColor(metricGroup) {
    let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
    let i = 0;
    for (let group in metricGroup) {
        let groupData = metricGroup[group].data;
        for (let data in groupData) {
            groupData[data]["color"] = NO_ERROR_CHART_COLORS[colorIndex[i]];
        }
        if (i === 3) {
            i = 0;
        } else {
            i = i + 1;
        }
    }
}

export function getPreviewPerformanceChartData(performanceResults, mlModelMapping) {
    let groupedCharts = [];
    if (performanceResults === undefined || performanceResults === null) {
        return groupedCharts;
    }

    for (let key of Object.keys(performanceResults)) {
        let charts = performanceResults[key];
        let modelName = mlModelMapping[key];
        if (modelName === undefined) {
            continue;
        }

        let metricGroup = {}
        let chartValues = [];
        metricGroup["metric"] = modelName;
        metricGroup["mlModelID"] = key;
        metricGroup["data"] = chartValues;
        let reqCombinedCharts = _.cloneDeep(MODEL_PERFORMANCE_COMBINATION_CHART);

        const allChartKeys = Object.keys(charts);
        const isClassification = allChartKeys.includes(modelPerformance.PRECISION);

        for (let chartName of allChartKeys) {
            if (chartName === modelPerformance.ROC_CURVE ||
                chartName === modelPerformance.CONFUSION_MATRIX) {
                continue;
            }

            if (isClassification && !MODEL_PERFORMANCE_PREVIEW_PAGE.includes(chartName)) {
                continue;
            }

            if (MODEL_MONITOR_PREVIEW_PAGE.includes(chartName)) {
                continue;
            }

            let priorityScore = MODEL_PERFORMANCE_RESULT_GROUP_MAP[chartName] === MODEL_ACCURACY ? 0 : 10;

            let chartData = charts[chartName];
            let ml_model_id = chartData["ml_model_id"]
            chartData["key"] = `${getNormalizedName(chartName)}_${ml_model_id}`;
            chartData["chartTitle"] = chartName;
            chartData["chartType"] = `areaChart`;
            chartData["ml_model_id"] = ml_model_id;
            chartData["attribute_name"] = undefined;
            chartData["name"] = chartName;
            chartData["toolTiptopHeader"] = METRIC_ALIAS_NAMES[chartName];
            chartData["label"] = "Model";
            chartData["xValue"] = "Time";
            chartData["isError"] = false;
            chartData["yValue"] = chartName;
            chartData["toolTipTitle"] = chartName;
            chartData["metric"] = chartName;
            chartData["scale"] = MIN_MAX_SCALE;
            chartData["data_source_name"] = modelName;
            chartData["sortPriority"] = priorityScore;
            chartData["isModelPerformance"] = true;

            let found = reqCombinedCharts.find(x => Object.values(x).includes(chartName));
            if (found === undefined || found === null) {
                // Normal chart does not require additional process
                chartValues.push(chartData);
                continue;
            }

            if (found.y === chartName && !allChartKeys.includes(found.y1)){
                // We have only one chart data for combined charts.
                // So we are ignoring the chart
                continue;
            }

            if (found.y1 === chartName && !allChartKeys.includes(found.y)){
                // We have only one chart data for combined charts.
                // So we are ignoring the chart
                continue;
            }

            const combinedName = `${found.y} vs ${found.y1}`;
            chartData["chartTitle"] = combinedName;
            chartData["toolTiptopHeader"] = combinedName;
            chartData["name"] = combinedName;
            chartData["useFixedChart"] = true;

            let combinedChart;
            if (found.added === undefined) {
                chartData["multipleLines"] = true;
                chartValues.push(chartData);
                combinedChart = chartData;
                found["added"] = chartData;
            } else {
                combinedChart = found["added"];
            }

            if (found.y === chartName) {
                combinedChart["drift"] = chartData["drift"];
                if (combinedChart.drift1 === undefined) {
                    combinedChart["drift1"] = [];
                }

                combinedChart["titleY"] = chartName;
                combinedChart["yValue"] = chartName;
            } else {
                combinedChart["drift1"] = chartData["drift"];
                combinedChart["titleY1"] = chartName;
                combinedChart["y1Value"] = chartName;
                combinedChart["y2Value"] = `${found.y} / ${found.y1}`;
            }
        }

        // Sort charts. We need to show Accuracy related charts first
        chartValues.sort((a, b) => a["sortPriority"] - b["sortPriority"]);
        groupedCharts.push(metricGroup);
    }
    addColor(groupedCharts);

    let processedIDs = groupedCharts.map(x => String(x["mlModelID"]));
    for (const [mlModelId, modelName] of Object.entries(mlModelMapping)) {
        if (processedIDs.includes(String(mlModelId))) {
            continue;
        }

        let metricGroup = {}
        metricGroup["metric"] = modelName;
        metricGroup["mlModelID"] = mlModelId;
        metricGroup["data"] = [];
        groupedCharts.push(metricGroup);
    }
    return groupedCharts;
}

export function getDqErrorDetails(modelInfo, monitorModule) {
       if(monitorModule.monitorDQListView !== undefined && monitorModule.monitorDQListView.issued_attributes !== undefined) {
               let modelDatasets = modelInfo["model_datasets"]
               let updatedDatasets = []
               let issuedAttributes = monitorModule.monitorDQListView.issued_attributes
               let attributeLevelData = monitorModule.monitorDQListView.attribute_level_data
               let datasetLevelData = monitorModule.monitorDQListView.dataset_level_data
               for(let i=0;i<modelDatasets.length && issuedAttributes !== undefined && attributeLevelData !== undefined && datasetLevelData !== undefined;i++) {
                  let data_set_id = Object.keys(issuedAttributes).filter(x => (x === modelDatasets[i]["data_set_id"]))
                  if(data_set_id.length > 0) {
                     let dataId = data_set_id[0]
                     if(issuedAttributes[dataId] === undefined) {
                       continue;
                     }
                     let latestError = issuedAttributes[dataId][0]["issued_attributes"]
                     let latestRefreshedTime = issuedAttributes[dataId][0]["refresh_time"]
                     let startDate = moment().format('YYYY-MM-DD 00:00:00');
                     let endDate = moment().format('YYYY-MM-DD 23:59:59');
                     let diff = moment(endDate).diff(latestRefreshedTime,'days')
                     if(moment(latestRefreshedTime).isSameOrBefore(moment(endDate)) && moment(latestRefreshedTime).isSameOrAfter(moment(startDate))) {
                       modelDatasets[i]["latestError"] = latestError.length
                       modelDatasets[i]["filteredValues"] = latestError
                     }else {
                       modelDatasets[i]["latestError"] = "not refreshed"
                       modelDatasets[i]["filteredValues"] = []
                       modelDatasets[i]["recencyInDays"] = diff
                       modelDatasets[i]["prevRefreshDate"] = issuedAttributes[dataId][0] !== undefined ? latestRefreshedTime : ""
                     }
                     modelDatasets[i]["lastProfiled"] = latestRefreshedTime
                     modelDatasets[i]["datasetLevelData"] = datasetLevelData[dataId]
                     modelDatasets[i]["attributeLevelData"] = attributeLevelData[dataId]
                     modelDatasets[i]["issuedAttributes"] = issuedAttributes[dataId]
                     updatedDatasets.push(modelDatasets[i]);
                  }

               }
               return updatedDatasets

       }else {
          return []
       }

}

export function getModelErrTableData(monitorModule, dataModule, modelPerformanceData, chartData) {
       let finalData = []
       let fullModelInfo = monitorModule.mlModelDetails
       const modelDataSourceDetails = mapModelToDataSource(fullModelInfo);
       const propsDataModuleInfo = dataModule.info;
       let lastProfilingTime = dataModule.lastProfilingTime;
       let modelPrefetchInfo = _.cloneDeep(monitorModule.mlModelAttributeInfo);
       let filteredData = _.cloneDeep(chartData["attribute"][4]["data"])
       let attributeOptions = {}
       for (let mlModelId of Object.keys(monitorModule.mlModelMapping)) {
          let filteredModel = fullModelInfo.find(x => String(x.ml_model_id) === String(mlModelId));
          let modelList = []
          if (filteredModel !== undefined && filteredModel !== null && Object.keys(lastProfilingTime).length>0) {
              let modelName = filteredModel["model_name"]
              let modelId = filteredModel["ml_model_id"]
              let integrationId = filteredModel["integration_id"]
              let datasourceName = filteredModel["integration_name"]
              let filteredDataFinal = filteredData.filter(x => (x.ml_model_id === modelId))
              let performanceError = filteredDataFinal.filter(x => (x.hasDrift === true))
              let performance_error = false;
              if (performanceError.length > 0)
              {
                performance_error = true;
              }
              let thumbnailCharts = getThumbnailChartsModelErr(filteredDataFinal, modelName, datasourceName)
              let dqErrorInfo = getDqErrorDetails(filteredModel,monitorModule)

              let summaryData = [{"text": "Model Type", "value": filteredModel["ml_model_type"]},
                                {"text": "Author", "value": filteredModel["author"]},
                                {"text": "Version", "value": filteredModel["version"]},
                                {"text": "Model Created On", "timeValue": filteredModel["created_time"].split(",")[1], "highlight": false},
                                {"text": "Model Updated On", "timeValue": filteredModel["modified_time"].split(",")[1]},
                                ]
              let errModelSummaryData = formModelErrorDataForListView([], modelDataSourceDetails,
                propsDataModuleInfo, modelId);
              let attributeDetails = getServingFeatureDriftAttributes(modelPrefetchInfo[modelId], dataModule.metaData);
              attributeOptions[mlModelId] = attributeDetails["attributesOptions"]
              modelList.push({"value": modelName, "type": "td"});
              modelList.push({"value": summaryData, "type": "modelSummary", "inProgress": false});
              modelList.push({"value": errModelSummaryData, "type": "errModelSummary", "noModelConfigured": false});
              modelList.push({"value": dqErrorInfo, "type": "dqErrorInfo", "datasourceName": datasourceName, "inProgress": false});
              modelList.push({"value": attributeDetails, "type": "servingFeatureDrift", "modelId": modelId, "noModelConfigured": false});
              modelList.push({"value": thumbnailCharts, "type": "thumbnailCharts", "noModelConfigured": false, "performanceError": performance_error});
              modelList.push({"value": modelId, "type": "detailedChart", "integrationId": integrationId, "createdTime": filteredModel.created_time});
          } else if (modelDataSourceDetails === undefined || modelDataSourceDetails === null ||
                modelDataSourceDetails[mlModelId] === undefined)  {
              modelList.push({"value": monitorModule.mlModelMapping[mlModelId], "type": "td"});
              modelList.push({"value": [], "type": "modelSummary", "inProgress": true});
              modelList.push({"value": [], "type": "errModelSummary", "noModelConfigured": false});
              modelList.push({"value": [], "type": "dqErrorInfo", "datasourceName": null, "inProgress": true});
              modelList.push({"value": [], "type": "servingFeatureDrift", "modelId": mlModelId, "noModelConfigured": false});
              modelList.push({"value": [], "type": "thumbnailCharts", "noModelConfigured": false});
              modelList.push({"value": mlModelId, "type": "detailedChart", "integrationId": null, "createdTime": null});

          }
          if(modelList.length > 0) {
            finalData.push(modelList)
          }

       }

       return {"finalData": finalData, "attributeOptions": attributeOptions};

    }


export function getThumbnailChartsDqErr(data, idInput, datasourceName, headerName, timelinessData, datasetName) {
        let allThumbnailChartSections = [];
        const sectionName = "Monitor Error";
        if(data === undefined) {
           return allThumbnailChartSections
        }
        let chartType = "thumbnailBar"
        let updatedData = []
        for (const _cData of Object.values(data))  {
            let newData = _.cloneDeep(_cData);
            if(newData.name === "Recency" && timelinessData !== undefined && timelinessData.length > 0) {
               newData = _.cloneDeep(timelinessData[0])
            }else if(newData.name === "Recency" && timelinessData !== undefined && timelinessData.length === 0) {
               continue;
            }
            if(["not_computed", "NO_DRIFT"].includes(newData.drift_pattern)) {
               continue;
            }
            newData[DQ_CHART_POINTS] = newData["value"];

            normalizeChartData(newData, DQ_CHART_POINTS);

            let colorInfo = MONITOR_PERF_LIST_VIEW[sectionName];
            newData["background"] = colorInfo.cardBackground;
            newData["textColor"] = colorInfo.textColor;
            delete newData["boxplot_data"];
            let reqChartData = {"time": newData.time};
            reqChartData.label = "Dataset";
            reqChartData.labelValue = datasetName;
            reqChartData.toolTiptopHeader = headerName;
            reqChartData.version_name = newData.version_name;
            let uniqid = Math.floor(Math.random() * 1000000000);
            newData["chartData"] = reqChartData;
            newData["idValue"] = "lv" + idInput + getNormalizedName(newData["name"]).replace(/ /g,"_")+"_"+uniqid
            reqChartData["values"] = convertDecimalDigits(newData.dq_chart_points);
            reqChartData["colorArray"] = colorInfo.colorArray;
            reqChartData["name"] = newData.name;
            newData["chartType"] = chartType;
            chartType = chartType === "thumbnailBar" ? "thumbnailArea": "thumbnailBar"

            newData["scale"] = MIN_MAX_SCALE;
            updatedData.push(newData);
        }

        let sectionData = {
            "name": "",
            "key": getNormalizedName(idInput + sectionName),
            "data": Object.values(updatedData).slice(0, 6)
        }

        if (sectionData.data.length > 0){
            allThumbnailChartSections.push(sectionData);
        }
    return allThumbnailChartSections;

}
export function getThumbnailChartsModelErr(data, modelName, datasourceName) {
        let allThumbnailChartSections = [];
        let ml_model_id = null;
        const sectionName = "Monitor Error";
        if(data === undefined) {
           return allThumbnailChartSections
        }
        let chartType = "thumbnailBar"
        for (let _cData of data) {
            let colorInfo = MONITOR_PERF_LIST_VIEW[sectionName];
            _cData["background"] = colorInfo.cardBackground;
            _cData["textColor"] = colorInfo.textColor;

            const reqChartData = {"time": _cData.time};
             // Add values for showing as tooltip header and labels
            reqChartData.label = "Model";
            reqChartData.labelValue = modelName;
            reqChartData.toolTiptopHeader = _cData["toolTiptopHeader"];

            if (ml_model_id === null) {
                ml_model_id = _cData.ml_model_id;
            }

            _cData["chartData"] = reqChartData;
            _cData["idValue"] = "lv" + getNormalizedName(_cData.key).replace(/ /g,"_");
            reqChartData["values"] = convertDecimalDigits(_cData.drift);
            reqChartData["colorArray"] = colorInfo.colorArray;
            _cData["chartType"] = chartType;
            chartType = chartType === "thumbnailBar" ? "thumbnailArea": "thumbnailBar"
            _cData["scale"] = MIN_MAX_SCALE;
        }
        let sectionData = {
            "name": "",
            "key": getNormalizedName(ml_model_id + sectionName),
            "data": data.slice(0, 4)
        }

        if (sectionData.data.length > 0){
            allThumbnailChartSections.push(sectionData);
        }


    return allThumbnailChartSections;

}

export function getThumbnailChartsForModelPerfLV(groupedDataForModel, modelName, lastProfilingTime, datasourceName) {
    let allThumbnailChartSections = [];
    let ml_model_id = null;
    for (let grpData of Object.values(groupedDataForModel)) {
        const sectionName = grpData.header;

        const chartDataList = grpData.data;
        for (let _cData of chartDataList) {
            let colorInfo = MONITOR_PERF_LIST_VIEW[sectionName];
            _cData["background"] = colorInfo.cardBackground;
            _cData["textColor"] = colorInfo.textColor;
            const reqChartData = {"time": _cData.time};

            // Add values for showing as tooltip header and labels
            reqChartData.label = "Model";
            reqChartData.labelValue = modelName;
            reqChartData.toolTiptopHeader = _cData["toolTiptopHeader"];

            if (ml_model_id === null) {
                ml_model_id = _cData.ml_model_id;
            }

            _cData["chartData"] = reqChartData;
            let currChartType = _cData["chartType"];
            const hasSecondLine = _cData.drift1 !== undefined;

            // If there is only one data point, we can show it as barchart. In area chart we cannot see the data point
            // if we have single data point
            if (currChartType === "areaChart" && reqChartData.time.length === 1 && !hasSecondLine) {
                currChartType = "barChart";
            }

            if (currChartType === "areaChart") {
                reqChartData["values"] = convertDecimalDigits(_cData.drift);
                if (hasSecondLine) {
                    reqChartData["Y2_Values"] = convertDecimalDigits(_cData.drift1);
                    reqChartData["y1Value"] = _cData.y1Value;
                }

                _cData["chartType"] = "thumbnailArea";
                _cData["scale"] = MIN_MAX_SCALE;
                reqChartData["yValue"] = _cData.yValue;
            } else if (currChartType === "barChart") {
                reqChartData["values"] = convertDecimalDigits(_cData.drift);
                reqChartData["colorArray"] = colorInfo.colorArray;
                _cData["chartType"] = "thumbnailBar";
                _cData["scale"] = MIN_MAX_SCALE;
                reqChartData["yValue"] = _cData.yValue;
            }else if (currChartType === "confusionMatrix") {
                reqChartData["values"] = _cData.values;
                 _cData["chartType"] = "thumbnailConfusionMatrix";
                 reqChartData["time"] = lastProfilingTime;
            }
            _cData["idValue"] = "lv" + _cData.key;
        }

        let sectionData = {
            "name": sectionName,
            "key": getNormalizedName(ml_model_id + sectionName),
            "data": chartDataList.slice(0, 6)
        }

        if (sectionData.data.length > 0) {
            allThumbnailChartSections.push(sectionData);
        }
    }

    return allThumbnailChartSections;
}

export function getModelPerfListViewTableData(completeDataOrg, modelDataSourceDetails,
                                              propsDataModule,
                                              mlModelMapping, fullModelInfo) {
    let currListViewTableData = [];
    if (completeDataOrg === undefined || completeDataOrg === null ||
        mlModelMapping === null || mlModelMapping === undefined ||
        fullModelInfo === undefined || fullModelInfo === null ||
        modelDataSourceDetails === undefined || modelDataSourceDetails === null) {
        return [];
    }
    let propsDataModuleInfo = propsDataModule.info
    let lastProfilingTime = propsDataModule.lastProfilingTime

    let completeData = _.cloneDeep(completeDataOrg);

    // const chartData = getAllHorizontalChartsData();

    for (let mlModelId of Object.keys(mlModelMapping)) {
        let modelName = mlModelMapping[mlModelId];
        let modelType = '';
        let modelAuthor = '';
        let integrationId;
        let lastProfilingTimeModel;
        let datasourceName;
        let createdTime;

        let matchData = fullModelInfo.find(x => String(x.ml_model_id) === String(mlModelId));
        let modelSummaryData = [];
        let inProgress = false;
        if (matchData !== undefined && matchData !== null && Object.keys(lastProfilingTime).length>0) {
            modelType = capitalize(matchData.ml_model_type);
            modelAuthor = matchData.author;
            integrationId = matchData.integration_id;
            datasourceName = matchData.integration_name;
            createdTime = matchData.created_time

            lastProfilingTimeModel = lastProfilingTime[integrationId]
            modelSummaryData.push({"text": "Model Type", "value": modelType});
            modelSummaryData.push({"text": "Author", "value": modelAuthor});
            modelSummaryData.push({"text": "Version", "value": matchData.version});
            modelSummaryData.push({"text": "Model Created On", "timeValue": matchData.created_time.split(",")[1], "highlight": false});
            modelSummaryData.push({"text": "Model Updated On", "timeValue": matchData.modified_time.split(",")[1]})
        } else if (modelDataSourceDetails === undefined || modelDataSourceDetails === null ||
                    modelDataSourceDetails[mlModelId] === undefined){
           inProgress = true;
        }
        let dataForModel = getGroupedData(completeData, {"value": mlModelId});
        let thumbnailChartData = getThumbnailChartsForModelPerfLV(dataForModel, modelName, lastProfilingTimeModel, datasourceName)

        const errorSummaryData = formModelErrorDataForListView([], modelDataSourceDetails,
            propsDataModuleInfo, mlModelId);

        const thumbnailData = {"key": mlModelId, "data": thumbnailChartData};

        let rowData = [{"value": modelName, "type": "td"},
            {"value": modelSummaryData, "type": "modelSummary", "inProgress": inProgress},
            {"value": errorSummaryData, "type": "modelErrorSummary", "noModelConfigured": false},
            {"value": thumbnailData, "type": "thumbnailCharts", "lastProfilingTime":lastProfilingTimeModel, "noModelConfigured": false},
            {"value": mlModelId, "type": "detailedChart", "integrationId": integrationId, "createdTime": createdTime}
        ]

        currListViewTableData.push(rowData);
    }

    return currListViewTableData;
}

let lastId = 0;

export function getId(tab) {
    lastId++;
    if (tab === "dq") {
        return `${"dq"}${lastId}`;
    }
    else if (tab === "ml") {
        return `${"ml"}${lastId}`;
    }
}

export function getNormalizedName(name) {
    let updated = name.split(' ').join('_');
    updated = updated.split('.').join('_');
    updated = updated.split('(').join('_');
    updated = updated.split(')').join('_');
    updated = updated.split('{').join('_');
    updated = updated.split('}').join('_');
    updated = updated.split('>').join('_');
    updated = updated.split('<').join('_');
    updated = updated.split('-').join('_');
    updated = updated.split('/').join('_');
    updated = updated.split('\\').join('_');

    // Following line replaces all non-alpha numeric characters with under score
    // Above statements can be removed after proper check until then let's have both operations
    updated = updated.replace(/[\W_]+/g,"_");
    return updated;
}


export function getDatasets(dataSets) {
    let option_arr = [];

    if (dataSets === undefined || dataSets === null) {
        return option_arr;
    }

    option_arr = dataSets.map(function (row) {
        return {"label": normalizeChildDatasetName(row["data_set_name"]), "value": row["data_set_id"]};
    });
    return option_arr;
}

export function formMetricsCardData(data) {
    let metricsCardData = [];
    data.forEach(d => {
            let element = _.cloneDeep(d);

            let data_set_id = element["data_set_id"]
            let metric_type = element["metric_type"]
            let metric_name = element["metric_name"]
            // let hasJob = (job_id !== undefined && job_id !== null);
            element["key"] = element["metrics_id"];
            // When there are no data_sets attached, that means they are Default metrics.
            // Only default metrics will be shown in cards
            if(DATA_CONSISTENCY_METRICS.includes(metric_name)){
                if (data_set_id === undefined || data_set_id === null) {
                    metricsCardData.push(element);
                }
            }
            else if (data_set_id === undefined || data_set_id === null || metric_type === "default") {
                metricsCardData.push(element);
            }
            // if((element.metric_type === metricType.DEFAULT && !hasJob) || (element.metric_type === metricType.COMBINED && !hasJob)){
            //     metricsCardData.push(element);
            // }
        });

    return metricsCardData;
}

export function convertDecimalDigits(drifts) {
    if (drifts === undefined) {
        return drifts;
    }

    if (typeof (drifts[0]) === "number") {
        for (let i = 0; i < drifts.length; i++) {
            const driftElement = drifts[i];
            if (driftElement === undefined || driftElement === null) {
                continue;
            }

            drifts[i] = parseFloat(driftElement.toFixed(4));
        }
    } else if (typeof (drifts[0]) === "object") {
        for (let i = 0; i < drifts.length; i++) {
            const driftElement = drifts[i];

            if (driftElement === undefined || driftElement === null) {
                continue;
            }

            for (let j = 0; j < drifts.length; j++) {
                if (driftElement[j] === undefined || typeof (driftElement[j]) !== "number") {
                    continue;
                }

                driftElement[j] = parseFloat((driftElement[j]).toFixed(4));
            }
        }
    }
    else if(typeof (drifts) === "number")
    {
        drifts = parseFloat(drifts.toFixed(4));
    }

    return drifts;
}

export function formMLMetricsCardData(data) {
    let metricsCardData = [];
    data.forEach(element => {
            let job_id = element["job_id"];
            let key = element["metrics_id"];
            element["hide_edit"] = false;
            let hasJob = (job_id !== undefined && job_id !== null);
            element["key"] = key;
            if((element.metric_type === metricType.DEFAULT && !hasJob) || (element.metric_type === metricType.COMBINED && !hasJob)){
                metricsCardData.push(element);
            }
        });
    return metricsCardData;
}

export function checkConsistencyStatus(data) {
    let finalOp = [];

    for (let i = 0; i < data.length; i++) {
        let masData = data[i];
        if (masData === undefined || masData === null) {
            continue;
        }
        let metrics = masData["metrics"];

        for (let j = 0; j < metrics.length; j++) {
            let d = metrics[j];
            const jobId = d['job_id'];
            const isTemplate = (jobId !== undefined && jobId !== null);
            if (d['metric_type'] === "UDF" || isTemplate) {
                finalOp.push(d);
            }
        }
    }

    if (finalOp.length > 0 ){
        return true
    }
    return false;
}

export function getConsistencyCardDetails(data){
    let dataConsistency = data.filter(x=>x.mas_name === DATA_CONSISTENCY);
    let result = {"categorical_encode": 0, "normalization_consistency": 0, "data_type_consistency": 0}
    if (dataConsistency !== undefined && dataConsistency.length > 0){
        let metrics = dataConsistency[0].metrics;
        for (let j = 0; j < metrics.length; j++) {
            let d = _.cloneDeep(metrics[j]);
            if (d["base_metric_id"] === null){
                continue;
            }
            const metricName = d['metric_name'].toLowerCase().replace(/ /g,'_');
            if(metricName === "categorical_encode"){
                result['categorical_encode'] = result["categorical_encode"]+1;
            } else if(metricName === "data_type_consistency"){
                result["data_type_consistency"] = result["data_type_consistency"]+1;
            } else if(metricName === "normalization_consistency"){
                result["normalization_consistency"] = result["normalization_consistency"]+1;
            }
        }
    }
    return result;
}

export function getDefaultMetricsTableData(data) {
    let headers = ["Metric Name", "Metric Associations", "Data Set",
        "Type of Formula", "Created By",
        "Created Date", "Version", "Actions"];

    let finalOp = [];

    for (let i = 0; i < data.length; i++) {
        let masData = data[i];
        if (masData === undefined || masData === null) {
            continue;
        }

        let metrics = masData["metrics"];
        const masName = masData["mas_name"];
        if (masName !== DATA_CONSISTENCY){
            continue
        }
        for (let j = 0; j < metrics.length; j++) {
            let d = _.cloneDeep(metrics[j]);
            const metricsDefinition = d['metrics_definition'];
            const metricName = d['metric_name'];
            const data_set_id = d['data_set_id'];
            let key = d["metrics_id"];
            const isTemplate = (data_set_id !== undefined && data_set_id !== null);
            if (isTemplate) {
                let row = [];
                let formula = metricsDefinition['formula'];
                if (typeof formula === "object") {
                    formula = formula["type"];
                }

                if (data_set_id !== undefined && data_set_id !== null) {
                    key = `${key}_${data_set_id}`;
                }

                d["key"] = `${key}_${d["status_code"]}`;
                row.push({"value": metricName, "type": "td"});
                row.push({"value": masName, "type": "td"});
                row.push({"value": normalizeChildDatasetName(d["data_set_name"]), "type": "td"});
                row.push({"value": formula, "type": "td"});
                row.push({"value": localStorage.getItem('user_name'), "type": "td"});
                row.push({"value": d["created_time"], "type": "td"});
                row.push({"value": metricsDefinition['version'], "type": "td"});
                row.push({"value": "", "type": "component", "metrics_data": d});
                finalOp.push(row);
            }
        }
    }


    let table_data = {
        "headers": headers,
        "data": finalOp
    }

    return table_data;
}


export function getMetricsTableData(data) {
    let headers = ["Metric Name", "Metric Associations", "Data Set",
        "Type of Formula", "Created By",
        "Created Date", "Version", "Actions"];

    let finalOp = [];

    for (let i = 0; i < data.length; i++) {
        let masData = data[i];
        if (masData === undefined || masData === null) {
            continue;
        }

        let metrics = masData["metrics"];
        const masName = masData["mas_name"];

        for (let j = 0; j < metrics.length; j++) {
            let d = _.cloneDeep(metrics[j]);
            const metricsDefinition = d['metrics_definition'];
            const metricName = d['metric_name'];
            const data_set_id = d['data_set_id'];
            let key = d["metrics_id"];
//            const isTemplate = (data_set_id !== undefined && data_set_id !== null);
            if (d['metric_type'] === "UDF") {
                let row = [];
                let formula = metricsDefinition['formula'];
                if (typeof formula === "object") {
                    formula = formula["type"];
                }

                if (data_set_id !== undefined && data_set_id !== null) {
                    key = `${key}_${data_set_id}`;
                }

                d["key"] = `${key}_${d["status_code"]}`;
                row.push({"value": metricName, "type": "td"});
                row.push({"value": masName, "type": "td"});
                row.push({"value": normalizeChildDatasetName(d["data_set_name"]), "type": "td"});
                row.push({"value": formula, "type": "td"});
                row.push({"value": metricsDefinition['author'], "type": "td"});
                row.push({"value": d["created_time"], "type": "td"});
                row.push({"value": metricsDefinition['version'], "type": "td"});
                row.push({"value": "", "type": "component", "metrics_data": d});
                finalOp.push(row);
            }
        }
    }


    let table_data = {
        "headers": headers,
        "data": finalOp
    }

    return table_data;
}

export function hasSimpleTextFormula(metricDefinition) {
    let hasTextFormula = false;
    if (metricDefinition === undefined || metricDefinition === null) {
        return hasTextFormula;
    }

    hasTextFormula = ((typeof metricDefinition.formula) === "string");
    return hasTextFormula;
}

export function getMetricTypeFromDefinition(metricDefinition) {
    let metricType = null;
    if (metricDefinition === undefined || metricDefinition === null) {
        return metricType;
    }

    const masType = metricDefinition.mas_type;
    if (masType === "dqm") {
        metricType = "dq";
    }
    else if (masType === "mlm") {
        metricType = "ml";
    }

    return metricType;
}

export function isTemplateMetric(metricDefinition) {
    let isTemplateMetric = false;
    if (metricDefinition === undefined || metricDefinition === null) {
        return isTemplateMetric;
    }

    const isTemplate = metricDefinition.is_template;
    if (isTemplate === undefined || isTemplate === null) {
        return isTemplateMetric;
    }

    isTemplateMetric = (isTemplate === true);
    return isTemplateMetric;
}

export function getFormulaContent(formula){
    let formulaDetail = formula;
    if (typeof (formula) == 'object') {
        let type = formula["type"];
        if (type === "sql_as_formula"){
            formulaDetail = formula["query"];
        }else if(type === "custom_formula"){
            formulaDetail = formula["components"];
        }
    }

    return formulaDetail;
}

export function getViewDetailsForMetrics(metricsData) {
    let viewDetails = {};
    Object.keys(metricsData).forEach(function (key) {
        let value;
        if (key === 'metrics_definition') {
            let definition = metricsData[key];
            const checks = definition["checks"];
            if (checks !== undefined && checks !== null && checks.length > 0) {
                viewDetails["checks"] = JSON.stringify(checks);
            }
            const formula = definition["formula"];
            viewDetails["formula"] = getFormulaContent(formula);
        }
        else {
            value = metricsData[key];
            viewDetails[key] = value;
        }
    });

    return viewDetails;
}

export function getCurrentFilterData(selectedDataSet, availableData) {
    let chartData = [];
    availableData.map(function (r) {
        if (r["data_set_id"] === selectedDataSet["value"]) {
            chartData.push(r);
        }
        return 1;
    });
    return chartData;
}


export function getCurrentMLFilterData(selectedMlModel, availableData) {
    let chartData = [];
    availableData.map(function (r) {
        if (r["ml_model_id"] === selectedMlModel["value"]) {
            chartData.push(r);
        }
        return 1;
    });
    return chartData;
}


export function getChartTitleBasedOnData(data, dataSource=null, includeDatasetName=true){
    if (data === undefined || data === null) {
        return "";
    }
    let selectedModel = data["selectedMlModel"];
    if (selectedModel !== undefined && selectedModel !=null) {
//        includeDatasetName = false;
        dataSource = selectedModel;
    }

    let dataSourceState = (dataSource !== undefined && dataSource !== null);
    let title  = dataSourceState? dataSource["label"] : "";
    let requiresSeparator = dataSourceState;
    let separator = CHART_TITLE_SEPARATOR;
    // let metricSeparator = CHART_TITLE_METRIC_SEPARATOR;

    let dataSetName = data.data_set_name;
    let attributeName = data.attribute_name;
    // let metricName = data.name;

    if (dataSetName !== undefined && includeDatasetName) {
        title = requiresSeparator ? `${title}${separator}${dataSetName}`
            : `${dataSetName}`;
        requiresSeparator = true;
    }

    if (attributeName !== undefined) {
        title = requiresSeparator ? `${title}${separator}${attributeName}`
            : `${attributeName}`;
    }

    // if (metricName !== undefined) {
    //     title = `${title}${metricSeparator}${metricName}`;
    // }

    return title;
}

export function formChartTitleForModelPerformanceChart(title, selectedMlModel){
    if (title.toLowerCase() === "confusion matrix") {
        title = "Model Confusion Matrix"
    }

    if (selectedMlModel === undefined || selectedMlModel === null) {
        return title;
    }

    return `${selectedMlModel["label"]} - ${title}`;
}

export function extractMetricNameFromChartName(chartName, isAttribute) {
    let nameSplit = chartName.split(CHART_TITLE_METRIC_SEPARATOR);
    if (nameSplit.length < 2){
        return chartName;
    }

    let fqMetricName = nameSplit[nameSplit.length - 1];
    let dataSetAttributeName = nameSplit[0];
    let dataSetAttributeSplit = dataSetAttributeName.split(CHART_TITLE_SEPARATOR);
    if (dataSetAttributeSplit.length < 2) {
        return fqMetricName;
    }

    let lastElement = dataSetAttributeSplit[dataSetAttributeSplit.length - 1];
    if (!isAttribute) {
        // last element is data set name, since full name does not have attribute in it
        return lastElement;
    }

    // Last element is attribute, since full name has attribute name in it
    return `${lastElement}_${fqMetricName}`;
}

export function replaceNullValues(chartData) {
    let driftValues = chartData["drift"];
    if (driftValues === undefined || driftValues === null) {
        return;
    }

    chartData["drift"] = driftValues.map(x => x === null ? 0 : x);
}

export function getMonitorTimeStampMessage(){
    if (this.state.lastMonitorTime){
        return "Charts displayed are based on the analysis happened at, "+ this.state.lastMonitorTime;
    } else{
        return "Loading ..";
    }
    }

export function getProfileTimeStampMessage() {
    if (this.state.lastProfilingTime){
        return "Your data last profiled at, "+ this.state.lastProfilingTime;
    } else {
        return "Loading ..";
    }
}

export function filterChartDataForTime(chartData, startTime, endTime, considerEndTimeAlone=false, maxDataPoints=8) {
    let driftValues;
    if ("dq_chart_points" in chartData) {
        driftValues = chartData.dq_chart_points;
    } else {
        driftValues = chartData.drift;
    }

    let times = chartData.time;
    let filteredTimes = [];
    let filteredValues = [];
    let filteredDriftPattern = [];
    let filterErrorRows = [];
    let DqValues = [];
    let boxPlotData = null;
    let filteredVersionName = [];
    let filteredMetricThresholdStatus = []
    let filteredAvgRowCount = []
    let filteredDeviationPercentage = []
    let filteredAllStringColumnStatus = []
    let filteredMetricThresholdError = []
    if (times === undefined) {
        return {
            "drift": filteredValues,
            "time": filteredTimes,
            "dq_chart_points": DqValues,
            "drift_patterns":  filteredDriftPattern,
            "boxPlotData": boxPlotData,
            "error_rows": filterErrorRows,
            "version_name": filteredVersionName,
            "metric_threshold_error_diff_value": filteredMetricThresholdError,
            "metric_threshold_error_status": filteredMetricThresholdStatus,
        }
    }
    if (chartData.boxPlotData !== undefined) {
         let boxPlotDataCopy = _.cloneDeep(chartData.boxPlotData);
         if (chartData.boxPlotData.statistics !== undefined && chartData.boxPlotData.statistics !== null) {
            let statisticsData = chartData.boxPlotData.statistics;
            let UpdatedStatisticsData = [];
            for (let i = 0; i < statisticsData.length; i++) {
                let copiedData = _.cloneDeep(statisticsData[i]);
                let quantile = copiedData.quantile;
                let timeList = copiedData.time;
                let filteredQuantile = [];
                let filteredTimes = [];
                for (let index = 0; index < timeList.length; index++) {
                    let value = quantile[index];
                    let xTime = timeList[index];

                    let timeObj = getDateObject(xTime);

                    const parsedDate = moment(timeObj);
                    const cDateIsBeforeEndDate = parsedDate.isSameOrBefore(endTime);

                    const canIncludeVal = ((parsedDate.isSameOrAfter(startTime) && cDateIsBeforeEndDate) ||
                        (considerEndTimeAlone && cDateIsBeforeEndDate));

                    if(canIncludeVal) {
                        filteredTimes.push(xTime);
                        filteredQuantile.push(value);
                    }
                }
                if (filteredTimes.length > 0){
                    if(considerEndTimeAlone) {
                       filteredQuantile = filteredQuantile.slice(Math.max(filteredQuantile.length - maxDataPoints, 0))
                       filteredTimes = filteredTimes.slice(Math.max(filteredTimes.length - maxDataPoints, 0))
                    }
                    copiedData.quantile = filteredQuantile;
                    copiedData.time = filteredTimes;
                    UpdatedStatisticsData.push(copiedData);
                }
            }
            boxPlotDataCopy.statistics = UpdatedStatisticsData;
            boxPlotData = boxPlotDataCopy;
         }
    }

    let drift_patterns = [];
    if ('drift_patterns' in chartData){
        drift_patterns = chartData.drift_patterns;
    }
    let error_rows = []
    if('error_rows' in chartData){
       error_rows = chartData.error_rows;
    }
    let metricThresholdErrors = chartData.metric_threshold_error_diff_value;
    let metricThresholdStatuses = chartData.metric_threshold_error_status;
    let avgRowCount = chartData.avg_row_count
    let deviationPercentage = chartData.deviation_percentage
    let isAllStringColumn = chartData.is_all_string_column;
    let version_names = []

    if('version_name' in chartData){
       version_names = chartData.version_name;
    }


    for (let index = 0; index < times.length; index++) {
        let value = driftValues[index];
        let xTime = times[index];

        let timeObj = getDateObject(xTime);

        const parsedDate = moment(timeObj);
        const cDateIsBeforeEndDate = parsedDate.isSameOrBefore(endTime);
        const canIncludeVal = ((parsedDate.isSameOrAfter(startTime) && cDateIsBeforeEndDate) ||
            (considerEndTimeAlone && cDateIsBeforeEndDate));

        if (canIncludeVal) {
            filteredTimes.push(xTime);
            filteredValues.push(value);
            if (times.length === drift_patterns.length) {
                let drift_pattern = drift_patterns[index];
                filteredDriftPattern.push(drift_pattern);
            }
            if(error_rows !== undefined && error_rows.length > 0 && error_rows.length === times.length) {
              let error_row = error_rows[index]
              filterErrorRows.push(error_row)
            }
            if(version_names !== undefined && version_names !== null && version_names.length > 0 && version_names.length === times.length) {
              let version_name = version_names[index]
              filteredVersionName.push(version_name)
            }
            if(metricThresholdErrors !== undefined && metricThresholdErrors.length > 0 && metricThresholdErrors.length === times.length) {
              let metricThresholdError = metricThresholdErrors[index]
              filteredMetricThresholdError.push(metricThresholdError)
            }
            if(metricThresholdStatuses !== undefined && metricThresholdStatuses.length > 0 && metricThresholdStatuses.length === times.length) {
              let metricThresholdStatus = metricThresholdStatuses[index]
              filteredMetricThresholdStatus.push(metricThresholdStatus)
            }
            if(isAllStringColumn !== undefined && isAllStringColumn.length > 0 && isAllStringColumn.length === times.length) {
              let isAllStringColumn_ = isAllStringColumn[index]
              filteredAllStringColumnStatus.push(isAllStringColumn_)
            }
            if(avgRowCount !== undefined && avgRowCount.length > 0 && avgRowCount.length === times.length) {
              let avgRow = avgRowCount[index]
              filteredAvgRowCount.push(avgRow)
            }
            if(deviationPercentage !== undefined && deviationPercentage.length > 0 && deviationPercentage.length === times.length) {
              let deviationPercentages = deviationPercentage[index]
              filteredDeviationPercentage.push(deviationPercentages)
            }
        }
    }

    if (boxPlotData !== null && boxPlotData.statistics.length === 0){
       boxPlotData = null;
    }

    if (considerEndTimeAlone) {
        filteredTimes = filteredTimes.slice(Math.max(filteredTimes.length - maxDataPoints, 0));
        filteredValues = filteredValues.slice(Math.max(filteredValues.length - maxDataPoints, 0));
        filteredDriftPattern = filteredDriftPattern.slice(Math.max(filteredDriftPattern.length - maxDataPoints, 0));
        filterErrorRows = filterErrorRows.slice(Math.max(filterErrorRows.length - maxDataPoints, 0));
    }
    if ("dq_chart_points" in chartData) {
        return {
            "time": filteredTimes,
            "dq_chart_points": filteredValues,
            "boxPlotData": boxPlotData,
            "drift_patterns": filteredDriftPattern,
            "error_rows": filterErrorRows,
            "metric_threshold_error_diff_value": filteredMetricThresholdError,
            "metric_threshold_error_status": filteredMetricThresholdStatus,
            "is_all_string_column":filteredAllStringColumnStatus,
            "avg_row_count": filteredAvgRowCount,
            "deviation_percentage": filteredDeviationPercentage
         }
    }
    return {
        "drift": filteredValues,
        "time": filteredTimes,
        "boxPlotData": boxPlotData,
        "drift_patterns": filteredDriftPattern,
        "error_rows": filterErrorRows,
        "metric_threshold_error_diff_value": filteredMetricThresholdError,
        "metric_threshold_error_status": filteredMetricThresholdStatus,
        "is_all_string_column":filteredAllStringColumnStatus
    }
}

export function getConfusionMatrixModelPerformanceTimeFilter(chartData, startTime, endTime, considerEndTimeAlone){
    let all_details =  chartData["all_details"];
    let filteredList = [];
    for(let d of all_details){
        let timeObj = new Date(d["payload_created_time"]);
        const parsedDate = moment(timeObj);
        const cDateIsBeforeEndDate = parsedDate.isSameOrBefore(endTime);
        const canIncludeVal = ((parsedDate.isSameOrAfter(startTime) && cDateIsBeforeEndDate) ||
            (considerEndTimeAlone && cDateIsBeforeEndDate));
        if(canIncludeVal) {
            filteredList.push(d);
        }
    }

    if (filteredList.length > 0){
        chartData["all_details"] = filteredList;
        return chartData
    } else {
        return null;
    }
}


export function getHorizontalChartModelPerformanceTimeFilter(chartData, startTime, endTime, considerEndTimeAlone){
    let chartDetails =  chartData["chartDataPoints"];
    let timeData = chartData["time"]
    let filteredTimeList = [];
    let filteredChartpointList = [];
    for(let i in timeData){
        let t = timeData[i]
        let timeObj = new Date(timeData[i]);
        const parsedDate = moment(timeObj);
        const cDateIsBeforeEndDate = parsedDate.isSameOrBefore(endTime);
        const canIncludeVal = ((parsedDate.isSameOrAfter(startTime) && cDateIsBeforeEndDate) ||
            (considerEndTimeAlone && cDateIsBeforeEndDate));
        if(canIncludeVal) {
            filteredTimeList.push(t);
            filteredChartpointList.push(chartDetails[i]);
        }
    }

    if (filteredTimeList.length > 0){
        chartData["time"]=filteredTimeList;
        chartData["chartDataPoints"] = filteredChartpointList;
        return chartData
    } else {
        return null;
    }
}


export function applyTimeFilterOnGroupedChart(timeData, chartDetails, startTime, endTime, considerEndTimeAlone){
    let filteredTimeList = [];
    let filteredChartpointList = [];
    for(let i in timeData){
        let t = timeData[i]
        let timeObj = new Date(timeData[i]);
        const parsedDate = moment(timeObj);
        const cDateIsBeforeEndDate = parsedDate.isSameOrBefore(endTime);
        const canIncludeVal = ((parsedDate.isSameOrAfter(startTime) && cDateIsBeforeEndDate) ||
            (considerEndTimeAlone && cDateIsBeforeEndDate));
        if(canIncludeVal) {
            filteredTimeList.push(t);
            filteredChartpointList.push(chartDetails[i]);
        }
    }
    return {"chart_points": filteredChartpointList, "time": filteredTimeList};
}


export function getHorizontalGroupChartModelPerformanceTimeFilter(chartData, startTime, endTime, considerEndTimeAlone){
    let chartDetails =  chartData["chartDataPoints"];
    let timeData = chartData["time"]
    let d1 = chartData["chartDataPoints"][0];
    let d2 = chartData["chartDataPoints"][1];
    let filteredTimeList = [];
    let filteredChartpointList = [];
    let newChartPoints = []
    if(chartData["chartVariant"] === "serving_vs_serving"){
        // don't change serving base data - available in d1
        // timefilter applicable to data available in d2. respective time available in key-data_set_2_time
        timeData=chartData["data_set_2_time"];
        chartDetails = chartData["chartDataPoints_2"];
        let res = applyTimeFilterOnGroupedChart(timeData,chartDetails, startTime, endTime, considerEndTimeAlone);
        filteredChartpointList=res["chart_points"].slice(-1).pop();
        filteredTimeList=res["time"];
        d2["data_points"] = filteredChartpointList
        newChartPoints=[d1,d2]
    } else if(chartData["chartVariant"] === "training_vs_serving"){
        // time filter applicable to data available in d1 and d2. respective time available in key-data_set_2_time
        //apply on serving data
        let timeData1=chartData["data_set_1_time"];
        let chartDetails1 = chartData["chartDataPoints_1"];
        let res1 = applyTimeFilterOnGroupedChart(timeData1,chartDetails1, startTime, endTime, considerEndTimeAlone);
        if (res1["chart_points"].length > 0){
            let data_points_1=res1["chart_points"].slice(-1).pop();
            d1["data_points"] = data_points_1
            filteredTimeList=res1["time"]
        }

        //apply in training data
        let timeData2 = chartData["data_set_2_time"];
        let chartDetails2 = chartData["chartDataPoints_2"];
        let res2 = applyTimeFilterOnGroupedChart(timeData2,chartDetails2, startTime, endTime, considerEndTimeAlone);
        if (res2["chart_points"].length > 0){
            let data_points_2=res2["chart_points"].slice(-1).pop();
            d2["data_points"] = data_points_2
            filteredTimeList=res2["time"]
        }
        newChartPoints=[d1,d2]
    }

    if (filteredTimeList.length > 0){
        chartData["time"]=filteredTimeList;
        chartData["chartDataPoints"] = newChartPoints;
        return chartData
    } else {
        return null;
    }
}


export function getChartFilterData(currentData, startDate, endDate, chartType, isPatternNotNeeded=false,
                                   considerEndTimeAlone = false, maxDataPoints = 2) {
    let copiedData = _.cloneDeep(currentData);
    let result = null;
    if (chartType === chartTypes.CONFUSION_MATRIX){
        result = getConfusionMatrixModelPerformanceTimeFilter(copiedData, startDate, endDate, considerEndTimeAlone);
        return result;
    } else if(chartType === chartTypes.HORIZONTAL_BAR_CHART || chartType === chartTypes.MULTILINE_FEATURE_CHART){
        result = getHorizontalChartModelPerformanceTimeFilter(copiedData, startDate, endDate, considerEndTimeAlone);
        return result;
    } else if(chartType === chartTypes.GROUPED_HORIZONTAL_BAR_CHART){
        result = getHorizontalGroupChartModelPerformanceTimeFilter(copiedData, startDate, endDate, considerEndTimeAlone)
        return result;
    }
    else {
        result = filterChartDataForTime(currentData, startDate, endDate, considerEndTimeAlone, maxDataPoints);
    }

    let boxPlotDataAvailable = false;
    if (result.boxPlotData !== undefined && result.boxPlotData !== null) {
        boxPlotDataAvailable = true;
        copiedData.boxPlotData = result.boxPlotData;
    }
    let updatedTime = result.time;
    if ((chartType === "boxPlotZoomable" && boxPlotDataAvailable === false)
        || (chartType !== "boxPlotZoomable" && updatedTime.length === 0)) {
        return null;
    } else {
        copiedData["time"] = updatedTime;
        copiedData["drift_patterns"] = result.drift_patterns;
        copiedData["error_rows"] = result.error_rows;
        copiedData["metric_threshold_error_diff_value"] = result.metric_threshold_error_diff_value;
        copiedData["metric_threshold_error_status"] = result.metric_threshold_error_status;
        copiedData["is_all_string_column"] = result.is_all_string_column;
        if (result.drift_patterns.length > 0) {
            let latest_drift_index = result.drift_patterns.length - 1;
            copiedData["drift_pattern"] = result.drift_patterns[latest_drift_index];
        }
        let dataPoints;
        if ("dq_chart_points" in result) {
            copiedData["dq_chart_points"] = result.dq_chart_points;
            dataPoints = result.dq_chart_points
        } else {
            copiedData["drift"] = result.drift;
            dataPoints = result.drift
        }
        if ("avg_row_count" in result) {
            copiedData["avg_row_count"] = result.avg_row_count
        }
        if ("deviation_percentage" in result) {
            copiedData["deviation_percentage"] = result.deviation_percentage
        }
        if(!isPatternNotNeeded) {
            let chartTypeData = getChartTypeForMonitorCharts(copiedData);
            let hasError = chartTypeData.hasError;
            copiedData["type"] = chartTypeData.chartPatternType;
            copiedData["hasDrift"] = hasError;
            let drift_len = dataPoints.length;
            if (drift_len > 1) {
                let recentValue = dataPoints[(drift_len - 1)];
                copiedData["error_percentage"] = recentValue.toFixed(2);
                copiedData["last_error"] = dataPoints[(drift_len - 2)].toFixed(2);
            } else if (drift_len === 1) {
                copiedData["error_percentage"] = dataPoints[(drift_len - 1)].toFixed(2);
                copiedData["last_error"] = dataPoints[(drift_len - 1)].toFixed(2);
            } else {
                copiedData["error_percentage"] = 0;
                copiedData["last_error"] = 0;
            }
        }

        return copiedData;
    }
}

export function getMLModelOptions() {
    let dataSourceOptions = [];
    for (const [key, value] of Object.entries(this.props.monitorModule.mlModelMapping)) {
        dataSourceOptions.push({"label": value, "value": key});
    }

    return dataSourceOptions;
}

export function getMLModelOptionsForGivenMapping(mlModels) {
    let modelOptions = [];

    for (const value of Object.values(mlModels)) {
        let modelType = value["ml_model_type"]
        if(modelType === "classification"){
          let modelDetails = value["model_details"]
          modelType = modelDetails["model_type"]
          if (modelType === undefined) {
            modelType = MODEL_TYPES.BINARY_CLASSIFICATION;
          }
        }

        if (modelType === "clustering"){
            let no_of_datasets = value["model_datasets"].length
            if (no_of_datasets === 3) {
                modelType = MODEL_TYPES.CLUSTERING_MODEL_WO_RESPONSE
            }
            else {
                modelType  = MODEL_TYPES.CLUSTERING_MODEL_WITH_RESPONSE
            }
        }
        modelOptions.push({"label": value["model_name"], "value": value["ml_model_id"].toString(), "type": modelType, "mlType": value["ml_model_type"]});
    }

    return modelOptions;
}

export function normalizeChartData(chart, key="drift") {
    if (chart === undefined || chart === null) {
        return;
    }

    let driftData = chart[key];
    let timeSeries = chart.time;
    if (driftData === undefined || driftData === null ||
        timeSeries === undefined || timeSeries === null) {
        return;
    }

    let updatedDrift = [];
    let updatedTimeSeries = [];
    for (let index = 0; index < driftData.length; index++) {
        let value = driftData[index];
        let timeData = timeSeries[index];
        if (value === null || value === -1) {
            continue;
        }

        updatedDrift.push(value);
        updatedTimeSeries.push(timeData);
    }

    chart[key] = updatedDrift;
    chart["time"] = updatedTimeSeries;

    let requiresNormalization = chart.requires_normalization;
    if (requiresNormalization === undefined || requiresNormalization === null || !requiresNormalization) {
        return;
    }

    let normalizationValue = chart.normalization_value;

    // updatedDrift = [];
    let normal_updatedDrift = [];
    for (let i=0; i < driftData.length; i++) {
        let currentValue = driftData[i];
        const normalizationValueElement = normalizationValue[i];

        if (normalizationValueElement === undefined ||
            normalizationValueElement === null ||
            normalizationValueElement === 0 || currentValue === 0) {
                normal_updatedDrift.push(currentValue);
        } else {
            let normResult = (currentValue / normalizationValueElement) * 100;
            //let normResult = (Math.abs(currentValue - normalizationValueElement) / (normalizationValueElement)) * 100;
            normal_updatedDrift.push(normResult);
        }
    }
    chart[key] = normal_updatedDrift;
    chart["original_value"] = updatedDrift;
}


export function getChartType(chartData, chartCount) {
    let isChartTypeFixed = chartData["useFixedChart"];
    let errorChart = chartData["errorChart"];
    let chartType = chartData["chartType"];

    if (isChartTypeFixed !== undefined && chartType !== undefined) {
        chartType = convertChartTypeToError(chartType, errorChart);
        return chartType;
    }

    chartType = "areaChart";
    if (chartCount % 2 === 0) {
        chartType = "barChart";
    }

    let timeSeries = chartData.time;
    if (timeSeries !== undefined && timeSeries !== null && timeSeries.length <= 2) {
        chartType = "barChart";
    }

    chartType = convertChartTypeToError(chartType, errorChart)
    return chartType;
}


export function sortBasedOnMetrics(a, b) {
    /*
    * Sort based on ascending order.
    * Default metrics should come first.
    * */
    let a_metricName = a["metric"];
    let b_metricName = b["metric"];
    if (a_metricName === undefined){
        a_metricName = a["name"];
        b_metricName = b["name"];
    }
    let aPriorityValue = DEFAULT_METRIC_PRIORITY[a_metricName];
    let bPriorityValue = DEFAULT_METRIC_PRIORITY[b_metricName];

    if (aPriorityValue === undefined || aPriorityValue === null) {
        aPriorityValue = 100;
    }

    if (bPriorityValue === undefined || bPriorityValue === null) {
        bPriorityValue = 100;
    }

    if (aPriorityValue > bPriorityValue) return 1;
    if (bPriorityValue > aPriorityValue) return -1;

    return 0;
}

export function sortBasedOnErrors(a, b) {
    let a_errorName = a["type"];
    let b_errorName = b["type"];
    let a_PriorityValue = DEFAULT_ERROR_PRIORITY[a_errorName];
    let b_PriorityValue = DEFAULT_ERROR_PRIORITY[b_errorName];
    if (a_PriorityValue === undefined || a_PriorityValue === null) {
        a_PriorityValue = 5;
    }
    if (b_PriorityValue === undefined || b_PriorityValue === null) {
        b_PriorityValue = 5;
    }
    if (a_PriorityValue > b_PriorityValue) return 1;
    if (b_PriorityValue > a_PriorityValue) return -1;
    return 0;
}


export function sortModelErrorsBasedOnMetrics(a, b) {
    /*
    * Sort based on ascending order.
    * Default metrics should come first.
    * */
    let a_metricName = a["metric"];
    let b_metricName = b["metric"];

    let aLevel = a['level'];
    let bLevel = b['level'];

    let aPriorityData = DEFAULT_ML_METRIC_PRIORITY[a_metricName];
    let bPriorityData = DEFAULT_ML_METRIC_PRIORITY[b_metricName];
    let aPriorityValue = 100;
    let bPriorityValue = 100;

    if (aPriorityData !== undefined && aPriorityData !== null) {
        aPriorityValue = aPriorityData[aLevel];
    }

    if (aPriorityValue === undefined || aPriorityValue === null) {
        aPriorityValue = 100;
    }

    if (bPriorityData !== undefined && bPriorityData !== null) {
        bPriorityValue = bPriorityData[bLevel];
    }

    if (bPriorityValue === undefined || bPriorityValue === null) {
        bPriorityValue = 100;
    }

    if (aPriorityValue > bPriorityValue) return 1;
    if (bPriorityValue > aPriorityValue) return -1;

    return 0;
}

export function getPercentage(value1, value2, defaultValue = 0, decimals = 2) {
    const number1 = parseFloat(String(value1));
    const number2 = parseFloat(String(value2));
    let diff = number1 - number2;
    if (parseInt(value1) === 0) {
        return defaultValue;
    }

    return ((diff / number1) * 100).toFixed(decimals);
}

export function getCurrentUTCDateTime() {
    const currentUTCMoment = moment.utc();
    const parsedYear = currentUTCMoment.year();
    const parsedMonth = currentUTCMoment.month();
    const parsedDay = currentUTCMoment.date();
    const parsedUTCHours = currentUTCMoment.hours();
    const parsedUTCMinutes = currentUTCMoment.minutes();
    const parsedUTCSeconds = currentUTCMoment.seconds();

    return new Date(parsedYear, parsedMonth, parsedDay,
        parsedUTCHours, parsedUTCMinutes, parsedUTCSeconds);
}

export function trimTimeLabel(timeLabel, timeValue) {
    if (timeValue <= 1){
        return timeLabel.slice(0, -1) //'Removes "s" from the end'
    }

    return timeLabel;
}

export function formatFutureTime(endTime) {
    /*
     * Gives the timer information between the incoming time and current time.
     * Requirements:
     * Incoming time will be always greater than current time.
     *
     * Incoming time object will be in UTC. We will be comparing it against current UTC time.
     *
     * Returns: "10 Mins more" or "10 days more" depends on the difference between the 2 timings
     */
    let minutes = null;
    let hours = null;
    let days = null;
    let currentTime = getCurrentUTCDateTime();
    let timeDifference = (endTime - currentTime);

    let differenceTimeString;
    let suffix = " more";
    if (timeDifference < 0) {
        timeDifference = timeDifference * -1;
        suffix = " ago";
    }

    minutes = parseInt((timeDifference) / (60 * 1000));
    hours = parseInt((timeDifference) / (3600 * 1000));
    days = parseInt((timeDifference) / (24 * 3600 * 1000));


    if (minutes != null && minutes <= 60) {
        differenceTimeString = minutes + " " + trimTimeLabel("Mins", minutes);
    } else if (hours != null && hours <= 24) {
        differenceTimeString = hours + " " + trimTimeLabel("Hours", hours);
    } else {
        differenceTimeString = days + " " + trimTimeLabel("days", days)
    }

    return differenceTimeString + suffix;
}

export function parseDateAsUTC(createdTimeString) {
    /*
     * This function parses the incoming GMT date time string into the same Date object.
     * For example, if the incoming GMT time is 'Aug 04 2020 23:59:59',
     * While parsing we will get as 'Aug 05 2020 05:29:59', if the browser is in India. (GMT + 5:30)
     * For the same account & scenario, we might get different time while accessing it from countries
     * which are in different time zones.
     *
     * To handle this discrepancy, while parsing itself we are parsing the date as UTC.
     * So now if we get date string as 'Aug 04 2020 23:59:59',
     * we will get date object as 'Aug 04 2020 23:59:59'
     * */
    const parsedMoment = moment.utc(createdTimeString);

    const parsedYear = parsedMoment.year();
    const parsedMonth = parsedMoment.month();
    const parsedDay = parsedMoment.date();
    const parsedUTCHours = parsedMoment.hours();
    const parsedUTCMinutes = parsedMoment.minutes();
    const parsedUTCSeconds = parsedMoment.seconds();
    const utcDate = new Date(parsedYear, parsedMonth, parsedDay,
        parsedUTCHours, parsedUTCMinutes, parsedUTCSeconds);

    return utcDate;
}

export function addTime(inputDate, quantity, part = DateParts.YEAR) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();

    let result = null;
    if (part === DateParts.YEAR) {
        result = new Date(year + quantity, month, day);
    } else if (part === DateParts.MONTH) {
        result = new Date(year, month + quantity, day);
    } else if (part === DateParts.DAY) {
        result = new Date(year, month, day + quantity);
    }

    return result;
}


export function convertChartTypeToError(chartType, isError) {
    if (!isError) {
        return chartType;
    }

    if (chartType === "barChart") {
        return "barWithError";
    }

    if (chartType === "areaChart") {
        return "areaWithError";
    }

    return chartType;
}

export function logoutPageRedirect(){
    let sessionToken = localStorage.getItem("sessionToken");
    if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
        window.location = '/';
        return true
    }
    else{
        return false
    }
}

export function removeLocalStorageKeys() {

    // Clear localstorage
    localStorage.removeItem('DataSetupStatus');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('team_id');
    localStorage.removeItem('env_id');
    localStorage.removeItem("org_id");
    localStorage.removeItem('user_id');
    localStorage.removeItem('current_status');
    localStorage.removeItem('user_signup_type');
    localStorage.removeItem('isPlanExpired');
    localStorage.removeItem(MANDATE_RESET_PASSWORD.Reset_Password);
    localStorage.removeItem('qualdoPlan');
    localStorage.removeItem('qualdoPlanCycle');
    localStorage.removeItem('qualdoEdition');
    localStorage.removeItem('plan_details');
    localStorage.removeItem('permissions');
    localStorage.removeItem('user_name');
    localStorage.removeItem('qualdoPlanDetails');
    localStorage.removeItem('emailId');
    localStorage.removeItem('warning_message');
    localStorage.removeItem('selected_notification_id');
    localStorage.removeItem('featureAccess');

    // Clear localforage - local db memory and reset the values
    store.dispatch(setInitialValues());
    store.dispatch(setMonitorInitialValues());

}

export function convertBytesToGB(values_in_bytes){
    return (values_in_bytes / Math.pow(1024, 3));
}

export function formatBytes(a, b = 2) {
    if (0 === a) return "0 GB";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    const memoryValue = parseFloat((a / Math.pow(1024, d)).toFixed(c));
    return memoryValue + " " + MEMORY_UNITS[d];
}

export function getChartTypeForMonitorCharts(chartData) {
    let hasError = false;
    let hasSecondaryError = false;
    let threshold_error_len = 0;
    const metricThresholdErrorStatuses = chartData.metric_threshold_error_status;
    if (metricThresholdErrorStatuses !== undefined && metricThresholdErrorStatuses !== null) {
        threshold_error_len = metricThresholdErrorStatuses.length;
    }

    const driftPatterns = chartData.drift_patterns;
    let chartPatternType = driftPatterns[driftPatterns.length - 1];

    if (threshold_error_len > 0) {
        let metric_threshold_error_status = metricThresholdErrorStatuses[(threshold_error_len - 1)];
        if (metric_threshold_error_status) {
            chartPatternType = DRIFT_PATTERN_TYPES.THRESHOLD_ALERT;
        }

        // If the last threshold status is True which means error and if it is ML Data Consistency,
        // we will show the chart pattern type as "Errors Detected"
        if(metric_threshold_error_status && chartData["name"] === ML_DATA_CONSISTENCY) {
           chartPatternType = DRIFT_PATTERN_TYPES.ERRORS_DETECTED;
        }

    }

    let prev = undefined;
    for (let i = 0; i < driftPatterns.length; i++) {
        const currentDriftPattern = driftPatterns[i];
        if (NON_ERROR_DRIFT_PATTERNS.includes(currentDriftPattern)){
            prev = currentDriftPattern;
            continue;
        }

        if (currentDriftPattern === DRIFT_PATTERN_TYPES.THRESHOLD_ALERT){
            prev = currentDriftPattern;
            hasError = true;
            break;
        }

        // if (prev === undefined) {
        //     prev = currentDriftPattern;
        //     continue;
        // }

        // hasSecondaryError = true;

        // if (currentDriftPattern !== prev){
        //     hasError = true;
        //     prev = currentDriftPattern;
        //     break;
        // }

        // prev = currentDriftPattern;
    }

    // If last data point is not error, but previous data point has any errors pattern
    // We will show this in error. i.e Gray and Red colour bars in charts
    if (!hasError && hasSecondaryError) {
        hasError = true;
    }

    // If last data point is not error, but previous data point has any errors pattern
    // We will show this in error. i.e Grey and Red colour bars in charts
    if (hasError && prev !== undefined &&
        !NON_ERROR_DRIFT_PATTERNS.includes(prev) &&
        NON_ERROR_DRIFT_PATTERNS.includes(chartPatternType)) {
        chartPatternType = prev;
    }

    return {
        "hasError": hasError,
        "chartPatternType": chartPatternType
    }
}

export function getAliasChartName(actualChartName, isDataSetLevel) {
    let aliasChartName = actualChartName;
    if (actualChartName === MODEL_DRIFT) {
        if (isDataSetLevel) {
            // Dataset level - Model Drift will be shown as Serving Data Drift
            aliasChartName = SERVING_DATA_DRIFT;
        } else {
            // Attribute level - Model Drift will be shown as Feature Drift
            aliasChartName = FEATURE_DRIFT_TEMPORAL;
        }
    }

    return aliasChartName;
}

export function getAliasNameForMetric(metricName) {
    let updatedMetricName = METRIC_ALIAS_NAMES[metricName];
    if (updatedMetricName !== undefined){
        return updatedMetricName === "Unique Values" ? "Unique Rows" : updatedMetricName;
    }
    if(metricName === "Unique Values" || metricName === "Unique Rows"){
        return "Uniqueness"
    }
    return metricName
}

export function getAliasNameForMetricAttributeLevel(metricName) {
    let updatedMetricName = METRIC_ALIAS_NAMES[metricName];
    if (updatedMetricName !== undefined){
        return updatedMetricName === "Unique Values" ? "Unique Values" : updatedMetricName;
    }
    if(metricName === "Unique Values" || metricName === "Unique Rows"){
        return "Uniqueness"
    }
    return metricName
}

export function getErrorTypeForStatsComponent(errorType){
    let updatedErrorType = errorType;
    if (errorType === "NO_DRIFT" || errorType === "not_computed"){
        updatedErrorType = "No Errors Detected";
    }

    if (errorType === "NO_DRIFT" || errorType === "not_computed" ||
        NON_ERROR_DRIFT_PATTERNS.includes(errorType)){
    updatedErrorType = "No Errors Detected";
}

    return convertToCamelCase(updatedErrorType);
}

export function convertToCamelCase(input) {
    if (input === undefined || input === null || input.length === 0) {
        return input;
    }

    const firstChar = input.charAt(0);
    return firstChar.toUpperCase() + input.slice(1);
}

export function getProtocol(ipAddress) {
    if (ipAddress.includes("localhost")){
        return "http:";
    }

    return PROTOCOL;
}

export function getQualdoPlanCycle() {
    let selectedPlanCycle = localStorage.getItem('qualdoPlanCycle');
    if (selectedPlanCycle === QualdoPlanCycle.TRIAL_14_DAYS) {
        selectedPlanCycle = QualdoPlanCycle.MONTHLY;
    }
    if (selectedPlanCycle === undefined || selectedPlanCycle === null ) {
        selectedPlanCycle = QualdoPlanCycle.YEARLY;
    }
    return selectedPlanCycle;
}

export function removeQualdoPlanCycle() {
    localStorage.removeItem("qualdoPlanCycle");
    localStorage.removeItem("src_choose_plan")
}

export function getCurrentPlanDetailOfUser() {
    let details_json = null;
    let planDetails = localStorage.getItem("plan_details");
    if (planDetails !== null && planDetails !== undefined
        && planDetails !== "null" && String(planDetails).trim().length > 0) {
        details_json = JSON.parse(planDetails);
    }

    return details_json;
}

export function isPlanChanged(selectedPlan, selectedCycle) {
    let currentUserPlan = getCurrentPlanDetailOfUser();
    if (currentUserPlan === null) {
        return true;
    }

    let currentPlanCycle = currentUserPlan.plan_cycle;
    let currentPlanName = currentUserPlan.plan_name;
    return (selectedPlan !== currentPlanName || selectedCycle !== currentPlanCycle);
}

export function isUserOnFreeTrial() {
    let currentUserPlan = getCurrentPlanDetailOfUser();
    if (currentUserPlan === null) {
        return false;
    }

    let currentPlanCycle = currentUserPlan.plan_cycle;
    let currentPlanName = currentUserPlan.plan_name;
    return (currentPlanCycle === QualdoPlanCycle.TRIAL_14_DAYS &&
        currentPlanName === QualdoPlanVersions.FREE)
}

export function getRedirectionLinkToExpiredPlan() {
    let freeTrialUser = isUserOnFreeTrial();
    if (freeTrialUser) {
        return "/choose-plan";
    }

    return "/plan";
}

export function getRedirectionLinkToDiscover() {
    return "/discover";
}

export function getRedirectionLinkToChangePassword() {
    return "/password-reset"
}

export function setBasicPlanInLocalStorage() {
    /*
     * This function will be called when user upgrades from Free tier to Basic plan.
     * After updating plan details in database, we need to update the same in local storage.
     * This function will update the plan detail present in local storage
     */
    let currentUserPlan = getCurrentPlanDetailOfUser();
    if (currentUserPlan === null) {
        return;
    }

    currentUserPlan.plan_cycle = QualdoPlanCycle.MONTHLY;
    localStorage.setItem("plan_details", JSON.stringify(currentUserPlan));
}

export function isUserOnBasicPlan() {
    let currentUserPlan = getCurrentPlanDetailOfUser();
    if (currentUserPlan === null) {
        return false;
    }

    let currentPlanCycle = currentUserPlan.plan_cycle;
    let currentPlanName = currentUserPlan.plan_name;
    return (currentPlanCycle === QualdoPlanCycle.MONTHLY &&
        currentPlanName === QualdoPlanVersions.FREE)
}


export function isDataLimitExceeded() {
    let currentUserPlan = getCurrentPlanDetailOfUser();
    if (currentUserPlan === null) {
        return false;
    }

    return currentUserPlan.usage_exceeded;
}

export function filterBoxPlotData(chartData) {
        if (chartData !== undefined && chartData !== null) {
            for (let j=0;j<chartData["statistics"].length;j++) {
                let time = chartData["statistics"][j].time
                let driftPattern = chartData["statistics"][j].drift_patterns
                let quantile = chartData["statistics"][j].quantile
                let timeNew = []
                let quantileNew = []
                let driftPatternNew = []
                if (quantile !== undefined) {
                    for(let i=0;i<quantile.length;i++) {
                        if (quantile[i] !== "NA" && quantile[i] !== undefined) {
                            quantile[i]["sub_q1"] = quantile[i].q1;
                            quantile[i]["sub_q1_min"] = quantile[i].q1_min;
                            quantile[i]["sub_min"] = quantile[i].min;
                            /*if (quantile[i].q1 === quantile[i].q2 && quantile[i].q3 === quantile[i].q1 &&
                                quantile[i].q2 ===quantile[i].q3){
                                // q1=q2=q3=q1_min => substitute q1 = 0 and q1_min = 0
                                if (quantile[i].q1 === quantile[i].q1_min) {
                                   quantile[i]["sub_q1"] = 0;
                                   quantile[i]["sub_q1_min"] = 0;
                                   quantile[i]["sub_min"] = 0;
                                } else {
                                    // q1_min is different => substitute q1=q1_min
                                   quantile[i]["sub_q1"] = quantile[i].q1_min;
                                }
                            }*/
                            quantileNew.push(quantile[i]);
                            timeNew.push(time[i]);
                            driftPatternNew.push(driftPattern[i])
                        }
                    }
                }
                chartData.statistics[j].time = timeNew
                chartData.statistics[j].quantile = quantileNew
                chartData.statistics[j].drift_patterns = driftPatternNew
            }
        }
        return chartData
    }

export function getCurrencySymbol() {
    let currentPlan = getCurrentPlanDetailOfUser();
    if (currentPlan === null) {
        return DEFAULT_CURRENCY_SYMBOL;
    }

    return currentPlan.details.currency;
}


export function canDisableShowMore(data) {
    if (data === undefined || data === null) {
        return true;
    }

    const enableShowMore = (data.hasChartData === true);
    return !enableShowMore;
}


export function hasValidNumberOfDatasets(integrationMap, selectedDataSource) {
    if (integrationMap === undefined || integrationMap === null) {
        return true;
    }

    let currentDatasourceInfo = integrationMap[selectedDataSource];
    if (currentDatasourceInfo === undefined || currentDatasourceInfo === null) {
        return true;
    }

    const discoveryStatus = currentDatasourceInfo.discovery_status;
    const integrationStatusText = currentDatasourceInfo.status_text;

    if (discoveryStatus === "complete" && integrationStatusText === NO_DATA_SET_FOUND_TEXT) {
        return false;
    }

    return true;
}



export function parseMetaMapping(props, type, parentId, id, selectedEnvironmentOption, selectedDataSourceOption) {
    let metaMapping = _.cloneDeep(props.dataModule.metaData);
    let prefix;
    switch (type) {
        case DATA_SET: {
            prefix = `${selectedEnvironmentOption.value}.${parentId}.${id}`;
            // const filtered = Object.keys(metaMapping)
            //     .filter( key => key.startsWith(prefix))
            //     .reduce((obj, key) => {
            //         obj[key] = metaMapping[key];
            //         return obj;
            //     }, {});

            let dataSetName = metaMapping[prefix];
            if (dataSetName === undefined) {
                return "NA";
            }

            let requiredDatasetName = datasetNameFromMappedValue(dataSetName);
            return requiredDatasetName;
        }
        case ATTRIBUTE: {
            prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}.${parentId}.${id}`;
            // const filtered = Object.keys(metaMapping)
            //     .filter(key => key === prefix)
            //     .reduce((obj, key) => {
            //         obj[key] = metaMapping[key];
            //         return obj;
            //     }, {});

            let attributeName = metaMapping[prefix];
            if (attributeName === undefined) {
                return "NA";
            }

            let requiredAttributeName = attributeNameFromMappedValue(attributeName)
            return requiredAttributeName;
        }
        default:
            return "NA";
    }
}


export function capitalize(_stringVal) {
    if (_stringVal === undefined || _stringVal === null) {
        return _stringVal;
    }

    const stringArray = _stringVal.split(/\b(\s)/);
    let result = "";
    for (let data of stringArray) {
        if (data !== " ") {
            result = result + data[0].toUpperCase() + data.slice(1)
        } else {
            result = result + " ";
        }
    }
    return result;
}


export function getDatsetIdForSelectedDatasource(props, integrationId) {
    let datasetId = -1;
    let dataSourceDataSetMapping = props.dataSourceDataSetMapping;
    if (dataSourceDataSetMapping !== undefined && dataSourceDataSetMapping !== null && integrationId !== -1) {
        let datasetIds = dataSourceDataSetMapping[integrationId];
        if (datasetIds !== undefined && datasetIds !== null) {
            if (datasetIds.length > 0) {
                datasetId = datasetIds[0];
            }
        }
    }
    return datasetId;
}

export function getAttributeNameIdForSelectedDatasource(props, integrationId, datasetId) {
    let attributeId = -1;
    let environmentId = -1;
    let attributeName;
    let dataSetAttributeMapping = props.dataSetAttributeMapping;
    if (dataSetAttributeMapping !== undefined && dataSetAttributeMapping !== null &&
        datasetId !== -1) {
        let attributeIds = dataSetAttributeMapping[datasetId];
        if (attributeIds.length > 0) {
           attributeId = attributeIds[0];
        }
    }
    for (const [key, value] of Object.entries(props.environmentDataSourceMapping)) {
        if (value.includes(integrationId.toString())) {
            environmentId = key;
        }
    }
    if (environmentId !== -1 && integrationId !== -1 && datasetId !== -1 && attributeId !== -1) {
        let metaDataKey = environmentId.toString() + "." + integrationId.toString() + "." + datasetId.toString() + "." + attributeId.toString();
        let metaDataValue = props.metaData[metaDataKey];
        if (metaDataValue !== undefined && metaDataValue !== null) {
            attributeName = attributeNameFromMappedValue(metaDataValue);
        }
    }
    return {"attributeName": attributeName, "attributeId": attributeId};
}


export function getRowCountContributionFromData(chartData) {
    const errorRowList = chartData.error_rows;
    if (errorRowList === undefined || errorRowList === null) {
        return GENERIC_CONSTANTS.NA;
    }

    const rowCount = errorRowList.length;
    if (rowCount === 0) {
        return GENERIC_CONSTANTS.NA;
    }

    return errorRowList[rowCount - 1];
}


export function get_document_link(page="index"){
    let token = localStorage.getItem("sessionToken");
    let document_link ="#"
    if (token !== "" && token !== null && token !== undefined) {
        const item = JSON.parse(token);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            removeLocalStorageKeys();
            window.location = '/';
        }
        token = item.value;
        if(page === undefined){
            page = "index"
        }
        let data = {"user_id":localStorage.getItem("user_id"),
                    "team_id":localStorage.getItem("team_id"),
                    "auth_token":token,
                    "iam_ip":iam_ip
        }

        // Encrypt data to prevent exposure of user details in url
        // Decryption will handled in documentation code [In Function : index.html]
        let encryptData = encryptForLocalUsage(data)
        encryptData = encryptData.replace(/\+/g, 'qua111111d000');
        let authDetails = "?data="+encryptData
        let url = DOCUMENT_LINK_MAPPING[page];
        if (url !== undefined){
            let sections = url.split("#")
            if(sections.length > 1){
                let section = sections[1];
                url= sections[0];
                authDetails = authDetails+"&section="+section;
            }
        }
        let doc_link = process.env.REACT_APP_DOCUMENT_BASE_LINK
        document_link = doc_link + url+authDetails

   }
   return document_link
}

export function filterInfoData_mlModel(filteredValues, infoData) {
    let updatedInfoData = {}
    if (filteredValues === null || filteredValues === undefined || infoData === undefined || infoData === null) {
        return updatedInfoData;
    }
    filteredValues.forEach((filteredValue) => {
        const filtered = Object.keys(infoData)
            .filter(key => key.startsWith(filteredValue))
            .reduce((obj, key) => {
                obj[key] = infoData[key];
                return obj;
            }, {});
        updatedInfoData = {...updatedInfoData, ...filtered}
    });
    return updatedInfoData;
}

export function getTimeFilteredData_mlModel(allMetricInfo, startDate,
                                            endDate, metricInfo, key) {
    let timeList = allMetricInfo[metricInfo][`${key}_created_time`];
    let startIndex = -1;
    let endIndex = -1;
    if (timeList.length === 0) {
        return [];
    }

    timeList.forEach((time) => {
        if (moment(time).isSameOrBefore(moment(endDate)) && moment(time).isSameOrAfter(moment(startDate))) {
            let index = timeList.indexOf(time);
            if (startIndex === -1 || startIndex > index) {
                startIndex = index;
            }
            if (endIndex === -1 || endIndex < index) {
                endIndex = index;
            }
        }
    });

    if (startIndex !== -1 && endIndex !== -1) {
        if (startIndex === 0 && endIndex === 0) {
            endIndex = 1;
        }
        let tempMetricValueList = allMetricInfo[metricInfo][`${key}_time_value`][startIndex];
        return tempMetricValueList;
    }
    return null;
}

export function filterTimeData_mlModel_profile(key, tempStartDate) {
    let timeList = [];
    for (let i = 0; i < 8; i++) {
        timeList.push(tempStartDate);
        tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
    }

    return {"time": timeList, "values": STATIC_CARDS[key]}
}

export function formCardData_mlModel(filteredValues, changedDataSource = -1,
                                     mlModelId, timeFilterApplied = false,
                                     startDate = null, endDate = null) {
    let cardInfo = [];
    let allMetricInfo;

    let dataSourceId = this.state.modelDataSourceDetails[mlModelId];

    if (changedDataSource === -1) {
        allMetricInfo = _.cloneDeep(this.props.dataModule.info[dataSourceId]);
    } else {
        allMetricInfo = _.cloneDeep(this.props.dataModule.info[changedDataSource]);
    }
    if (filteredValues.length !== 0) {
        allMetricInfo = this.filterInfoData_mlModel(filteredValues, allMetricInfo);
    }
    let commonId = 0;
    ML_METRIC_ORDER.forEach((key) => {
        let attributeMetricList = [];
        for (const metricInfo in allMetricInfo) {
            if (key in allMetricInfo[metricInfo]) {
                if (timeFilterApplied) {
                    let timeFilteredData = this.getTimeFilteredData_mlModel(allMetricInfo, startDate, endDate, metricInfo, key);
                    if (timeFilteredData !== null && timeFilteredData.length > 0) {
                        attributeMetricList.push(Math.min.apply(null, timeFilteredData));
                    }
                } else {
                    attributeMetricList.push(allMetricInfo[metricInfo][key]);
                }
            }
        }
        if (attributeMetricList.length !== 0) {
            let tempInfo = {};
            if (key === 16) {
                tempInfo["metric_name"] = "No of Serving Features Drift";
                tempInfo["metric_value"] = attributeMetricList.filter(x => x !== 0).length;
            } else if (key === 10) {
                tempInfo["metric_name"] = "No of Serving Features Drift Over Training";
                tempInfo["metric_value"] = attributeMetricList.filter(x => x !== 0).length;
            } else {
                tempInfo["metric_name"] = ML_METRIC[key];
                tempInfo["metric_value"] = `${(attributeMetricList.reduce((a, b) => a + b) / attributeMetricList.length).toFixed(2)}`;
            }
            tempInfo["hide_edit"] = true;
            tempInfo["cardType"] = "chart";
            tempInfo["background"] = ML_METRIC_COLOR[key].cardBackground;
            tempInfo["textColor"] = ML_METRIC_COLOR[key].textColor;
            tempInfo["idValue"] = `mlmetric${commonId}`;
            tempInfo["chartData"] = filterTimeData_mlModel_profile(commonId, this.state.startDate);
            commonId = commonId + 1;
            cardInfo.push(tempInfo);
        }
    });

    return cardInfo;
}

export function getServingFeatureDriftAttributes(data, metaData, timeFilterApplied = false,
                                              startDate = null, endDate = null) {

            let metaDataLength = Object.keys(metaData).length;
            if(data === undefined || metaDataLength === 0) {
               return {"attributeInfo":[],"attributes": [], "attributesOptions": []}
            }
            let attributeInfo = []
            let attributes = []
            let servingDrift = Object.keys(data)[0]
            let servingDriftData = data[servingDrift]
            let attributesOptions = []
            for (const [key, value] of Object.entries(servingDriftData)) {
                 let metaDataValue = metaData[key]
                 if(metaDataValue !== undefined) {
                   let metaDataSplit = metaDataValue.split(METADATA_MAP_VALUE_SEPARATOR)
                   let idsSplit = key.split(METADATA_MAP_KEY_SEPARATOR)
                   let attributeName = metaDataSplit[metaDataSplit.length - 1]
                   let attributeId = idsSplit[idsSplit.length - 1];
                   let timeList = value.time;
                   if(attributeName !== undefined && timeList.length > 0) {
                       attributesOptions.push({"label": attributeName, "value": attributeId})
                       let latest_refresh = timeList[(timeList.length - 1)]
                       startDate = moment().format('YYYY-MM-DD 00:00:00');
                       endDate = moment().format('YYYY-MM-DD 23:59:59');
                       attributeInfo.push({"attribute_name": attributeName, "drift": value["drift"], "drift_patterns": value["drift_patterns"], "time": value["time"],"latest_drift":value["error_percentage"]})
                       if(!attributes.includes(attributeName) && value["drift_pattern"] !== "not_computed" && value["drift_pattern"] !== "NO_DRIFT" && (moment(latest_refresh).isSameOrBefore(moment(endDate)) && moment(latest_refresh).isSameOrAfter(moment(startDate)))) {
                         attributes.push(attributeName)
                       }

                   }
                 }
            }
            return {"attributeInfo":attributeInfo,"attributes": attributes, "attributesOptions": attributesOptions};
}

export function formModelErrorDataForListView(filteredValues, modelDataSourceDetails,
                                              propsDataModuleInfo,
                                              mlModelId, timeFilterApplied = false,
                                              startDate = null, endDate = null) {
    let cardInfo = [];
    let allMetricInfo;
    if (modelDataSourceDetails === undefined || modelDataSourceDetails === null) {
        return [];
    }

    let dataSourceId = modelDataSourceDetails[mlModelId];
    if (dataSourceId === undefined || dataSourceId === null) {
        return [];
    }

    allMetricInfo = _.cloneDeep(propsDataModuleInfo[dataSourceId]);

    if (filteredValues.length !== 0) {
        allMetricInfo = filterInfoData_mlModel(filteredValues, allMetricInfo);
    }

    ML_METRIC_ORDER.forEach((key) => {
        let attributeMetricList = [];
        for (const metricInfo in allMetricInfo) {
            if (key in allMetricInfo[metricInfo]) {
                if (timeFilterApplied === false) {
                    startDate = moment().format('YYYY-MM-DD 00:00:00');
                    endDate = moment().format('YYYY-MM-DD 23:59:59');
                }
                let timeFilteredData = getTimeFilteredData_mlModel(allMetricInfo, startDate,
                        endDate, metricInfo, key);
                if (timeFilteredData) {
                        attributeMetricList.push(timeFilteredData);
                }

            }
        }
        if (attributeMetricList.length !== 0) {
            let tempInfo = {};
            if (key === 16) {
                tempInfo["text"] = "No of Serving Features Drift";
                tempInfo["value"] = attributeMetricList.filter(x => x !== 0).length;
            } else if (key === 10) {
                tempInfo["text"] = "No of Serving Features Drift Over Training";
                tempInfo["value"] = attributeMetricList.filter(x => x !== 0).length;
            } else {
                tempInfo["text"] = ML_METRIC[key];
                tempInfo["value"] = `${(attributeMetricList.reduce((a, b) => a + b) / attributeMetricList.length).toFixed(2)}`;
            }

            cardInfo.push(tempInfo);
        }
    });

    return cardInfo;
}

export function mapModelToDataSource(modelDetails) {
    /**
     * Returns model to data source mapping information.
     * A dictionary with model id as key and corresponding data source id as it's value
     * */
    let modelToDataSource = {};
    modelDetails.forEach((modelDetail) => {
        modelToDataSource[modelDetail.ml_model_id] = modelDetail.integration_id;
    });

    return modelToDataSource;
}

export function getMonitorTabDQListViewTableData(datasetMapping, dqListData, monitorModule, timelinessData) {
    let dqData = []
    if(dqListData.dataset_level_data !== undefined  && dqListData.attribute_level_data !== undefined && timelinessData !== undefined) {
        let timelinessDataValues = timelinessData["DefaultDataSet"]
        let issuedAttributes = dqListData.issued_attributes;
        let attributeLevelData = dqListData.attribute_level_data;
        let dataset_level_data = dqListData.dataset_level_data;
        let followAttributes = monitorModule.follow_attributes;
        let attributeIds = datasetMapping["attributeIds"]
        for (const [key, value] of Object.entries(datasetMapping["idDatasetDetail"])) {
            if(key === "attributeIds" || issuedAttributes[key] === undefined) {
              continue;
            }
            let totalLength = issuedAttributes[key].length
            let lastProfilingTime = issuedAttributes[key][0]["refresh_time"]
            let createdTime = issuedAttributes[key][totalLength - 1]["refresh_time"]
            let startDate = moment().subtract(9, 'days').set({"hour": 0, "minute": 0, "seconds": 0});
            let endDate = moment().endOf('day').set({"hour": 23, "minute": 59, "seconds": 59});
            let previousTime = null;
            let isAlertNeeded = !(moment(lastProfilingTime).isSameOrBefore(endDate) && moment(lastProfilingTime).isSameOrAfter(startDate))
            if(isAlertNeeded) {
              previousTime = lastProfilingTime
            }
            let filteredTimelinessData = []
            let filteredTimeliness = timelinessDataValues[0]
            if(filteredTimeliness !== undefined) {
               filteredTimelinessData = filteredTimeliness["data"].filter(x=>x.data_set_id === key)
            }
            let datasourceName = value["dataSourceName"];
            let datasetName = value["datasetName"]
            let thumbnailCharts = getThumbnailChartsDqErr(dataset_level_data[key], key, datasourceName, datasetName, filteredTimelinessData, datasetName)
            let row = []
            let dataSourceId = value["dataSourceId"]
            let follow_attributes_details = {}
            if(Object.keys(followAttributes).length !== 0){
               follow_attributes_details = followAttributes[dataSourceId]
            }
            row.push({"value": datasourceName, "type": "td", "group": "dataset"});
            row.push({"value": normalizeChildDatasetName(datasetName), "type": "datasetName", "lastProfilingTime": lastProfilingTime, "previousTime": previousTime});
            row.push({"value": 'Dataset Level - Includes All Attributes', "type": "td"});
            row.push({"value": thumbnailCharts, "type": "thumbnailCharts", "issuedAttributes": issuedAttributes[key], "noDataAvailable":  false, "createdTime": createdTime});
            row.push({"value": key, "type": "action", "level": "dataset", "datasetName": datasetName, "dataSourceId": value["dataSourceId"], "dataSourceName":datasourceName, "lastProfilingTime": lastProfilingTime})
            dqData.push(row)
            let filteredAttributes = attributeIds.filter(x=>x.datasetId === key)
            for(let j=0;j<filteredAttributes.length;j++) {
                row = []
                let attributeId = filteredAttributes[j]["attributeId"]
                let attributeName = filteredAttributes[j]["attributeName"]
                let follow_status = follow_attributes_details[attributeId] !== undefined ? follow_attributes_details[attributeId] : false
                let thumbnailChartsAttributes = getThumbnailChartsDqErr(attributeLevelData[key][attributeId], attributeId, datasourceName, attributeName, datasetName)
                row.push({"value": "", "type": "td", "group": "attribute"});
                row.push({"value": "", "type": "td"});
                let reqAttributeName = normalizeAttributeName(attributeName);
                row.push({"value": reqAttributeName, "type": "td"});
                row.push({"value": thumbnailChartsAttributes, "type": "thumbnailCharts","createdTime": createdTime});
                row.push({"value": key, "type": "action", "level": "attribute", "follow_status": follow_status, "datasetName": datasetName, "attributeId": attributeId, "attributeName":attributeName, "dataSourceId": value["dataSourceId"],"dataSourceName": datasourceName})
                dqData.push(row)
            }

    }


    }
    return {
        "tableData": dqData,
    };

}

export function extractDatasetInfoForMonitorListView(metaDataMapping) {
    /**
     * Converts meta data mapping to required format
     *
     * @param metaDataMapping: JSON containing id as keys and names as values
     * Sample:
     * {
     *  "23.294.337.109": "Test_env.AutomationDS.accuracy_csv.infectionProb",
     *  "23.294.338.110": "Test_env.AutomationDS.response_csv.age"
     * }
     *
     * @return dictionary with dataset id as key and
     * data source name and data set name as values
     * Sample:  {
     *            337: {"datasetName": "accuracy_csv", "dataSourceName": "AutomationDS"},
     *            338: {"datasetName": "response_csv", "dataSourceName": "AutomationDS"},
     *          }
     */
    let idDatasetDetail = {};
    let attributeIds = []

    let datasetLists = []

    let attributeList = []
    for (let combinedIDKeys of Object.keys(metaDataMapping)) {
        let combinedNamesStr = metaDataMapping[combinedIDKeys];

        if (combinedNamesStr === undefined || combinedNamesStr === null) {
            continue
        }

        const idArr = combinedIDKeys.split(METADATA_MAP_KEY_SEPARATOR);
        const dataSourceId = idArr[1];
        const datasetId = idArr[2];
        const attributeId = idArr[3];

        const combinedNames = combinedNamesStr.split(METADATA_MAP_VALUE_SEPARATOR);
        let dataSourceName = combinedNames[1];
        let datasetName = combinedNames[2];
        let attributeName = combinedNames[combinedNames.length-1];
        let isChildDataset = false
        if (idArr.length === 3) {
            idDatasetDetail[datasetId] = {
                "datasetName": datasetName,
                 "dataSourceId": dataSourceId,
                "dataSourceName": dataSourceName,
            }
            if(datasetName !== undefined && datasetName !== null && datasetName.includes(QUALDO_CHILD_DATASET_SEPARATOR)){
                datasetName = normalizeChildDatasetName(datasetName)
                isChildDataset = true
            }
            datasetLists.push({"value":datasetId, "label":datasetName, "dataSourceId":dataSourceId, "isChildDataset":isChildDataset})
            continue;
        }

        attributeIds.push({"attributeName": attributeName, "attributeId":attributeId, "datasetId": datasetId, "dataSourceId": dataSourceId,"datasetName": datasetName})

        attributeList.push({"value":attributeId, "label":attributeName, "datasetId":datasetId,"datasetName":datasetName, "dataSourceId":dataSourceId})


    }
    let finalData = {"idDatasetDetail": idDatasetDetail, "attributeIds":attributeIds,"datasetLists":datasetLists,"attributeList":attributeList,"metaDataMapping":metaDataMapping}

    return finalData;
}

export function getDataSetList(Attributes, dataSourceId, propsDataModule) {
    let DataSetList = [];
    let uniqueList = [];
    if (propsDataModule === undefined || propsDataModule.metaData === undefined) {
        return DataSetList;
    }

    for (const [key, value] of Object.entries(propsDataModule.metaData)) {
        let keyList = key.split(METADATA_MAP_KEY_SEPARATOR);
        if (keyList.length === 4) {
            let data_src_id = keyList[1];
            if (dataSourceId !== null && (dataSourceId !== data_src_id)) {
                continue;
            }
            let data_set_id = keyList[2];
            const values = value.split(METADATA_MAP_VALUE_SEPARATOR);
            let data_set_name = values[2];

            // data_set_name = values[2] + "." + values[3];
            // if (values.length === 4) {
            //     data_set_name = values[2];
            // }

            let name = [data_set_name, data_set_id].join("_");
            if (uniqueList.indexOf(name) === -1) {
                DataSetList.push({"label": data_set_name, "value": data_set_id});
                uniqueList.push(name);
            }
        }
    }

    return DataSetList;
}

export function replaceMetricNameByBracket(metricName) {

    let commonName = metricName.replace(/[\W_]+/g, "_")

    if(commonName.includes("(")) {
        commonName = commonName.replace("(", "_")
     }

    if (commonName.includes(")")){
        commonName = commonName.replace(")", "_")
    }
    return commonName.toString()
}

export function convertDataForListView(data, datasetName, datasourceName, filterError=false, filteredAttributes) {
        let updatedData = []
        if (data !== undefined && data !== null){
            for (const chartData of Object.values(data)) {
                    const metricName = chartData.name;
                    if (metricName) {
                        let attributeName = chartData.attribute_name;
                        let toolTiptopHeader;
                        if(filteredAttributes !== undefined && !filteredAttributes.includes(attributeName)) {
                        continue;
                        }

                        if(attributeName === undefined) {
                            toolTiptopHeader = datasetName;
                        } else {
                            toolTiptopHeader = chartData.attribute_name;
                        }

                        if (chartData["name"] === "Data Drift") {
                            let boxPlotData = {}
                            let statistics = {}
                            let quantile = chartData["boxplot_data"]
                            if (quantile !== undefined && quantile !== null) {
                                statistics["attribute_name"] = attributeName
                                statistics["quantile"] = quantile
                                statistics["drift_patterns"] = chartData["drift_patterns"]
                                statistics["time"] = chartData["time"]
                                if (attributeName !== null) {
                                    boxPlotData["chart_level"] = "Attribute"
                                } else {
                                    boxPlotData["chart_level"] = "Dataset"
                                }
                                boxPlotData["statistics"] = [statistics]
                                chartData["boxPlotData"] = boxPlotData
                                chartData["chartType"] = "boxPlotZoomable"
                            }
                        }
                        let chartTypeData = getChartTypeForMonitorCharts(chartData);
                        let hasError = chartTypeData.hasError;
                        chartData["type"] = chartTypeData.chartPatternType;
                        chartData["hasDrift"] = hasError;
                        if(!hasError && filterError === true) {
                        continue
                        }
                        if (attributeName !== null) {
                            chartData["attribute_name"] = attributeName;
                        }

                        chartData["datasetName"] = datasetName;
                        chartData["errorChart"] = true;
                        chartData["toolTiptopHeader"] = toolTiptopHeader;
                        chartData["title"] = "title";
                        let charKeyUnformatted = `${metricName}_${"aa"}`;
                        chartData["key"] = replaceMetricNameByBracket(charKeyUnformatted);
                        chartData["data_source_name"] = datasourceName;
                        if (REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART.includes(metricName)) {
                            chartData["settings"] = CONTRIBUTION_KEY;
                        }

                        if (metricName === DATA_TYPE_CONSISTENCY) {
                            chartData["referenceDataSetLabel"] = "Reference Dataset";
                            let contributions = chartData["contribution"];
                            let referenceDataset = "";
                            if (contributions !== undefined && contributions.length > 0) {
                                referenceDataset = contributions[contributions.length - 1].target_data_set_name;
                            }

                            chartData["referenceDataSetName"] = referenceDataset;
                        }

                        chartData[DQ_CHART_POINTS] = chartData["value"];

                        normalizeChartData(chartData, DQ_CHART_POINTS);

                        let chartValues = chartData[DQ_CHART_POINTS];
                        let drift_len = chartValues.length;

                        if (drift_len === 0) {
                            continue;
                        }
                        let time_len = chartData["time"].length;

                        if (drift_len > 1) {
                            chartData["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
                            chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                            chartData["last_error"] = chartValues[(drift_len - 2)].toFixed(2);
                        } else if (drift_len === 1) {
                            chartData["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
                            chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                            chartData["last_error"] = chartValues[(drift_len - 1)].toFixed(2);
                        } else {
                            chartData["error_percentage"] = 0;
                            chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                            chartData["last_error"] = 0;
                        }

                        // We are changing scale of all charts to min max scale
                        // Part of fix for issue 1038
                        chartData["scale"] = MIN_MAX_SCALE;

                        // if (chartInfo["metricType"] === 'custom' || MIN_MAX_SCALE_LIST.includes(key)) {
                        //     chartData["scale"] = MIN_MAX_SCALE;
                        // }
                        updatedData.push(chartData)
                    }
                }
            }
            return updatedData
}

export function setDQShowMoreDataOnGroupingLevel(dataSetData, isDataSet,
                                                 dataSourceId, metricType,
                                                 section, propsDataModule) {
    /**
     * This function will prepare the data for 'Show More' section of
     * Data Quality error page in "Monitor" section
     *
     */
    let gridData = [];
    let profileKeys = ["avg", "duplicate", "empty", "max", "min", "mode", "std", "unique"];
    let hasChartData = false;

    if (dataSetData !== undefined) {
        for (const [key, value] of Object.entries(dataSetData)) {
            let metricGroup = {};
            let chartInfo = getMetaInformationAboutChart(isDataSet, key);

            // Skip attribute level charts for consistency related metrics
            if (!isDataSet && REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART.includes(key)) {
                continue;
            }

            metricGroup["metric"] = key;
            metricGroup["key"] = `${key}_${isDataSet}`;
            let processedKeys = [];
            metricGroup["description"] = chartInfo["description"];
            metricGroup["header"] = chartInfo["header"];
            metricGroup["metricType"] = chartInfo["metricType"];
            metricGroup["data"] = []

            if (metricType !== chartInfo["metricType"]) {
                continue;
            }
            if (key === "Recency" && !isDataSet) {
                continue;
            }

            if (profileKeys.includes(key)) {
                continue;
            }

            for (const [attribute, chartData] of Object.entries(value)) {
                // If consolidation is done at attribute level,
                // make sure that there are 4 parts in attribute key (Ex) 22.33.16.2003)
                const attributeKeys = attribute.split(METADATA_MAP_KEY_SEPARATOR);
                if (!isDataSet && attributeKeys.length !== 4) {
                    continue;
                }

                let data_src_id = attributeKeys[1];
                if (dataSourceId !== null && dataSourceId !== data_src_id) {
                    continue;
                }

                // To avoid repetitive charts for same attribute - metric combination
                const metricName = chartData.name;
                let uniqueKey = `${attribute}_${metricName}`;
                if (processedKeys.includes(uniqueKey)) {
                    continue;
                }
                processedKeys.push(uniqueKey);

                let metaData = propsDataModule.metaData[attribute];
                if (metaData === undefined) {
                    metaData = propsDataModule.metaData;
                    // const filtered = Object.keys(metaData)
                    //     .filter(key => key.startsWith(attribute))
                    //     .reduce((obj, key) => {
                    //         obj[key] = metaData[key];
                    //         return obj;
                    //     }, {});
                    metaData = metaData[attribute];
                    if (metaData !== undefined) {
                        let metaDataAfterSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR)
                        if(metaDataAfterSplit.length > 4) {
                          metaData = metaDataAfterSplit.slice(0, metaDataAfterSplit.length - 2).join(METADATA_MAP_VALUE_SEPARATOR);

                        }
                    }
                }
                if (metaData !== undefined) {
                    const metadataSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                    let attributeName = null;
                    let datasetName;
                    let toolTiptopHeader;

                    if (isDataSet) {
                        datasetName = metadataSplit[2];
                        toolTiptopHeader = datasetName;
                    } else {
                        datasetName = metadataSplit[2];
                        attributeName = metadataSplit[metadataSplit.length - 1];
                        toolTiptopHeader = attributeName;
                    }
                    if (chartData["name"] === "Data Drift") {
                        let boxPlotData = {}
                        let statistics = {}
                        let quantile = chartData["boxplot_data"]
                        if (quantile !== undefined && quantile !== null) {
                            statistics["attribute_name"] = attributeName
                            statistics["quantile"] = quantile
                            statistics["drift_patterns"] = chartData["drift_patterns"]
                            statistics["time"] = chartData["time"]
                            if (attributeName !== null) {
                                boxPlotData["chart_level"] = "Attribute"
                            } else {
                                boxPlotData["chart_level"] = "Dataset"
                            }
                            boxPlotData["statistics"] = [statistics]
                            chartData["boxPlotData"] = boxPlotData
                            chartData["chartType"] = "boxPlotZoomable"
                        }
                    }
                    let chartTypeData = getChartTypeForMonitorCharts(chartData);
                    let hasError = chartTypeData.hasError;
                    chartData["type"] = chartTypeData.chartPatternType;
                    chartData["hasDrift"] = hasError;
                    if (attributeName !== null) {
                        chartData["attribute_name"] = attributeName;
                    }
                    let idSplit = attribute.split(METADATA_MAP_KEY_SEPARATOR)

                    chartData["datasetName"] = datasetName;
                    chartData["errorChart"] = true;
                    chartData["toolTiptopHeader"] = toolTiptopHeader;
                    chartData["data_set_id"] = idSplit[2];
                    chartData["attribute_id"] = idSplit[idSplit.length-1];
                    chartData["title"] = `${metadataSplit.join(" > ")} - ${metricName}`;
                    let charKeyUnformatted = `${metricName}_${attribute}`;
                    chartData["key"] = replaceMetricNameByBracket(charKeyUnformatted);
                    chartData["data_source_name"] = metadataSplit[1];
                    if (REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART.includes(metricName)) {
                        chartData["settings"] = CONTRIBUTION_KEY;
                    }

                    if (metricName === DATA_TYPE_CONSISTENCY) {
                        chartData["referenceDataSetLabel"] = "Reference Dataset";
                        let contributions = chartData["contribution"];
                        let referenceDataset = "";
                        if (contributions !== undefined && contributions.length > 0) {
                            referenceDataset = contributions[contributions.length - 1].target_data_set_name;
                        }

                        chartData["referenceDataSetName"] = referenceDataset;
                    }

                    chartData[DQ_CHART_POINTS] = chartData["value"];

                    normalizeChartData(chartData, DQ_CHART_POINTS);

                    let chartValues = chartData[DQ_CHART_POINTS];
                    let drift_len = chartValues.length;
                    if (drift_len === 0) {
                        continue;
                    }
                    let time_len = chartData["time"].length

                    if (drift_len > 1) {
                        chartData["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = chartValues[(drift_len - 2)].toFixed(2);
                    } else if (drift_len === 1) {
                        chartData["error_percentage"] = chartValues[(drift_len - 1)].toFixed(2);
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = chartValues[(drift_len - 1)].toFixed(2);
                    } else {
                        chartData["error_percentage"] = 0;
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = 0;
                    }

                    // We are changing scale of all charts to min max scale
                    // Part of fix for issue 1038
                    chartData["scale"] = MIN_MAX_SCALE;

                    // if (chartInfo["metricType"] === 'custom' || MIN_MAX_SCALE_LIST.includes(key)) {
                    //     chartData["scale"] = MIN_MAX_SCALE;
                    // }

                    metricGroup.data.push(chartData);
                    hasChartData = true;
                }
            }

            metricGroup.data.sort(sortBasedOnErrors);

            if (chartInfo["metricType"] === "default") {
                gridData.push(metricGroup);
            } else if (chartInfo["metricType"] === "custom" && metricGroup.data.length > 0) {
                gridData.push(metricGroup);
            }
        }
    }
    gridData.sort(sortBasedOnMetrics);

    // Add missing metrics for consistency with empty
    if (metricType === 'default' &&
        isDataSet &&
        section === DQ_MONITOR_SECTIONS.CONSISTENCY) {

        for (let consistencyMet of REQUIRES_CONTRIBUTION_INSTEAD_OF_CHART) {
            let match = gridData.find(x => x.metric === consistencyMet);
            if (match !== undefined && match !== null) {
                continue;
            }

            let metricGroup = {};
            let chartInfo = getMetaInformationAboutChart(true, consistencyMet);
            metricGroup["metric"] = consistencyMet;
            metricGroup["description"] = chartInfo["description"];
            metricGroup["header"] = chartInfo["header"];
            metricGroup["metricType"] = chartInfo["metricType"];
            metricGroup["data"] = [];
            gridData.push(metricGroup);
        }
    }
    return {
        "data": gridData,
        "hasChartData": hasChartData
    };
}


export function setProfilingData(dataSetData, isDataSet, dataSourceId, propsDataModule) {
    /**
     * This function will prepare the data for 'Show More' section of
     * Data Quality error page in "Monitor" section
     *
     */
    let gridData = [];
    let profileKeys = ["avg", "duplicate", "empty", "max", "min", "mode", "std", "unique"];
    if (dataSetData !== undefined) {
        for (const [key, value] of Object.entries(dataSetData)) {
            let metricGroup = {};

            metricGroup["metric"] = key;
            let processedKeys = [];
            metricGroup["data"] = []

            if (!profileKeys.includes(key)) {
                continue;
            }

            for (const [attribute, chartData] of Object.entries(value)) {
                // If consolidation is done at attribute level,
                // make sure that there are 4 parts in attribute key (Ex) 22.33.16.2003)
                const attributeKeys = attribute.split(METADATA_MAP_KEY_SEPARATOR);
                if (!isDataSet && attributeKeys.length !== 4) {
                    continue;
                }

                let data_src_id = attributeKeys[1];
                if (dataSourceId !== null && dataSourceId !== data_src_id) {
                    continue;
                }

                // To avoid repetitive charts for same attribute - metric combination
                const metricName = chartData.name;
                let uniqueKey = `${attribute}_${metricName}`;
                if (processedKeys.includes(uniqueKey)) {
                    continue;
                }
                processedKeys.push(uniqueKey);

                let metaData = propsDataModule.metaData[attribute];
                if (metaData === undefined) {
                    metaData = propsDataModule.metaData;
                    // const filtered = Object.keys(metaData)
                    //     .filter(key => key.startsWith(attribute))
                    //     .reduce((obj, key) => {
                    //         obj[key] = metaData[key];
                    //         return obj;
                    //     }, {});

                    let matchedKeys = metaData[attribute];
                    if (matchedKeys !== undefined && matchedKeys !== null && matchedKeys.length === 0) {
                        continue;
                    }

                    metaData = metaData[attribute];
                    if (metaData === undefined || metaData === null) {
                        continue;
                    }
                    let metaDataAfterSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR)
                    if(metaDataAfterSplit.length > 4) {
                      metaData = metaDataAfterSplit.slice(0, metaDataAfterSplit.length - 2).join(METADATA_MAP_VALUE_SEPARATOR);

                    }

                }
                if (metaData !== undefined) {
                    const metadataSplit = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                    let attributeName;
                    if (isDataSet) {
                        attributeName = metadataSplit[2];
                    } else {
                        attributeName = metadataSplit[metadataSplit.length - 1];
                    }

                    let chartTypeData = getChartTypeForMonitorCharts(chartData);
                    chartData["type"] = chartTypeData.chartPatternType;
                    chartData["hasDrift"] = chartTypeData.hasError;

                    chartData["attribute_name"] = attributeName;
                    chartData["data_set_id"] = attribute.split(METADATA_MAP_KEY_SEPARATOR)[2];
                    chartData["title"] = `${metadataSplit.join(" > ")} - ${metricName}`;

                    chartData["key"] = `${metricName}_${attribute}`;
                    chartData["data_source_name"] = metadataSplit[1];

                    normalizeChartData(chartData);
                    let time_len = chartData.time.length;
                    let drift_len = chartData.drift.length;
                    if (drift_len > 1) {
                        chartData["error_percentage"] = chartData.drift[(drift_len - 1)].toFixed(2);
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = chartData.drift[(drift_len - 2)].toFixed(2);
                    } else if (drift_len === 1) {
                        chartData["error_percentage"] = chartData.drift[(drift_len - 1)].toFixed(2);
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = chartData.drift[(drift_len - 1)].toFixed(2);
                    } else {
                        chartData["error_percentage"] = 0;
                        chartData["latest_refresh"] = chartData.time[(time_len - 1)];
                        chartData["last_error"] = 0;
                    }

                    chartData[DQ_CHART_POINTS] = chartData["value"];
                    chartData["scale"] = MIN_MAX_SCALE;
                    metricGroup.data.push(chartData);
                }
            }
            gridData.push(metricGroup);
        }
    }

    gridData.sort(sortBasedOnMetrics);
    return gridData;
}

export function setDQShowMoreData(propsDataSetData, propsAttributeData, propsDataModule,
                                  dataSourceId, section = null) {
    /**
     * This function will prepare the data for 'Show More' section of
     * Data Quality error page in "Monitor" section
     *
     */
    const dataSetData = _.cloneDeep(propsDataSetData);
    const attributeData = _.cloneDeep(propsAttributeData);
    // const dataSetData = propsDataSetData;
    // const attributeData = propsAttributeData;

    const DefaultDatasetGroups = setDQShowMoreDataOnGroupingLevel(dataSetData, true,
        dataSourceId, "default", section, propsDataModule);
    const CustomDatasetGroups = setDQShowMoreDataOnGroupingLevel(dataSetData, true,
        dataSourceId, "custom", section, propsDataModule);
    const DefaultAttributeGroups = setDQShowMoreDataOnGroupingLevel(attributeData, false,
        dataSourceId, "default", section, propsDataModule);
    const CustomAttributeGroups = setDQShowMoreDataOnGroupingLevel(attributeData, false,
        dataSourceId, "custom", section, propsDataModule);
    const profileData = setProfilingData(attributeData, false,
        dataSourceId, propsDataModule);

    const defaultDatasetGroupChartData = DefaultDatasetGroups.data;
    const customDatasetGroupChartData = CustomDatasetGroups.data;
    const defaultAttributeChartData = DefaultAttributeGroups.data;
    const customAttributeChartData = CustomAttributeGroups.data;

    const hasChartData = (DefaultDatasetGroups.hasChartData ||
        CustomDatasetGroups.hasChartData ||
        DefaultAttributeGroups.hasChartData ||
        CustomAttributeGroups.hasChartData
    );

    const result = {
        "DefaultDataSet": defaultDatasetGroupChartData,
        "CustomDataSet": customDatasetGroupChartData,
        "DefaultAttribute": defaultAttributeChartData,
        "CustomAttribute": customAttributeChartData,
        "profilingData": profileData,
        "dataSet": defaultDatasetGroupChartData.concat(customDatasetGroupChartData),
        "attribute": defaultAttributeChartData.concat(customAttributeChartData),
        "hasChartData": hasChartData
    };
    return result;
}

export function getAllDQShowMoreCharts(propsMonitorModule, propsDataModule, dataSourceId) {
    // Get Dataset list information for each metric association
    const completenessDataSetList = getDataSetList(propsMonitorModule.completenessAttributeInfo,
        dataSourceId, propsDataModule);
    const timelinessDataSetList = getDataSetList(propsMonitorModule.timelinessAttributeInfo,
        dataSourceId, propsDataModule);
    const accuracyDataSetList = getDataSetList(propsMonitorModule.accuracyAttributeInfo,
        dataSourceId, propsDataModule);
    const conformityDataSetList = getDataSetList(propsMonitorModule.conformityAttributeInfo,
        dataSourceId, propsDataModule);
    const consistencyDataSetList = getDataSetList(propsMonitorModule.consistencyAttributeInfo,
        dataSourceId, propsDataModule);
    const driftDataSetList = getDataSetList(propsMonitorModule.driftAttributeInfo,
        dataSourceId, propsDataModule);
    const uniquenessDataSetList = getDataSetList(propsMonitorModule.uniquenessAttributeInfo,
        dataSourceId, propsDataModule);

    // Get Show More chart information for each metric association
    const completenessShowMore = setDQShowMoreData(propsMonitorModule.completenessDataSetInfo,
        propsMonitorModule.completenessAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.COMPLETENESS);
    const timelinessShowMore = setDQShowMoreData(propsMonitorModule.timelinessDataSetInfo,
        propsMonitorModule.timelinessAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.TIMELINESS);
    const accuracyShowMore = setDQShowMoreData(propsMonitorModule.accuracyDataSetInfo,
        propsMonitorModule.accuracyAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.ACCURACY);
    const conformityShowMore = setDQShowMoreData(propsMonitorModule.conformityDataSetInfo,
        propsMonitorModule.conformityAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.CONFORMITY);
    const consistencyShowMore = setDQShowMoreData(propsMonitorModule.consistencyDataSetInfo,
        propsMonitorModule.consistencyAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.CONSISTENCY);
    const driftShowMore = setDQShowMoreData(propsMonitorModule.driftDataSetInfo,
        propsMonitorModule.driftAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.DRIFT);
    const uniquenessShowMore = setDQShowMoreData(propsMonitorModule.uniquenessDataSetInfo,
        propsMonitorModule.uniquenessAttributeInfo, propsDataModule,
        dataSourceId, DQ_MONITOR_SECTIONS.UNIQUENESS);

    return {
        "completenessDataSetList": completenessDataSetList,
        "timelinessDataSetList": timelinessDataSetList,
        "accuracyDataSetList": accuracyDataSetList,
        "conformityDataSetList": conformityDataSetList,
        "consistencyDataSetList": consistencyDataSetList,
        "driftDataSetList": driftDataSetList,
        "uniquenessDataSetList": uniquenessDataSetList,
        "completenessShowMore": completenessShowMore,
        "timelinessShowMore": timelinessShowMore,
        "accuracyShowMore": accuracyShowMore,
        "conformityShowMore": conformityShowMore,
        "consistencyShowMore": consistencyShowMore,
        "driftShowMore": driftShowMore,
        "uniquenessShowMore": uniquenessShowMore,
    }
}

export function dqRenderGroup(modalData) {
    let DefaultDatasetData = modalData.DefaultDataSet;
    let DefaultAttributeData = modalData.DefaultAttribute;
    let CustomDatasetData = modalData.CustomDataSet;
    let CustomAttributeData = modalData.CustomAttribute;
    let initialCount = 0;
    let data = [{"data": DefaultDatasetData, "level": "Dataset", "initialCount": 0},
        {"data": DefaultAttributeData, "level": "Attribute", "initialCount": 0},
        {"data": CustomDatasetData, "level": "Dataset", "initialCount": 0},
        {"data": CustomAttributeData, "level": "Attribute", "initialCount": 0}
    ];

    let undefinedAppliedCheck =false
        undefinedAppliedCheck = this.state.applied_dataset === undefined || this.state.applied_attribute === undefined || this.state.applied_metrics === undefined
        if(undefinedAppliedCheck === false && this.state.applied_attribute.length === 0 && (this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')) && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action ={"clear_selection"}/>);
        }
        if(this.state.applied_metrics !== undefined && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action={"clear_metric"}/>);
        }
        if(this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')){
            return (<NoErrorComponent action={"clear_dataset"}/>);
        }

    return data.map((fullScreenData) => {
        let sectionData = fullScreenData.data;
        let level = fullScreenData.level;
        if (sectionData === undefined) {
            return '';
        }

        let fKey = "";
        if (fullScreenData.key !== undefined) {
            fKey = fullScreenData.key;

        }

        return sectionData.map((groupData, index) => {
            if(this.state.isAdvancedFilterApplied){
                let metric = groupData.metric;
                if(metric === 'Unique Values'){
                    //Uniqueness label metric name have been handled in UI, not yet updated in backend
                    // Handling metric name for comparsion
                    metric = "Uniqueness"
                }
                let metricsFiltered = this.state.selectedMetrics.filter(x=>x.label === metric);
                if(metricsFiltered.length === 0) {
                   return ""
                }
            }
            let header = groupData.header;
            if(!this.state.showAttributes && header.includes("By Attributes")){
              return ""
            }

            initialCount = initialCount + 1;
            return (
                <div className="qd-chart-group m-0 mb-4"
                     key={groupData.key === undefined ? `${index}_${fKey}` : `${groupData.key}_${fKey}`}>
                    <div className="qd-chart-group-heading">
                        <h4 className="qd-chart-group-title">
                                <span className="circle-number">
                                {initialCount}
                            </span>
                            {header}
                        </h4>
                        <p className="m-0">
                            {groupData.description}
                        </p>

                    </div>
                    <div className="qd-chart-group-body">
                        <div className="row row-sm"
                             key={groupData.key === undefined ? `divKey_${index}_${fKey}` : `divKey_${groupData.key}_${fKey}`}>
                            {this.dqRenderModalChart(level, groupData.data, groupData.metricType)}
                        </div>
                    </div>
                </div>
            );
        });
    });
}


export function dqRenderModalChart(chartLevel, chartDataList, metricType) {
    let message = "No data seleted"

    if (chartDataList === undefined || chartDataList === null || chartDataList.length === 0) {
        return (<NoErrorComponent  message={message}/>);
    }
    let profileKeys = ["avg", "duplicate", "empty", "max", "min", "mode", "std", "unique"];
    let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
    let chartCount = 0;
    // To append color for attribute level charts
    let noErrorCount = 0;
    let is_same_col = false;
    let j = 1;
    let attributeList = []
    // To append color for dataset level charts
    let noErrorDatasetCount = 0;
    let is_same_data_col = false;
    let k = 1;
    let datasetList = []
    let filteredDataset = []
    let filteredAttributes = []
    let isEmpty = true


    if (chartDataList) {
        return chartDataList.map((chartData, index) => {
            const attribute_name = chartData.attribute_name;
            let attributeId = chartData.attribute_id;
            let datasetId = chartData.data_set_id;
            let lastDiv = (chartDataList.length === index + 1 && chartCount === 0)
            let message = "No data found for the selected " + chartLevel
            if(chartLevel === "Dataset" && this.state.isAdvancedFilterApplied) {
              filteredDataset = this.state.selectedDatasets.filter(x=>Number(x.value) === Number(datasetId))
              if(isEmpty && lastDiv && filteredDataset.length === 0) {
                return(<NoErrorComponent message={message} key={chartData["key"]}/>)
              }
              else if(filteredDataset.length === 0) {
                 return ""
              }
            }else if(chartLevel === "Attribute" && this.state.isAdvancedFilterApplied){
              filteredAttributes = this.state.selectedAttributes.filter(x=>Number(x.value) === Number(attributeId))
              if(isEmpty && lastDiv) {
                return(<NoErrorComponent message = {message}  key={chartData["key"]}/>)
              }else if(filteredAttributes.length === 0) {
                 return ""
              }
            }
            if(!isEmpty) {
              isEmpty = false
            }

            chartData["drift"] = convertDecimalDigits(chartData["drift"])
            chartData["dq_chart_points"] = convertDecimalDigits(chartData["dq_chart_points"])
            let not_computed = chartData["value"].every((val, i, arr) => val === -1);

            let metricName = chartData.name;
            if (not_computed === true && lastDiv === true) {
                return (<NoErrorComponent key={chartData["key"]}/>);
            } else if (not_computed === true) {
                return "";
            } else if (!this.state.isAdvancedFilterApplied || this.state.showErrorsOnly) {
                if (chartData["hasDrift"] === false && lastDiv === true) {
                    return (<NoErrorComponent key={chartData["key"]}/>);
                }
                if (chartData["hasDrift"] === false) {
                    return "";
                }
            }
//             else if (String(this.state.selectedDataSet["value"]) !== String(chartData["data_set_id"]) && lastDiv === true) {
//                return (<NoErrorComponent key={chartData["key"]}/>);
//            } else if (String(this.state.selectedDataSet["value"]) !== String(chartData["data_set_id"])) {
//                return ""
//            }
            else if (profileKeys.includes(metricName)) {
                return "";
            }


            if (chartLevel === "Dataset" ||
                (attribute_name !== undefined && attribute_name !== null && chartLevel === "Attribute")) {

                let aggregateOptions = null;
                let id = chartData["data_set_id"];

                let key = `modal_${metricName}_${id}`;
                let title = this.props.title !== undefined ? this.props.title : '';
                let compareDatasetOptions = null
                let compareType = null;
                let compareAttributeOptions = null;
                if (attribute_name !== undefined && attribute_name !== null) {
                    key = key + "_" + attribute_name;
                }
                let idValue = ''

                if (chartLevel === "Attribute") {
                    key = key + "_" + attribute_name;
                    idValue = chartData["data_set_id"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g, "_");
                    compareType = chartLevel;
                    compareAttributeOptions = _.reject(this.state.compareAttributeOptions, function (d) {
                        return ((d.label === attribute_name && d.data_set_id === chartData.data_set_id) || d.value.split("$")[1] !== metricName ||
                            d.data_set_id !== chartData.data_set_id);
                    });
                }

                let showAggregateError = false;
                let canShowAggregateOptions = (metricName === DATA_DRIFT);
                if (chartLevel === "Attribute" && canShowAggregateOptions) {
                    aggregateOptions = this.state.aggregateOptions.filter(function (d) {
                        return d.attribute_name === attribute_name && d.data_set_id === chartData.data_set_id
                    });
                    let isAggregateError = aggregateOptions.filter(function (d) {
                        return d.is_error === true
                    });
                    if (isAggregateError.length > 0) {
                        showAggregateError = true;
                    }
                    let drift_pattern = chartData.type;
                    if (drift_pattern !== undefined && drift_pattern !== null) {
                        if (drift_pattern === "NO_DRIFT" || drift_pattern === "not_computed") {
                            aggregateOptions.unshift({
                                "label": <a className="qd-cw_dp-item"
                                            href="/#">self</a>,
                                "value": "self",
                                "attribute_name": attribute_name,
                                "data_set_id": chartData.data_set_id,
                                "data": chartData,
                                "is_error": false
                            });
                        } else {
                            showAggregateError = true;
                            aggregateOptions.unshift({
                                "label": <a className="qd-cw_dp-item"
                                            href="/#">self<span className="label label-danger">Error</span></a>,
                                "value": "self",
                                "attribute_name": attribute_name,
                                "data_set_id": chartData.data_set_id,
                                "data": chartData,
                                "is_error": true
                            });
                        }
                    }
                }

                if (chartLevel === "Dataset") {
                    idValue = chartData["data_set_id"] + "_" + chartData["name"].replace(/ /g, "_");
                    compareType = chartLevel;
                    compareDatasetOptions = _.reject(this.state.compareDatasetOptions, function (d) {
                        return (d.value === chartData["data_set_id"] || d.type !== metricName)
                    });
                }

                title = title.replace(" ", "_");
                chartCount = chartCount + 1;

                let chartType = getChartType(chartData, chartCount);
                const customGridKey = this.props.customKey;
                if (customGridKey !== undefined) {
                    key = `${key}_${customGridKey}`;
                }

                if (chartData["key"] !== undefined) {
                    // Since we are using single complete model data for all Grids
                    // related to model error, we will append the existing chart key with
                    // current Grid's custom key.
                    chartData = _.cloneDeep(chartData);

                    key = `${key}_${chartData["key"]}`;
                    chartData["key"] = key;
                }

                if (chartLevel === "Dataset") {
                    if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                        if (datasetList.length === 0) {
                            chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                            if (chartCount % 2 !== 0) {
                                is_same_data_col = true;
                            }
                        } else {
                            if (is_same_data_col === true && datasetList.length === 1) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                is_same_data_col = false;
                            } else {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[k]];
                                if (is_same_data_col === false) {
                                    is_same_data_col = true;
                                } else {
                                    is_same_data_col = false;
                                    k = k + 1;
                                }
                                if (k === 4) {
                                    k = 0;
                                }
                            }
                        }
                        datasetList.push(noErrorDatasetCount);
                        noErrorDatasetCount = noErrorDatasetCount + 1;
                        chartData["errorChart"] = false;
                        if (chartType === "barWithError") {
                            chartType = "barChart";
                        } else {
                            if (chartType === "areaWithError") {
                                chartType = "areaChart";
                            }
                        }
                    }
                }
                if (chartLevel === "Attribute") {
                    if (chartData["type"] === "NO_DRIFT" || chartData["type"] === "not_computed") {
                        if (attributeList.length === 0) {
                            chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                            if (chartCount % 2 !== 0) {
                                is_same_col = true;
                            }
                        } else {
                            if (is_same_col === true && attributeList.length === 1) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                is_same_col = false;
                            } else {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                                if (is_same_col === false) {
                                    is_same_col = true;
                                } else {
                                    is_same_col = false;
                                    j = j + 1;
                                }
                                if (j === 4) {
                                    j = 0;
                                }
                            }
                        }
                        attributeList.push(noErrorCount);
                        noErrorCount = noErrorCount + 1;
                        chartData["errorChart"] = false;
                        if (chartType === "barWithError") {
                            chartType = "barChart";
                        } else {
                            if (chartType === "areaWithError") {
                                chartType = "areaChart";
                            }
                        }
                    }
                }

                if (chartData["name"] === "Data Drift") {
                    let isBoxplot = chartData["boxplot_data"].filter(x=>x !== "NA")
                    if(isBoxplot.length > 0){
                        chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                        if (chartData["chartType"] === "boxPlotZoomable") {
                            chartType = "boxPlotZoomable";
                        }
                    }
                }
                let filteredData = null;
                let propsStartDate = this.state.startDateInside === null ? this.props.startDate : this.state.startDateInside
                    let propsEndDate = this.state.endDateInside === null ? this.props.endDate : this.state.endDateInside
                    if (propsStartDate !== null && propsEndDate !== null) {
                        filteredData = getChartFilterData(chartData, propsStartDate,
                            propsEndDate, chartType, false, false, MAX_DATA_POINTS_FOR_LIST_VIEW);
                        let timeVals = null;
                        if (filteredData !== null) {
                            timeVals = filteredData.time;
                        }
                        if (timeVals !== undefined && timeVals !== null && timeVals.length > 0) {
                            propsStartDate = moment(new Date(timeVals[0]));
                        }
                    }
                return (
                    <div key={key} className="col-md-6" id={idValue}>
                        <LazyLoadComponent placeholder={<Load isBootStrapColumn={true}/>}>
                            <ChartFilterHeader
                                yValue={chartData["name"]}
                                showHeatMap={true}
                                showSettingsMenu={true}
                                data={chartData}
                                scale={chartData["scale"]}
                                colorCode={chartData["color"]}
                                lineColorCode={LIGHT_COLOR_MAP[chartData["color"]]}
                                dataSetId={id}
                                mlModelId={id}
                                chartLevel={chartLevel}
                                showContribution={chartLevel === "Dataset" && metricType === "default"}
                                selectedIntegration={this.props.selectedIntegration}
                                compareAttributeOptions={compareAttributeOptions}
                                compareDataSrc={compareDatasetOptions}
                                aggregateOptions={aggregateOptions}
                                title={title}
                                startDate={propsStartDate}
                                endDate={propsEndDate}
                                filteredData={filteredData}
                                timeFilterApplied={true}
                                considerEndTime={false}
                                compareType={compareType}
                                chartType={chartType}
                                variant={this.state.variant + "_" + chartData["name"] + "_" + chartData["data_set_id"] + "_" + chartData["attributeId"]}
                                chartTimeFilter={this.chartTimeFilter}
                                dataSetName={chartData["datasetName"]}
                                attributeName={chartData["attribute_name"]}
                                hideDistanceFilter={true}
                                referenceDataSetName={chartData["referenceDataSetName"]}
                                referenceDataSetLabel={chartData["referenceDataSetLabel"]}
                                showAggregateError={showAggregateError}
                            />
                        </LazyLoadComponent>
                    </div>

                );
            } else if (lastDiv === true) {
                return (<NoErrorComponent/>);
            }
            return '';
        });
    }
}


export function getDefaultDateRangeOptions() {
    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    return ranges;
}

export function setShowMoreData(dataSetData, attributeData, mlModelDetails, modelPerformanceCharts) {
        let mlModelMapping = this.props.monitorModule.mlModelMapping;
        let metadataMap = this.props.dataModule.metaData;

        let showMoreDataForDatasets = setShowMoreDataOnGroupingLevel(dataSetData, true,
            mlModelDetails, mlModelMapping, metadataMap, canIncludeMetric,
            modelPerformanceCharts);
        let showMoreDataForAttributes = setShowMoreDataOnGroupingLevel(attributeData, false,
            mlModelDetails, mlModelMapping, metadataMap, canIncludeMetric,
            modelPerformanceCharts);

        return {
            "dataSet": showMoreDataForDatasets,
            "attribute": showMoreDataForAttributes
        };
    }


export function decryptMsg(data) {
    let secretKey = process.env.REACT_APP_IAM_DECRYPT_KEY
    data = data.replace(/qua111111d000/g, '+');
    data = data.replace("'",'')
    let result = CryptoJS.DES.decrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(result)
}

// This function is not tested and is not used anywhere. For future purpose it is added
export function encryptMsg(data) {
    let secretKey = process.env.REACT_APP_IAM_DECRYPT_KEY
    let result = CryptoJS.DES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
    });
    result = result.toString()
    result = result.replace('+', /qua111111d000/g);
    return result
}

export function encryptForLocalUsage(incomingJSON) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(incomingJSON), IAM_SECRET_KEY);
    return ciphertext.toString();
}

export function decryptForLocalUsage(incomingString) {
    const decText = CryptoJS.AES.decrypt(incomingString, IAM_SECRET_KEY);
    const decryptedRes = decText.toString(CryptoJS.enc.Utf8);
    if (decryptedRes !== "") {
        return JSON.parse(decryptedRes)
    }
    return null
}

export function getFeatureAccess() {
    let featureAccess = cachedLocalData.featureAccess;
    if (!IS_NONE_CHECK.includes(featureAccess)) {
        return featureAccess;
    }

    let fa = localStorage.getItem('featureAccess');
    if (IS_NONE_CHECK.includes(fa) || fa === '') {
        // Someone deleted feature access from local storage
        return null;
    }

    let featureAccessData = decryptForLocalUsage(fa);
    cachedLocalData.featureAccess = featureAccessData;
    return featureAccessData;
}

export function getSupportedDatasourceOptions() {
    let datasourceOptions = cachedLocalData.datasourceOptions;
    if (!IS_NONE_CHECK.includes(datasourceOptions)) {
        return datasourceOptions;
    }

    let featureAccessData = getFeatureAccess();
    if (featureAccessData === null) {
        return [];
    }

    const filterOptions = [];
    for (let currOption of DATA_SOURCE_OPTIONS) {
        let subOptions = [];
        for (let currSrc of currOption.options) {
            const srcVal = currSrc.value;

            const accessData = featureAccessData[srcVal];
            if (accessData === false) {
                continue;
            }

            subOptions.push(currSrc);
        }
        if (subOptions.length === 0) {
            continue;
        }

        currOption.options = subOptions;
        filterOptions.push(currOption);
    }

    cachedLocalData.datasourceOptions = filterOptions;
    return filterOptions;
}

export function getSupportedPipelineOptions() {
    return [{
        "options": [
            {
            "label": "Synapse",
            "value": "synapse"
        },
        {
            "label": "Azure Data Factory",
            "value": "adf"
        }
    ],
        "label": "Azure"
    },
    {
        "options": [
            {
            "label": "Glue",
            "value": "glue"
        },
        {
            "label": "MwaAirflow",
            "value": "mwa_airflow"
        }
    ],
        "label": "AWS"
    },
    {
        "options": [
            {
            "label": "Cloud Composer",
            "value": "composer"
        }],
        "label": "Google Cloud"
    },
    {
        "options": [
            {
            "label": "DBT",
            "value": "dbt"
        }],
        "label": "DBT"
    }
    , {
        "options": [{
            "label": "Teradata",
            "value": "teradata"
        }, {
            "label": "Airflow",
            "value": "airflow"
        },
        {
            "label": "Snowflake",
            "value": "snowflake"
        }
    ],
        "label": "Others"
    }];
}
export function getCompleteEndpoint(protocol, ip, end_point) {
    let _separator = "/";
    if (end_point.startsWith("/")) {
        _separator = "";
    }
    return protocol + '//' + ip + _separator + end_point;
}


export function validateSQLQueryMetric(values, selectMetricType) {
    let errors = {};

    // Perform additional validation on SQL query
    const inputSQLTxt = values.sqlQuery;
    const checkQuery = selectMetricType === "sql query" && String(inputSQLTxt).length > 0;
    if (checkQuery) {
        const validStatus = isValidSQLQuery(inputSQLTxt);
        if (validStatus.status === false) {
            errors.sqlQuery = validStatus.message;
        }
    }

    return errors;
}


export function isValidSQLQuery(inputSQLQuery) {
    let response = {
        status: true,
        message: ''
    };

    try {
        inputSQLQuery = inputSQLQuery.split(DOUBLE_COLON).join(QUALDO_ATTRIBUTE_SEPARATOR);
        inputSQLQuery = inputSQLQuery.replace("$dataset$", "SdatasetS");
        inputSQLQuery = inputSQLQuery.replace(LEFT_BRACKET, DOUBLE_QUOTES);
        inputSQLQuery = inputSQLQuery.replace(RIGHT_BRACKET, DOUBLE_QUOTES);
        const parser = new Parser();
        parser.parse(inputSQLQuery);
    } catch (err) {
        response.status = false;
        response.message = String(err);
    }
    return response;
}

export function getSQLQueryErrorComponents(errorData) {
    if (errorData === undefined || errorData === null || errorData === '') {
        return errorData;
    }


    let multiLines = errorData.split('\n');
    if (errorData.includes('--')) {
        const chartCount = (errorData.match(/-/g) || []).length;
        // Add this position information at the beginning of the list, so that it will be shown at top
        multiLines.unshift(`Error at position: ${chartCount}`);
    }

    return multiLines.map((x, index) => <div key={index}>{x}<br/></div>)
}

export function customDateFormat(date_str){
    let d= new Date(date_str).toGMTString()
    d = d.split(' ');
    let result = d[1] +' '+ d[2]+ ", "+ d[3]
    return result
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export function getTrimmedNotificationMessage(notificationData) {
    const notificationMessage = notificationData["message"];
    const replacedText = notificationMessage.replace("Quality Gate", " ").replace("Data source name:", "");
    return replacedText.substring(0, 45) + "...";
}


export function dqpRenderGroup(modalData) {
    let DefaultDatasetData = modalData.DefaultDataSet;
    let DefaultAttributeData = modalData.DefaultAttribute;
    let CustomDatasetData = modalData.CustomDataSet;
    let CustomAttributeData = modalData.CustomAttribute;
    let initialCount = 0;
    let data = [{"data": DefaultDatasetData, "level": "Dataset", "initialCount": 0},
        {"data": DefaultAttributeData, "level": "Attribute", "initialCount": 0},
        {"data": CustomDatasetData, "level": "Dataset", "initialCount": 0},
        {"data": CustomAttributeData, "level": "Attribute", "initialCount": 0}
    ];

    let undefinedAppliedCheck =false
        undefinedAppliedCheck = this.state.applied_dataset === undefined || this.state.applied_attribute === undefined || this.state.applied_metrics === undefined
        if(undefinedAppliedCheck === false && this.state.applied_attribute.length === 0 && (this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')) && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action ={"clear_selection"}/>);
        }
        if(this.state.applied_metrics !== undefined && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action={"clear_metric"}/>);
        }
        if(this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')){
            return (<NoErrorComponent action={"clear_dataset"}/>);
        }

    return data.map((fullScreenData) => {
        let sectionData = fullScreenData.data;
        let level = fullScreenData.level;
        if (sectionData === undefined) {
            return '';
        }

        let fKey = "";
        if (fullScreenData.key !== undefined) {
            fKey = fullScreenData.key;

        }

        return sectionData.map((groupData, index) => {
            if(this.state.isAdvancedFilterApplied){
                let metric = groupData.metric;

                if(metric === 'Unique Values'){
                    //Uniqueness label metric name have been handled in UI, not yet updated in backend
                    // Handling metric name for comparsion
                    metric = "Uniqueness"
                }

                let metricsFiltered = this.state.selectedMetrics.filter(x=>x.label === metric);
                if(metricsFiltered.length === 0) {
                   return ""
                }
            }
            let header = groupData.header;
            if(!this.state.showAttributes && header.includes("By Attributes")){
              return ""
            }
           header = header.replace("Errors", '');
           let description = groupData.description.replace("errors", '');
            initialCount = initialCount + 1;
            return (
                <div className="qd-chart-group m-0 mb-4"
                     key={groupData.key === undefined ? `${index}_${fKey}` : `${groupData.key}_${fKey}`}>
                    <div className="qd-chart-group-heading">
                        <h4 className="qd-chart-group-title">
                                <span className="circle-number">
                                {initialCount}
                            </span>
                            {header}
                        </h4>
                        <p className="m-0">
                            {description}
                        </p>

                    </div>
                    <div className="qd-chart-group-body">
                        <div className="row row-sm"
                             key={groupData.key === undefined ? `divKey_${index}_${fKey}` : `divKey_${groupData.key}_${fKey}`}>
                            {this.dqpRenderModalChart(level, groupData.data, groupData.metricType)}
                        </div>
                    </div>
                </div>
            );
        });
    });
}

export function dqpRenderModalChart(chartLevel, chartDataList, metricType) {
    let message = "No Data found in the latest computation."
    if (chartDataList === undefined || chartDataList === null || chartDataList.length === 0) {
        return (<NoErrorComponent message={message}/>);
    }

    let profileKeys = ["avg", "duplicate", "empty", "max", "min", "mode", "std", "unique"];
    let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
    let chartCount = 0;
    // To append color for attribute level charts
    let noErrorCount = 0;
    let is_same_col = false;
    let j = 1;
    let attributeList = []
    // To append color for dataset level charts
    let noErrorDatasetCount = 0;
    let is_same_data_col = false;
    let k = 1;
    let datasetList = []
    let filteredDataset = []
    let filteredAttributes = []

    if (chartDataList) {
        return chartDataList.map((chartData, index) => {
            message = "No data found for the selected " +chartLevel
            const attribute_name = chartData.attribute_name;
            let attributeId = chartData.attribute_id;
            let datasetId = chartData.data_set_id;
            let lastDiv = false;
            if (index > 0) {
                lastDiv = (chartDataList.length === index + 1 && chartCount === 0)
            }
            if(chartLevel === "Dataset" && this.state.isAdvancedFilterApplied) {
              filteredDataset = this.state.selectedDatasets.filter(x=>Number(x.value) === Number(datasetId))
              if(lastDiv && filteredDataset.length === 0) {
                return(<NoErrorComponent message={message} footer = {"Please select the "+ chartLevel} key={chartData["key"]}/>)
              }
              else if(filteredDataset.length === 0) {
                return ""
              }
            } else if(chartLevel === "Attribute" && this.state.isAdvancedFilterApplied){
              filteredAttributes = this.state.selectedAttributes.filter(x=>Number(x.value) === Number(attributeId))
              if(lastDiv && filteredAttributes.length === 0) {
                return(<NoErrorComponent message={message} footer = {"Please select the "+  chartLevel} key={chartData["key"]}/>)
              }else if(filteredAttributes.length === 0) {
                return ""

              }
            }

            chartData["drift"] = convertDecimalDigits(chartData["drift"])
            chartData["dq_chart_points"] = convertDecimalDigits(chartData["dq_chart_points"])
            let not_computed = chartData["value"].every((val, i, arr) => val === -1);

            let metricName = chartData.name;
            if (not_computed === true && lastDiv === true) {
                return (<NoErrorComponent message={message} key={chartData["key"]}/>);
            } else if (not_computed === true) {
                return "";
            }
            else if (!this.state.isAdvancedFilterApplied) {
                if (lastDiv === true) {
                    return (<NoErrorComponent message={message} key={chartData["key"]}/>);
                }

            } else if (profileKeys.includes(metricName)) {
                return "";
            }


            if (chartLevel === "Dataset" ||
                (attribute_name !== undefined && attribute_name !== null && chartLevel === "Attribute")) {

                let aggregateOptions = null;
                let id = chartData["data_set_id"];

                let key = `modal_${metricName}_${id}`;
                let title = this.props.title !== undefined ? this.props.title : '';
                let compareDatasetOptions = null
                let compareType = null;
                let compareAttributeOptions = null;
                if (attribute_name !== undefined && attribute_name !== null) {
                    key = key + "_" + attribute_name;
                }
                let idValue = ''

                if (chartLevel === "Attribute") {
                    key = key + "_" + attribute_name;
                    idValue = chartData["data_set_id"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g, "_");
                    compareType = chartLevel;
                    compareAttributeOptions = _.reject(this.state.compareAttributeOptions, function (d) {
                        return ((d.label === attribute_name && d.data_set_id === chartData.data_set_id) || d.value.split("$")[1] !== metricName ||
                            d.data_set_id !== chartData.data_set_id);
                    });
                }

                let showAggregateError = false;
                let canShowAggregateOptions = (metricName === DATA_DRIFT);
                if (chartLevel === "Attribute" && canShowAggregateOptions) {
                    aggregateOptions = this.state.aggregateOptions.filter(function (d) {
                        return d.attribute_name === attribute_name && d.data_set_id === chartData.data_set_id
                    });
                    let isAggregateError = aggregateOptions.filter(function (d) {
                        return d.is_error === true
                    });
                    if (isAggregateError.length > 0) {
                        showAggregateError = true;
                    }
                    aggregateOptions.unshift({
                        "label": <a className="qd-cw_dp-item"
                                    href="/#">self<span className="label label-danger">Error</span></a>,
                        "value": "self",
                        "attribute_name": attribute_name,
                        "data_set_id": chartData.data_set_id,
                        "data": chartData,
                        "is_error": true
                    });
                }

                if (chartLevel === "Dataset") {

                    if(chartData["name"] === "Unique Values"){
                        chartData["name"] = "Unique Rows"
                    }

                    idValue = chartData["data_set_id"] + "_" + chartData["name"].replace(/ /g, "_");
                    compareType = chartLevel;
                    compareDatasetOptions = _.reject(this.state.compareDatasetOptions, function (d) {
                        return (d.value === chartData["data_set_id"] || d.type !== metricName)
                    });
                }

                title = title.replace(" ", "_");
                chartCount = chartCount + 1;

                let chartType = getChartType(chartData, chartCount);
                const customGridKey = this.props.customKey;
                if (customGridKey !== undefined) {
                    key = `${key}_${customGridKey}`;
                }

                if (chartData["key"] !== undefined) {
                    // Since we are using single complete model data for all Grids
                    // related to model error, we will append the existing chart key with
                    // current Grid's custom key.
                    chartData = _.cloneDeep(chartData);

                    key = `${key}_${chartData["key"]}`;
                    chartData["key"] = key;
                }
                if (chartLevel === "Dataset") {
                        if (datasetList.length === 0) {
                            chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                            if (chartCount % 2 !== 0) {
                                is_same_data_col = true;
                            }
                        } else {
                            if (is_same_data_col === true && datasetList.length === 1) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                is_same_data_col = false;
                            } else {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[k]];
                                if (is_same_data_col === false) {
                                    is_same_data_col = true;
                                } else {
                                    is_same_data_col = false;
                                    k = k + 1;
                                }
                                if (k === 4) {
                                    k = 0;
                                }
                            }
                        }
                        datasetList.push(noErrorDatasetCount);
                        noErrorDatasetCount = noErrorDatasetCount + 1;
                        chartData["errorChart"] = false;
                        if (chartType === "barWithError") {
                            chartType = "barChart";
                        } else {
                            if (chartType === "areaWithError") {
                                chartType = "areaChart";
                            }
                        }
                }
                if (chartLevel === "Attribute") {
                        if (attributeList.length === 0) {
                            chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                            if (chartCount % 2 !== 0) {
                                is_same_col = true;
                            }
                        } else {
                            if (is_same_col === true && attributeList.length === 1) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                is_same_col = false;
                            } else {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                                if (is_same_col === false) {
                                    is_same_col = true;
                                } else {
                                    is_same_col = false;
                                    j = j + 1;
                                }
                                if (j === 4) {
                                    j = 0;
                                }
                            }
                        }
                        attributeList.push(noErrorCount);
                        noErrorCount = noErrorCount + 1;
                        chartData["errorChart"] = false;
                        if (chartType === "barWithError") {
                            chartType = "barChart";
                        } else {
                            if (chartType === "areaWithError") {
                                chartType = "areaChart";
                            }
                        }
                }

                if (chartData["name"] === "Data Drift") {
                    let isBoxplot = chartData["boxplot_data"].filter(x=>x !== "NA")
                    if(isBoxplot.length > 0){
                        chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                        if (chartData["chartType"] === "boxPlotZoomable") {
                            chartType = "boxPlotZoomable";
                        }
                    }
                    if (chartData["boxPlotData"]["statistics"] !== undefined && chartData["boxPlotData"]["statistics"].length > 0) {
                        delete chartData["boxPlotData"]["statistics"][0]["drift_patterns"];
                    }
                    chartData["drift_patterns"] =[]
                    chartData["hasDrift"] = false;
                    chartData["colorCode"] = chartData["color"];
                }

                let filteredData = null;
                let propsStartDate = this.state.startDateInside === null ? this.props.startDate : this.state.startDateInside
                    let propsEndDate = this.state.endDateInside === null ? this.props.endDate : this.state.endDateInside
                    if (propsStartDate !== null && propsEndDate !== null) {
                        filteredData = getChartFilterData(chartData, propsStartDate,
                            propsEndDate, chartType, false, false, MAX_DATA_POINTS_FOR_LIST_VIEW);
                        let timeVals = null;
                        if (filteredData !== null) {
                            timeVals = filteredData.time;
                        }
                        if (timeVals !== undefined && timeVals !== null && timeVals.length > 0) {
                            propsStartDate = moment(new Date(timeVals[0]));
                        }
                    }

                return (
                    <div key={key} className="col-md-6" id={idValue}>
                        <LazyLoadComponent placeholder={<Load isBootStrapColumn={true}/>}>
                            <ChartFilterHeader
                                dqpGrid={true}
                                yValue={chartData["name"]}
                                showHeatMap={true}
                                showSettingsMenu={true}
                                data={chartData}
                                scale={chartData["scale"]}
                                colorCode={chartData["color"]}
                                lineColorCode={LIGHT_COLOR_MAP[chartData["color"]]}
                                dataSetId={id}
                                mlModelId={id}
                                chartLevel={chartLevel}
                                showContribution={chartLevel === "Dataset" && metricType === "default"}
                                selectedIntegration={this.props.selectedIntegration}
                                compareAttributeOptions={compareAttributeOptions}
                                compareDataSrc={compareDatasetOptions}
                                aggregateOptions={aggregateOptions}
                                title={title}
                                startDate={propsStartDate}
                                endDate={propsEndDate}
                                filteredData={filteredData}
                                timeFilterApplied={true}
                                considerEndTime={false}
                                compareType={compareType}
                                chartType={chartType}
                                variant={this.state.variant + "_" + chartData["name"] + "_" + chartData["data_set_id"] + "_" + chartData["attributeId"]}
                                chartTimeFilter={this.chartTimeFilter}
                                dataSetName={chartData["datasetName"]}
                                attributeName={chartData["attribute_name"]}
                                hideDistanceFilter={true}
                                referenceDataSetName={chartData["referenceDataSetName"]}
                                referenceDataSetLabel={chartData["referenceDataSetLabel"]}
                                showAggregateError={showAggregateError}
                            />
                        </LazyLoadComponent>
                    </div>

                );
            } else if (lastDiv === true) {
                return (<NoErrorComponent message={message}/>);
            }
            return '';
        });
    }
}


export function getThumbnailChartsDqpErr(level, data, idInput, datasourceName, headerName, timelinessData, datasetName) {
        let allThumbnailChartSections = [];
        let sectionName = "Monitor Data Quality Performance";
        if(data === undefined) {
           return allThumbnailChartSections
        }
        let chartType = "thumbnailBar"
        let updatedData = []
        for (const _cData of Object.values(data))  {
            let newData = _.cloneDeep(_cData);
            if(newData.name === "Recency" && timelinessData !== undefined && timelinessData.length > 0) {
               newData = _.cloneDeep(timelinessData[0])
            } else if(newData.name === "Recency" && timelinessData !== undefined && timelinessData.length === 0) {
            //    continue;
            } else if(newData.name === "Data Drift" && newData.is_all_string_column !== undefined && !(newData.is_all_string_column.includes(false))) {
               continue;
            }
            if(level === "dataset" && newData.attribute_name !== undefined) {
               continue;
            }
            newData[DQ_CHART_POINTS] = newData["value"];
            normalizeChartData(newData, DQ_CHART_POINTS);
            if (newData[DQ_CHART_POINTS] !== undefined && newData[DQ_CHART_POINTS].length === 0){
                continue;
            }
            let section = sectionName+"-1"
            if(level === "dataset"){
                section = sectionName+"-1"
            } else {
                 section = "Monitor Data Quality Performance-2";
            }

            let colorInfo = MONITOR_PERF_LIST_VIEW[section];
            newData["background"] = colorInfo.cardBackground;
            newData["textColor"] = colorInfo.textColor;
            delete newData["boxplot_data"];
            let reqChartData = {"time": newData.time};
            reqChartData.label = "Dataset";
            reqChartData.labelValue = datasetName;
            reqChartData.toolTiptopHeader = headerName;
            reqChartData.version_name = newData.version_name;
            let uniqid = Math.floor(Math.random() * 1000000000);
            newData["chartData"] = reqChartData;
            newData["idValue"] = "lv"+ idInput + getNormalizedName(newData["name"]).replace(/ /g,"_")+"_"+uniqid
            reqChartData["values"] = convertDecimalDigits(newData.dq_chart_points);
            reqChartData["colorArray"] = colorInfo.colorArray;
            reqChartData["name"] = newData.name;
            newData["chartType"] = chartType;
            chartType = chartType === "thumbnailBar" ? "thumbnailArea": "thumbnailBar"
            newData["scale"] = MIN_MAX_SCALE;
            updatedData.push(newData);
        }

        if (updatedData.length < 4){
            let sectionData = {
                    "name": "",
                    "key": getNormalizedName(idInput + sectionName),
                    "data": Object.values(updatedData)
            }

            if (sectionData.data.length > 0){
                allThumbnailChartSections.push(sectionData);
            }
        } else {
            let i,j, chartCount = 4 ;
            for (i = 0,j = updatedData.length; i < j; i += chartCount) {
                let sectionData = {
                    "name": "",
                    "key": getNormalizedName(idInput + sectionName),
                    "data": Object.values(updatedData).slice(i, i+chartCount)
                    }
                allThumbnailChartSections.push(sectionData);
            }
        }
    return allThumbnailChartSections;
}

export function getMonitorTabDQPListViewTableData(datasetMapping, dqListData, followedAttributes, timelinessData) {
    let dqData = []
    if(dqListData.dataset_level_data !== undefined  && dqListData.attribute_level_data !== undefined && timelinessData !== undefined) {
        let timelinessDataValues = timelinessData["DefaultDataSet"]
        let issuedAttributes = dqListData.issued_attributes;
        let followAttributes = followedAttributes;
        let attributeLevelData = dqListData.attribute_level_data;
        let dataset_level_data = dqListData.dataset_level_data;
        let attributeIds = datasetMapping["attributeIds"]
        for (const [key, value] of Object.entries(datasetMapping["idDatasetDetail"])) {
            if(key === "attributeIds" || issuedAttributes[key] === undefined) {
                continue;
            }
            let totalLength = issuedAttributes[key].length
            let lastProfilingTime = issuedAttributes[key][0]["refresh_time"]
            let createdTime = issuedAttributes[key][totalLength - 1]["refresh_time"]
            let startDate = moment().subtract(9, 'days').set({"hour": 0, "minute": 0, "seconds": 0});
            let endDate = moment().endOf('day').set({"hour": 23, "minute": 59, "seconds": 59});
            let previousTime = null;
            let isAlertNeeded = !(moment(lastProfilingTime).isSameOrBefore(endDate) && moment(lastProfilingTime).isSameOrAfter(startDate))
            if(isAlertNeeded) {
              previousTime = lastProfilingTime
            }
            let filteredTimelinessData = []
            let filteredTimeliness = timelinessDataValues[0]
            if(filteredTimeliness !== undefined) {
               filteredTimelinessData = filteredTimeliness["data"].filter(x=>x.data_set_id === key)
            }
            let datasourceName = value["dataSourceName"];
            let datasetName = value["datasetName"]
            let datasetId = key
            let thumbnailCharts = getThumbnailChartsDqpErr("dataset", dataset_level_data[key], key, datasourceName, datasetName,
            filteredTimelinessData, datasetName)
            let row = []
            let dataSourceId = value["dataSourceId"]
            let follow_attributes_details = {}
            if(Object.keys(followAttributes).length !== 0){
               follow_attributes_details = followAttributes[dataSourceId]
            }
            row.push({"value": datasourceName, "type": "td", "group": "dataset"});
            row.push({"value": normalizeChildDatasetName(datasetName), "type": "datasetName", "lastProfilingTime": lastProfilingTime, "previousTime": previousTime});
            row.push({"value": 'Dataset Level - Includes All Attributes', "type": "td"});
            row.push({"value": thumbnailCharts, "type": "thumbnailCharts", "issuedAttributes": issuedAttributes[key], "noDataAvailable":  false, "createdTime": createdTime,"datasetName": datasetName, "dataSourceId": dataSourceId, "dataSourceName":datasourceName, "lastProfilingTime": lastProfilingTime,"level": "dataset","datasetId":datasetId});
            row.push({"value": key, "type": "action", "level": "dataset", "datasetName": datasetName, "dataSourceId": dataSourceId, "dataSourceName":datasourceName, "lastProfilingTime": lastProfilingTime,"datasetId":datasetId})
            dqData.push(row)
            let filteredAttributes = attributeIds.filter(x=>x.datasetId === key)
            for(let j=0;j<filteredAttributes.length;j++) {
                row = []
                let attributeId = filteredAttributes[j]["attributeId"]
                let attributeName = filteredAttributes[j]["attributeName"]
                let follow_status = false
                if(follow_attributes_details!== undefined){
                    follow_status = follow_attributes_details[attributeId] !== undefined ? follow_attributes_details[attributeId] : false
                }
                let thumbnailChartsAttributes = getThumbnailChartsDqpErr( "attribute", attributeLevelData[key][attributeId], attributeId, datasourceName, attributeName, datasetName)
                row.push({"value": "", "type": "td", "group": "attribute"});
                row.push({"value": "", "type": "td"});
                let reqAttributeName = normalizeAttributeName(attributeName);
                row.push({"value": reqAttributeName, "type": "td"});
                row.push({"value": thumbnailChartsAttributes, "type": "thumbnailCharts","createdTime": createdTime,"datasetName": datasetName, "dataSourceId": dataSourceId, "dataSourceName":datasourceName, "lastProfilingTime": lastProfilingTime,"level": "attribute","attributeName":attributeName,"datasetId":datasetId});
                row.push({"value": key, "type": "action", "follow_status": follow_status, "level": "attribute", "datasetName": datasetName, "attributeId": attributeId, "attributeName":attributeName, "dataSourceId": value["dataSourceId"],"dataSourceName": datasourceName,"datasetId":datasetId})
                dqData.push(row)
            }

    }
    }
    return {
        "tableData": dqData,
    };

}

export function getDatasetsWithDuplicateRows(data){
    let DatasetsWithDuplicates = 0;
    for (let dataSetId of Object.keys(data)) {
        let valueList = data[dataSetId];
        // if value is less than 100 mean, duplicates present in that table
        let has_duplicates = valueList.some(el => el < 100);
        if (has_duplicates === true){
            DatasetsWithDuplicates = DatasetsWithDuplicates +1;
        }
    }
    return DatasetsWithDuplicates;
}

export function getConvertedSize(bytes, decimals = 2) {
       if (bytes === 0) return '0 Bytes';
       const k = 1024;
       const dm = decimals < 0 ? 0 : decimals;
       const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

       const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function userValidation(data,type="username")
{
    if(data === "" || data ===null || data ===undefined){return false;}
    const urlRegex = RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    let result = (urlRegex.test(data));
    if(!result && type === "username")
    {
        const userNameRegex = RegExp(/^(?![_.~`!@#$%^&*()?<>;:"'{} ])([a-zA-Z0-9\u00C0-\u00ff .]*)$/);
        result = (userNameRegex.test(data));
        return result;
    }
    else if (!result && type === "input")
    {
        const userInput = RegExp(/^(?![_.~`!@#$%^&*()?<>;:"'{} ])([a-zA-Z0-9\u00C0-\u00ff -_ .]*)$/);
        result = (userInput.test(data));
        return result;
    }
}

export function getFullNameOfMetric(metricName){
    if (metricName && metricName.toLowerCase() === "mae"){
        metricName = "Mean Absolute Error";
    } else if(metricName && metricName.toLowerCase() === "rmse"){
        metricName = "Root Mean Squared Error";
    }
    return metricName;
}


export function getNoOfDuplicateRows(data){
    let noOfDuplicatesRows = 0;
    for (let dataSetId of Object.keys(data)) {
        let duplicate_count = data[dataSetId];
        // if value is less than 100 mean, duplicates present in that table
        noOfDuplicatesRows = noOfDuplicatesRows + duplicate_count;
    }
    return noOfDuplicatesRows;
}

export function getAttributesWithDuplicateRows(data){
    let attributesWithDuplicates = 0;
    for (let dataSetId of Object.keys(data)) {
        let valueList = data[dataSetId];
        let duplicates = valueList.filter(el => el < 100);
        attributesWithDuplicates = attributesWithDuplicates + duplicates.length;
    }
    return attributesWithDuplicates;
}

export function formModelVersionMap(mlModels) {
    const modelVersionMap = {};
    const modelsDropDown = [];
    const modelsDataMap = {};
    const modelTypeMap = {};

    for (let currModel of mlModels) {
        const currModelId = currModel["ml_model_id"];
        const ml_model_type = currModel["ml_model_type"];

        // Has Complete model data with model id as key
        modelsDataMap[String(currModelId)] = currModel;

        // List contains drop down options for model selection drop down
        const ddItem = {"label": currModel["model_name"], "value": String(currModelId)};
        modelsDropDown.push(ddItem);

        // List has version information for the selected root model. Currently we have only one entry
        // in list. Will have more values when we implement model versioning
        modelVersionMap[String(currModelId)] = [{"label": currModel["version"], "value": String(currModelId)}];

        // Has model type as key and associated drop down options as their values
        let mtOptions = modelTypeMap[ml_model_type];
        if (mtOptions === undefined) {
            mtOptions = [];
        }

        mtOptions.push(ddItem);
        modelTypeMap[ml_model_type] = mtOptions;
    }

    return {
        "modelsDropDown": modelsDropDown,
        "modelVersionMap": modelVersionMap,
        "modelsDataMap": modelsDataMap,
        "modelTypeMap": modelTypeMap
    };
}


export function checkUniqueModelABCombination(modelAVersion, modelBVersion, modelCVersion,
                                              existingVersions) {
    const currCombinationList = [];
    if (modelAVersion !== undefined) {
        currCombinationList.push(modelAVersion);
    }

    if (modelBVersion !== undefined) {
        currCombinationList.push(modelBVersion);
    }

    if (modelCVersion !== undefined) {
        currCombinationList.push(modelCVersion);
    }

    if (currCombinationList.length <= 1) {
        // User has selected 0 or 1 input, so no need to verify for unique combination
        return true;
    }

    let matches = existingVersions.filter(x => {
        const matching_first_2_found = currCombinationList.includes(String(x.model_1)) &&
            currCombinationList.includes(String(x.model_2));

        let isThirdMatch = true;
        if (x.model_3 !== undefined && x.model_3 !== null) {
            isThirdMatch = currCombinationList.includes(String(x.model_3));
        } else if (modelCVersion !== undefined) {
            isThirdMatch = currCombinationList.includes(String(x.model_3));
        }

        return matching_first_2_found && isThirdMatch;
    });

    // If there are no matches found, it means the given configuration is a new configuration
    return matches.length === 0;
}

export function getABCompareViewModelSummary(modelData, modelErrMap) {
    const model_name = modelData.model_name;
    const model_id = modelData.ml_model_id;

    const createdTimeStr = modelData.created_time.replace(" GMT", "");
    const modifiedTimeStr = modelData.modified_time.replace(" GMT", "");
    const lastComputedTime = modelData.last_computed_time;

    const cDateObj = new Date(createdTimeStr);
    const mDateObj = new Date(modifiedTimeStr);

    const DATE_FORMAT = 'MMM YYYY';
    let createdTime = getSuffixedDate(cDateObj) + ' ' + moment(cDateObj).format(DATE_FORMAT)
    let modifiedTime = getSuffixedDate(mDateObj) + ' ' + moment(mDateObj).format(DATE_FORMAT);
    let lastComputedTimeFormatted = '-';

    if (!IS_NONE_CHECK.includes(lastComputedTime)) {
        const lct = new Date(lastComputedTime.replace(" GMT", ""));
        lastComputedTimeFormatted = getSuffixedDate(lct) + ' ' + moment(lct).format(DATE_FORMAT);
    }

    const abKeyValueSummary = [
        {"key": "Model Type", "value": convertToCamelCase(modelData.ml_model_type)},
        {"key": "Author", "value": modelData.author},
        {"key": "Version", "value": modelData.version},
        {"key": "Created on", "value": createdTime},
        {"key": "Updated on", "value": modifiedTime},
        {"key": "Last Processed on", "value": lastComputedTimeFormatted}
    ];

    let modelHasError = modelErrMap[model_id];
    if (IS_NONE_CHECK.includes(modelHasError)){
        modelHasError = false;
    }

    let lastProcessedTime = '';
    if (modelHasError) {
        lastProcessedTime = lastComputedTime;
    }

    return {
        "name": model_name,
        "model_id": model_id,
        "modelHasError": modelHasError,
        "lastProcessedTime": lastProcessedTime,
        "abKeyValueSummary": abKeyValueSummary,
    }
}

export function getABChartData(combinedData, currModelCharts,
                               requiredMetricName, index, fullChartInfoForCurrMetric) {
    /**
     * combinedData - is a dictionary object which will have combined thumbnail chart data.
     * We will combine current model performance data of all the 2 or 3 models data into single object.
     *
     * currModelCharts - Represents a chart data of the current model (among the 2 or 3 AB configure models.)
     *
     * requiredMetricName - Represents current model performance metric name
     *
     * index - Represents the configured index of model in Model AB configuration.
     *      For ex) if it is 'A' model, index will be 1. For B, it will be 2, 'C' it will be 3
     *
     * fullChartInfoForCurrMetric - Will have complete chart data for the current metric name combination.
     * **/
    if (IS_NONE_CHECK.includes(currModelCharts)) {
        // Current model does not have anything chart data yet. Nothing to process here
        return {"combinedData": combinedData, "currModelInfo": null};
    }

    let currChartData = currModelCharts[requiredMetricName];
    if (IS_NONE_CHECK.includes(currChartData)) {
        // Required chart data is not found. So we can skip further processing
        return {"combinedData": combinedData, "currModelInfo": null};
    }

    if (combinedData === null) {
        combinedData = _.cloneDeep(currChartData);
    }

    // const clonedChart = _.cloneDeep(currChartData);
    const modelName = currChartData.data_source_name;

    // clonedChart["source"] = MODEL_AB_COMPARE_SRC;
    // clonedChart.drift = convertDecimalDigits(clonedChart.drift);
    // fullChartInfoForCurrMetric[`chart${index}`] = clonedChart;

    combinedData[`line${index}`] = convertDecimalDigits(currChartData.drift);
    combinedData[`time${index}`] = currChartData.time;
    combinedData[`labelText1Line${index}`] = "Model";
    combinedData[`labelValue1Line${index}`] = modelName;

    // if (!IS_NONE_CHECK.includes(combinedData.name) && combinedData.name.includes(" vs ")) {
    //     // Dual charts. So add this to chart data
    //     combinedData[`line${index}_a`] = currChartData.drift1;
    //     combinedData[`time${index}_a`] = currChartData.time;
    // }


    const currModelData = {
        "ml_model_id": currChartData.ml_model_id,
        "index_in_ab": index,
        "model_name": modelName
    };

    return {"combinedData": combinedData, "currModelInfo": currModelData};
}

export function getChartsWithMinTime(timeArr) {
    // Returns minimum time & corresponding chart indices among the
    // given timestamps ["2021-07-01 11:20:05", undefined, "2021-07-14 18:10:47"]
    const chartInfo = {};
    let minTime = null;
    let requiredChartIndices = [];

    for (let index = 0; index < timeArr.length; index++) {
        let currVal = timeArr[index];
        if (currVal === undefined) {
            continue;
        }

        if (minTime === null) {
            minTime = currVal;
            requiredChartIndices.push(index + 1);
            continue;
        }

        const currMoment = moment(new Date(currVal))
        const minMoment = moment(new Date(minTime))
        if (currMoment.isAfter(minMoment)) {
            continue;
        }

        if (currVal === minTime) {
            requiredChartIndices.push(index + 1);
            continue;
        }

        if (currMoment.isBefore(minMoment)) {
            minTime = currVal;
            requiredChartIndices = [index + 1]
        }
    }

    chartInfo["requiredChartIndices"] = requiredChartIndices;
    chartInfo["minTime"] = minTime;
    return chartInfo;
}

export function mergeTimelines(abChartDataCombined) {
    /** Merge timeline of multiple charts. We will populate with previous data points
    * for missing data points.
     *
    **/
    const clonedAbChartDataCombined = _.cloneDeep(abChartDataCombined);
    let prevLine1 = undefined;
    let prevLine2 = undefined;
    let prevLine3 = undefined;
    let line1 = clonedAbChartDataCombined.line1;
    let time1 = clonedAbChartDataCombined.time1;
    let line2 = clonedAbChartDataCombined.line2;
    let time2 = clonedAbChartDataCombined.time2;
    let time3 = clonedAbChartDataCombined.time3;
    let line3 = clonedAbChartDataCombined.line3;

    const combined_time = [];
    const updatedLine1 = [];
    const updatedLine2 = [];
    const updatedLine3 = [];
    const line_1_fake = [];
    const line_2_fake = [];
    const line_3_fake = [];

    while (true) {
        let currIndexTimes = [];
        if (time1 !== undefined) {
            currIndexTimes.push(time1[0]);
        } else {
            currIndexTimes.push(undefined);
        }
        if (time2 !== undefined) {
            currIndexTimes.push(time2[0]);
        } else {
            currIndexTimes.push(undefined);
        }
        if (time3 !== undefined) {
            currIndexTimes.push(time3[0]);
        } else {
            currIndexTimes.push(undefined);
        }

        if (currIndexTimes.filter(x => x === undefined).length === 3) {
            break;
        }

        const res = getChartsWithMinTime(currIndexTimes);
        combined_time.push(res.minTime);
        const requiredChartIndices = res.requiredChartIndices;
        if (requiredChartIndices.length === 0){
            break;
        }

        if (time1 !== undefined && requiredChartIndices.includes(1)) {
            // Remove first element from line 1,s time array
            time1.shift();
            let point = line1.shift();
            prevLine1 = point;
            updatedLine1.push(point);
            line_1_fake.push(false)
        } else if (time1 !== undefined) {
            line_1_fake.push(true)
            if (prevLine1 === undefined || line1[0] === undefined) {
                updatedLine1.push(undefined);
            } else {
                // const avgPoint = (prevLine1 + line1[0]) / 2;
                const avgPoint = prevLine1;
                updatedLine1.push(avgPoint);
                prevLine1 = avgPoint;
            }
        }

        if (time2 !== undefined && requiredChartIndices.includes(2)) {
            // Remove first element from line 1,s time array
            time2.shift();
            let point = line2.shift();
            prevLine2 = point;
            updatedLine2.push(point);
            line_2_fake.push(false)
        } else if (time2 !== undefined) {
            line_2_fake.push(true)
            if (prevLine2 === undefined || line2[0] === undefined) {
                updatedLine2.push(undefined);
            } else {
                // const avgPoint = (prevLine2 + line2[0]) / 2
                const avgPoint = prevLine2
                updatedLine2.push(avgPoint);
                prevLine2 = avgPoint;
            }
        }

        if (time3 !== undefined && requiredChartIndices.includes(3)) {
            // Remove first element from line 1,s time array
            time3.shift();
            let point = line3.shift();
            prevLine3 = point;
            updatedLine3.push(point);
            line_3_fake.push(false)
        } else if (time3 !== undefined) {
            line_3_fake.push(true)
            if (prevLine3 === undefined || line3[0] === undefined) {
                updatedLine3.push(undefined);
            } else {
                // const avgPoint = (prevLine3 + line3[0]) / 2
                const avgPoint = prevLine3
                updatedLine3.push(avgPoint);
                prevLine3 = avgPoint;
            }
        }
    }

    clonedAbChartDataCombined.time = combined_time;
    if (updatedLine1.length > 0) {
        clonedAbChartDataCombined.line1 = updatedLine1;
        clonedAbChartDataCombined.is_fake_line1 = line_1_fake;
    }

    if (updatedLine2.length > 0) {
        clonedAbChartDataCombined.line2 = updatedLine2;
        clonedAbChartDataCombined.is_fake_line2 = line_2_fake;
    }

    if (updatedLine3.length > 0) {
        clonedAbChartDataCombined.line3 = updatedLine3;
        clonedAbChartDataCombined.is_fake_line3 = line_3_fake;
    }

    return clonedAbChartDataCombined;
}


export function getModelABCombineChartsInMonitor(currModelAB_id, model_ids, mappedChartData) {
    const thumbnailData = {};
    const detailedChartData = {};
    thumbnailData["model_ids"] = model_ids;
    const sections = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
    const thumbnailDataSections = [];
    let recentTimeValueInChart = null;

    // Commented Safari not supported  replaceAll function
    // const idKeyStr = currModelAB_id + '_' + model_ids.toString().replaceAll(",","_");
    const idKeyStr = currModelAB_id + '_' + model_ids.toString().replace(/,/g, "_");
    // Add keys to be used
    const modelAccuracySection = sections[MODEL_ACCURACY];
    modelAccuracySection["key"] = `${MODEL_ACCURACY}_${idKeyStr}`;
    modelAccuracySection["name"] = `${MODEL_ACCURACY} Metrics`;

    const detailedSections = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);

    // Delete model evaluation, model explainability, feature_importance section, since we won't show
    // that in Compare View - Detailed charts page.
    delete detailedSections[MODEL_EVALUATION];
    delete detailedSections[MODEL_EXPLAINABILITY];
    delete detailedSections[FEATURE_IMPORTANCE];

    thumbnailDataSections.push(modelAccuracySection);

    const modelMonitoringSection = sections[MODEL_MONITORING];
    modelMonitoringSection["key"] = `${MODEL_MONITORING}_${idKeyStr}`;
    // Name is changed as per QPS-2230
    modelMonitoringSection["name"] = `${MODEL_RUNTIME_PROFILE} Metrics`;
    thumbnailDataSections.push(modelMonitoringSection);
    thumbnailData["sections"] = thumbnailDataSections;

    // Get chart data of the required 2 or 3 models
    const model_id_1 = model_ids[0];
    const model_id_2 = model_ids[1];
    const model_id_3 = model_ids[2];

    const chartData_1 = mappedChartData[model_id_1];
    const chartData_2 = mappedChartData[model_id_2];
    const chartData_3 = mappedChartData[model_id_3];



    // Combine the metric names into single list
    let metricNames = [];
    if (!IS_NONE_CHECK.includes(chartData_1)) {
        metricNames = [...metricNames, ...Object.keys(chartData_1)];
    }

    if (!IS_NONE_CHECK.includes(chartData_2)) {
        metricNames = [...metricNames, ...Object.keys(chartData_2)];
    }

    if (!IS_NONE_CHECK.includes(chartData_3)) {
        metricNames = [...metricNames, ...Object.keys(chartData_3)];
    }

    const uniqueMetricNames = new Set(metricNames);

    // Get the data for each metric of models, merge the charts into single charts for each metric
    // and add the merged result to the corresponding section.
    for (let currentMetric of uniqueMetricNames) {
        if (currentMetric.includes(" vs ")){
            continue;
        }
        if (currentMetric === modelPerformance.HOMOGENEITY_COMPLETENESS_V_MEASURE_SCORE){
            continue;
        }
        // Check for model 1
        let sectionType = MODEL_PERFORMANCE_RESULT_GROUP_MAP[currentMetric];
        if (sectionType === MODEL_EVALUATION || sectionType === MODEL_EXPLAINABILITY) {
            continue;
        }

        if (sectionType === undefined) {
            sectionType = MODEL_ACCURACY;
        }

        let fullChartInfoForCurrMetric = {};
        let associatedModels = [];
        let combinedChart1 = getABChartData(null, chartData_1,
            currentMetric, 1, fullChartInfoForCurrMetric);
        let combinedChart = combinedChart1["combinedData"];
        associatedModels.push(combinedChart1["currModelInfo"]);

        const combinedChart2 = getABChartData(combinedChart, chartData_2,
            currentMetric, 2, fullChartInfoForCurrMetric);
        combinedChart = combinedChart2["combinedData"];
        associatedModels.push(combinedChart2["currModelInfo"]);

        const combinedChart3 = getABChartData(combinedChart, chartData_3,
            currentMetric, 3, fullChartInfoForCurrMetric);
        combinedChart = combinedChart3["combinedData"];
        associatedModels.push(combinedChart3["currModelInfo"]);

        if (IS_NONE_CHECK.includes(combinedChart)) {
            // All 3 models does not have chart
            continue;
        }

        const reqKey = `model_ab_${getNormalizedName(currentMetric)}_${idKeyStr}`;
        combinedChart["idValue"] = reqKey;
        combinedChart["key"] = reqKey;
        combinedChart["chartType"] = "thumbnailMultiLine";
        combinedChart["lineColors"] = "multi-line-colors";

        // Merge timelines of combined charts
        combinedChart = mergeTimelines(combinedChart);


        const timeArr = combinedChart.time;
        if (IS_NONE_CHECK.includes(recentTimeValueInChart) && timeArr.length > 1) {
            recentTimeValueInChart = timeArr[timeArr.length - 1];
        }

        sections[sectionType].data.push(combinedChart);

        // Add to detailed chart data
        const clonedCombinedChart = _.cloneDeep(combinedChart);
        clonedCombinedChart["key"] = `fs_${reqKey}`;
        clonedCombinedChart["chartType"] = "multiLineChart";
        clonedCombinedChart["source"] = MODEL_AB_COMPARE_SRC;
        clonedCombinedChart["isCombinedChart"] = true;
        clonedCombinedChart["associatedModels"] = associatedModels;

        fullChartInfoForCurrMetric["combinedChart"] = clonedCombinedChart;

        detailedSections[sectionType].data.push(fullChartInfoForCurrMetric);
    }

    detailedChartData["key"] = `mpcv_detailed_${idKeyStr}`
    detailedChartData["data"] = detailedSections

    thumbnailData["recentTimeValueInChart"] = recentTimeValueInChart


    return {
        "thumbnailData": thumbnailData,
        "detailedChartData": detailedChartData
    };
}

export function getMonitorCompareViewTableData(modelABData, modelInfoMap,
                                               mappedChartData, showMoreData) {
    if (IS_NONE_CHECK.includes(modelABData) || IS_NONE_CHECK.includes(modelInfoMap)) {
        return {"abDetailedChartData": {},"tableRows": []};
    }

    // Get error status about available models
    const errMap = getModelErrorStatusForModelAB(showMoreData);

    const tableRows = [];
    const abDetailedChartData = {};
    for (const currAB of modelABData) {
        let currentRow = [];

        // First column data -> Model name with text
        const modelNameData = [];
        const model_ids = [];
        const modelSummaryData = [];

        const currModelAB_id = currAB.model_ab_id;
        const model_1 = currAB.model_1;
        model_ids.push(String(model_1));
        const model_1_data = modelInfoMap[model_1];
        if (IS_NONE_CHECK.includes(model_1_data)){
            continue;
        }

        modelNameData.push({"name": model_1_data.model_name, "text": "A"});
        const model_1_summary = getABCompareViewModelSummary(model_1_data, errMap);
        modelSummaryData.push(model_1_summary);

        const model_2 = currAB.model_2;
        const model_2_data = modelInfoMap[model_2];
        model_ids.push(String(model_2));
        if (IS_NONE_CHECK.includes(model_2_data)){
            continue;
        }

        modelNameData.push({"name": model_2_data.model_name, "text": "B"});
        const model_2_summary = getABCompareViewModelSummary(model_2_data, errMap);
        modelSummaryData.push(model_2_summary);

        const model_3 = currAB.model_3;
        let model_3_data = modelInfoMap[model_3];
        if (!IS_NONE_CHECK.includes(model_3_data)) {
            model_ids.push(String(model_3));
            modelNameData.push({"name": model_3_data.model_name, "text": "C"});
            const model_3_summary = getABCompareViewModelSummary(model_3_data, errMap);
            modelSummaryData.push(model_3_summary);
        }

        currentRow.push(modelNameData);
        currentRow.push(modelSummaryData);

        const abChartData = getModelABCombineChartsInMonitor(currModelAB_id, model_ids, mappedChartData);

        const thumbnailChartsData = abChartData["thumbnailData"];
        const detailedChartData = abChartData["detailedChartData"];
        thumbnailChartsData["key"] = `thumbnail_${currAB.model_ab_id}`;

        // For Performance Metrics column
        currentRow.push(thumbnailChartsData);

        // For Detailed Charts column
        currentRow.push({"model_ab_id": currModelAB_id, "model_ids": model_ids});

        tableRows.push(currentRow);

        detailedChartData["model_ids"] = model_ids;
        abDetailedChartData[String(currModelAB_id)] = detailedChartData;
    }

    return {"abDetailedChartData": abDetailedChartData,"tableRows": tableRows};
}


export function getSuffixedDate(date_obj) {
    // Get the input date string and adds required suffix to date
    // Given input should be Date object. Ex) new Date()
    const dayValue = date_obj.getDate();
    let suffix;
    if (dayValue >= 11 && dayValue <= 20) {
        suffix = "th";
        return `${dayValue}${suffix}`;
    }

    let lastDigit = dayValue % 10;
    suffix = SUFFIXED_DATE[String(lastDigit)];
    if (suffix === undefined) {
        suffix = "th";
    }

    return `${dayValue}${suffix}`;
}


export function getModelNameMappedCharts(chartData) {
    /*
     * Gets performance chart data in format required for Model AB combination charts
     *
     * Sample return data:
     * {"2": {"Weighted precision": {<Complete 'Weight precision' chart data for model id 2>}
     */
    const modelABCombinationChart = {};
    for (const currChart of chartData) {
        const ml_model_id = String(currChart["ml_model_id"]);
        const name = currChart["name"];
        if (modelABCombinationChart[ml_model_id] === undefined) {
            modelABCombinationChart[ml_model_id] = {};
        }

        modelABCombinationChart[ml_model_id][name] = _.cloneDeep(currChart);
    }

    return modelABCombinationChart;
}


export function getTotalShowMoreDataPointsCount(fullShowMoreData) {
    let count = 0;
    const dataSetData = fullShowMoreData.dataSet;
    const attributeData = fullShowMoreData.attribute;
    for (let currData of dataSetData) {
        let chartData = currData.data;
        if (IS_NONE_CHECK.includes(chartData)) {
            continue;
        }

        count = count + chartData.length;
    }

    for (let currData of attributeData) {
        let chartData = currData.data;
        if (IS_NONE_CHECK.includes(chartData)) {
            continue;
        }

        count = count + chartData.length;
    }

    return count;
}


export function getModelErrorStatusForModelAB(fullShowMoreData) {
    /**
     * Returns a dictionary with model id as key and true if the model has error chart data in it.
    * */
    if (IS_NONE_CHECK.includes(fullShowMoreData)) {
        return {};
    }

    const modelErrMap = {};
    const dataSetLevelMLCharts = fullShowMoreData.dataSet;
    const attributeLevelMLCharts = fullShowMoreData.attribute;
    for (let currSection of dataSetLevelMLCharts) {
        const charts = currSection.data;

        for (let chart of charts) {
            const currModelID = chart.ml_model_id;
            if (!IS_NONE_CHECK.includes(modelErrMap[currModelID])){
                // We have taken result for this model already.
                continue;
            }

            const res = getChartTypeForMonitorCharts(chart);
            if (res.hasError) {
                modelErrMap[currModelID] = true;
            }
        }
    }

    for (let currSection of attributeLevelMLCharts) {
        const charts = currSection.data;
        for (let chart of charts) {
            const currModelID = chart.ml_model_id;
            if (!IS_NONE_CHECK.includes(modelErrMap[currModelID])){
                // We have taken result for this model already.
                continue;
            }

            const res = getChartTypeForMonitorCharts(chart);
            if (res.hasError) {
                modelErrMap[currModelID] = true;
            }
        }
    }

    return modelErrMap;
}

export function filterBasedOnModelAB_ID(mpCompareViewTableData, selectedModelABID) {
    let currentTableData = [];
    for (let currRow of mpCompareViewTableData) {
        if (currRow[3].model_ab_id !== selectedModelABID) {
            continue;
        }
        currentTableData.push(_.cloneDeep(currRow));
        break;
    }
    return currentTableData;
}


export function getModelOptionsAssociatedWithAB(currABData, mlModelOptions) {
    if (IS_NONE_CHECK.includes(currABData)) {
        currABData = [];
    }

    if (IS_NONE_CHECK.includes(mlModelOptions)) {
        mlModelOptions = [];
    }

    let associatedModels = [];
    for (let modelABData of currABData) {
        const model_1 = modelABData.model_1;
        if (!IS_NONE_CHECK.includes(model_1) && !associatedModels.includes(model_1)) {
            associatedModels.push(model_1);
        }

        const model_2 = modelABData.model_2;
        if (!IS_NONE_CHECK.includes(model_2) && !associatedModels.includes(model_2)) {
            associatedModels.push(model_2);
        }

        const model_3 = modelABData.model_3;
        if (!IS_NONE_CHECK.includes(model_3) && !associatedModels.includes(model_3)) {
            associatedModels.push(model_3);
        }
    }

    associatedModels = associatedModels.map(x=> Number(x));
    return mlModelOptions.filter(x => associatedModels.includes(Number(x.value)));
}


export function filterBasedOnMetricNames(fullScreenData, requiredMetrics, startDate, endDate) {
    if (IS_NONE_CHECK.includes(fullScreenData)){
        return fullScreenData;
    }

    const requiredFullScreenData = _.cloneDeep(fullScreenData);

    for (let sectionName of Object.keys(requiredFullScreenData)){
        let sectionData = requiredFullScreenData[sectionName];
        if (IS_NONE_CHECK.includes(sectionData)){
            continue;
        }

        let data = sectionData["data"];

        let filteredData = [];
        for (let currData of data){
            const combinedChart = currData.combinedChart;
            if (IS_NONE_CHECK.includes(combinedChart)){
                continue;
            }

            // Perform time filter
            currData.combinedChart = performTimeFilterForModelABCombinedCharts(combinedChart, startDate, endDate);

            // If metrics list is given, we will filter only selected metrics.
            // If required metrics is not given, we will include all the chart results
            if (requiredMetrics === null || requiredMetrics.includes(combinedChart.name)){
                filteredData.push(currData)
            }
        }

        sectionData["data"] = filteredData;
        requiredFullScreenData[sectionName] = sectionData;
    }

    return requiredFullScreenData;
}

export function performTimeFilterForModelABCombinedCharts(currentData, startDate, endDate) {
    let times = currentData.time;
    let line_1_values = currentData.line1;
    let line_1_status = currentData.is_fake_line1;
    let line_2_values = currentData.line2;
    let line_2_status = currentData.is_fake_line2;
    let line_3_values = currentData.line3;
    let line_3_status = currentData.is_fake_line3;
    const filteredTimes = [];
    const filtered_line_1 = [];
    const filtered_line_1_status = [];
    const filtered_line_2 = [];
    const filtered_line_2_status = [];
    const filtered_line_3 = [];
    const filtered_line_3_status = [];

    for (let index = 0; index < times.length; index++) {
        let xTime = times[index];
        let timeObj = new Date(xTime);
        const parsedDate = moment(timeObj);
        const cDateIsBeforeEndDate = parsedDate.isSameOrBefore(endDate);
        const canIncludeVal = parsedDate.isSameOrAfter(startDate) && cDateIsBeforeEndDate;
        if (!canIncludeVal) {
            continue;
        }

        filteredTimes.push(xTime);

        if (line_1_values !== undefined) {
            filtered_line_1.push(line_1_values[index]);
            filtered_line_1_status.push(line_1_status[index]);
        }

        if (line_2_values !== undefined) {
            filtered_line_2.push(line_2_values[index]);
            filtered_line_2_status.push(line_2_status[index]);
        }

        if (line_3_values !== undefined) {
            filtered_line_3.push(line_3_values[index]);
            filtered_line_3_status.push(line_3_status[index]);
        }
    }

    const clonedData = _.cloneDeep(currentData);
    clonedData.time = filteredTimes;
    if (line_1_values !== undefined) {
        clonedData.line1 = filtered_line_1;
        clonedData.is_fake_line1 = filtered_line_1_status;
    }

    if (line_2_values !== undefined) {
        clonedData.line2 = filtered_line_2;
        clonedData.is_fake_line2 = filtered_line_2_status;
    }

    if (line_3_values !== undefined) {
        clonedData.line3 = filtered_line_3;
        clonedData.is_fake_line3 = filtered_line_3_status;
    }

    return clonedData;
}


export function getAllMetricRecencyInfo(otherMetrics, recencyMetrics) {
        let recencyKeys = Object.keys(recencyMetrics);
        let otherKeys = Object.keys(otherMetrics);
        if (otherKeys !== undefined && otherKeys !== null && recencyKeys !== undefined && recencyKeys !== null) {
            for (let intKey in recencyKeys) {
                const integrationKey = recencyKeys[intKey];
                if (otherKeys.includes(integrationKey)) {
                    let recencyIntegData = recencyMetrics[integrationKey];
                    let otherIntegData = otherMetrics[integrationKey];
                    let recencyIntegDataKeys = Object.keys(recencyIntegData);
                    for (let key in recencyIntegDataKeys) {
                        const recencyMetricDataKey = recencyIntegDataKeys[key];
                        if (Object.keys(otherIntegData).includes(recencyMetricDataKey)) {
                            const recencyMetricKey = Object.keys(recencyMetrics[integrationKey][recencyMetricDataKey]);
                            for (let recencyKey in recencyMetricKey) {
                                const recencyValueKey = recencyMetricKey[recencyKey];
                                otherMetrics[integrationKey][recencyMetricDataKey][recencyValueKey] = recencyMetrics[integrationKey][recencyMetricDataKey][recencyValueKey]
                            }
                        } else {
                            otherMetrics[integrationKey][recencyMetricDataKey] = recencyMetrics[integrationKey][recencyMetricDataKey];
                        }
                    }
                } else {
                    otherMetrics[integrationKey] = recencyMetrics[integrationKey];
                }
            }
        }
        return otherMetrics;
    }


export function formatNumber(number) {
    let formattedNumber;
    try{
        formattedNumber = new Intl.NumberFormat('en-US', {
                        notation: "compact",
                        maximumFractionDigits: 4
                        }).format(number);
    } catch {
        formattedNumber = number

    }
    return formattedNumber;
}


export function checkExpiryPowerBI() {
    const featureAccess = getFeatureAccess();
    let enablePowerBiTab = false;
    if (!IS_NONE_CHECK.includes(featureAccess)) {
        const powerbiTab = featureAccess["powerbi"];
        enablePowerBiTab = powerbiTab === true;
    }
    return enablePowerBiTab;
}

export function checkSessionForRedirection() {
    let sessionToken = localStorage.getItem("sessionToken");
    if (sessionToken === "undefined" || sessionToken === "null" ||
        sessionToken === null || sessionToken === undefined) {
        window.location = '/';
        return;
    }
}


export function getShortTitleForAttribute(fullAttributeName, separator=STRUCT_SEPARATOR){
    // Most of the time we will use struct separator.
    // But if user gives different separator, we will use that.
    // (Different separator will come for cases like tooltip in charts)
    if(fullAttributeName === undefined || fullAttributeName === null){
        return {"requiredName": "", "fqTooltipContent": null};
    }

    let arrSplit = fullAttributeName.split(separator);
    if (arrSplit.length <= 1) {
        return {"requiredName": fullAttributeName, "fqTooltipContent": null};
    }

    let miniText = arrSplit.slice(-2).join(STRUCT_SEPARATOR);
    return {"requiredName": miniText, "fqTooltipContent": fullAttributeName}
}


export function getChartTooltipHeaderNestedRecord(tooltipTopHeader) {
    /**
     * This is for having shortened name for nested struct.
     * This function is called by all tooltip functions of charts.
     * This function returns a json object with 2 keys.
     *
     * 'tooltipTopHeader' -> Represents shortened name
     * 'attributeTooltip' -> Represents full name
     */

    let shortenedData = getShortTitleForAttribute(tooltipTopHeader, QUALDO_ATTRIBUTE_SEPARATOR);
    let shortenedName = shortenedData["requiredName"]
    let attributeTooltip = shortenedData["fqTooltipContent"];
    if (attributeTooltip !== null) {
        // We have tool tip component. So use shortened component
        shortenedName = FQ_TOOLTIP_PLACEHOLDER + shortenedName;
        attributeTooltip = normalizeAttributeName(attributeTooltip);
        tooltipTopHeader = normalizeAttributeName(shortenedName);   // This is for the tooltip dataset which contains _qld_ in its name and displaying > symbol in it instead of _qld_
    }

    return {
        "tooltipTopHeader": tooltipTopHeader,
        "attributeTooltip": attributeTooltip
    }
}



export function getNestedAttributeTreeOptions(all_options) {
    let flattenedOptions = [{label: "Select All", value: "selectAll",
                            className: "select-all"}];
    for (let datasetOption of all_options) {
        let datasetName = datasetOption.label;
        flattenedOptions.push({ label: datasetName, disabled: true });
        let attributes = datasetOption.options;
        let tmpData = {};

        for (let currAttribute of attributes) {
            let attributeName = currAttribute.label;
            let attributeId = currAttribute.value;
            let sepArr = attributeName.split(STRUCT_SEPARATOR);

            if (sepArr.length === 1) {
                tmpData[attributeName] = { label: attributeName, value: attributeId }
                continue;
            }

            let parent = tmpData;
            let index = 0;
            let lastIdx = sepArr.length;
            let pathArr = [];
            for (let part of sepArr) {
                pathArr.push(part);
                index = index + 1;

                if (index === lastIdx) {
                    // Leaf element. So update with value
                    parent["children"].push({
                        "label": part, "value": attributeId,
                        "pathArr": _.cloneDeep(pathArr)
                    });
                    continue;
                }

                let currentPart = parent[part];
                if (currentPart === undefined) {
                    let newParent = {
                        "label": part, "children": [],
                        "pathArr": _.cloneDeep(pathArr)
                    };
                    parent[part] = newParent
                    if (index > 1) {
                        parent["children"].push(newParent);
                    }
                }

                parent = parent[part];
            }

        }

        flattenedOptions.push(...Object.values(tmpData));
    }

    if (flattenedOptions.length === 1) {
        flattenedOptions = [];
    }

    return flattenedOptions;
}


export function getVersionNameFromData(data) {
    let versionName;
    if (data.version_name !== undefined && data.version_name !== null) {
        versionName = data.version_name;
    } else {
        versionName = "Not Applicable";
    }

    return versionName;
}


/* Monitor Tab New Row Count Feature Check */
export function checkNewRowCnt(){
    return process.env.REACT_APP_NEW_ROW_CNT_FEATURE === "true" ? true : false;
}


export function sortDict(inputDict){
    if(inputDict === undefined || inputDict === null || Object.keys(inputDict).length === 0){
        return inputDict
    }
    let sortedDict = inputDict.sort((a, b) => a.label.localeCompare(b.label));
    return sortedDict
}


export function checkPermissions(tab_name){
    let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
    let permissionsList = [];
    if (permissions !== undefined && permissions !== null) {
        permissionsList = permissions;
    }

    let disableAction = false
    if (tab_name === 'Configure' && permissionsList.includes('ConfigureReadAccess')){
        disableAction = true
    }
    if (tab_name === 'Metrics' && permissionsList.includes('MetricsReadAccess')){
        disableAction = true
    }
    if (tab_name === 'Alerts' && permissionsList.includes('AlertsReadAccess')){
        disableAction = true
    }
    return disableAction;
}
