import React from 'react';

export default class SearchField extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            searchValue: ""
        }
    }


    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidMount() {

    }

    render() {

        return (
            <>
                <input
                    type="search"
                    placeholder="Search..."
                    className="form-control"
                    autoComplete="off"
                    value={this.state.searchValue}
                    onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        this.setState({ searchValue: e.target.value })
                        if(e.target.value.length >= 3 || e.target.value.length === 0/* || this.state.searchValue.length > e.target.value.length**/ ){
                            this.props.searchData(e.target.value)
                        }
                    }}
                />
            </>
        );
    }
}
