import React from 'react';
import Image from '../components/image';
import ChartFilterHeader from './components/chartFilterHeader';
import _ from 'lodash';
// import AdvancedFilterDq from './components/advanced_filter_dq';
import NoErrorComponent from "./components/monitorNoResult";
import {
    getChartType,
    convertDecimalDigits,
    filterBoxPlotData,
    getRowCountContributionFromData, convertDataForListView, getChartFilterData
} from "../utils/common_utils";
import Load from '../components/loadAction';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import moment from "moment";
import {
    NO_ERROR_CHART_COLORS,
    LIGHT_COLOR_MAP,
    SINGLE_ARROW_CHART_TYPE,
    GENERIC_CONSTANTS,
    FEATURE_DRIFT_ALIASES,
    MAX_DATA_POINTS_FOR_LIST_VIEW,
    NON_ERROR_DRIFT_PATTERNS
} from "../utils/constant";
import {getDateObject} from "../charts/browser_utils";
import { normalizeAttributeName, normalizeChildDatasetName } from '../utils/attribute_name_utils';
import {faChartArea} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomTreeFilter from './components/customTreeFilter';
import { getMetricsOption} from "./components/filter_common_utils";
import { getMonitorListViewData} from "../utils/monitorEventHandling";
//import { tree } from 'd3';
// import { addFollowAttributes, addMonitorDQListViewData} from "../redux/actions";
class DqErrorFullScreen extends React.Component {

    constructor(props) {
        super(props);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.renderGroup = this.renderGroup.bind(this);
        this.updateFullScreenData = this.updateFullScreenData.bind(this);
        this.changeGroup  = this.changeGroup.bind(this);
        this.fullscreenapplyDataset  = this.fullscreenapplyDataset.bind(this);
        this.changeShowErrors  = this.changeShowErrors.bind(this);
        this.changePartitions  = this.changePartitions.bind(this);
        this.getMetricsOption = getMetricsOption.bind(this);
        this.fetchDataSource = this.fetchDataSource.bind(this);
        this.loadDataSourceRender = this.loadDataSourceRender.bind(this)
        this.applyFilter = this.applyFilter.bind(this)
        this.getFetchMonitorListViewData = getMonitorListViewData.bind(this)
        this.clearAllFilterData = this.clearAllFilterData.bind(this);
        this.applyDateFilter = this.applyDateFilter.bind(this);

        this.state={
            idValue: this.props.title,
            tempFullScreenData: null,
           // tempFullScreenDataAttribute: null,
            fullScreen: "fade",
            isAdvancedFilterApplied: false,
            startDateInside: null,
            endDateInside: null,
            showFullScreen: false,
            showErrorsOnly: true,
            variant: this.props.variant,
            fullScreenData: null,
            showDatasets: null,
            metrics_data:this.props.metrics_data,
            selectedGroup:this.props.selectedGroup,
            showpartitions:this.props.showpartitions,
            showerrors:true,
            // metrics_data:[],
            intial_datasource_id:"",
            intial_datasource_name:"",
            intial_dataset_Id:[],
            intial_attribute_Id:[],
            initial: {
                datasource: "",
                dataset: "",
                attribute: "",
            },
            showLoader:false,
            applied_dataset:[],
            applied_attribute:[],
            applied_metrics:[],
            applied_start_date:'',
            applied_end_date:'',
            applied_dataset_level:this.props.isDatasetLevel,
            tempFullScreenDataAttribute:Object.values(this.props.fullScreenAttributeData),
            clearAllFilter:false,
            dateDisplay:"",
            intial_datasource:[],
            intial_datset:[],
            intial_attribute:[]
        }
    }

    setFullScreen() {
      document.body.classList.add("overflow-hidden");
      this.setState({showFullScreen : true, fullScreen: "show"});
    }

    renderAttributeCharts(attributeData) {
        attributeData = attributeData === "" ? this.state.tempFullScreenDataAttribute : attributeData;
        let displayNumber = 0
        return attributeData.map((chartData, index) => {
               let chart_data_value = Object.values(chartData)
               if(chart_data_value.length === 0) {
                 return ""
               }
               let chartDataValue = chart_data_value[0]
               let datasetName = chartDataValue.datasetName !== undefined ? chartDataValue.datasetName : this.props.datasetName
               let datasourceName = chartDataValue.datasourceName !== undefined ? chartDataValue.datasourceName : this.props.datasourceName
               let convertedData = convertDataForListView(chartData, datasetName, datasourceName)
               let attributeName = chartDataValue.attribute_name

               displayNumber = displayNumber + 1
                return (
                <div className="qd-chart-group m-0 mb-4">
                    <div className="qd-chart-group-heading">
                         {displayNumber === 1 ?
                            <h4 className="qd-chart-group-title">

                                {"Attribute Level Charts"}
                            </h4>
                            : ""
                        }
                        <h2 className="qd-chart-group-title">
                            <span className="circle-number">
                            {displayNumber}
                        </span>
                            {normalizeAttributeName(attributeName)}
                        </h2>
                        <p className="m-0">
                              {""}
                        </p>

                    </div>
                    <div className="qd-chart-group-body">
                        <div className="row row-sm">
                            { this.renderModalChart("Attribute", convertedData) }
                        </div>
                    </div>
                </div>
            );

        });
    }

    updateFullScreenData(data, attributeData, startDate, endDate, showErrorsOnly,showDatasets){

        this.setState({tempFullScreenData: data,
                        tempFullScreenDataAttribute: attributeData,
                        isAdvancedFilterApplied: !this.state.isAdvancedFilterApplied,
                        startDateInside: startDate,
                        endDateInside: endDate,
                        showErrorsOnly: showErrorsOnly,
                        showDatasets:showDatasets})
    }
    componentDidMount() {
        let selectedDataSet =  {label:this.props.defaultDatasetInside[0].label,value:this.props.defaultDatasetInside[0].value}

        let datsetid = [];
        let attribute = [];
        for(let loop=0;loop<this.props.filterData["parentDatasets"].length;loop++){

            if(this.props.filterData["parentDatasets"][loop].children===undefined){
                if(parseInt(this.props.filterData["parentDatasets"][loop].value) === parseInt(this.props.defaultDatasetInside[0].value)){
                    this.props.filterData["parentDatasets"][loop]["checked"] = true;
                datsetid.push(
                    {label:this.props.filterData["parentDatasets"][loop]["label"],
                    value:this.props.filterData["parentDatasets"][loop]["value"]
                    }
                )
                }else {
                    this.props.filterData["parentDatasets"][loop]["checked"] = "";
                }
            }else {
                if(parseInt(this.props.filterData["parentDatasets"][loop].value) === parseInt(this.props.defaultDatasetInside[0].value)){
                    this.props.filterData["parentDatasets"][loop]["checked"] = true;
                datsetid.push(
                    {label:this.props.filterData["parentDatasets"][loop]["label"],
                    value:this.props.filterData["parentDatasets"][loop]["value"]
                    }
                )
                }else {
                    this.props.filterData["parentDatasets"][loop]["checked"] = "";
                }


                for(let innerloop=0;innerloop<this.props.filterData["parentDatasets"][loop].children.length;innerloop++){
                    if(parseInt(this.props.filterData["parentDatasets"][loop].children[innerloop].value) === parseInt(this.props.defaultDatasetInside[0].value)){
                        this.props.filterData["parentDatasets"][loop].children[innerloop]["checked"] = true;
                    datsetid.push(
                        {label:this.props.filterData["parentDatasets"][loop].children[innerloop]["label"],
                        value:this.props.filterData["parentDatasets"][loop].children[innerloop]["value"]
                        }
                    )
                    }else {
                        this.props.filterData["parentDatasets"][loop].children[innerloop]["checked"] = "";
                    }
                } 
            }
        }
        
        if(this.props.filterData["parentDatasets"] !== undefined && this.props.filterData["parentDatasets"].length > 0 && this.props.defaultAttributeInside === undefined && this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value] !== undefined){
            for(let loop=0;loop<this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value].length;loop++){
                this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["checked"] = true;
                attribute.push(
                    {label:this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["label"],
                    value:this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["value"]
                    }
                )
            
            }
        }

        if(this.props.defaultAttributeInside !== undefined && this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value]){
        for(let loop=0;loop<this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value].length;loop++){
            if(parseInt(this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop].value) === parseInt(this.props.defaultAttributeInside.value)){
                this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["checked"] = true;
                attribute.push(
                    {label:this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["label"],
                    value:this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["value"]
                    })
            }else {
                this.props.filterData["attributesOptions"][this.props.defaultDatasetInside[0].value][loop]["checked"] = "";
            }
        }
    }

    this.setState({
        intial_datasource:'',
        intial_datset:selectedDataSet,
        intial_attribute:this.props.defaultAttributeInside,
        applied_dataset:datsetid,
        applied_attribute:attribute,
        applied_metrics:this.state.metrics_data,
        applied_start_date:this.props.startDate,
        applied_end_date:this.props.endDate,
        intial_datasource_id:[1],
        metrics_data:this.getMetricsOption([selectedDataSet],1,-1)
    });

    }

    renderGroup() {


         if (this.props.fullScreenData === undefined) {
            return '';
        }
        let finalData = [];
        let datasetKeys = []

        let undefinedAppliedCheck =false
        undefinedAppliedCheck = this.state.applied_dataset === undefined || this.state.applied_attribute === undefined || this.state.applied_metrics === undefined
        if(undefinedAppliedCheck === false && this.state.applied_attribute.length === 0 && (this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')) && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action ={"clear_selection"}/>);
        }
        if(this.state.applied_metrics !== undefined && this.state.applied_metrics.length === 0){
            return (<NoErrorComponent action={"clear_metric"}/>);
        }
        if(this.state.applied_dataset.length === 0 || (this.state.applied_dataset.length === 1 && this.state.applied_dataset[0] === '*')){
            return (<NoErrorComponent action={"clear_dataset"}/>);
        }

        if(this.state.tempFullScreenData !== null) {
           finalData = Object.values(this.state.tempFullScreenData)
           datasetKeys = Object.keys(this.state.tempFullScreenData)
        }else {
           finalData = Object.values(this.props.fullScreenData)
        }


        return finalData.map((chartData, index) => {
                let convertedData;
                let attributeData;
                let datasetName = normalizeChildDatasetName(this.props.datasetName);
                let datasourceName = this.props.datasourceName;
                if(Object.values(chartData) === undefined || Object.values(chartData).length === 0){
                    attributeData = [];
                    convertedData = [];
                    return null
                }
                else{
                    datasetName = Object.values(chartData)[0].datasetName !== undefined ? normalizeChildDatasetName(Object.values(chartData)[0].datasetName) : normalizeChildDatasetName(this.props.datasetName)
                    datasourceName = Object.values(chartData)[0].datasourceName !== undefined ? Object.values(chartData)[0].datasourceName : this.props.datasourceName
                    convertedData = convertDataForListView(chartData, datasetName, datasourceName)
                    attributeData = Object.values(this.props.fullScreenAttributeData)
                   if(this.state.tempFullScreenDataAttribute !== null) {
                     let datasetId = datasetKeys[index]
                     attributeData = Object.values(this.state.tempFullScreenDataAttribute)
                     if(attributeData.length > 0) {
                        if(this.state.tempFullScreenDataAttribute[datasetId]!==undefined){
                             attributeData = Object.values(this.state.tempFullScreenDataAttribute[datasetId])
                        }     
                    }
                }
               }


               return (
                <>
                {this.props.lastProfilingTime !== null && this.props.lastProfilingTime !== undefined ? <div className="alert moniker-alert d-inline-block"
                                     style={{minWidth: '700px'}}
                                     role="alert">
                                    <p className="mb-0">
                                        <strong> {`Your data last profiled at ${this.props.lastProfilingTime}`}
                                        </strong>
                                    </p>
                </div>
                :
                ""
                }
                {this.state.applied_dataset_level !== null && this.state.applied_dataset_level === false 
                ?
                    ''
                :
                    <div className="qd-chart-group m-0 mb-4">
                        <div className="qd-chart-group-heading">
                            <h4 className="qd-chart-group-title">
                                <span className="circle-number">
                                {1}
                            </span>
                                {"Dataset Level Charts - "} {datasetName}
                            </h4>
                            <p className="m-0">
                                {""}
                            </p>

                        </div>
                        <div className="qd-chart-group-body">
                            <div className="row row-sm">
                                { this.renderModalChart("Dataset", convertedData) }
                            </div>
                        </div>
                    </div>
                }
                   {this.state.selectedGroup.value === "all" || this.state.selectedGroup.value === 'attributes' ? (
                    attributeData.length > 0 ? (
                        this.renderAttributeCharts(attributeData)
                    ) : (
                        <div className="text-center text-muted py-5"> <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                        <h4>Please select an attribute</h4>
                        </div>
                    )
                ) : (
                    ""
                )}               </>
            );
        });
    }

    renderModalChart(chartLevel, chartDataList) {
        let colorIndex = ["Purple", "Green", "Blue", "Yellow"];
        let chartCount = 0;

        // To append color for attribute level charts
        let noErrorCount = 0;
        let is_same_col = false;
        let j = 1;
        let attributeList = []
        // To append color for dataset level charts
        let noErrorDatasetCount = 0;
        let is_same_data_col = false;
        let k = 1;
        let datasetList = []
        chartDataList = Object.values(chartDataList)
        if (chartDataList) {
            return chartDataList.map((chartData, index) => {
                let dataSetId = chartData["datasetId"]
                // commenting here, because we combinely fetch recency data along with other metrics
                // if (chartData["name"] === "Recency") {
                //    let timelinessData = this.props.timelinessData.DefaultDataSet[0]
                //    if(timelinessData === undefined || timelinessData.data === undefined) {
                //       return "";
                //    }

                //    if(dataSetId === undefined) {
                //      chartData = this.props.filteredTimeliness[0]
                //    } else{
                //      let chartDataFiltered = timelinessData.data.filter(x=>Number(x.data_set_id) === Number(dataSetId))
                //      if(chartDataFiltered.length > 0) {
                //        chartData = chartDataFiltered[0]
                //      }
                //    }
                // }
                if(chartData === undefined) {
                  return ""
                }
                let lastDiv = (chartDataList.length === index + 1 && chartCount === 0)
                chartData["drift"] = convertDecimalDigits(chartData["drift"])
                chartData["dq_chart_points"] = convertDecimalDigits(chartData["dq_chart_points"])
                const attribute_name = chartData.attribute_name;
                if (this.state.showErrorsOnly) {
                    if (chartData["hasDrift"] === false && lastDiv === true) {
                        return (<NoErrorComponent key={chartData["key"]}/>);
                    }
                    if (chartData["hasDrift"] === false) {
                        return "";
                    }
                }
                if ( chartLevel === "Dataset"  ||
                    (attribute_name !== undefined && attribute_name !== null && chartLevel === "Attribute") ) {

                    let id = null;
                    let aggregateOptions = null;

                    id = chartData["data_set_id"];

                    let key = `modal_${chartData.name}_${id}`;
                    let title = this.props.title !== undefined ? this.props.title: '';
                    let compareDatasetOptions = null
                    let compareType =null;
                    let compareAttributeOptions = null;
                    let idValue = ''
                    let showAggregateError = false;
                    if (chartLevel === "Attribute") {
                        key = key + "_" + attribute_name;
                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + attribute_name + "_" + chartData["name"].replace(/ /g,"_");
                        compareType = chartLevel;
                        compareAttributeOptions = _.reject(this.state.compareAttributeOptions, function(d) { return ((d.label === attribute_name && d.data_set_id === chartData.data_set_id)  || d.value.split("$")[1] !== chartData.name ||
                        d.data_set_id !== chartData.data_set_id); });
                    }
                    if (chartLevel === "Dataset") {
                        
                        if(chartData["name"] === "Unique Values"){
                            chartData["name"] = "Unique Rows"
                        }

                        idValue = chartData["ml_model_id"] + "_" + chartData["data_set_id"] + "_" + chartData["name"].replace(/ /g,"_");
                        compareType = chartLevel;
                        compareDatasetOptions = _.reject(this.state.compareDatasetOptions, function(d) { return (d.value === chartData["data_set_id"] || d.type !== chartData.name)  });
                    }

                    title = title.replace(" ", "_");

                    chartCount = chartCount + 1;

                    let chartType = getChartType(chartData, chartCount);
                    const customGridKey = this.props.customKey;
                    if (customGridKey !== undefined) {
                        key = `${key}_${customGridKey}`;
                    }

                    if (chartData["key"] !== undefined) {
                        // Since we are using single complete model data for all Grids
                        // related to model error, we will append the existing chart key with
                        // current Grid's custom key.
                        chartData = _.cloneDeep(chartData);

                        key = `${key}_${chartData["key"]}`;
                        chartData["key"] = key;
                    }

                    const chartPatternType = chartData["type"];
                    if (chartLevel === "Dataset") {
                        if (NON_ERROR_DRIFT_PATTERNS.includes(chartPatternType)) {
                            if (datasetList.length === 0) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                if (chartCount % 2 !== 0) {
                                    is_same_data_col = true;
                                }
                            } else {
                                if (is_same_data_col === true && datasetList.length === 1) {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                    is_same_data_col = false;
                                } else {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[k]];
                                    if (is_same_data_col === false) {
                                        is_same_data_col = true;
                                    } else {
                                        is_same_data_col = false;
                                        k = k + 1;
                                    }
                                    if (k === 4) {
                                        k = 0;
                                    }
                                }
                            }
                            datasetList.push(noErrorDatasetCount);
                            noErrorDatasetCount = noErrorDatasetCount + 1;
                            chartData["errorChart"] = false;
                            if (chartType === "barWithError") {
                                chartType = "barChart";
                            } else {
                                if (chartType === "areaWithError") {
                                    chartType = "areaChart";
                                }
                            }
                        }
                    }
                    if (chartLevel === "Attribute") {
                        if (NON_ERROR_DRIFT_PATTERNS.includes(chartPatternType)) {
                            if (attributeList.length === 0) {
                                chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                if (chartCount % 2 !== 0) {
                                    is_same_col = true;
                                }
                            } else {
                                if (is_same_col === true && attributeList.length === 1) {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[0]];
                                    is_same_col = false;
                                } else {
                                    chartData["color"] = NO_ERROR_CHART_COLORS[colorIndex[j]];
                                    if (is_same_col === false) {
                                        is_same_col = true;
                                    } else {
                                        is_same_col = false;
                                        j = j + 1;
                                    }
                                    if (j === 4) {
                                        j = 0;
                                    }
                                }
                            }
                            attributeList.push(noErrorCount);
                            noErrorCount = noErrorCount + 1;
                            chartData["errorChart"] = false;
                            if (chartType === "barWithError") {
                                chartType = "barChart";
                            } else {
                                if (chartType === "areaWithError") {
                                    chartType = "areaChart";
                                }
                            }
                        }
                    }

                    const rowValue = getRowCountContributionFromData(chartData)
                    if (FEATURE_DRIFT_ALIASES.includes(chartData["name"]) && rowValue !== GENERIC_CONSTANTS.NA) {
                        chartType = SINGLE_ARROW_CHART_TYPE;
                    }
                    if(chartData["dq_chart_points"].length === 0) {
                      return ""
                    }

                    if (chartData["name"] === "Data Drift") {
                        let isBoxplot = chartData["boxplot_data"].filter(x=>x !== "NA")
                        if(isBoxplot.length > 0){
                            chartData["boxPlotData"] = filterBoxPlotData(chartData["boxPlotData"])
                            if (chartData["chartType"] === "boxPlotZoomable") {
                                chartType = "boxPlotZoomable";
                            }
                        }
                    }
                    let filteredData = null;
                    let propsStartDate = this.state.startDateInside === null ? this.props.startDate : this.state.startDateInside
                    let propsEndDate = this.state.endDateInside === null ? this.props.endDate : this.state.endDateInside
                    let timeFilterApplied = true;
                    if (propsStartDate !== null && propsEndDate !== null) {
                        filteredData = getChartFilterData(chartData, propsStartDate,
                            propsEndDate, chartType, false, false, MAX_DATA_POINTS_FOR_LIST_VIEW);
                        let timeVals = null;
                        if (filteredData !== null) {
                            timeVals = filteredData.time;
                        }
                        if (timeVals !== undefined && timeVals !== null && timeVals.length > 0) {
                            const timeValStr = timeVals[0];
                            propsStartDate = moment(getDateObject(timeValStr));
                        }
                    }


                    return (
                        <div key={key} className="col-md-6" id={idValue}>
                            <LazyLoadComponent placeholder={<Load isBootStrapColumn={true}/>}>
                                    <ChartFilterHeader
                                    yValue={chartData["name"]}
                                    showHeatMap={true}
                                    tab="listView"
                                    startDate={propsStartDate}
                                    endDate={propsEndDate}
                                    filteredData={filteredData}
                                    timeFilterApplied={timeFilterApplied}
                                    showSettingsMenu={true}
                                    data={chartData}
                                    scale={chartData["scale"]}
                                    colorCode={chartData["color"]}
                                    lineColorCode={LIGHT_COLOR_MAP[chartData["color"]]}
                                    dataSetId={id}
                                    mlModelId={id}
                                    chartLevel={chartLevel}
                                    showContribution={chartLevel === "Dataset"}
                                    selectedIntegration={this.props.selectedIntegration}
                                    compareAttributeOptions={compareAttributeOptions}
                                    compareDataSrc={compareDatasetOptions}
                                    aggregateOptions={aggregateOptions}
                                    title={title}
                                    compareType={compareType}
                                    chartType={chartType}
                                    variant={this.state.variant + "_" + chartData["name"] + "_" + dataSetId + "_" + chartData["attributeId"]}
                                    chartTimeFilter={this.chartTimeFilter}
                                    dataSetName={chartData["datasetName"]}
                                    attributeName={chartData["attribute_name"]}
                                    hideDistanceFilter={true}
                                    referenceDataSetName={chartData["referenceDataSetName"]}
                                    referenceDataSetLabel={chartData["referenceDataSetLabel"]}
                                    showAggregateError={showAggregateError}
                                />
                            </LazyLoadComponent>
                        </div>

                    );
                }
                return '';
            });
        }
    }

    loadDataSourceRender(selectedDataSource){
    
    }

    loadDataSourceRender1(selectedDataSource){
        
         this.setState({showLoader:true});
        let selectedDataSourceobj = {"label":selectedDataSource.integration_name,"value":selectedDataSource.integration_id}
        this.setState({ showLoader: true,clearAllFilter: false})
        let selectedDataSourceID = selectedDataSource.integration_id;
        let initial = this.state.initial
        initial.datasource = selectedDataSourceID
        //let filteredRows = [];
        /*
        for (let rowData of this.state.currListViewTableData) {
            if (rowData[4].dataSourceId === selectedDataSourceID) {
                filteredRows.push(rowData);
            }
        }
        let datasets = []
        for (const [key, value] of Object.entries(this.props.mappedDatasetInfo["idDatasetDetail"])) {
            if (value["dataSourceId"] === selectedDataSourceID) {
                datasets.push({ "label": normalizeChildDatasetName(value["datasetName"]), "value": key });
            }
        }
        let lastProfilingTimeData = this.props.dataModule.lastProfilingTime[selectedDataSourceID]
        */
        this.setState({
            selectedDatasource: selectedDataSourceobj,
            selectedDataSourceID: selectedDataSource.integration_id,
            selectedDataSourceName:selectedDataSource.integration_name,
          //  lastProfilingTimeData: lastProfilingTimeData,
          //  initial: initial
        });
        this.fetchDataSource(selectedDataSource.integration_id, 'dqp_list_view')
    }

    applyFilter(){

        let datasetLevelData = this.props.allData.dataset_level_data
        // let attributeLevelData = this.props.allData.attribute_level_data
        let datasourceName = this.props.seletedDatasourceName;
        let newData = {}
        let newDataAttribute = {}
        let check = 0;

        // return;
        
        for (let i=0; i<this.props.defaultDatasetInside.length; i++) {
            let datasetId = Number(this.props.defaultDatasetInside[i]["value"])
            let datasetName = this.props.defaultDatasetInside[i]["label"]
            let filteredDataset = datasetLevelData[datasetId]

            check = this.props.filterData.parentDatasets.filter((data)=> 
            parseInt(data.value) === parseInt(datasetId)
            ).length;

            if(filteredDataset !== undefined) {
                 let metricundfisedcheck = false; 
                 for (let i=0; i<this.state.metrics_data.length; i++) {
                     let metricsId = Number(this.state.metrics_data[i]["value"])
                     let filteredMetrics = filteredDataset[metricsId]
                     if(filteredMetrics !== undefined) {
                         metricundfisedcheck = true;
                     }
                 }
                 if(metricundfisedcheck === true && this.state.metrics_data.length > 0 ){
                    if(this.state.showpartitions === false){
                        if(check > 0 ){
                            if(this.state.selectedGroup.value !== "attributes"){
                                newData[datasetId] = {}
                            }
                        }
                    }else {
                        if(this.state.selectedGroup.value !== "attributes"){
                        newData[datasetId] = {}
                        }
                    }
                  
                    for (let i=0; i<this.state.metrics_data.length; i++) {
                     if(this.state.metrics_data[i]["checked"] === true && this.state.metrics_data[i]["value"]!=="*"){
                       let metricsId = Number(this.state.metrics_data[i]["value"])
                       let filteredMetrics = filteredDataset[metricsId]
                       if(filteredMetrics !== undefined) {
                        if(this.state.showpartitions === false){
                        if(check > 0 ){
                            if(newData[datasetId]!==undefined){
                                if(this.state.selectedGroup.value !== "attributes"){
                          newData[datasetId][metricsId] = filteredMetrics
                          newData[datasetId][metricsId]["datasetName"] = datasetName.toString()
                          newData[datasetId][metricsId]["datasourceName"] = datasourceName
                          newData[datasetId][metricsId]["datasetId"] = datasetId
                                }
                            }
                        }
                        }else {
                          if(newData[datasetId]!==undefined){
                            if(this.state.showpartitions === false){
                                if(check > 0 ){
                                    if(this.state.selectedGroup.value !== "attributes"){
                          newData[datasetId][metricsId] = filteredMetrics
                          newData[datasetId][metricsId]["datasetName"] = datasetName.toString()
                          newData[datasetId][metricsId]["datasourceName"] = datasourceName
                          newData[datasetId][metricsId]["datasetId"] = datasetId
                                    }
                                }}else {
                                    if(this.state.selectedGroup.value !== "attributes"){
                                    newData[datasetId][metricsId] = filteredMetrics
                                    newData[datasetId][metricsId]["datasetName"] = datasetName.toString()
                                    newData[datasetId][metricsId]["datasourceName"] = datasourceName
                                    newData[datasetId][metricsId]["datasetId"] = datasetId
                                    }
                                }
                          }
                        }
                          
                       }
                    }
                  }
                 }
                //   let filteredAttributeData = attributeLevelData[datasetId]
                //   let filteredAttribute = {}
                  if(this.state.showpartitions === false){
                    if(check > 0 ){
                      newDataAttribute[datasetId] = {}
                    }
                    }else {
                        newDataAttribute[datasetId] = {}
                    }

                    /*  for(let i=0;i<pm.attribute.length;i++) {
                     let attributeId = Number(pm.attribute[i]["value"])
                                 filteredAttribute = filteredAttributeData[attributeId]
                     if(filteredAttribute !== undefined) {
                        if(this.state.showpartitions === false && this.state.selectedGroup.value !== "datasets"){
                            if(check > 0 ){
                                if(this.state.selectedGroup.value !== "datasets"){
                         newDataAttribute[datasetId][attributeId] = {}
                                }
                            } 
                        }else {

                            if(this.state.selectedGroup.value !== "datasets"){
                                newDataAttribute[datasetId][attributeId] = {}
                            }
                        }          
                         for (let i=0; i<this.state.metrics_data.length; i++) {
                             let metricsId = Number(this.state.metrics_data[i]["value"])
                             if(filteredAttribute !== undefined) {
                                 let filteredMetricsAttributes = filteredAttribute[metricsId]
                                 if(filteredMetricsAttributes !== undefined) {
                                    if(this.state.showpartitions === false){
                                        if(check > 0 ){
                                    if(this.state.selectedGroup.value !== "datasets"){
                                     newDataAttribute[datasetId][attributeId][metricsId] = filteredMetricsAttributes
                                     newDataAttribute[datasetId][attributeId][metricsId]["datasetName"] = datasetName.toString()
                                     newDataAttribute[datasetId][attributeId][metricsId]["datasourceName"] = datasourceName
                                     newDataAttribute[datasetId][attributeId][metricsId]["attributeId"] = attributeId
                                        }
                                    }
                                     }else {
                                         if(this.state.selectedGroup.value !== "datasets"){
                                            newDataAttribute[datasetId][attributeId][metricsId] = filteredMetricsAttributes
                                            newDataAttribute[datasetId][attributeId][metricsId]["datasetName"] = datasetName.toString()
                                            newDataAttribute[datasetId][attributeId][metricsId]["datasourceName"] = datasourceName
                                            newDataAttribute[datasetId][attributeId][metricsId]["attributeId"] = attributeId
                                         }
                                        }       
                                    }
                             }
                         }
                     }
                 } */
            }
       }
       this.updateFullScreenData(newData, newDataAttribute, this.state.startDate, this.state.endDate, this.state.showerrors,this.state.showDatasets);
    }

    fetchDataSource(value = null, tab = null) {
        let teamID = localStorage.getItem('team_id')
        let integrationId = value
        if(this.state.selectedDataSourceID !== Number(integrationId)){
            this.getFetchMonitorListViewData(teamID, this.props.addMonitorDQListViewData, integrationId);
            this.setState({showLoader:true})
        }
    }

    fullscreenapplyDataset(pm){
        this.setState({"applied_dataset":pm.dataset,
        "applied_attribute":pm.attribute,
        "applied_metrics":pm.metrics,
        "applied_start_date":pm.startdate,
        "applied_end_date": pm.enddate
        })
        
            let datasetLevelData = this.props.allData.dataset_level_data
            let attributeLevelData = this.props.allData.attribute_level_data
            let datasourceName = this.props.seletedDatasourceName;
            let newData = {}
            let newDataAttribute = {}
            let check = 0;
           for (let i=0; i<pm.dataset.length; i++) {
                let datasetId = Number(pm.dataset[i]["value"])
                let datasetName = pm.dataset[i]["label"]
                let filteredDataset = datasetLevelData[datasetId]

                check = this.props.filterData.parentDatasets.filter((data)=> 
                parseInt(data.value) === parseInt(datasetId)
                ).length;

                if(filteredDataset !== undefined) {
                     let metricundfisedcheck = false; 
                     for (let i=0; i<this.state.metrics_data.length; i++) {
                         let metricsId = Number(this.state.metrics_data[i]["value"])
                         let filteredMetrics = filteredDataset[metricsId]
                         if(filteredMetrics !== undefined) {
                             metricundfisedcheck = true;
                         }
                     }
                     if(metricundfisedcheck === true && this.state.metrics_data.length > 0 ){
                        if(this.state.showpartitions === false){
                            if(check > 0 ){
                                    newData[datasetId] = {}
                                
                            }
                        }else {
                            newData[datasetId] = {}
                        }
                      
                        for (let i=0; i<this.state.metrics_data.length; i++) {
                         if(this.state.metrics_data[i]["checked"] === true && this.state.metrics_data[i]["value"]!=="*"){
                           let metricsId = Number(this.state.metrics_data[i]["value"])
                           let filteredMetrics = filteredDataset[metricsId]
                           if(filteredMetrics !== undefined) {
                            if(this.state.showpartitions === false){
                            if(check > 0 ){
                                if(newData[datasetId]!==undefined){
                              newData[datasetId][metricsId] = filteredMetrics
                              newData[datasetId][metricsId]["datasetName"] = datasetName.toString()
                              newData[datasetId][metricsId]["datasourceName"] = datasourceName
                              newData[datasetId][metricsId]["datasetId"] = datasetId
                                }
                            }
                            }else {
                              if(newData[datasetId]!==undefined){
                                if(this.state.showpartitions === false){
                                    if(check > 0 ){
                              newData[datasetId][metricsId] = filteredMetrics
                              newData[datasetId][metricsId]["datasetName"] = datasetName.toString()
                              newData[datasetId][metricsId]["datasourceName"] = datasourceName
                              newData[datasetId][metricsId]["datasetId"] = datasetId
                                       
                                    }}else {
                                        newData[datasetId][metricsId] = filteredMetrics
                                        newData[datasetId][metricsId]["datasetName"] = datasetName.toString()
                                        newData[datasetId][metricsId]["datasourceName"] = datasourceName
                                        newData[datasetId][metricsId]["datasetId"] = datasetId
                                     
                                    }
                              }
                            }
                              
                           }
                        }
                      }
                     }
                    //   if(!this.state.showAttributes) {
                    //     continue
                    //   }
                      let filteredAttributeData = attributeLevelData[datasetId]
                      let filteredAttribute = {}
                      if(this.state.showpartitions === false){
                        if(check > 0 ){
                          newDataAttribute[datasetId] = {}
                        }
                        }else {
                            newDataAttribute[datasetId] = {}
                        }



                      for(let i=0;i<pm.attribute.length;i++) {
                         let attributeId = Number(pm.attribute[i]["value"])
                                     filteredAttribute = filteredAttributeData[attributeId]
                         if(filteredAttribute !== undefined) {
                            if(this.state.showpartitions === false && this.state.selectedGroup.value !== "datasets"){
                                if(check > 0 ){
                             newDataAttribute[datasetId][attributeId] = {}
                                   
                                } 
                            }else {

                                    newDataAttribute[datasetId][attributeId] = {}
                               
                            }          
                             for (let i=0; i<this.state.metrics_data.length; i++) {
                                if(this.state.metrics_data[i]["checked"] === true){
                                 let metricsId = Number(this.state.metrics_data[i]["value"])
                                 if(filteredAttribute !== undefined) {
                                     let filteredMetricsAttributes = filteredAttribute[metricsId]
                                     if(filteredMetricsAttributes !== undefined) {
                                        if(this.state.showpartitions === false){
                                            if(check > 0 ){
                                         newDataAttribute[datasetId][attributeId][metricsId] = filteredMetricsAttributes
                                         newDataAttribute[datasetId][attributeId][metricsId]["datasetName"] = datasetName.toString()
                                         newDataAttribute[datasetId][attributeId][metricsId]["datasourceName"] = datasourceName
                                         newDataAttribute[datasetId][attributeId][metricsId]["attributeId"] = attributeId
                                          
                                        }
                                         }else {
                                                newDataAttribute[datasetId][attributeId][metricsId] = filteredMetricsAttributes
                                                newDataAttribute[datasetId][attributeId][metricsId]["datasetName"] = datasetName.toString()
                                                newDataAttribute[datasetId][attributeId][metricsId]["datasourceName"] = datasourceName
                                                newDataAttribute[datasetId][attributeId][metricsId]["attributeId"] = attributeId
                                            
                                            }       
                                        }
                                 }
                                 }
                             }
                         }
                     }
                }
           }
           this.updateFullScreenData(newData, newDataAttribute, pm.startdate, pm.enddate, this.state.showerrors,this.state.showDatasets);
    }

    applyDateFilter(pmStartDate,pmEndDate,pmDateDisplay) {
        this.setState({ applied_start_date:pmStartDate,
            applied_end_date:pmEndDate}, function() {
            let obj = {dataset:this.state.applied_dataset,
                attribute:this.state.applied_attribute,
                applied_metrics:this.state.metrics_data,
                startdate:this.state.applied_start_date,
                enddate:this.state.applied_end_date,
                dateDisplay:pmDateDisplay
                }
                this.fullscreenapplyDataset(obj);
        });
    }

    clearAllFilterData() {
        if(this.props.filterData!==""){
            for (let i = 0; i < this.props.filterData["datasetsOptions"].length; i++) {
                this.props.filterData["datasetsOptions"][i]["checked"]=""
            }
        }
        let metrics_data;
        if(this.state.metrics_data!==""){
            metrics_data = this.state.metrics_data;
            for (let i = 0; i < metrics_data.length; i++) {
                metrics_data[i]["checked"]=""
            }
        }

        this.setState({
            metrics_data:metrics_data,
            clearAllFilter:true,
            applied_dataset:[],
            applied_attribute:[],
            applied_metrics:[],
            })
    }

    changePartitions(){
        if(Object.keys(this.props.monitorDQListViewChild).length === 0){
            this.setState({showLoader:true})
            this.props.changePartitionsParent()
        }else {
            this.setState({ showpartitions:!this.state.showpartitions }, function() {
                let obj = {dataset:this.state.applied_dataset,
                attribute:this.state.applied_attribute,
                applied_metrics:this.state.metrics_data,
                startdate:this.state.applied_start_date,
                enddate:this.state.applied_end_date,
                }
            this.fullscreenapplyDataset(obj);
        });

        }
    }

    changeShowErrors(){
        this.setState({  showerrors: !this.state.showerrors }, function() {
            let obj = {dataset:this.state.applied_dataset,
            attribute:this.state.applied_attribute,
            applied_metrics:this.state.metrics_data,
            startdate:this.state.applied_start_date,
            enddate:this.state.applied_end_date,
            }
            this.fullscreenapplyDataset(obj);
        });
    }
    changeGroup(selectedOption) {
        let dataset_level = this.state.applied_dataset_level;
        if(selectedOption.value==="attributes"){
            dataset_level = false
        }else {
            dataset_level = true
        }
        this.setState({ selectedGroup:selectedOption,applied_dataset_level:dataset_level}, function() {
            let obj = {dataset:this.state.applied_dataset,
                attribute:this.state.applied_attribute,
                applied_metrics:this.state.metrics_data,
                startdate:this.state.applied_start_date,
                enddate:this.state.applied_end_date,
                }
                this.fullscreenapplyDataset(obj);
        });
    }    
    render() {
        let attributeData = Object.values(this.props.fullScreenAttributeData)

        // let selectedMetricsCount = null
        // let selectedMetrics = null
        // if (this.state.metrics_data !== undefined){
        //     selectedMetrics = this.state.metrics_data.filter((data) => data.checked === true)
        //     selectedMetricsCount = selectedMetrics.length
        //     if (this.state.selectedGroup.label === "Attribute Only" && selectedMetrics !== null && selectedMetrics.length !== 0){
        //         let row_count_recency = selectedMetrics.filter((data) => data.label === 'Row Count' || data.label === 'Recency')
        //         selectedMetricsCount = selectedMetricsCount-row_count_recency.length
        //     }
        // }

        return (
                <div className={"qd-fullscreen fade " + this.props.fullScreenClassVal}>
                    <div className="qd-fs_dialog">
                        <div className="qd-fs_content">
                            <div className="qd-fs_header">
                                <div className="header_content">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logo"
                                    />
                                    <h4 className="header_title">
                                        {this.props.title}
                                    </h4>
                                </div>
                                <div className="actions">
                                {/* <AdvancedFilterDq dataSourceOption={this.props.dataSourceOption}
                                               mappedDatasetInfo={this.props.mappedDatasetInfo}
                                               defaultDatasource={this.props.defaultDatasourceInside}
                                               metricsList={this.props.metricsList}
                                               defaultDataset={this.props.defaultDatasetInside}
                                               defaultAttributeInside={this.props.defaultAttributeInside}
                                               updateFullScreenData={this.updateFullScreenData}
                                               allData={this.props.allData}
                                               startDate={this.props.startDate}
                                               endDate={this.props.endDate}
                                               type="listView"
                                               isDatasetLevel={this.props.isDatasetLevel}

                                /> */}
                                <button type="button" onClick={this.props.closeFullScreen} className="close" aria-label="Close">
                                    <span aria-hidden="true"/>
                                    <span className="sr-only">Close</span>
                                </button>
                                </div>
                            </div>
                            <div className="qd-fs_body overflow-auto pt-2">
                                <div className="qd-grid qd-grid-monitor">
                                    <div className="qd-grid-item qd-section" key={this.state.isAdvancedFilterApplied}>
                                {this.state.intial_datasource_id.length > 0 ?
                                    <CustomTreeFilter
                                startDate={this.state.applied_start_date}
                                endDate={this.state.applied_end_date}
                                seletedDatasourceId={this.props.seletedDatasourceId}
                                seletedDatasourceName={this.props.seletedDatasourceName}
                                data={this.props.filterData}
                                clearAllFilter={this.props.clearAllFilter}
                                loadDataSourceRender={this.loadDataSourceRender}
                                applyDataset={this.fullscreenapplyDataset}
                                applyDateFilter={this.applyDateFilter}
                                clearAllFilterData={this.clearAllFilterData}
                                showAttributes={true}
                                showDatasets={true}
                                showMetrics={true}
                                metrics_data={this.state.metrics_data}
                                viewOptions={true}
                                showParitionCheckbox={true}
                                showErrorCheckbox={true}
                                changeGroup={this.changeGroup}
                                selectedGroup={this.state.selectedGroup}
                                fullscreenviewselect={this.props.fullscreenview}
                                changePartitions={this.changePartitions}
                                changeShowErrors={this.changeShowErrors}
                                showpartitions={this.state.showpartitions}
                                showerrors={this.state.showerrors}
                                datasourcechange={false}
                                dateDisplay={this.state.dateDisplay}
                                /> : "" }
                                        {this.state.showLoader === true  ? <Load /> : "" }
                                        {(this.props.isDatasetLevel === true || this.state.tempFullScreenData !== null) && this.state.showLoader===false ? this.renderGroup() : ""}
                                        {(this.state.tempFullScreenData === null && this.props.isDatasetLevel === false) && this.state.showLoader===false ? this.renderAttributeCharts(attributeData) : ""}
                                        {/* {(this.state.tempFullScreenData !== null && this.state.showLoader===false) ?  Object.keys(this.state.tempFullScreenData).length === 0 && this.state.showLoader===false ? 
                                    <>
                                    <div className="text-center text-muted py-5"> <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                                    <h4>No Error found for the selected metric</h4>
                                    {(this.state.applied_attribute.length === 0 || this.state.applied_dataset.length === 0) && <p>please select data</p>}
                                    </div>
                                    </>    
                                     : "" : "" }
                                        {selectedMetricsCount === 0 && (this.state.applied_attribute.length > 0 || this.state.applied_dataset.length > 0)?
                                        <div className="text-center text-muted py-5"> <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                                    <h4>Please select a metric</h4>
                                    </div>
                                    : ""} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
export default DqErrorFullScreen;

