import React from 'react';
import DropdownTreeSelect from "react-dropdown-tree-select";
import $ from 'jquery';
import "./nested-data.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomTreeSelectDataset extends React.Component {

    constructor(props) {
        super(props);
        this.onFocus = this.onFocus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loadFilterDataset = this.loadFilterDataset.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
        this.state = {
            id: this.props.id,
            placeholder: this.props.placeholder,
            selected_id: [],
            selected_id_index: [],
            data: this.props.data,
            cur_data: "",
            renderDataset: true
        }
    }

    loadFilterDataset() {
        let parms
        let filterDatasetId = []
        if (this.props.name === "data_set") {
            let cur_data = this.props.data.filter((data) =>
                data.integration_id === this.props.seletedDatasource || data.integration_id === "-1"
            )

            for (let i = 0; i < cur_data.length; i++) {
                if (cur_data[i].checked === true && cur_data[i].value !== "*" && cur_data[i].checked !== undefined) {
                    filterDatasetId.push(cur_data[i].value)
                }
                if (cur_data[i]["children"] !== undefined) {
                    for (let j = 0; j < cur_data[i]["children"].length; j++) {
                        if (cur_data[i]["children"][j].value !== "*" && cur_data[i]["children"][j].checked === true) {
                            filterDatasetId.push(cur_data[i]["children"][j].value);
                        }
                    }
                }
            }

            parms = {
                filterdDatsource: [this.props.seletedDatasource],
                fiteredDatseset: filterDatasetId,
                fitlerdDate: [this.props.startDate.format("Y/MM/DD"), this.props.endDate.format("Y/MM/DD")],
                fitlerdAttr: []
            }
        } else {
            for (let i = 0; i < this.props.data.length; i++) {
                if (this.props.data[i].checked === true && this.this.props.data[i].value !== "*") {
                    filterDatasetId.push(this.props.data[i].value)
                }
                // for(let j=0;j<this.props.data[i]["children"].length;j++){
                //     if(this.props.data[i]["children"][j].value!=="*"){
                //         filterDatasetId.push(this.props.data[i]["children"][j].value);
                //     }
                // }
            }
            parms = {
                filterdDatsource: [this.props.seletedDatasource],
                fiteredDatseset: filterDatasetId,
                fitlerdDate: [this.props.startDate.format("Y/MM/DD"), this.props.endDate.format("Y/MM/DD")],
                fitlerdAttr: []
            }
        }
        if (filterDatasetId.length > 0) {
            this.props.loadFilterDataset(parms)
        }
    }

    componentDidMount() {
        $('body').click(function () {
            $(".textfield-outlined").removeClass("customselect-active");
        });

        $(document).off('click', '#datasettreeselectapply');
        $(document).on('click', '#datasettreeselectapply', function (e) {
            this.loadFilterDataset();
            $(".textfield-outlined").removeClass("customselect-active");
            document.body.click()
        }.bind(this));

        $(document).off('click', '#datasettreeselectcancel');
        $(document).on('click', '#datasettreeselectcancel', function (e) {
            document.body.click()
            $(".textfield-outlined").removeClass("customselect-active");
            // eslint-disable-next-line
        }.bind(this));
        this.setState({ "cur_data": this.state.data });

        let cur_id = this.state.id;
        $("#" + cur_id + " .search").keyup(function () {
            if ($("span").hasClass("no-matches")) {
                $(".custom-dropdown-action").remove();
                setTimeout(() => {
                    $(".custom-dropdown-action").remove();
                }, 100);
            } else {
                setTimeout(() => {
                    if ($('.custom-dropdown-action').length === 0 && $("span").hasClass("no-matches") === false && this.props.data.length > 1) {
                        $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                        <button type="button" id="datasettreeselectcancel" class="btn btn-outline btn-grey btn-circle">Close</button>
                        <button type="button" id="datasettreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                        // $("#"+cur_id).parent().parent().addClass('customselect-active');
                    }
                }, "100");
            }
        });
    }

    componentDidUpdate() {
        // let cur_id = this.state.id;
        let datalength;
        if(this.props.data!==undefined){
            datalength = this.props.data.length
        }
        setTimeout(() => {
            if ($('.custom-dropdown-action').length === 0 && $("span").hasClass("no-matches") === false && datalength > 1) {
                $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                <button type="button" id="datasettreeselectcancel" class="btn btn-outline btn-grey btn-circle">Close</button>
                <button type="button" id="datasettreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
            }
        }, "100");
    }

    onFocus() {
        // While changing datasource need to render the dropdown with latest data
        if (this.props.name === "data_set") {
            this.setState({ renderDataset: true, type: "data_set" })
        }
        if (this.props.name === "attributes") {
            this.setState({ renderDataset: true, type: "attributes" })
        }
        let cur_id = this.state.id;
        if (this.state.data !== undefined && this.state.data.length > 0) {
            setTimeout(() => {
                if ($('.custom-dropdown-action').length === 0 && $("span").hasClass("no-matches") === false && this.props.data!== undefined && this.props.data.length > 1) {
                    $(".dropdown-content").append(`<div class="custom-dropdown-action text-right p-3 border-top"><div>
                    <button type="button" id="datasettreeselectcancel" class="btn btn-outline btn-grey btn-circle">Close</button>
                    <button type="button" id="datasettreeselectapply" class="btn btn-primary btn-circle show-success-toast">Apply</button></div></div>`);
                    $("#" + cur_id).parent().parent().addClass('customselect-active');
                }
            }, "100");
        }

    }

    onChange = (currentNode, selectedNodes) => {
        if(this.props.data !== undefined && this.props.data.length > 0 && this.props.name==="data_set"){
            let cur_data = this.props.data;
            if (currentNode.value === "*") {
                this.toggleAll(currentNode);
            } else {
            let selected_id  = this.state.selected_id;
            let selected_id_index = this.state.selected_id_index;
            let index = -1;
            if(currentNode.parent_data_set_id===undefined){
                index = cur_data.findIndex(function(item){
                return parseInt(item.value) === parseInt(currentNode["value"]);
                  });
                  if(index > -1){
                    cur_data[index]["checked"] = currentNode["checked"]
                }
                if(currentNode["_children"]!==undefined){
                    for (let i = 0; i < cur_data.length; i++) {
                        if(cur_data[i]["value"]===currentNode.value){
                            for (let j = 0; j < cur_data[i]["children"].length; j++) {
                                // eslint-disable-next-line
                                if(cur_data[i]["children"][j].checked!==undefined){
                                    // eslint-disable-next-line
                                    cur_data[i]["children"][j].checked = cur_data[i]["children"][j].checked;
                                }else {
                                    cur_data[i]["children"][j].checked = false;
                                }
                            }

                        }
                    }
                }

             let obj = {
                "type":this.props.name,
                "id":currentNode["value"],
                "label":currentNode["label"],
                "attribute_ids":currentNode["attribute_ids"]
              }
              if(currentNode["checked"] === true){
                if(currentNode["value"]!==null){
                    selected_id_index.push(currentNode["value"]);
                    selected_id.push({'id':currentNode["value"],"type":this.props.name,'detail':obj});
                }
              }
              else {
                let temp = [];
                for (var i = 0; i < selected_id.length; i++) {
                    if(selected_id[i].id!==currentNode["value"]){
                        temp.push({'id':selected_id[i]["id"],
                        "type":selected_id[i]["type"],
                        'detail':selected_id[i]["detail"]});
                    }
                }
                selected_id = temp;
              }

            }else {
                let childindex = -1;
                index = cur_data.findIndex(function(item){
                    return item.value === currentNode["parent_data_set_id"];
                  });
                if(index > -1){
                  childindex = cur_data[index]["children"].findIndex(function(item){
                    return item.value === currentNode["value"];
                  });
                }
                if(childindex > -1){
                    cur_data[index]['children'][childindex]["checked"] = currentNode["checked"]
                }
                }
        this.props.handleSelectedAttributes(cur_data);
        this.setState({renderDataset:false,selected_id:selected_id})
        }
        }

        else {
            let cur_data = this.props.data;
            if (currentNode.value === "*") {
                this.setState({ renderDataset: false })
                this.toggleAll(currentNode);
            } else {
                // let selected_id  = this.state.selected_id;
                // let selected_id_index = this.state.selected_id_index;
                let index = -1;
                if (currentNode.parent_data_set_id === undefined) {
                    index = cur_data.findIndex(function (item) {
                        return item.value === currentNode["value"];
                    });
                    if (index > -1) {
                        cur_data[index]['checked'] = currentNode["checked"]
                    }
                }

            }

            let expanddata = this.props.data;
            this.setState({ cur_data: expanddata });


        }
    }
    onAction = (node, action) => {


    }

    toggleAll = (node) => {

        if (this.props.data !== undefined && this.props.name === "data_set") {
            let data;
            if (this.props.data !== "") {
                data = this.props.data.filter((data) =>
                    data.integration_id === this.props.seletedDatasource || data.value === "*"
                )
                if (node.is_child_dataset === true) {
                    for (let i = 0; i < data.length; i++) {

                        if (data[i]["value"] === node.parent_data_set_id || data[i]["value"] === "*") {
                            if (data[i]["value"] === "*") {
                                data[i].checked = node.checked;
                            } else {
                                for (let j = 0; j < data[i]["children"].length; j++) {
                                    data[i]["children"][j].checked = node.checked;
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < data.length; i++) {
                        data[i].checked = node.checked;
                        if (data[i]["children"] !== undefined) {
                            for (let j = 0; j < data[i]["children"].length; j++) {
                                data[i]["children"][j].checked = node.checked;
                            }
                        }
                    }
                }
                this.setState({ renderDataset: true, data: data })
            }
            this.props.handleSelectedAttributes(data);
            return data;
        }
    };

    onNodeToggle = currentNode => {
        let index = -1;
        if (this.props.name === "data_set") {
            let cur_data = this.props.data;
            index = cur_data.findIndex(function (item) {
                return item.value === currentNode["value"];
            });
            if (index > -1) {
                cur_data[index]["expanded"] = currentNode["expanded"]
            }
            this.setState({ cur_data: cur_data });
            this.props.handleExpand(cur_data);
        } else {
            this.props.data.find(function (item, i) {
                if (item.value === currentNode["value"]) {
                    index = i;
                    return i;
                }
                return item;
            });
            if (index > -1) {
                this.props.data[index]["expanded"] = currentNode["expanded"]
            }
            let expanddata = this.state.data;
            this.setState({ cur_data: expanddata });
            this.props.handleSelectedAttributes(this.state.cur_data);

        }
    }

    render() {
        let cur_data = [];
        let selectplaceholdercnt = 0;

        if(this.props.name==="data_set") {
            if(this.props.data!=="" && this.props.data !== undefined ){

                cur_data = this.props.data.filter((data)=>
                parseInt(data.integration_id) === parseInt(this.props.seletedDatasource) || data.integration_id === "-1"
                )
                for (let i = 0; i < cur_data.length; i++) {
                    if(cur_data[i].checked === true && cur_data[i].value !=="*" && cur_data[i].checked !== undefined ){
                        //&& String(cur_data[i].value).includes(")") === false
                        selectplaceholdercnt++;
                    }
                    if(cur_data[i]["children"]!==undefined){
                        for (let j = 0; j < cur_data[i]["children"].length; j++) {
                            if(cur_data[i]["children"][j].checked === true && cur_data[i]["children"][j].value !=="*" ){
                                //&& String(cur_data[i].value).includes(")") === false
                                selectplaceholdercnt++;
                            }
                        }
                    }
                }
            }
        }

        // let checkedAll = cur_data.filter((data) =>data.integration_id !== "-1" &&  data.checked !== true ).length;
        let checkedAll=[];
        cur_data.map((data) =>{
                if(data.integration_id !== "-1" && data.checked !== true){
                    checkedAll.push(false)
                }
                if(data.children !== undefined){
                    let childAtt = data.children.filter((childData)=> childData.parent_data_set_id === data.value &&  childData.value !== "*" && (childData.checked === undefined || childData.checked !== true))//.length
                    if(childAtt.length===0){
                        data.children[0].checked = true
                    }
                    else{
                        data.children[0].checked = false
                        checkedAll.push(false)
                    }
                }
                return null
            }
        )

        if (cur_data && cur_data.length > 0) {
            if (checkedAll.includes(false)) {
                cur_data[0].checked = false;
            } else {
                cur_data[0].checked = true;
            }
        }


        return (
            <div className="react-dropdown-tree">
                <div className="react-dropdown-tree-prepend">
                    <FontAwesomeIcon icon={this.props.prependIcon} />
                </div>
                <DropdownTreeSelect
                    id={this.props.id}
                    className="bootstrap-demo"
                    data={cur_data}
                    onChange={this.onChange}
                    onAction={this.onAction}
                    onNodeToggle={this.onNodeToggle}
                    onFocus={this.onFocus}
                    texts={{ placeholder: selectplaceholdercnt === 0 ? this.props.placeholder : selectplaceholdercnt + " selected", noMatches: "No Options" }}
                />
            </div>
        );
    }
}
export default CustomTreeSelectDataset;
