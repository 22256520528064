import React from 'react';
import Image from '../components/image';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt, faSearch, faAsterisk, faClock, faChevronRight} from '@fortawesome/free-solid-svg-icons'; //, faServer, faDatabase, faLayerGroup
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import moment from "moment";
// import ganttChart1 from '../assets/img/gantt-chart1.png';
import { DISPLAY_DATE_FORMAT, PIPELINE_CONNECTOR } from "../utils/constant";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Select,{components} from "react-select";
// import { Gantt} from 'gantt-task-react';
// import gantt_chart from '../assets/img/gantt-chart1.png';
import "gantt-task-react/dist/index.css";
import 'react-rangeslider/lib/index.css';
import PipelineLineage from "./pipelineLineage";
import PipelineLineageErr from "./pipelineLineageErrTroubleShoot";
import { getApi } from "../utils/event_handling";
// import LoadingBar from 'react-top-loading-bar';
import QualdoDataTable from '../components/bootstrapTable';
import RcTooltip from 'rc-tooltip';
// import { timeHours } from 'd3';
import Load from '../components/loadAction';
// import { wordBreak } from 'html2canvas/dist/types/css/property-descriptors/word-break';
import ProgressLoader from '../components/progressLoader';
import GanttChart from './pipelineGanttChart';


/*
let tasks: Task[] = [{
	"start": new Date("02 Aug 2023 02:15:02"),
	"end": new Date("02 Aug 2023 03:15:02"),
	"name": "Some Project",
	"id": "ProjectSample",
	"type": "project",
    "isDisabled": true,
	"hideChildren": false,
    "styles": { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
}, {
	"start": new Date("02 Aug 2023 04:15:02"),
	"end": new Date("02 Aug 2023 05:15:02"),
	"name": "Idea",
    "isDisabled": true,
	"id": "Task 0",
	"type": "task",
	"project": "ProjectSample",
    "styles": { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
}, {
	"start": new Date("02 Aug 2023 04:15:02"),
	"end": new Date("02 Aug 2023 05:15:02"),
	"name": "Research",
	"id": "Task 1",
    "isDisabled": true,
	"type": "task",
	"project": "ProjectSample",
    "styles": { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
}, {
	"start": new Date("02 Aug 2023 04:15:02"),
	"end": new Date("02 Aug 2023 09:15:02"),
	"name": "Discussion with team",
	"id": "Task 2",
	"type": "task",
    "isDisabled": true,
	"project": "ProjectSample",
    "styles": { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
}]
*/
const customSelectDatasource = ({ children, ...props }) => (
    <components.Control {...props} className="has-icon">
        <FontAwesomeIcon
            icon={faAsterisk}
            className="form-control__icon"
        />
        {children}
    </components.Control>

);
export default class PipelinePerformFullScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleTab = this.handleTab.bind(this);
        this.gobackfullscreen = this.gobackfullscreen.bind(this);
        this.changeDataSourceFullScreen = this.changeDataSourceFullScreen.bind(this);
        this.showDetailsPipeline = this.showDetailsPipeline.bind(this);
        this.handleNestedTreeNode = this.handleNestedTreeNode.bind(this);
        this.fullscreenPipelineDateApply = this.fullscreenPipelineDateApply.bind(this);
        this.datewisejobsdata = this.datewisejobsdata.bind(this);
        this.loadRightPanel = this.loadRightPanel.bind(this);
        this.applystagefilter = this.applystagefilter.bind(this);
        this.getApi = getApi.bind(this);
        this.searchJobs = this.searchJobs.bind(this);
        this.pipelineTableComponent = this.pipelineTableComponent.bind(this)
        this.formPipelineRunsTableData=this.formPipelineRunsTableData.bind(this)
        this.pipelinesourceFilterSelection = this.pipelinesourceFilterSelection.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleFilterSelection = this.handleFilterSelection.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.filterStageDetails = this.filterStageDetails.bind(this);
        this.Rightpanelinfo=this.Rightpanelinfo.bind(this)
        this.clearSearch = this.clearSearch.bind(this);
        this.clearTask = this.clearTask.bind(this);
        this.filterStageSearch = this.filterStageSearch.bind(this);


        this.state = {
            current_tab: "ji",
            totaljob: '',
            totalsucceed: '',
            totalfailedjobs: '',
            selectedPipelinesourceFullScreen: null,
            selectedPipelinesourceFullScreenID: null,
            jobname: null,
            runscount: null,
            jobsrunsuccesscnt: null,
            jobsrunfailedcnt: null,
            jobsrun: null,
            jobsrunfailed: null,
            filterData: null,
            troubleshooting: [],
            detailJobList:[],
            rangeValue: 0,
            detailviewid:"",
            profiledatealert: true,
            fullscreenpipelinesource_data:this.props.pipelinesource_data,
            fullscreenendDate:this.props.endDate,
            fullscreenpipelinefilterdata:[],
            fullscreenFilterApplied:false,
            fullscreenselectedPipelineID:this.props.selectedPipelineInfo.pipeline_integration_id,
            fullscreenpipelinesource_filter:[],
            gantt_chart_details:[],
            gantt_chart_grid:[],
            gantt_chart_grid_temp:[],
            gantt_chart_grid_filter:[],
            gantt_chart_job_details:[],
            selectedJobDetails:this.props.selectedJobDetails,
            // selectedJobDetails:null,
            selectedPipelineInfo:this.props.selectedPipelineInfo,
            searchingJobText:'',
            searchingTaskText:'',
            errorMessage:'',
            detailPipelineName:this.props.detailPipelineName,
            detailJobId:this.props.detailJobId,
            progress:0,
            tableData:this.props.tableData,
            selectedJobRunsFullscreen:[],
            fullscreen_success_cnt:0,
            fullscreen_failed_cnt:0,
            fullscreen_total_cnt:0,
            fullscreen_active_cnt:0,
            pipelinesource_filter:[],
            pipeline_lastProfiling_fullscreen:this.props.pipeline_lastProfiling_fullscreen,
            monitor_pipeline_list:this.props.monitor_pipeline_list,
            lineageData:null,
            trigger_first_click:0,
            selectedFilters: [],
            filterCount:[],
            lienage_graph_type:"job-level",
            tagTextCopied: false,
            filterChanged:false,
            successfulRunsCheck: false,
            failedRunsCheck: false,
            stageFailedCheck: false,
            fullscreen_api_call_loader:1,
            failed_task_cnt:0,
            others_task_cnt:0,
            success_task_cnt:0,
            isloader:true
        }
    }

     treeData = [
        {
            label: 'Success',
            value: 'Success',
        },
        {
            label: 'Failed',
            value: 'Failed',
        }
    ]

    
    filterStageSearch(event){
        let searchstage = event.target.value;
        this.filterStageDetails(searchstage)
    }

    filterStageDetails(searchstagevalue){
        let searchstage = String(searchstagevalue).toLowerCase();
        let gantt_chart_grid_temp;
        if(searchstage===""){
            gantt_chart_grid_temp = this.state.gantt_chart_grid
        }else {

            if(searchstage.toLowerCase().includes("success")){
                searchstage = "succeeded"
            }
            if(this.state.gantt_chart_grid_filter.length > 0){
                searchstage +=  this.state.gantt_chart_grid_filter.toString().split(" ")
            }
         gantt_chart_grid_temp = this.state.gantt_chart_grid.filter((data) => {
            return data.stage_name.toLowerCase().includes(searchstage) || (data.stage_type!==null && data.stage_type.toLowerCase().includes(searchstage)) || data.stage_status_text.toLowerCase().includes(searchstage);
        });
        }
        this.setState({
            gantt_chart_grid_temp: gantt_chart_grid_temp,
            searchingTaskText:searchstagevalue
        });

    }
    searchJobs(event){
        this.setState({
            searchingJobText: event.target.value
        });
    }

    clearTask(){
        this.setState({
            searchingTaskText:""
        });
        this.filterStageDetails("")
    }

    loadRightPanel(pmData) {

    // $("#grant_chart_view").addClass("d-none")
    $("#fullscreendatefilter").show()
    $("#remove_chart_view").removeClass("d-none")
    $(".lineage-graph-sidebar").removeClass("show")
    $("#datepicker_details").show()

    let failedcnt=0,sucesscnt=0,activecnt=0,totaljobscnt = 0;
    let arr = this.state.fullscreenpipelinesource_data;
    let id = this.state.fullscreenselectedPipelineID;
    let filterpipeline =  arr.filter(function(item){
        return item.pipeline_integration_id === id;         
    })
    if(filterpipeline.length > 0){
        let pipelinecntlist = filterpipeline[0];
        totaljobscnt = pipelinecntlist['pipelinedata'].length;
        let initalfilterdate = moment(this.state.fullscreenendDate).format('Y-MM-DD');
        for(let loop=0;loop<totaljobscnt;loop++){
            if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate]!==undefined){
            if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate][0]["status"]!==undefined){
                activecnt++;
                if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate][0]["status"]==="Succeeded"){
                    sucesscnt++;
                }
                if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate][0]["status"]!=="Succeeded"){
                    failedcnt++;
                }
            }
        }
        }
    }
    if(filterpipeline.length > 0){


        this.setState({
            progress:30,
            detailJobId: pmData.pipeline_job_id,
            selectedPipelineInfo:pmData,
            fullscreen_success_cnt:sucesscnt,
            fullscreen_failed_cnt:failedcnt,
            fullscreen_total_cnt:totaljobscnt,
            fullscreen_active_cnt:activecnt,
            profiledatealert: true,
            detailPipelineName:filterpipeline[0]["pipelinesourcename"],
            selectedFilters:[],
            filterChanged:false,
            successfulRunsCheck: false,
            failedRunsCheck: false,
            stageFailedCheck: false,
            lienage_graph_type:"job-level",
            gantt_chart_grid_filter:[]
    },() => {
        this.getApi("get_pipeline_run_details");
        this.getApi("get_lineage_data")
    });
    }
    }

    fullscreenPipelineDateApply(event, picker) {
        let endDate = picker.endDate;
        this.setState({
            fullscreenendDate: endDate,
            fullscreenFilterApplied: true,
        });
        setTimeout(
                () => {
        $("#left_job_"+this.state.detailJobId+" div span").click()
          },
        500
        );

      //  $("#lastSechduleDate").html(endDate.format(PIPELINE_DISPLAY_DATE_FORMAT))
    }

    handleNestedTreeNode(pm, id) {
        $('#nested-node_' + pm).toggleClass("has-nested-nodes");
    }

    millisecondToMinutesAndSeconds(millis) {
        millis = parseInt(millis);
        if(isNaN(millis) === true){
          return;
        }
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        if(minutes<1){
            return seconds +"s";
        }else{
            return minutes + "m" + (seconds < 10 ? '' : '') + seconds +"s";
        }
    }
    // GanntTooltip(pmData){
    //     return <><div style={{fontSize:"12px !important",backgroundColor:"#fff",height:"150px",width:"250px",padding:"10px"}}>
    //         <div><b>Name</b>:{pmData.task.name}</div>
    //         <div><b>Start At</b>:{pmData.task.start.toString().replace("GMT+0530 (India Standard Time)","")}</div>
    //         <div><b>End At</b>:{pmData.task.start.toString().replace("GMT+0530 (India Standard Time)","")}</div>
    //         <div><b>Status</b>:{pmData.task.status}</div>
    //         </div></>;
    // }
    GanntJobLevleTooltip(pmData){


        return <>
            <div class="popover pipeline-popover">
                <h3 className="popover-header">{pmData.task.name}</h3>
                <div className="popover-body">
                    <div className="job-details">
                        <p><span>{JSON.stringify(pmData.task.stage_details)}</span></p>
                    </div>
                </div>
            </div></>;

    }

    GanntTooltip(pmData){
        return <>
            <div class="popover pipeline-popover">
                <h3 className="popover-header">{pmData.task.name}</h3>
                <div className="popover-body">
                    <div className="job-details">
                        <p><span><label>Started:</label>{pmData.task.start.toString().replace("GMT+0530 (India Standard Time)","")}</span></p>
                        <p><span><label>End:</label>{pmData.task.start.toString().replace("GMT+0530 (India Standard Time)","")}</span></p>
                        <p>
                            <span class="d-flex align-items-center">
                                <label>Status</label>
                                {
                                    pmData.task.status === 'Failed' ?
                                    <span className="badge badge-light-danger mr-2"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>Failed</span>
                                :<span className="badge badge-light-success mr-2"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>Success</span>
                                }
                            </span>
                        </p>
                    </div>
                </div>
            </div></>;
    }
    formatUTCDate(Your_Date){
        return moment.utc(Your_Date).format("DD MMM HH:mm");
    }
    closeLineageRightPanel(){
        $(".lineage-graph-sidebar").removeClass("show")
    }
    gobackfullscreen() {

        this.setState({
            detailviewid:"",
            lienage_graph_type:"job_level"
        } ,() => {
            // $("#grant_chart_view").addClass("d-none")
            $("#fullscreendatefilter").show()
            $("#remove_chart_view").removeClass("d-none")
            $(".lineage-graph-sidebar").removeClass("show")
            $("#datepicker_details").show()
        });

    }

    showDetailsPipeline(pm, test) {
        this.setState({
        progress:50,
        detailJobId: this.state.detailJobId,
        detailviewid: pm.job_run_id,
        detailviewstatus: pm.run_status_text,
        detailviewtiming: this.formatUTCDate(pm.run_end_time),
        detailviewsuccesscnt: pm.stage_count.stage_success_count,
        detailviewfailedcnt: pm.stage_count.stage_failed_count,
        detailviewjobname: pm.job_name,
        detailviewduration: this.millisecondToMinutesAndSeconds(pm.duration),
        detailviewstages: pm.stages,
        gantt_chart_runid: pm.job_run_id,
        gantt_chart_jobid: this.state.detailJobId,
        lienage_graph_type:"run_level"
        },() => {
            this.getApi("get_pipeline_runs_stage_info");
            this.getApi("get_lineage_data")


        setTimeout(
            () => {
                // $("#grant_chart_view").removeClass("d-none")
                $("#remove_chart_view").addClass("d-none")
                $("#fullscreendatefilter").hide()
                $("#datepicker_details").hide()
            },
            2000
        );



        });
    }

    changeDataSourceFullScreen(selectedPipelineSource) {
        let lastmodifiedrecord;
        if(this.state.monitor_pipeline_list.length > 0){
                lastmodifiedrecord  = this.state.monitor_pipeline_list.filter(function(item){
                return item.pipeline_integration_id  === selectedPipelineSource.value;
            })
        }
        let selectedPipelineID = selectedPipelineSource.value;
        this.setState({
            selectedPipelinesourceFullScreen: selectedPipelineSource,
            selectedDatasource:selectedPipelineSource,
            fullscreenselectedPipelineID: selectedPipelineID,
            fullscreen_api_call_loader:0,
            pipeline_created_time:lastmodifiedrecord[0]["created_time"],
            pipeline_lastProfiling_fullscreen:lastmodifiedrecord[0]["last_profiled_time"],
            detailJobList: [], 
        },
        () => {
            this.getApi("get_pipelineprefetch",{'detailscreen':1});
        });
    }

    datewisejobsdata11(pmData){
         let newarray = [];
         let filterdate = moment(this.state.endDate).format('Y-MM-DD');
         let initalfilterdate = moment(this.state.endDate).format('Y-MM-DD');
         let gant_show_flag,active_runs = 0;
        for(let loop=0;loop<pmData.pipelinedata.length;loop++){
            // This Condition is used on search bar for skipping the jobs for the jobs column in bootstrap table
            if(
                this.state.searchText.length > 0 && 
                pmData.pipelinedata[loop]['job_name']!== undefined && 
                pmData.pipelinedata[loop]['job_name']!== null &&  
                !pmData.pipelinedata[loop]['job_name'].toLowerCase().includes(this.state.searchText.toLowerCase()) &&
                !pmData['pipelinesourcename'].toLowerCase().includes(this.state.searchText.toLowerCase())
            ){
                continue;
             }

            if(pmData.pipelinedata[loop].payload!==undefined){
                if(!pmData.pipelinedata[loop].payload.hasOwnProperty(initalfilterdate) && this.state.activeRunFilter===0){
                    var firstKey =  pmData.pipelinedata[loop].payload.time_stamp[pmData.pipelinedata[loop].payload.time_stamp.length-1]
                    filterdate = firstKey;
                    gant_show_flag = 0
                    if(pmData.pipelinedata[loop].payload[initalfilterdate]!==undefined){
                        active_runs = pmData.pipelinedata[loop].payload[initalfilterdate].length;
                    }
                }else {
                    filterdate = initalfilterdate;
                    gant_show_flag = 1
                    if(pmData.pipelinedata[loop].payload[filterdate]!==undefined){
                        active_runs = pmData.pipelinedata[loop].payload[filterdate].length;
                    }    
                }
                let nextDate = "NA";
                let previousDate = "NA";
                if(pmData.pipelinedata[loop].payload.time_stamp.length > 0) {
                   let previousindex = pmData.pipelinedata[loop].payload.time_stamp.indexOf(filterdate)
                   previousDate = pmData.pipelinedata[loop].payload.time_stamp[previousindex === 0 || previousindex ===-1 ? 0 : previousindex-1];
                   nextDate = pmData.pipelinedata[loop].payload.time_stamp[previousindex===pmData.pipelinedata[loop].payload.time_stamp.length-1 ? "NA" :  previousindex+1];
                   if(nextDate===undefined){
                    nextDate = "NA";
                   }
                }
                if(pmData.pipelinedata[loop].payload[filterdate]!==undefined){
                    if((pmData.pipelinedata[loop].payload[filterdate].length > 0 && this.state.pipelinesource_filter.length === 0) || (pmData.pipelinedata[loop].payload[filterdate].length > 0 && this.state.pipelinesource_filter.length > 0 && this.state.pipelinesource_filter.includes(pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status']))){
                         let obj = {}
                         obj.pipeline_type = pmData.pipeline_type;
                         obj.pipelinesourcename = pmData.pipelinesourcename;
                         obj.tenant_id = pmData.tenant_id;
                         obj.is_parent = pmData.pipelinedata[loop]['is_parent'];
                         obj.job_name = pmData.pipelinedata[loop]['job_name'];
                         let avg = 0  
                         if(pmData.pipelinedata[loop].payload["avg_duration_list"].hasOwnProperty(filterdate)){
                            avg = pmData.pipelinedata[loop].payload["avg_duration_list"][filterdate];
                         } 
                         obj.pipeline_integration_id = pmData.pipelinedata[loop]['pipeline_integration_id'];
                         obj.pipeline_job_id = pmData.pipelinedata[loop]['pipeline_job_id'];
                         obj.avg_duration  = avg;

                         if(pmData.pipelinedata[loop].payload.hasOwnProperty('dependent_job') === true ){
                            obj.dependent_job  = pmData.pipelinedata[loop].payload.dependent_job[filterdate] === undefined ? pmData.pipelinedata[loop].payload.dependent_job[Object.keys(pmData.pipelinedata[loop].payload.dependent_job)[Object.keys(pmData.pipelinedata[loop].payload.dependent_job).length - 1]] : pmData.pipelinedata[loop].payload.dependent_job[filterdate];
                        }
                       
                         obj.status = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status'];
                         obj.total_runs    = pmData.pipelinedata[loop].payload[filterdate].length;
                         obj.success_count = pmData.pipelinedata[loop].payload[filterdate].filter(function(item){
                            return item.status === "Succeeded";         
                        }).length
                         obj.failed_count  = pmData.pipelinedata[loop].payload[filterdate].filter(function(item){
                            return item.status === "Failed";         
                        }).length

                         obj.last_run      = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]["run_end_time"]
                         obj.last_duration = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]["duration"]
                         obj.runsjob     = pmData.pipelinedata[loop].payload[filterdate]
                         obj.staus     = pmData.pipelinedata[loop].payload[filterdate]
                         obj.successjobs = pmData.successjobs;
                         obj.failedjobs = pmData.failedjobs;
                         obj.totaljobs = pmData.totaljobs;
                         obj.latest_job_run_time = pmData.pipelinedata[0]['latest_job_run_time']
                         obj.gant_show_flag  = gant_show_flag;
                         obj.active_runs     = active_runs;
                         obj.previousdate    = previousDate;
                         obj.nextDate        = nextDate;
                         newarray.push(obj)
                    }
                }}}}

    datewisejobsdata(pmData){
         let newarray = [];
         let filterdate = moment(this.state.fullscreenendDate).format('Y-MM-DD');
         let initalfilterdate = moment(this.state.fullscreenendDate).format('Y-MM-DD');

        for(let loop=0;loop<pmData.pipelinedata.length;loop++){
            if(pmData.pipelinedata[loop].payload!==undefined){
                if(!pmData.pipelinedata[loop].payload.hasOwnProperty(initalfilterdate)){
                    var firstKey =  pmData.pipelinedata[loop].payload.time_stamp[pmData.pipelinedata[loop].payload.time_stamp.length-1]
                    filterdate = firstKey;
                }else {
                    filterdate = initalfilterdate;
                }

                if(pmData.pipelinedata[loop].payload[filterdate]!==undefined){
                    if((pmData.pipelinedata[loop].payload[filterdate].length > 0 && this.state.fullscreenpipelinesource_filter.length === 0) || (pmData.pipelinedata[loop].payload[filterdate].length > 0 && this.state.fullscreenpipelinesource_filter.length > 0 && this.state.fullscreenpipelinesource_filter.includes(pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status']))){
                        if(this.state.searchingJobText === "" || (this.state.searchingJobText!==null && this.state.searchingJobText!=="" && String(pmData.pipelinedata[loop]['job_name']).toLowerCase().includes(String(this.state.searchingJobText).toLowerCase()))){
                         let obj = {}
                         obj.pipeline_type = pmData.pipeline_type;
                         obj.pipelinesourcename = pmData.pipelinesourcename;
                         obj.tenant_id = pmData.tenant_id;
                         obj.is_parent = pmData.pipelinedata[loop]['is_parent'];
                         obj.job_name = pmData.pipelinedata[loop]['job_name'];
                         obj.pipeline_integration_id = pmData.pipelinedata[loop]['pipeline_integration_id'];
                         obj.pipeline_job_id = pmData.pipelinedata[loop]['pipeline_job_id'];
                        obj.avg_duration  = pmData.pipelinedata[loop]['avg_duration_list'];
                        if(pmData.pipelinedata[loop].payload.hasOwnProperty('dependent_job') === true ){
                            obj.dependent_job  = pmData.pipelinedata[loop].payload.dependent_job[filterdate] === undefined ? pmData.pipelinedata[loop].payload.dependent_job[Object.keys(pmData.pipelinedata[loop].payload.dependent_job)[Object.keys(pmData.pipelinedata[loop].payload.dependent_job).length - 1]] : pmData.pipelinedata[loop].payload.dependent_job[filterdate];
                        }
                         obj.status = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]['status'];
                         obj.total_runs    = pmData.pipelinedata[loop].payload[filterdate].length;
                        if(pmData.pipelinedata[loop].payload[initalfilterdate]!==undefined){
                         obj.success_count = pmData.pipelinedata[loop].payload[initalfilterdate].filter(function(item){
                            return item.status === "Succeeded";
                        }).length
                         obj.failed_count  = pmData.pipelinedata[loop].payload[initalfilterdate].filter(function(item){
                            return item.status === "Failed";
                        }).length
                        }else {
                            obj.success_count = 0;
                            obj.failed_count = 0;
                        }
                         obj.last_run      = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]["run_end_time"]
                         obj.last_duration = pmData.pipelinedata[loop].payload[filterdate][pmData.pipelinedata[loop].payload[filterdate].length-1]["duration"]
                         obj.runsjob     = pmData.pipelinedata[loop].payload[filterdate]
                         obj.staus     = pmData.pipelinedata[loop].payload[filterdate]
                         obj.time_stamp     = pmData.pipelinedata[loop].payload.time_stamp;
                         //  obj.successjobs = pmData.successjobs;
                        //  obj.failedjobs = pmData.failedjobs;
                        //  obj.totaljobs = pmData.totaljobs;
                         newarray.push(obj)
                        }
                    }
                }
            }
        }
        return newarray;
    }


    pipelineSourceTableData(){

        let input_data = this.state.fullscreenpipelinesource_data;
        let jobs = [];
        for(let i=0; i < input_data.length; i++) {
              if(this.state.fullscreenselectedPipelineID === input_data[i].pipeline_integration_id  ){
                input_data[i].pipelinesourcename = input_data[i].pipeline_integration_name
                input_data[i].page_selected_pipeline = this.state.page_selected_pipeline;
                input_data[i].cur_page_selected_pipeline = this.state.cur_page_selected_pipeline;
                input_data[i].successjobs = input_data[i].success_count
                input_data[i].failedjobs = input_data[i].failed_count
                input_data[i].totaljobs = input_data[i].total_count
                input_data[i].endDate =  this.state.fullscreenendDate;
                input_data[i].timefilter =  this.state.timefilter;
                input_data[i].paginationtrack =  this.state.paginationtrack;
                input_data[i].format_jobs_result =  this.datewisejobsdata(input_data[i]);
                jobs.push(input_data[i]);
              }
        }
        return jobs;
      }

      formPipelineRunsTableData(inputData) {
        let headers=["Run ID","Timing","Job","Status"];
        let final_op=[];
        let failed_run_op=[];
        let filtered_runs=[]
        let { selectedFilters } = this.state;
        // alert(inputData.runs.length)
        for(let i=0; i < inputData.length; i++) {

            let row=[];
            let pipeline = inputData[i];
            
            let reqData = {}
            reqData['value'] = pipeline['run_end_time']
            reqData['duration'] = pipeline['duration']
            row.push({"value": pipeline, "type": "td"});
            row.push({"value": reqData, "type": "pipeline"});
            row.push({"value": pipeline, "type": "pipeline"});
            row.push({"value": pipeline['run_status_text'], "type": "td"});
            if(pipeline['run_status_text'] ==="Failed"){
                failed_run_op.push(row);
            }
            final_op.push(row);

        if (selectedFilters.length === 0 || selectedFilters.includes(pipeline['run_status_text'])||selectedFilters.includes(pipeline['stage_count']['is_stage_failed'])||selectedFilters.includes(pipeline['run_status_text'] === "Failed") ) {
            row.push({ "value": pipeline, "type": "td" });
            row.push({ "value": reqData, "type": "pipeline" });
            row.push({ "value": pipeline, "type": "pipeline" });
            row.push({ "value": pipeline['run_status_text'], "type": "td" });

            if (pipeline['run_status_text'] === "Failed") {
                filtered_runs.push(row);
            }
            if(pipeline['run_status_text'] ==="Succeeded"){
                filtered_runs.push(row)
            }
            if(pipeline['stage_count']['is_stage_failed'] === true){
                filtered_runs.push(row)
            }
        }
    }

    let table_data = { "headers": headers, "data": final_op };

    if (this.state.current_tab === 'fr') {
        table_data = { "headers": headers, "data": failed_run_op };
    } else if (this.state.selectedFilters.length > 0) {
        table_data = { "headers": headers, "data": filtered_runs };
    }
        return table_data;
    }

    handleTab(key) {
        this.setState({ current_tab: key });
    }

    componentDidMount() {
        // let totaljob;
        let totalsucceed;
        let totalfailedjobs;
        let jobname;
        let runscount;
        let troubleshooting = [];
        let jobsrunsuccess = [];
        let jobsrunfailed = [];
        let jobsrun = [];
        let failedcnt=0,sucesscnt=0,activecnt=0,totaljobscnt = 0; //totaljobs=0;
        let arr = this.state.fullscreenpipelinesource_data;
        let id = this.state.fullscreenselectedPipelineID;
        let filterpipeline =  arr.filter(function(item){
            return item.pipeline_integration_id === id;
        })
        if(filterpipeline.length > 0){
            let pipelinecntlist = filterpipeline[0];
            totaljobscnt = pipelinecntlist['pipelinedata'].length;
            let initalfilterdate = moment(this.state.fullscreenendDate).format('Y-MM-DD');
            for(let loop=0;loop<totaljobscnt;loop++){
                if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate]!==undefined){
                if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate][0]["status"]!==undefined){
                    activecnt++;
                    if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate][0]["status"]==="Succeeded"){
                        sucesscnt++;
                    }
                    if(pipelinecntlist['pipelinedata'][loop]['payload'][initalfilterdate][0]["status"]!=="Succeeded"){
                        failedcnt++;
                    }
                }
            }
            }
        }
        this.getApi("get_lineage_data")
    // let arr = this.state.fullscreenpipelinesource_data;
    // let id = this.state.fullscreenselectedPipelineID;
    // let filterpipeline =  arr.filter(function(item){
    //     return item.pipeline_integration_id === id;
    //
    // let totaljobs,failedcnt,sucesscnt = 0;
    // if(filterpipeline.length > 0){
    //     let datewisefilterdata =  this.datewisejobsdata(filterpipeline[0]);
    //     totaljobs = datewisefilterdata.length;
    //     failedcnt = datewisefilterdata.filter(function(item){
    //         return item.status === "Failed";
    //     }).length
    //     sucesscnt = datewisefilterdata.filter(function(item){
    //         return item.status === "Succeeded";
    //     }).length
    // }        this.getApi("get_pipeline_runs_stage_info");
        this.getApi("get_pipeline_run_details");
        this.setState({
            selectedPipelineID: this.props.detailPipelineName,
            selectedPipelinesourceFullScreen: { "label": this.props.detailPipelineName, "value": this.props.detailPipelineName },
            // totaljob: totaljob,
            totalsucceed: totalsucceed,
            totalfailedjobs: totalfailedjobs,
            jobname: jobname,
            runscount: runscount,
            jobsrunsuccesscnt: jobsrunsuccess.length,
            jobsrunfailedcnt: jobsrunfailed.length,
            jobsrun: jobsrun,
            jobsrunfailed: jobsrunfailed,
            troubleshooting: troubleshooting,
            detailJobList:this.props.detailJobList,
            fullscreen_success_cnt:sucesscnt,
            fullscreen_failed_cnt:failedcnt,
            fullscreen_total_cnt:totaljobscnt,
            fullscreen_active_cnt:activecnt,
            pipeline_created_time:this.props.pipeline_created_time,
            pipeline_initialendDate:this.props.pipeline_initialendDate,
            selectedFilters:[],
            filterChanged:false,
            successfulRunsCheck: false,
            failedRunsCheck: false,
            stageFailedCheck: false
        });

            setTimeout(
                () => {
    if(document.getElementById("left_job_"+this.state.detailJobId) !== null){
        document
        .getElementById("left_job_"+this.state.detailJobId)
        .scrollIntoView({ behavior: "smooth" });
    }
    this.getApi("get_pipeline_run_details");
    },
        1000
    );

            if(this.props.more_job_runid!==""){
                setTimeout(
                    () => {
                        this.showDetailsPipeline(this.props.more_job_runid, "dsd")
                        $("#fullscreendatefilter").hide()
                        $("#remove_chart_view").addClass("d-none")
                        $(".lineage-graph-sidebar").addClass("show")
                        $("#datepicker_details").hide()
                        this.props.resetRunView();
                    },
                    1000
                );

            }
    }

    statusIcon(pmStatus) {
        if (pmStatus === "Succeeded") {
            return <RcTooltip
								placement="top"
								overlay={
									<span>
										Success
									</span>}
								arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
								>
                    <div className="d-flex align-items-center text-success">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                <span className="ml-1"></span>
                    </div>
</RcTooltip>

        }
        else if (pmStatus === "Failed") {

            return <RcTooltip
								placement="top"
								overlay={
									<span>
										Failed
									</span>}
								arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
								>
             <div className="d-flex align-items-center text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                <span className="ml-1"></span>
            </div>
            </RcTooltip>
        }
        else if (pmStatus === "Schedules" || pmStatus === "Scheduled") {
            return <div className="d-flex align-items-center text-purple">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                <span className="ml-1"></span>
            </div>
        }
        else if (pmStatus === "In Progre") {
            return <div className="d-flex align-items-center text-info">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                <span className="ml-1"></span>
            </div>
        }
        else if (pmStatus === "Queued") {
            return <div className="d-flex align-items-center text-warning">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                <span className="ml-1"></span>
            </div>
        }
    }


    Taskheader(){
        return "tests";
    }  

    pipelineTableComponent(actionType, headerValue, cell, tableProps, row) {
        if (headerValue === "Run ID") {
            return (
                <a href="#/" className="text-info" onClick={this.showDetailsPipeline.bind(this, cell)}>{cell['job_run_id']}</a>
            )
        }

        if (headerValue === "Timing") {
            return (
                <dl>
                    <dt>
                        {this.formatUTCDate(cell.value)}
                    </dt>
                    <dd className="text-muted"><i><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i> {this.millisecondToMinutesAndSeconds(cell.duration)}</dd>
                </dl>
            )
        }
        if (headerValue === "Job") {
            return (
                <dl>
                    <dt className="mb-2"> {cell.job_name} <a href="#/" className="text-info" ><i className="ml-1"><FontAwesomeIcon icon={faExternalLinkAlt} /></i></a></dt>
                    <dd style={{ Width: "600px", whiteSpace: 'normal' }}>
                        {cell.stage_count.stages_name.map((stages) => {
                            return (<span className={cell.stage_count.stages_status_dict=== undefined ? "badge badge-light mr-1 mb-1" :cell.stage_count.stages_status_dict[stages] ==="Succeeded" ? "badge badge-light-green mr-1 mb-1" : "badge badge-light-red mr-1 mb-1"} className1="badge badge-light mr-1 mb-1">{stages}</span>)/* need to add badge-light-green for success and badge-light-red for failure */
                        })}
                    </dd>
                </dl>
            )
        }
        if (headerValue === "Status") {
            return (
                (
                    cell === "Succeeded" ?
                        <div className="badge badge-light-success bdr-light-success">
                            <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg><span className="ml-1">Success</span>
                        </div>
                        :
                        <div className="badge badge-light-danger bdr-light-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg><span className="ml-1">Failed</span>
                        </div>
                )
            )
        }
        return cell;
    }

    pipelinesourceFilterSelection() {
        let filter = [];
        $('input:checkbox[name=defaulErrorCheckFullscreen]:checked').each(function()
        {
            filter.push($(this).val());
        });
        this.setState({"fullscreenpipelinesource_filter":filter});
    }

    applystagefilter() {
        let filter = [];
        $('input:checkbox[name=stageFilter]:checked').each(function()
        {
            filter.push($(this).val().toLowerCase());
        });
        let gantt_chart_grid_temp  = [];
        if(filter.length === 1 || filter.length ===2 ){
            let searchstage = filter.join(" ").toLowerCase();
            gantt_chart_grid_temp = this.state.gantt_chart_grid.filter((data) => {
                return new RegExp(`\\b(${filter.join('|')})\\b`, 'i').test(data.stage_status_text.toLowerCase()); // Case-insensitive
            });

            if(searchstage.includes("others")){
                let othersinfo = this.state.gantt_chart_grid.filter((data) => {
                    return data.stage_status_text.toLowerCase()!=="succeeded" && data.stage_status_text.toLowerCase()!=="failed";
                })
                if(othersinfo.length > 0){
                    if(gantt_chart_grid_temp.length > 0){
                        gantt_chart_grid_temp = gantt_chart_grid_temp.concat(othersinfo);
                    }
                    else {
                        gantt_chart_grid_temp = othersinfo;
                    }
                }
                else {
                    gantt_chart_grid_temp = othersinfo;
                }
            }
        }else {
            gantt_chart_grid_temp = this.state.gantt_chart_grid;
        }
        this.setState({
            gantt_chart_grid_temp: gantt_chart_grid_temp,
            gantt_chart_grid_filter:filter
        })
    }


    hideAlert() {
        this.setState({
          profiledatealert: '',
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.trigger_first_click === 1){
            setTimeout(
                () => {
            $("#left_job_"+this.state.detailJobId+" div span").click()
            this.setState({trigger_first_click:0})
        },
        100
    );



        }
        if(this.state.isloader===true){
        setTimeout(() => {
            this.setState({ isloader: false });
        }, 1000);}
        // if (this.state.trigger_first_click !== prevState.trigger_first_click) {
        //     $("#left_job_"+this.state.detailJobId).click()
        // }
    }

    handleFilter(event) {
        const isChecked = event.target.checked;
        const value = event.target.value;

        this.setState(prevState => ({
            [value]: isChecked
        }), () => {
            this.handleFilterSelection(value, isChecked);
        });
    }

    handleFilterSelection(value, isChecked) {
        const { selectedFilters } = this.state;
        let updatedFilters;

        if (isChecked) {
            // Add filter if checked and not already present
            if (!selectedFilters.includes(value)) {
                updatedFilters = [...selectedFilters, value];
            } else {
                updatedFilters = selectedFilters;
            }
        } else {
            // Remove filter if unchecked
            updatedFilters = selectedFilters.filter((filter) => filter !== value);
        }

        const filterCount = updatedFilters.length;

        this.setState({
            selectedFilters: updatedFilters,
            filterCount: filterCount,
            filterChanged: true
        });
    }

    handleTagClipboard = (pm,index,text,type) => {
        navigator.clipboard.writeText(index);
        this.setState({tagTextCopied : text+"_"+pm});
        setTimeout(()=>{
            this.setState({tagTextCopied : ""});
        },3000);

    }
    Rightpanelinfo(label, value) {
        if (value !== undefined && value !== "NA" && value !== null) {
            let icon;
            if (label === "Duration") {
                icon = (
                    <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g>
                    <g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg>
                    </i>
                );
            } else if (label === "Job Name") {
                icon = (
                    <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i>
                );
            } else if (label === "Start Time" || label === "End Time"|| label==="Last Updated Time") {
                icon = (
                    <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8" /><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67" /></svg>
                    </i>
                );
            }
            else if (label === "Job Run ID") {
                icon = (
                    <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#86899A"><g><rect fill="none" height="24" width="24"/><path d="M0,0h24v24H0V0" fill="none" /></g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M10.94,18L7.4,14.46l1.41-1.41 l2.12,2.12l4.24-4.24l1.41,1.41L10.94,18z M13,9V3.5L18.5,9H13" /></g></svg></i>
                );
            }else {
                icon = <i><FontAwesomeIcon icon={faAsterisk} /></i>;
            }

            return (
                <p key={label}>
                <label>{label}:</label>
                <span>{icon}{value}</span>
            </p>
            );
        }
        return null;
    }
    clearSearch() {
        this.setState({
            searchingJobText: ''
        });
    }
    render() {
        
        const { selectedJobDetails, detailviewid } = this.state;
        const selectedRun = selectedJobDetails.runs?.find(run => run.job_run_id === detailviewid);
        const Stage = this.state.gantt_chart_grid_temp.find(stage => stage.job_run_id === this.state.detailviewid);
        let show_err_panel = false;
        let joblastprofiledate;
        let JobsInfo = this.pipelineSourceTableData();
        let pipelinejoblist = [];
        if(JobsInfo.length > 0){
            let detailJobId = this.state.detailJobId;
            let jobsfiter =  JobsInfo[0].pipelinedata.filter(function(item){
                return item.pipeline_job_id === detailJobId;
            })

            let jobenddate; // jobstartdate,
            if(jobsfiter[0]['payload']['time_stamp'].length > 0){
                // jobstartdate = jobsfiter[0]['payload']['time_stamp'][0];
                jobenddate = jobsfiter[0]['payload']['time_stamp'][jobsfiter[0]['payload']['time_stamp'].length-1]
               // joblastprofiledate = moment.utc(jobsfiter[0]['payload'][jobenddate][jobsfiter[0]['payload'][jobenddate].length-1]['run_end_time']).format("ddd , DD MMM YYYY hh:mm")+" GMT";
               joblastprofiledate = moment.utc(this.state.selectedPipelineInfo.last_run).format("ddd ,DD MMM YYYY HH:MM")+" GMT";
               if(this.state.fullscreenendDate.format("Y-MM-DD") >=jobenddate){
                    show_err_panel = true;
                }

            }


            if(JobsInfo[0]["format_jobs_result"].length > 0){
                pipelinejoblist = JobsInfo[0]["format_jobs_result"];
            }
        }
        let{filterCount} = this.state;
        let tableData = null
        let headers=["Run ID","Timing","Job","Status"];
        let final_op=[];
        tableData = {"headers": headers, "data": final_op};
        let tableflag = 0;
        if(this.state.selectedJobRunsFullscreen!==undefined){
            if(this.state.selectedJobRunsFullscreen.length > 0){
                tableflag = 1;
                tableData = this.formPipelineRunsTableData(this.state.selectedJobRunsFullscreen)
            }
        }
        return (
            <>


                <div className="qd-fullscreen qd-pipeline-fullscreen show">
                    <div className="qd-fs_dialog">
                        <div className="qd-fs_content bg-white">
                            <div className="qd-fs_header">
                                <div className="header_content">
                                    <Image
                                        className="qd-header__brand-logo-default"
                                        src="logo"
                                    />
                                </div>
                                <div className="actions ">
                                    <div className="actions_left">
                                    <div className="qd-pipeline-details">
                                            <div className="qd-pipeline__name">
                                                <i className="mr-1"><FontAwesomeIcon icon={faAsterisk} /></i>{this.state.detailPipelineName}
                                                <div className="divider"></div>
                                                <span className="connector-img">
                                                    {
                                                    this.state.selectedPipelineInfo["pipeline_type"] === "synapse" ?
                                                                <Image src="connector_logo_azure_synapse" alt="" /> :
                                                                this.state.selectedPipelineInfo["pipeline_type"] === "airflow" ?
                                                            <Image src="connector_logo_airflow" alt="" />
                                                            :
                                                            this.state.selectedPipelineInfo["pipeline_type"] === "adf" ?
                                                            <Image src="connector_logo_adf" alt="" />
                                                            :
                                                            this.state.selectedPipelineInfo["pipeline_type"] === "glue" ?
                                                            <Image src="connector_logo_glue" alt="" />
                                                            :
                                                            this.state.selectedPipelineInfo["pipeline_type"] === "composer" ?
                                                            <Image src="connector_logo_cloud_composer" alt="" /> :""
                                                        }
                                                </span>
                                            </div>
                                            <ul className="qd-pipeline__stats">
                                                    <li>
                                                        <div className="badge bg-white border">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i>
                                                            <span>
                                                                <label className="mr-1">Total Jobs -</label>
                                                                <strong>{
                                                                    this.state.fullscreen_total_cnt
                                                                }</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge bg-light-success text-dark bdr-light-success">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor">
                    <path d="M0 0h24v24H0z" fill="none"/><path d="M17 6H7c-3.31 0-6 2.69-6 6s2.69 6 6 6h10c3.31 0 6-2.69 6-6s-2.69-6-6-6zm0 10H7c-2.21 0-4-1.79-4-4s1.79-4 4-4h10c2.21 0 4 1.79 4 4s-1.79 4-4 4zm0-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                </svg></i>
                                                            <span>
                                                                <label className="mr-1">Active Jobs -</label>
                                                                <strong>{this.state.fullscreen_active_cnt}</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge bg-light-success text-dark bdr-light-success">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg></i>
                                                            <span>
                                                                <label className="mr-1">Successful Jobs -</label>
                                                                <strong>{this.state.fullscreen_success_cnt}</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge bg-light-danger text-dark bdr-light-danger">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg></i>
                                                            <span>
                                                                <label className="mr-1">Failed Jobs -</label>
                                                                <strong>{this.state.fullscreen_failed_cnt}</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge bg-light-orange text-dark bdr-light-orange">
                                                            <i className="mr-1"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.583 6.597h1.064v1.058h-2.72a.607.607 0 0 0-.612.6v1.147H3.282a.611.611 0 0 0-.616.605v2.72c0 .335.277.606.616.606H6.05c.34 0 .616-.272.617-.605v-2.721a.611.611 0 0 0-.616-.605H5.02V8.347h5.961v1.055H9.948a.611.611 0 0 0-.616.605v2.72c0 .335.276.606.616.606h.135a2.67 2.67 0 0 1 3.25-3.25v-.076a.611.611 0 0 0-.616-.605h-1.031V8.256c0-.331-.275-.601-.612-.601H8.352V6.597h1.065A.577.577 0 0 0 10 6.024V3.24a.579.579 0 0 0-.584-.574H6.583a.579.579 0 0 0-.584.574v2.784c0 .316.262.573.584.573zm6.073 6.36-.7.7-.29-.29.7-.7-.7-.7.3-.3.7.7.7-.7.3.3-.7.7.69.69-.3.3-.7-.7zm-1.405 1.124c.39.39.862.585 1.415.585s1.025-.194 1.415-.585c.39-.39.585-.861.585-1.415 0-.553-.195-1.024-.585-1.415a1.928 1.928 0 0 0-1.415-.585c-.553 0-1.025.195-1.415.585-.39.39-.585.862-.585 1.415 0 .554.195 1.025.585 1.415z" fill="currentColor"/></svg></i>
                                                            <span>
                                                                <label className="mr-1">Cancelled Jobs -</label>
                                                                <strong>0</strong>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="badge badge-light-blue text-dark bdr-light-blue">
                                                            <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="14" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg></i>
                                                            <span>
                                                                <label className="mr-1">In Progress -</label>
                                                                <strong>0</strong>
                                                            </span>
                                                        </div>
                                                    </li>

                                                </ul>
                                        </div>
                                        <Dropdown className="qd-env-dropdown qd-env--light  d-none" alignRight>
                                            <Dropdown.Toggle 
                                                variant=""
                                                className="qd-env-dropdown__btn"
                                            >
                                                <span className="env-tag">E</span>
                                                <span className="env-info">
                                                    <label>Pipeline Environment</label>
                                                    <strong className="env-name">Environment</strong>
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="qd-search-bar">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <button id="button-addon4" type="button" className="btn"><FontAwesomeIcon icon={faSearch} /></button>
                                                        </div>
                                                        <input type="search" placeholder="Search..." className="form-control" />
                                                    </div>
                                                </div>
                                                <Dropdown.Header>Pipeline Environment</Dropdown.Header>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#00c7d4'}}>M</span>My Environment</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#ffc700'}}>P</span>Production</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#4d9ff9'}}>E</span>Environment</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#915cff'}}>V</span>Verizon envi</Dropdown.Item>
                                                <Dropdown.Item><span className="env-tag" style={{backgroundColor: '#ff6c7c'}}>D</span>Dev envi</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="actions_right" >
                                    <div id="datepicker_details">
                                    <DateRangePicker id="fullscreendatefilter" containerClass="btn btn-datapicker reportrange mx-2"
                                        singleDatePicker={true}
                                        showDropdowns={true}
                                        minDate={moment(this.state.pipeline_created_time)}
                                        maxDate={moment(this.state.pipeline_initialendDate)}
                                        onApply={this.fullscreenPipelineDateApply}
                                        >
                                        <i>
                                            <FontAwesomeIcon icon={faCalendar} />
                                        </i>
                                        <span
                                            className="ml-2 d-none d-md-inline-block">
                                           {this.state.fullscreenendDate.format(DISPLAY_DATE_FORMAT)}
                                        </span>
                                    </DateRangePicker>
                                    </div>        
                                        <button type="button" onClick={this.props.closeFullScreenMode}
                                        className="close" aria-label="Close">
                                        <span aria-hidden="true" />
                                        <span className="sr-only">Close</span>
                                    </button>
                                    </div>
                                </div>
                                {this.state.fullscreen_api_call_loader === 0 || this.state.progress!==100 ?
                                <ProgressLoader /> : "" }

                                {/* <div>
      <LoadingBar
        color='#007BFF'
        style={{top:"150px"}}
        progress={this.state.progress}
      />
      <br />
    </div> */}
                            </div>

                            <div className="qd-fs_body overflow-hidden p-0">
                                <div className="qd-grid">
                                    <div className="qd-fs_aside">
                                        <div className="tree-wrap">
                                            <div className="tree__header">
                                                <div className="py-2 border-bottom">
                                                    <Select
                                                        name="datasource1"
                                                        filterOption={({ label }, query) => label.includes(query)}
                                                        defaultValue={this.state.selectedPipelinesourceFullScreen}
                                                        id="choosefullscreenpipeline"
                                                        options={this.props.dataSourceOption.length > 0 ? this.props.dataSourceOption : this.props.dataSourceOption }
                                                        onChange={this.changeDataSourceFullScreen}
                                                        value={this.state.selectedPipelinesourceFullScreen}
                                                        classNamePrefix='form-control'
                                                        placeholder="Pipeline Datasource"
                                                        components={{ Control: customSelectDatasource }}
                                                    />

                                                </div>
                                                <div className="tree__title">
                                                    <div className="caption">
                                                        <span className="text-uppercase">Jobs</span>
                                                    </div>
                                                    <div className="action">
                                                        <Dropdown className='d-none'>
                                                            <Dropdown.Toggle variant="light" className="btn-sm mr-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#AAAEB2"><path d="M0 0h24v24H0V0" fill="none" /><path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2" /></svg>
                                                                <span className="sr-only">Sort</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-running" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-running">
                                                                            Name
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-1" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-scheduled" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                            Last Run Time
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-progress" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                            Duration
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-queued" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                            Sort by Failed
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="light" className="btn-sm">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#AAAEB2"><path d="M0 0h24v24H0V0" fill="none" /><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6" /></svg>
                                                                <span className="sr-only">Filter</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            onClick={this.pipelinesourceFilterSelection}
                                                                            value="Running"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-running" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-running">
                                                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_263_199)">
                                                                                    <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76V7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20" fill="currentColor" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_263_199">
                                                                                        <rect width="24" height="24" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                            <span className="ml-1">Running</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-1" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onClick={this.pipelinesourceFilterSelection}
                                                                            value="Scheduled"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-scheduled" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg>
                                                                            <span className="ml-1">Scheduled</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            onClick={this.pipelinesourceFilterSelection}
                                                                            value="In Progress"
                                                                            id="pipeline-progress" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5" /></g></svg>
                                                                            <span className="ml-1">In Progress</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onClick={this.pipelinesourceFilterSelection}
                                                                            value="Queued"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-queued" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2" /></svg>
                                                                            <span className="ml-1">Queued</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            onClick={this.pipelinesourceFilterSelection}
                                                                            value="Succeeded"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-done" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-done">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                            <span className="ml-1">Done</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onClick={this.pipelinesourceFilterSelection}
                                                                            value="Failed"
                                                                            name="defaulErrorCheckFullscreen"
                                                                            id="pipeline-failed" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                            <span className="ml-1">Failed</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="#/action-3" className="py-1">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <Form.Control
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            name="defaulErrorCheck"
                                                                            id="pipeline-cancelled" />
                                                                        <Form.Label className="custom-control-label" for="pipeline-cancelled">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                            <span className="ml-1">Cancelled</span>
                                                                        </Form.Label>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* Start:Search Box */}
                                                <div class="qd-search-bar has-btn-close bordered">
                                                    <div class="input-group">
                                                        <div className="input-group-prepend">
                                                                <button id="button-addon4" type="button" className="btn">
                                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                        <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                                                    </svg>
                                                                </button>
                                                        </div>
                                                        <input type="search" placeholder="search" onChange={this.searchJobs} class="form-control" value={this.state.searchingJobText} />
                                                    </div>
                                                    {this.state.searchingJobText && (<button className="btn btn-clear" title="Clear" onClick={this.clearSearch}><i className="icon-close"></i></button>)}
                                                </div>
                                                {/* End:Search Box */}
                                            </div>
                                            <div className="tree__body">
                                                <div className="tree__group">
                                                    <div className="tree__group-header">
                                                        <h6 className="title">{this.state.selectedPipelineInfo.job_name}</h6>
                                                        <ol className="breadcrumb">
                                                            <li className="breadcrumb-item">
                                                                <i><svg xmlns="http://www.w3.org/2000/svg"  className="d-block" enable-background="new 0 0 24 24" height="12px" viewBox="0 0 24 24" width="12px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i> 
                                                                {this.state.fullscreen_total_cnt}
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div className="tree__group-body">
                                                        <div className="tree">
                                                            <ul className="tree__nodes">
                                                                {
                                                                    this.state.fullscreen_api_call_loader === 1 || this.state.fullscreen_api_call_loader === 0 ?
                                                                    pipelinejoblist.length > 0 ?
                                                                            // eslint-disable-next-line
                                                                            pipelinejoblist.map((pipeline, pipelineindex) => {
                                                                            let newarraydepend = [];
                                                                            let builddependdata = [];
                                                                            // eslint-disable-next-line
                                                                            if(pipeline.is_parent==="true"){
                                                                            if(pipeline.hasOwnProperty('dependent_job') === true ){
                                                                                newarraydepend = pipeline.dependent_job;
                                                                                if(newarraydepend!==undefined){
                                                                                    for(let dependloop=0;dependloop<newarraydepend.length;dependloop++){
                                                                                        for(let dependinnnerloop=0;dependinnnerloop<this.state.detailJobList.length;dependinnnerloop++){
                                                                                            if(newarraydepend[dependloop]===this.state.detailJobList[dependinnnerloop].pipeline_job_id) {
                                                                                                builddependdata.push(this.state.detailJobList[dependinnnerloop])
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            } 

                                                                            let failedcntflag;
                                                                            if(pipeline.hasOwnProperty('dependent_job') === true){
                                                                            failedcntflag  = builddependdata.filter(function(item){
                                                                                return item.status === "Failed";         
                                                                            }).length
                                                                            }

                                                                            return (
                                                                            <li id={"nested-node_" + pipelineindex} className="tree__node" >
                                                                                <div id={"left_job_"+pipeline.pipeline_job_id} className={this.state.detailJobId===pipeline.pipeline_job_id? "tree__node-item --is-selected" :"tree__node-item"}>
                                                                                    <div className="tree__node-label"  >
                                                                                        <div className={failedcntflag > 0 ? "tree__node-toggle has-failed-jobs" :  builddependdata.length > 0 ? "tree__node-toggle" : "tree__node-toggle disabled"} onClick={builddependdata.length > 0 ? this.handleNestedTreeNode.bind(this, pipelineindex) : ""}>
                                                                                            { builddependdata.length > 0 ?
                                                                                            <i className="tree__node-toggle-icon"></i> :
                                                                                            <RcTooltip placement="right"
                                                                                        overlay={
                                                                                        <span>No Dependent Jobs</span>
                                                                                        }
                                                                                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                        >
                                                                                        <i className="tree__node-toggle-icon"></i>
                                                                                        </RcTooltip>}
                                                                                        </div>
                                                                                        <span className="tree__node-text" onClick={this.loadRightPanel.bind(this,pipeline)}>{pipeline.job_name}</span>
                                                                                    </div>
                                                                                    {this.statusIcon(pipeline.status)}
                                                                                </div>
                                                                                <ul className="tree__nodes tree__node--nested">
                                                                                {builddependdata.map((stage, index) => {
                                                                                    if (stage.payload && typeof stage.payload === 'object') {
                                                                                        let dates = Object.keys(stage.payload);
                                                                                        let latestDate = dates.sort((a, b) => new Date(b) - new Date(a))[0];
                                                                                        let latestJobRun = stage.payload[latestDate]?.[0];
                                                                                        let latestStatus = latestJobRun ? latestJobRun.status : '';

                                                                                        return (
                                                                                            <li className="tree__node" onClick={this.loadRightPanel.bind(this, stage)} key={index}>
                                                                                                <div className="tree__node-item">
                                                                                                    <div className="tree__node-label">
                                                                                                        <span className="tree__node-text" onClick={this.loadRightPanel.bind(this, stage)}>{stage.job_name}</span>
                                                                                                    </div>
                                                                                                    {this.statusIcon(latestStatus)}
                                                                                                </div>
                                                                                            </li>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <li className="tree__node" onClick={this.loadRightPanel.bind(this, stage)} key={index}>
                                                                                                <div className="tree__node-item">
                                                                                                    <div className="tree__node-label">
                                                                                                        <span className="tree__node-text" onClick={this.loadRightPanel.bind(this, stage)}>{stage.job_name}</span>
                                                                                                    </div>
                                                                                                    {this.statusIcon(stage.status)} 
                                                                                                </div>
                                                                                            </li>)}
                                                                                })}
                                                                                </ul>
                                                                            </li>)
                                                                            }

                                                                        }) : <div className="">
                                                                        <div className="placeholder-msg">
                                                                            <div className="placeholder-msg__icon">
                                                                                <Image src="icon_placeholder_no_pipeline_data" />
                                                                            </div>
                                                                            <p>No jobs found</p>
                                                                        </div>
                                                                    </div>
                                                                 : <Load></Load>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="qd-fs_main-content">
                                   {this.state.progress === 100 &&


                                   <div className={this.state.lienage_graph_type==="job-level" ? "qd-pipeline-fs_content " : "qd-pipeline-fs_content d-none"}  id="remove_chart_view">
                                        {/* Start: Pipeline Jobs Info */}
                                        <div className="qd-jobs-wrap">
                                            <Tab.Container  defaultActiveKey={this.state.current_tab} onSelect={this.handleTab}>
                                            {
                                             show_err_panel === true && this.state.profiledatealert!=="" ?
                                                <div className="alert alert-sm moniker-alert alert-dismissible has-close">
                                                    <p className="mb-0"><strong>Your data last profiled at {joblastprofiledate}</strong></p>
                                                    <button type="button" className="close close-sm" onClick={() => this.hideAlert()} data-dismiss="alert" aria-label="Close"></button>
                                                </div> : ''
                                            }
                                                <div className="qd-jobs__header">
                                                    <div className="qd-jobs__header-title">
                                                        <h5><i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M15,22h4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-1v-2c0-1.1-0.9-2-2-2h-3V9h1c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2h-4 C8.9,2,8,2.9,8,4v3c0,1.1,0.9,2,2,2h1v2H8c-1.1,0-2,0.9-2,2v2H5c-1.1,0-2,0.9-2,2v3c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2v-3 c0-1.1-0.9-2-2-2H8v-2h8v2h-1c-1.1,0-2,0.9-2,2v3C13,21.1,13.9,22,15,22" /></g></svg></i>{this.state.selectedPipelineInfo.job_name}</h5>
                                                        <div className="divider"></div>
                                                        <div className="qd-jobs__stats">
                                                        {
                                                        this.state.selectedPipelineInfo.status !== "Failed" ?
                                                            <div className="badge badge-light-success bdr-light-success mr-2">
                                                                <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg></i>
                                                            Success
                                                        </div>
                                                            :
                                                            <div className="badge badge-light-danger bdr-light-danger mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg></i>
                                                            Failed
                                                        </div>
                                                    }
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><svg xmlns="http://Fwww.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg></i>
                                                                <span>Latest Run: <strong>{this.formatUTCDate(this.state.selectedPipelineInfo.last_run)}</strong><span className="divider"></span><strong>{this.millisecondToMinutesAndSeconds(this.state.selectedPipelineInfo.last_duration)}</strong></span>
                                                            </div>
                                                            
                                                            <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.986 13.667c-1.443 0-2.701-.475-3.774-1.426-1.073-.95-1.69-2.142-1.853-3.574h1.02a4.56 4.56 0 0 0 1.555 2.863c.868.758 1.886 1.137 3.052 1.137 1.3 0 2.403-.453 3.309-1.358C12.2 10.402 12.653 9.3 12.653 8c0-1.3-.453-2.403-1.358-3.308-.906-.906-2.009-1.359-3.309-1.359-.728 0-1.41.162-2.047.486a5.039 5.039 0 0 0-1.645 1.335h1.744v1H2.653V2.769h1v1.58a5.659 5.659 0 0 1 1.944-1.487 5.579 5.579 0 0 1 2.39-.529c.786 0 1.522.15 2.21.447a5.737 5.737 0 0 1 1.797 1.212c.511.512.915 1.11 1.213 1.798.297.687.446 1.424.446 2.21s-.149 1.523-.446 2.21a5.737 5.737 0 0 1-1.213 1.798 5.737 5.737 0 0 1-1.797 1.213 5.503 5.503 0 0 1-2.21.446zm2.002-2.982L7.506 8.203V4.667h1v3.13l2.184 2.185-.702.703z" fill="#000" fill-opacity=".5"/></svg></i>
                                                                <span>Avg.Duration <strong>{this.millisecondToMinutesAndSeconds(this.state.selectedPipelineInfo.last_duration)}</strong></span>
                                                            </div>

                                                            {/* <div className="badge badge-light mr-2">
                                                                <i className="mr-1"><svg xmlns="http://Fwww.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="#915EF9"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2" /></g></g></g></svg></i>
                                                                <span>At <strong>00:00 GMT,</strong> Daily (@daily)</span>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="tabbable-line">
                                                        <Nav variant="tabs" id="page-nav-tabs">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="ji" >
                                                                    <span className="qd-menu__link-text">Jobs Info</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="jr">
                                                                    <span className="qd-menu__link-text">All Runs</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fr">
                                                                    <span className="qd-menu__link-text">Failed Runs</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                            {PIPELINE_CONNECTOR.includes(this.state.selectedPipelineInfo["pipeline_type"]) &&
                                                                <Nav.Link eventKey="td">
                                                                    <span className="qd-menu__link-text">Lineage</span>
                                                                </Nav.Link> }
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>

                                                </div>
                                                <div className="qd-jobs__content">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="ji" className="p-3">
                                                                <ul className="qd-jobs__status">
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M15 7.5V2H9v5.5l3 3 3-3zM7.5 9H2v6h5.5l3-3-3-3zM9 16.5V22h6v-5.5l-3-3-3 3zM16.5 9l-3 3 3 3H22V9h-5.5" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Total Runs</label>
                                                                                <p className="card-text"><strong>{this.state.selectedJobDetails!=="" &&this.state.selectedJobDetails.pipeline_metadata.run_counts!==null? (this.state.selectedJobDetails.pipeline_metadata.run_counts !== undefined && this.state.selectedJobDetails.pipeline_metadata.run_counts.total_run) : 0}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Success Runs</label>
                                                                                <p className="card-text"><strong>{this.state.selectedJobDetails!=="" &&this.state.selectedJobDetails.pipeline_metadata.run_counts!==null? this.state.selectedJobDetails.pipeline_metadata.run_counts.run_success_count : 0}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Failed Runs</label>
                                                                                <p className="card-text"><strong>{this.state.selectedJobDetails!=="" &&this.state.selectedJobDetails.pipeline_metadata.run_counts!==null? this.state.selectedJobDetails.pipeline_metadata.run_counts.run_failed_count : 0}</strong></p>
                                                                                {/* <p className="card-text"><strong>{this.state.selectedJobDetails.run_counts.run_failed_count}</strong></p> */}
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                    <li>
                                                                        <div className="qd-jobs__status-card">
                                                                            <div className="card-icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.15 7.054V2.55c0-.577-.473-1.05-1.05-1.05H9.9c-.578 0-1.05.472-1.05 1.05v4.505c0 .136.053.272.158.367l2.625 2.625c.21.21.535.21.745 0l2.625-2.625a.507.507 0 0 0 .147-.367zM7.054 8.85H2.55c-.577 0-1.05.473-1.05 1.05v4.2c0 .578.472 1.05 1.05 1.05h4.505a.493.493 0 0 0 .367-.158l2.625-2.625a.52.52 0 0 0 0-.745L7.422 8.997a.507.507 0 0 0-.367-.147zm1.796 8.095v4.505c0 .578.473 1.05 1.05 1.05h4.2c.274 0 .524-.106.711-.28a5.23 5.23 0 0 1-1.311-3.47c0-1.044.305-2.017.83-2.834l-1.963-1.963a.52.52 0 0 0-.745 0l-2.625 2.625a.507.507 0 0 0-.147.367zM22.5 14.11c0 .271-.104.519-.274.706A5.23 5.23 0 0 0 18.75 13.5c-1.05 0-2.027.308-2.847.838l-1.96-1.96a.52.52 0 0 1 0-.745l2.624-2.625a.47.47 0 0 1 .378-.147h4.505c.578 0 1.05.472 1.05 1.05v4.2zm-3.52 5.47-1.4 1.4-.58-.58 1.4-1.4-1.4-1.4.6-.6 1.4 1.4 1.4-1.4.6.6-1.4 1.4 1.38 1.38-.6.6-1.4-1.4zm-2.81 2.25c.78.78 1.723 1.17 2.83 1.17s2.05-.39 2.83-1.17C22.61 21.05 23 20.107 23 19s-.39-2.05-1.17-2.83C21.05 15.39 20.107 15 19 15s-2.05.39-2.83 1.17C15.39 16.95 15 17.893 15 19s.39 2.05 1.17 2.83z" fill="currentColor"/>
                                                                            </svg>
                                                                            </div>
                                                                            <div className="card-content">
                                                                                <label className="card-label">Cancelled Runs</label>
                                                                                <p className="card-text"><strong>{this.state.jobsrunfailedcnt}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                {this.state.selectedJobDetails.job_info !== undefined ?

                                                                <table className="table table-bordered">
                                                                    <tbody>
                                                                        <tr style={{display:this.state.selectedJobDetails.job_info.job_name==="NA" ? "none" :""}}>
                                                                            <td width="250" >Job Name</td>
                                                                            <td >{this.state.selectedJobDetails.job_info.job_name}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.job_info.type==="NA" ? "none" :""}}>
                                                                            <td width="250" >Type</td>
                                                                            <td >{this.state.selectedJobDetails.job_info.type}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.job_info.id==="NA" ? "none" :""}}>
                                                                            <td width="250" >ID</td>
                                                                            <td >{this.state.selectedJobDetails.job_info.id}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.job_info.last_update_time==="NA" ? "none" :""}}>
                                                                            <td width="250" >Last Updated Time</td>
                                                                            <td >{this.state.selectedJobDetails.job_info.last_update_time}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.job_info.stages_count==="NA" ? "none" :""}}>
                                                                            <td width="250" >Task Count</td>
                                                                            <td >{this.state.selectedJobDetails.job_info.stages_count}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.job_info.description==="NA" ? "none" :""}}>
                                                                            <td width="250">Description</td>
                                                                            <td >{this.state.selectedJobDetails.job_info.description===null ? "-" : this.state.selectedJobDetails.job_info.description}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.pipeline_metadata.pipeline_details.project_id==="NA" ? "none" :""}}>
                                                                            <td width="250">Project ID</td>
                                                                            <td >{this.state.selectedJobDetails.pipeline_metadata.pipeline_details.project_id===null ? "-" : this.state.selectedJobDetails.pipeline_metadata.pipeline_details.project_id}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.pipeline_metadata.pipeline_details.job_type==="NA" ? "none" :""}}>
                                                                            <td width="250">Job Type</td>
                                                                            <td >{this.state.selectedJobDetails.pipeline_metadata.pipeline_details.job_type===null ? "-" : this.state.selectedJobDetails.pipeline_metadata.pipeline_details.job_type}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.pipeline_metadata.pipeline_details.cron_humanized==="NA" ? "none" :""}}>
                                                                            <td width="250">Scheduled</td>
                                                                            <td >{this.state.selectedJobDetails.pipeline_metadata.pipeline_details.cron_humanized===null ? "-" : this.state.selectedJobDetails.pipeline_metadata.pipeline_details.cron_humanized}</td>
                                                                        </tr>
                                                                        <tr style={{display:this.state.selectedJobDetails.pipeline_metadata.pipeline_details.execute_steps==="NA" ? "none" :""}}>
                                                                            <td width="250">Execute Steps</td>
                                                                            <td >{this.state.selectedJobDetails.pipeline_metadata.pipeline_details.execute_steps===null ? "-" : this.state.selectedJobDetails.pipeline_metadata.pipeline_details.execute_steps}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            : "" }
                                                                {/* <div className="portlet">
                                                                    <div className="portlet-title">
                                                                        <div className="caption">
                                                                            <span className="font-dark">Tasks</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="portlet-body">

                                                                <div className="col">
                                                                <div style={{"width":"98%"}} id="job_level_gannt_chart">
                                                                {this.state.gantt_chart_job_details.length > 0 ?
                                                                <Gantt tasks={this.state.gantt_chart_job_details}
                                                                        preStepsCount={5}
                                                                        viewMode={"Hour"}
                                                                        TaskListTable={this.Taskheader}
                                                                        TooltipContent={this.GanntJobLevleTooltip}
                                                                        listCellWidth={false}
                                                                /> : ""
                                                               }
                                                                </div>
                                                                </div>

                                                                    </div>
                                                                </div> */}
                                                            </Tab.Pane>
                                                        <Tab.Pane eventKey="jr" className="p-3">
                                                                <div className="portlet">
                                                                    <div className="portlet-title">
                                                                        <div className="caption">
                                                                            <span className="font-dark"><span className="text-muted">Job:</span>{this.state.selectedPipelineInfo.job_name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="portlet-body">
                                                                        <div className="react-bootstrap-table table-responsive">
                                                                                {this.state.isloader ? <Load/> :
                                                                                    tableflag===1 && this.state.current_tab==='jr'?
                                                                                    <QualdoDataTable
                                                                                    key={"pipelineListView"}
                                                                                    paginationSize={5}
                                                                                    component_name="pipelineListRunTable"
                                                                                    data={tableData}
                                                                                    customGetActionComponent={this.pipelineTableComponent}
                                                                                    filterCount={filterCount}
                                                                                    filterChanged={this.state.filterChanged}
                                                                                    successfulRunsCheck={this.state.successfulRunsCheck}
                                                                                    failedRunsCheck={this.state.failedRunsCheck}
                                                                                    stageFailedCheck={this.state.stageFailedCheck}
                                                                                    handleFilter={this.handleFilter}
                                                                                    current_tab={this.state.current_tab}
                                                                                />
                                                                                :  ""
                                                                                }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="fr" className="p-3">
<div className="portlet">
    <div className="portlet-title">
        <div className="caption">
            <span className="font-dark"><span className="text-muted">Job:</span>{this.state.selectedPipelineInfo.job_name}</span>
        </div>
    </div>
    <div className="portlet-body">
        <div className="react-bootstrap-table table-responsive">
               {tableflag===1 ?
                <QualdoDataTable
                        key={"pipelineListView"}
                        paginationSize={5}
                        component_name="pipelineListRunTable"
                        data={tableData}
                        customGetActionComponent={this.pipelineTableComponent}
                /> : "" }
        </div>
    </div>
</div>
</Tab.Pane>
                                                       
                                                        <Tab.Pane eventKey="td">
                                                                <div className="portlet lineage-graph-portlet border-0">
                                                                {this.state.lineageData!==null ?
                                                                         <PipelineLineage
                                                                        height="calc(100vh - 78px)"
                                                                        chart_id="linenage"
                                                                        selectedNode = {this.state.selectedPipelineInfo.job_name}
                                                                        data={this.state.lineageData}
                                                                        /> :""}
                                                                    {this.state.detailviewid === "" ?
                                                                        <div></div>
                                                                        : ""
                                                                                    }
                                                                    {this.state.lienage_graph_type==="job-level" &&
                                                                        (<div className="lineage-graph-sidebar">
                                                                            <div className="lg-sidebar__content">
                                                                                <div className="lg-sidebar__header">
                                                                                    <div className="caption">
                                                                                        <span>Task</span>
                                                                                        <p>{this.state.pipeline_integration_name}</p>
                                                                                    </div>
                                                                                    <div className="action">
                                                                                        <button type="button" onClick={this.closeLineageRightPanel} className="close"><span className="sr-only">Close</span></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-sidebar__body">
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Origin</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <div className="pipeline-details">
                                                                                                <p><label>Pipeline:</label><span><i><FontAwesomeIcon icon={faAsterisk} /></i>{this.state.detailPipelineName}</span></p>
                                                                                                <p><label>Run:</label><span><i><FontAwesomeIcon icon={faClock} /></i><strong>At {this.formatUTCDate(this.state.selectedPipelineInfo.last_run)}</strong></span></p>
                                                                                                {this.state.selectedJobDetails.job_info!==undefined && (
                                                                                                    <>
                                                                                                        {this.Rightpanelinfo("Job Name", this.state.selectedJobDetails.job_info.job_name)}
                                                                                                        {this.Rightpanelinfo("Type", this.state.selectedJobDetails.job_info.type)}
                                                                                                        {this.Rightpanelinfo("ID", this.state.selectedJobDetails.job_info.id)}
                                                                                                        {this.Rightpanelinfo("Stage Count", this.state.selectedJobDetails.job_info.stages_count)}
                                                                                                        {this.Rightpanelinfo("Last Updated Time", this.state.selectedJobDetails.job_info.last_update_time)}
                                                                                                        {this.Rightpanelinfo("Description", this.state.selectedJobDetails.job_info.description || "-")}
                                                                                                    </>
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>)}
                                                                        {/* <div className="pipeline-custom-tree">
                                                                            <ul className="pipeline-ct__nodes">
                                                                                <li className="pipeline-ct__node">
                                                                                    <div className="pipeline-ct__node-item">
                                                                                        {
                                                                                            this.state.troubleshooting.length > 0 ? this.state.troubleshooting[0] : ""
                                                                                        }

                                                                                    </div>
                                                                                    <ul className="pipeline-ct__nodes">
                                                                                        {
                                                                                            this.state.troubleshooting.map((pipelinejob, index) => {
                                                                                                return (
                                                                                                    index > 0 ?
                                                                                                        <li className="pipeline-ct__node">
                                                                                                            <div className="pipeline-ct__node-item">{pipelinejob}</div>
                                                                                                        </li> : ""
                                                                                                )
                                                                                            })}
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}


                                                                        {/* <div className="pipeline-custom-tree">
                                                                                <ul className="pipeline-ct__nodes">
                                                                                    <li className="pipeline-ct__node">
                                                                                        <div className="pipeline-ct__node-item">Data Ingestion Pipeline</div>
                                                                                        <ul className="pipeline-ct__nodes">
                                                                                            <li className="pipeline-ct__node">
                                                                                                <div className="pipeline-ct__node-item">Data Quality Check Pipeline</div>
                                                                                            </li>
                                                                                            <li className="pipeline-ct__node">
                                                                                                <div className="pipeline-ct__node-item">ML Training Pipeline</div>
                                                                                            </li>
                                                                                            <li className="pipeline-ct__node">
                                                                                                <div className="pipeline-ct__node-item">Data Dashboarding Pipeline</div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul> 
                                                                            </div> */}
                                                                    </div>
                                                                {/* </div> */}
                                                            </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                        {/* End: Pipeline Jobs Info */}
                                    </div> }
                                    {this.state.lienage_graph_type === "run_level" ?
                                    <div className="qd-jobs-run-wrapper" id="grant_chart_view">
                                        <div className="qd-jr__header">
                                            <div className="qd-jr__header-left">
                                                <a href="#/"  onClick={this.gobackfullscreen}><i>
                                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#767676"><rect fill="none" height="24" width="24" /><path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19" /></svg>
                                                </i></a>
                                            </div>
                                            <div className="qd-jr__header-right">
                                                <div className="qd-jr__header-title">
                                                    <h4 id="detailsid">{this.state.detailviewid}</h4>
                                                    <div className="divider"></div>
                                                    {
                                                        this.state.detailviewstatus !== "Failed" ?
                                                            <div className="badge badge-light-success bdr-light-success mr-2">
                                                                <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg></i>
                                                            Success
                                                        </div>
                                                            :
                                                            <div className="badge badge-light-danger bdr-light-danger mr-2">
                                                                <i className="mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg></i>
                                                            Failed
                                                        </div>
                                                    }
                                                    <div className="badge bg-white border mr-2">
                                                        <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0V0" fill="none" /><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8" /></svg></i>Run of {this.state.detailviewjobname}
                                                    </div>
                                                    <div className="badge bg-white border mr-2">
                                                        <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8" /><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67" /></svg></i>{this.state.detailviewtiming}
                                                    </div>
                                                    <div className="badge bg-white border mr-2">
                                                        <i className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M15,1H9v2h6V1z M11,14h2V8h-2V14z M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42 C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9s4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7 s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20" /></g></g></g></svg></i>{this.state.detailviewduration}
                                                    </div>
                                                </div>
                                                <ul className="qd-jobs__status">
                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white border">
                                                            <div className="card-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#86899A"><g><path d="M0,0h24v24H0V0" fill="none" /></g><g><path d="M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M10.94,18L7.4,14.46l1.41-1.41 l2.12,2.12l4.24-4.24l1.41,1.41L10.94,18z M13,9V3.5L18.5,9H13" /></g></svg>                                                                </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Total Tasks</label>
                                                                <p className="card-text"><strong>{parseInt(this.state.failed_task_cnt)+parseInt(this.state.others_task_cnt)+parseInt(this.state.success_task_cnt)}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white border">
                                                            <div className="card-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#86899A"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9" /></svg>
                                                            </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Success Tasks</label>
                                                                <p className="card-text"><strong>{this.state.success_task_cnt}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white border">
                                                            <div className="card-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#86899A"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                            </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Failed Tasks</label>
                                                                <p className="card-text"><strong>{this.state.failed_task_cnt}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="qd-jobs__status-card bg-white border">
                                                            <div className="card-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#86899A"><path d="M320-280q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm160 320h160q17 0 28.5-11.5T680-320q0-17-11.5-28.5T640-360H480q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160h160q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H480q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160h160q17 0 28.5-11.5T680-640q0-17-11.5-28.5T640-680H480q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"/></svg>
                                                            </div>
                                                            <div className="card-content">
                                                                <label className="card-label">Other Tasks</label>
                                                                <p className="card-text"><strong>{this.state.others_task_cnt}</strong></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="qd-jr__body">
                                            <div className="row no-gutters">
                                                <div className="col">
                                                <Tab.Container defaultActiveKey="pp_timedView">
                                                        <div className="tabbable-line">
                                                            <Nav variant="tabs" id="page-nav-tabs" className="pl-3">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="pp_timedView">
                                                                        <span className="qd-menu__link-text">Timed View</span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                               <Nav.Item>
                                                                    <Nav.Link eventKey="pp_attribute">
                                                                        <span className="qd-menu__link-text">
                                                                        { this.state.detailviewstatus === "Failed" ? 
                                                                            "Troubleshooting & Diagnostics" :  (PIPELINE_CONNECTOR.includes(this.state.selectedPipelineInfo["pipeline_type"]) ? "Lineage":"") }
                                                                            </span>
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="pp_timedView">
                                                            <div className="row no-gutters">
                                                                <div className="col">
                                                                {/* <img src={gantt_chart} alt="" />  */}
                                                                <div style={{"width":"98%"}}>
                                                                {this.state.gantt_chart_details.length > 0 ? 
                                                                  <GanttChart data={this.state.gantt_chart_details} />
                                                                // <Gantt tasks={this.state.gantt_chart_details}
                                                                //         preStepsCount={5} 
                                                                //         viewMode={"Hour"}
                                                                //         TaskListTable={this.Taskheader}
                                                                //         TooltipContent={this.GanntTooltip}
                                                                //         listCellWidth={false}
                                                                // />
                                                                 : ""
                                                               }
                                                                      

                                                                </div>
                                                                    {/* <img src={ganttChart1} alt="" width="100%" /> */}
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters">
                                                                <div className="col">
                                                                    <div className="pipeline-stage-wrap">
                                                                        <div className="pipeline-stage__head">
                                                                            <h5 className="title">
                                                                                <i className="mr-1">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="currentColor">
                                                                                        <path d="M632.31-140q-24.54 0-42.27-17.73-17.73-17.73-17.73-42.27v-167.69q0-24.54 17.73-42.27 17.73-17.73 42.27-17.73H800q24.54 0 42.27 17.73Q860-392.23 860-367.69V-200q0 24.54-17.73 42.27Q824.54-140 800-140H632.31ZM100-253.85v-60h344.62v60H100Zm532.31-278.46q-24.54 0-42.27-17.73-17.73-17.73-17.73-42.27V-760q0-24.54 17.73-42.27Q607.77-820 632.31-820H800q24.54 0 42.27 17.73Q860-784.54 860-760v167.69q0 24.54-17.73 42.27-17.73 17.73-42.27 17.73H632.31ZM100-646.15v-60h344.62v60H100Z"/>
                                                                                    </svg>
                                                                                </i> Tasks
                                                                            </h5>
                                                                            <div class="qd-search-bar has-btn-close bordered mr-2">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <button id="button-addon4" type="button" class="btn">
                                                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                                                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                    <input type="search" placeholder="Search..." onChange={this.filterStageSearch} class="form-control" value={this.state.searchingTaskText}  />
                                                                                </div>
                                                                                <button class="btn btn-clear" title="clear" onClick={this.clearTask}><i class="icon-close"></i></button>
                                                                            </div>
                                                                            <div className="btn-toolbar">
                                                                                <div className="btn-toolbar__wrap">
                                                                                    <div className="btn-toolbar__item">
                                                                                        <Dropdown>
                                                                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                                {this.state.gantt_chart_grid_filter.length === 0 ?
                                                                                                <svg className="" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="18"
                                                                                                    viewBox="0 0 24 24" width="18">
                                                                                                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                                                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                                                                </svg> : ""
                                                                                                }
                                                                                                {/* <span className={filterCount > 0 ? "filter-count d-inline-flex" : "filter-count d-none"}>{filterCount}</span> */}
                                                                                                <span className={this.state.gantt_chart_grid_filter.length > 0 ? "filter-count d-inline-flex" : "filter-count d-none"}>{this.state.gantt_chart_grid_filter.length}</span>
                                                                                                <span className="ml-1">Filter</span>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item href="#/action-1" className="py-1">
                                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                                        <Form.Control
                                                                                                            type="checkbox"
                                                                                                            value="Succeeded"
                                                                                                            className="custom-control-input"
                                                                                                            name="stageFilter"
                                                                                                            onClick={this.applystagefilter}
                                                                                                           id="pipeline-done"
                                                                                                        />
                                                                                                        <Form.Label className="custom-control-label" for="pipeline-done">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                                            <span className="ml-1">Success</span>
                                                                                                        </Form.Label>
                                                                                                    </div>
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                                        <Form.Control
                                                                                                            type="checkbox"
                                                                                                            className="custom-control-input"
                                                                                                            value="Failed"
                                                                                                            onClick={this.applystagefilter}
                                                                                                            name="stageFilter"
                                                                                                            id="pipeline-failed"
                                                                                                        />
                                                                                                        <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                                                            <span className="ml-1">Failed</span>
                                                                                                        </Form.Label>
                                                                                                    </div>
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item href="#/action-2" className="py-1">
                                                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                                                        <Form.Control
                                                                                                            type="checkbox"
                                                                                                            className="custom-control-input"
                                                                                                            value="Others"
                                                                                                            onClick={this.applystagefilter}
                                                                                                            name="stageFilter"
                                                                                                            id="pipeline-others"
                                                                                                        />
                                                                                                        <Form.Label className="custom-control-label" for="pipeline-others">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                                                            <span className="ml-1">Others</span>
                                                                                                        </Form.Label>
                                                                                                    </div>
                                                                                                </Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pipeline-stage__content">
                                                                            <div className="table-responsive custom-tbl">
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>S.No</th>
                                                                                            <th>Timestamp</th>
                                                                                            <th>Task Name</th>
                                                                                            <th>Task Type</th>
                                                                                            <th>Status</th>
                                                                                            <th>Additional Info</th>
                                                                                            <th>Error</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {
                                                                                    this.state.gantt_chart_grid_temp.length > 0 ?
                                                                                    this.state.gantt_chart_grid_temp.map((data, key) => {
                                                                                                  return (<tr>
                                                                                            <td>{key+1}</td>
                                                                                            <td>{
                                                                                            moment(data.stage_end_time.replace("GMT","")).format('hh:mm:ss')+" GMT"
                                                                                            }</td>
                                                                                            <td>{data.stage_name}</td>
                                                                                            <td>{data.stage_type}</td>
                                                                                            <td>
                                                                                                {
                                                                                                data.stage_status_text === "Succeeded" ?
                                                                                                <div class="badge badge-light-success bdr-light-success">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor">
                                                                                                        <path d="M0 0h24v24H0" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9"></path>
                                                                                                    </svg>
                                                                                                    <span class="ml-1">Success</span>
                                                                                                </div> : data.stage_status_text === "Failed" ?  <div class="badge badge-light-danger bdr-light-danger">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="currentColor">
                                                                                                    <path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" />
                                                                                                </svg>
                                                                                                    <span class="ml-1">Failed</span>
                                                                                                </div> : <div class="badge badge-light-brown bdr-light-brown">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="currentColor"><path d="M320-280q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm160 320h160q17 0 28.5-11.5T680-320q0-17-11.5-28.5T640-360H480q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160h160q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H480q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160h160q17 0 28.5-11.5T680-640q0-17-11.5-28.5T640-680H480q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"/></svg>
                                                                                                    <span class="ml-1">Others</span>
                                                                                                    <RcTooltip 
                                                                                                        placement="top"
                                                                                                        overlay={
                                                                                                            <span>{data.stage_status_text}</span>
                                                                                                        }
                                                                                                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                    >
                                                                                                        <i className="icon-info-circle icon-info-circle-sm ml-1"></i>
                                                                                                    </RcTooltip>
                                                                                                </div>
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className="tbl-tag has-copy-icon">
                                                                                                    <RcTooltip placement="top"
                                                                                                        overlay={
                                                                                                            <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(data.activity_output)}</span>
                                                                                                        }
                                                                                                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                        overlayClassName="w-25"
                                                                                                    >
                                                                                                        <span className="tbl-tag__text">{JSON.stringify(data.activity_output)}</span>
                                                                                                    </RcTooltip>
                                                                                                    <span className="tbl-tag__action">
                                                                                                        {this.state.tagTextCopied === "output_"+key ?
                                                                                                            <RcTooltip placement="top"
                                                                                                                overlay={
                                                                                                                    <span>Copied!</span>
                                                                                                                }
                                                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                            >
                                                                                                                <i className="icon-done"></i>
                                                                                                            </RcTooltip>
                                                                                                        :
                                                                                                            <RcTooltip placement="top"
                                                                                                                overlay={
                                                                                                                    <span>Copy to clipboard</span>
                                                                                                                }
                                                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                            >
                                                                                                                <i className="icon-clipboard" id={"output_"+key} onClick={this.handleTagClipboard.bind(this,key,JSON.stringify(data.activity_output),'output')}></i>
                                                                                                            </RcTooltip>
                                                                                                        }
                                                                                                    </span>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className="tbl-tag has-copy-icon">
                                                                                                    <RcTooltip placement="top"
                                                                                                        overlay={
                                                                                                            <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(data.error_message)}</span>
                                                                                                        }
                                                                                                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                        overlayClassName="w-25"
                                                                                                    >
                                                                                                        <span className="tbl-tag__text">{JSON.stringify(data.error_message)}</span>
                                                                                                    </RcTooltip>
                                                                                                    <span className="tbl-tag__action">
                                                                                                        {this.state.tagTextCopied === "err_"+key ?
                                                                                                            <RcTooltip placement="top"
                                                                                                                overlay={
                                                                                                                    <span>Copied!</span>
                                                                                                                }
                                                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                            >
                                                                                                                <i className="icon-done"></i>
                                                                                                            </RcTooltip>
                                                                                                        :
                                                                                                            <RcTooltip placement="topRight"
                                                                                                                overlay={
                                                                                                                    <span>Copy to clipboard</span>
                                                                                                                }
                                                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                                            >
                                                                                                            <i className="icon-clipboard" id={"err_"+key} onClick={this.handleTagClipboard.bind(this,key,JSON.stringify(data.error_message),'err')}></i>
                                                                                                            </RcTooltip>
                                                                                                        }
                                                                                                    </span>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr> ) }) : <tr><td colspan="7"><div className="row text-center">
                                                                                                            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                                                                                                <div className="placeholder-msg">
                                                                                                                    <div className="placeholder-msg__icon">
                                                                                                                        <Image src="icon_placeholder_no_pipeline_data" />
                                                                                                                    </div>
                                                                                                                    <p>No Tasks Found</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div></td></tr> }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.detailviewstatus === "Success" ?
                                                                        <div className="col-3">
                                                                            <div className="job-run-summary">
                                                                                <div className="job-run-summary__title">
                                                                                    <h6>Succeeded (4)</h6>
                                                                                </div>
                                                                                <div className="job-run-summary__body">
                                                                                    <ul className="job-run-summary__list">
                                                                                        <li>
                                                                                            <label>Orders</label>
                                                                                            <span>0.0805s</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <label>Users</label>
                                                                                            <span>0.269s</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <label>run_dbt_da47d</label>
                                                                                            <span>5.941s</span>
                                                                                        </li>
                                                                                        <li>
                                                                                            <label>order_forecast_model</label>
                                                                                            <span>0.323s</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ""
                                                                }


                                                            </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="pp_attribute">
                                                            <div className="portlet lineage-graph-portlet border-0">
                                                            {this.state.lineageData!==null ?
                                                            <PipelineLineageErr
                                                            height="calc(100vh - 78px)"
                                                            chart_id="linenage"
                                                            selectedNode = {this.state.selectedPipelineInfo.job_name}
                                                            data={this.state.lineageData}
                                                              />:""}
                                                               {(this.state.lienage_graph_type==="run_level") &&
                                                                        <div className="lineage-graph-sidebar">
                                                                            <div className="lg-sidebar__content">
                                                                                <div className="lg-sidebar__header">
                                                                                    <div className="caption">
                                                                                        <span>Task</span>
                                                                                        <p>{this.state.pipeline_integration_name}</p>
                                                                                    </div>
                                                                                    <div className="action">
                                                                                        <button type="button" onClick={this.closeLineageRightPanel} className="close"><span className="sr-only">Close</span></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lg-sidebar__body">
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Origin</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <div className="pipeline-details">
                                                                                                <p><label>Pipeline:</label><span><i><FontAwesomeIcon icon={faAsterisk} /></i>{this.state.detailPipelineName}</span></p>
                                                                                                <p><label>Run:</label><span><i><FontAwesomeIcon icon={faClock} /></i><strong>At {this.formatUTCDate(this.state.selectedPipelineInfo.last_run)}</strong></span></p>
                                                                                                {(this.state.selectedJobDetails.runs!==undefined && selectedRun)&& (
                                                                                                    <>
                                                                                                            {this.Rightpanelinfo("Job Name", this.state.selectedJobDetails.job_info.job_name)}
                                                                                                            {this.Rightpanelinfo("Job Run ID", selectedRun.job_run_id)}
                                                                                                            {this.Rightpanelinfo("Start Time", this.formatUTCDate(selectedRun.run_started_time))}
                                                                                                            {this.Rightpanelinfo("End Time", this.formatUTCDate(selectedRun.run_end_time))}
                                                                                                            {this.Rightpanelinfo("Duration", `${selectedRun.duration} ms`)}
                                                                                                    </>

                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="lg-section__body">
                                                                                        {(this.state.gantt_chart_grid_temp!==undefined && Stage) && (
                                                                                            <>
                                                                                                {(Stage.activity_output!==undefined && Stage.activity_output!=="") && (
                                                                                                    <>
                                                                                                        <h5 className="lg-section__title">Activity Output:</h5>
                                                                                                        <div className="highlight">
                                                                                                            <pre className="chroma">
                                                                                                                <code className="language-js">
                                                                                                                    <span> {typeof Stage.activity_output === 'object'? JSON.stringify(Stage.activity_output):Stage.activity_output}</span>
                                                                                                                </code>
                                                                                                            </pre>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                                {(Stage.error_message!==undefined && Stage.error_message==="Failed") && (
                                                                                                    <>
                                                                                                        <h5 className="lg-section__title">Error Message:</h5>
                                                                                                        <span>
                                                                                                            {typeof Stage.error_message === 'object'? JSON.stringify(Stage.error_message):Stage.error_message}
                                                                                                        </span>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    </div>
                                                                                    <div className="lg-section">
                                                                                        <h5 className="lg-section__title">Impact Analysis</h5>
                                                                                        <div className="lg-section__body">
                                                                                            <Tab.Container defaultActiveKey="aj">
                                                                                                <div className="tabbable-line">
                                                                                                    <Nav variant="tabs" id="page-nav-tabs">
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="aj" >
                                                                                                                <span className="qd-menu__link-text">Affected Jobs <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                        <Nav.Item>
                                                                                                            <Nav.Link eventKey="ad">
                                                                                                                <span className="qd-menu__link-text">Affected Datasets <span className="badge badge-circle badge-light-danger ml-1">5</span></span>
                                                                                                            </Nav.Link>
                                                                                                        </Nav.Item>
                                                                                                    </Nav>
                                                                                                </div>
                                                                                                <Tab.Content className="pt-2">
                                                                                                    <Tab.Pane eventKey="aj">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                    <Tab.Pane eventKey="ad">
                                                                                                        <ul className="pipeline-affected-list">
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers_processed_count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forcast_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>suv_forecast_model_job</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>data_forecast_jpb</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <a href="!#">
                                                                                                                    <i>
                                                                                                                        <svg className="d-block" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                                                            <g id="icon_job" transform="translate(-8449 726)">
                                                                                                                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
                                                                                                                                <path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </i><span>timers__count</span><i className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                                                </a>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </Tab.Pane>
                                                                                                </Tab.Content>
                                                                                            </Tab.Container>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : "" }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}