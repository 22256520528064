import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import RcTooltip from 'rc-tooltip';
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faDatabase, faTag, faCheck, faServer } from "@fortawesome/free-solid-svg-icons";
import { metricClassMapping } from '../metric_utils/constants';
import { REGEX_METRIC, DEFAULT_CHECKS } from '../metric_utils/constants';
import { postToggleAction, putThresholdData, postUIlogs } from '../../utils/metricEventHandling'
import Modal from "react-bootstrap/Modal";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";

export default class AttributeToggle extends React.Component {
    constructor(props) {
        super(props);

        this.postUIlogs = postUIlogs.bind(this);

        this.closeUpdateThreshold = this.closeUpdateThreshold.bind(this);
        this.updateThresholdData = this.updateThresholdData.bind(this);
        this.postToggleAction = postToggleAction.bind(this);
        this.putThresholdData = putThresholdData.bind(this);
        this.popoverAction = this.popoverAction.bind(this);
        this.closeAlertmodel = this.closeAlertmodel.bind(this);
        this.goToMetricPage = this.goToMetricPage.bind(this);

        this.getMetricsMetadata = this.props.getMetricsMetadata.bind(this);
        this.showToast = this.props.showToast.bind(this);

        this.state = {
            // metricData: this.props.metricData,
            // attributeData: this.props.attributeData,
            check_value: null,
            threshold_value: null,
            thresholdError: false,
            popoverShow: false,
            errorMessage: "This field is a required field",
            showAlertModel: false,
            confirmationData: {},
            toggleId: "",
            checked: "",

        }
    }

    // shouldComponentUpdate() {
    //     if (this.state.check_value === null && this.state.threshold_value === null) {
    //         return false
    //     }
    //     else {
    //         return true
    //     }
    // }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        $('.attributeHighlight').removeClass("tbl-row--highlighted")

        if (JSON.stringify(prevProps.metricData) !== JSON.stringify(this.props.metricData)) {
            this.setState({
                metricData: this.props.metricData,
                attributeData: this.props.attributeData,
                // dataset:this.props.dataset,
                // addOnClass:this.props.addOnClass
            })
        }

        let className = ".highlight" + this.state.currentAttributeId

        setTimeout(() => {
            if ($('.attribute-popover').hasClass('show')) {
                $(className).addClass("tbl-row--highlighted")
                $('#topCover').addClass("overflow-hidden");
            }
            else {
                $(className).removeClass("tbl-row--highlighted")
                $('#topCover').removeClass("overflow-hidden");
            }
        }, 100);
    }

    closeUpdateThreshold(data) {
        let className = ".clickpopover" + data.data_set_id + "_" + data.attribute_id + "_" + data.metrics_id + "_ i"
        $(className).eq(0).trigger("click");

        this.setState({
            check_value: null,
            threshold_value: null
        })
    }

    updateThresholdData(data) {
        data["check_value"] = this.state.check_value === null ? data['check_value'] : this.state.formated_check
        data["updated_threshold_value"] = this.state.threshold_value === null ? Number(data.threshold_value) : Number(this.state.threshold_value)
        let className = ".clickpopover" + data.data_set_id + "_" + data.attribute_id + "_" + data.metrics_id + "_ i"
        this.putThresholdData(data, className)
    }

    popoverAction(attributethresholdData) {

        let className = ".attributeHighlight"
        $(className).removeClass("tbl-row--highlighted")

        // if($('.attribute-popover').hasClass('show')){
        //     $(className).removeClass("tbl-row--highlighted")
        //     $('#topCover').removeClass("overflow-hidden");
        // }
        // else{
        //     $(className).addClass("tbl-row--highlighted")
        //     $('#topCover').addClass("overflow-hidden");
        // }

        // let className = ".highlight" + attributethresholdData.attribute_id
        // $(className).removeClass("tbl-row--highlighted")

        // if($(className).hasClass('tbl-row--highlighted')){
        //     $('#topCover').removeClass("overflow-hidden");
        // }
        // else{
        //     $('#topCover').addClass("overflow-hidden");
        //     $(className).addClass("tbl-row--highlighted")
        // }
        // let className = ".highlight" + attributethresholdData.attribute_id
        // console.log("className",className)
        // $(className).addClass("tbl-row--highlighted")

        // console.log("className",className)
        // if($(className).hasClass('tbl-row--highlighted')){
        //     $(className).removeClass("tbl-row--highlighted")
        // }
        // else{
        //     $(className).addClass("tbl-row--highlighted")
        // }
        this.setState({
            thresholdError: false,
            threshold_value: attributethresholdData['threshold_value'],
            currentAttributeId: attributethresholdData['attribute_id'],
            popoverShow: !this.state.popoverShow
        })
    };

    handlePopoverAction(attributethresholdData) {
        if ($('.attribute-popover').hasClass('show')) {
            $('#topCover').addClass("overflow-hidden");
        }
        else {
            $('#topCover').removeClass("overflow-hidden");
        }

        // if ($('.attribute-popover').hasClass('show')) {
        //     $('#topCover').addClass("overflow-hidden");
        // }
        // else {
        //     $('#topCover').removeClass("overflow-hidden");
        // }

        // let className = ".highlight" + attributethresholdData.attribute_id
        // console.log("className",className)
        // $(className).addClass("tbl-row--highlighted")

        // console.log("className",className)
        // if($(className).hasClass('tbl-row--highlighted')){
        //     $(className).removeClass("tbl-row--highlighted")
        // }
        // else{
        //     $(className).addClass("tbl-row--highlighted")
        // }

        this.setState({
            thresholdError: false,
            threshold_value: attributethresholdData['threshold_value'],
            currentAttributeId: attributethresholdData['attribute_id'],
            disableSave: false,
        })
    };

    attributePopover(attributeData, attributethresholdData, metricData) {
        $("#topCover").addClass("overflow-hidden")
        if (metricData['payload'] !== undefined && metricData['payload']['check'] !== undefined) {
            metricData['payload']['checks'] = metricData['payload']['check']
        }
        return (
            <Popover show={this.state.popoverShow} className={"popover-theme-blue scheduleMetric-popover attribute-popover attributeHighlight highlight" + attributethresholdData.attribute_id}>
                <Popover.Title>
                    <div className="title">
                        <h3 className="caption">{metricData.metrics_name}: Data Quality Metric</h3>
                    </div>
                </Popover.Title>

                {/* <Formik

                    validationSchema={validateschema}
                    initialValues={initial_values}
                    onSubmit={this.confirmationBulkSchedule}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        resetForm,
                        dirty
                    }) => (
                        <Form> */}

                <Popover.Content>
                    <div className="popover-content">
                        <div className="form-line-input">
                            <div className="form-line-input__prepend">
                                <i><FontAwesomeIcon icon={faServer} /></i>
                            </div>
                            <input type="text" disabled={true} defaultValue={attributeData.envName} className="form-control" placeholder="Enter Environment" />
                        </div>
                        <div className="form-line-input">
                            <div className="form-line-input__prepend">
                                <i><FontAwesomeIcon icon={faDatabase} /></i>
                            </div>
                            <input type="text" disabled={true} defaultValue={attributeData.integrationName} className="form-control" placeholder="Enter Datasource" />
                        </div>
                        <div className="form-line-input">
                            <div className="form-line-input__prepend">
                                <i><FontAwesomeIcon icon={faLayerGroup} /></i>
                            </div>
                            <input type="text" disabled={true} defaultValue={attributeData.datasetName} className="form-control" placeholder="Enter Dataset" />
                        </div>
                        <div className="form-line-input">
                            <div className="form-line-input__prepend">
                                <i><FontAwesomeIcon icon={faTag} /></i>
                            </div>
                            <input type="text" disabled={true} defaultValue={attributeData.attributeName} className="form-control" placeholder="Enter Attribute" />
                        </div>
                        <div className={"qd-metrics-section " + metricClassMapping[metricData['metrics_name']]['tableClass']}>
                            <div className={"qd-metrics-section__head " + metricClassMapping[metricData['metrics_name']]['textClass'] + metricClassMapping[metricData['metrics_name']]['tableClass']}>
                                <i>
                                    <svg width="20" height="20" class="d-block opacity-75" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM6 7.5C5.7875 7.5 5.60938 7.57188 5.46562 7.71562C5.32187 7.85938 5.25 8.0375 5.25 8.25V12C5.25 12.2125 5.32187 12.3906 5.46562 12.5344C5.60938 12.6781 5.7875 12.75 6 12.75C6.2125 12.75 6.39062 12.6781 6.53438 12.5344C6.67812 12.3906 6.75 12.2125 6.75 12V8.25C6.75 8.0375 6.67812 7.85938 6.53438 7.71562C6.39062 7.57188 6.2125 7.5 6 7.5ZM9 5.25C8.7875 5.25 8.60938 5.32187 8.46563 5.46562C8.32188 5.60938 8.25 5.7875 8.25 6V12C8.25 12.2125 8.32188 12.3906 8.46563 12.5344C8.60938 12.6781 8.7875 12.75 9 12.75C9.2125 12.75 9.39063 12.6781 9.53438 12.5344C9.67813 12.3906 9.75 12.2125 9.75 12V6C9.75 5.7875 9.67813 5.60938 9.53438 5.46562C9.39063 5.32187 9.2125 5.25 9 5.25ZM12 9.75C11.7875 9.75 11.6094 9.82188 11.4656 9.96563C11.3219 10.1094 11.25 10.2875 11.25 10.5V12C11.25 12.2125 11.3219 12.3906 11.4656 12.5344C11.6094 12.6781 11.7875 12.75 12 12.75C12.2125 12.75 12.3906 12.6781 12.5344 12.5344C12.6781 12.3906 12.75 12.2125 12.75 12V10.5C12.75 10.2875 12.6781 10.1094 12.5344 9.96563C12.3906 9.82188 12.2125 9.75 12 9.75Z" fill="currentColor" />
                                    </svg>
                                </i>
                                <div className="title-area">
                                    <h6 className={"headline " + metricClassMapping[metricData['metrics_name']]['textClass']}><strong>{metricData.metrics_name}</strong> Metric</h6>
                                    <p className="paratext">This specific settings that only apply to this particular metric</p>
                                </div>
                            </div>
                            <div className="qd-metrics-section__content">
                                {metricData['metrics_name'] === "Fill Rate" &&
                                    <Form.Group>
                                        <InputGroup className="mb-1">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="metric">List of invalid values</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="text"
                                                placeholder='"NA","", "Not Available"'
                                                value={this.state.check_value === null ? metricData['payload'] !== undefined && metricData['payload']['checks'].length > 0 && metricData['payload']['checks'][0]['value'] !== undefined ? metricData['payload']['checks'][0]['value'] : this.state.check_value : this.state.check_value}
                                                onChange={e => {
                                                    let default_format = metricData['payload']['checks']
                                                    if (e.target.value.length > 0) {
                                                        default_format[0]['value'] = e.target.value
                                                    }
                                                    else {
                                                        default_format = DEFAULT_CHECKS[metricData['metrics_name']]
                                                    }
                                                    this.setState({ check_value: e.target.value, formated_check: default_format });
                                                }}
                                            />
                                        </InputGroup>
                                        <Form.Text>
                                            Example: ["NA","", "Not Available"]
                                        </Form.Text>
                                    </Form.Group>
                                }
                                {REGEX_METRIC.includes(metricData['metrics_name']) &&
                                    <Form.Group >
                                        <InputGroup className="mb-1">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="metric" className="flex-column align-items-start">Pattern to identify<span>possible values:</span></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="text"
                                                placeholder='Regex'
                                                className="h-auto"
                                                value={this.state.check_value === null ? metricData['payload'] !== undefined && metricData['payload']['checks'].length > 0 && metricData['payload']['checks'][0]['value'] !== undefined ? metricData['payload']['checks'][0]['value'] : "" : this.state.check_value}
                                                onChange={e => {
                                                    let default_format = metricData['payload']['checks']
                                                    if (e.target.value.length > 0) {
                                                        default_format[0]['value'] = e.target.value
                                                    }
                                                    else {
                                                        default_format = DEFAULT_CHECKS[metricData['metrics_name']]
                                                    }
                                                    this.setState({ check_value: e.target.value, formated_check: default_format });
                                                }}
                                            />
                                        </InputGroup>
                                        <Form.Text>
                                            {metricData['metrics_name'] === "Gender Conformity"
                                                &&
                                                "Example: ^M(ale)?$|^F(emale)?$"
                                            }
                                        </Form.Text>
                                    </Form.Group>
                                }
                                <Form.Group>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="metric-threshold">Metric Threshold (in %)</InputGroup.Text>
                                        </InputGroup.Prepend>

                                        <Form.Control
                                            type="text"
                                            pattern="[a-zA-Z0-9 ]+"
                                            step="0.1"
                                            min={1}
                                            max={100}
                                            placeholder="Metric Threshold"
                                            value={this.state.threshold_value === null ? metricData.threshold_value : this.state.threshold_value}
                                            onChange={e => {
                                                let threshold_value = e.target.value // .split('.')[0]
                                                if (threshold_value.length === 0) {
                                                    this.setState({
                                                        thresholdError: true,
                                                        threshold_value: threshold_value
                                                    })
                                                }
                                                else if (Number(threshold_value) <= 100) {
                                                    this.setState({
                                                        thresholdError: false,
                                                        threshold_value: threshold_value
                                                    })
                                                }
                                                if (threshold_value.length > 0 && Number(threshold_value) < 0) {
                                                    this.setState({
                                                        thresholdError: true,
                                                        errorMessage: "Threshold cannot be less than 0"
                                                    })
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                {
                                    this.state.thresholdError === true &&
                                    <h6 className="headline text-orange"><strong>{this.state.errorMessage}</strong></h6>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="popover-footer justify-content-end">
                        <button
                            type="button"
                            class="btn btn-outline btn-grey btn-circle mr-2"
                            id="attribute"
                            onClick={() => {
                                this.closeUpdateThreshold(attributethresholdData)
                            }
                            }
                        >Cancel</button>
                        <button
                            type="button"
                            class="btn btn-primary btn-circle"
                            data={attributethresholdData}
                            disabled={this.state.disableSave === true || this.state.thresholdError === true ? true : false}
                            onClick={() => {
                                this.setState({ disableSave: true })
                                attributethresholdData["check_value"] = this.state.check_value === null ? attributethresholdData.check_value : this.state.check_value
                                attributethresholdData["threshold_value"] = this.state.threshold_value === null ? Number(attributethresholdData.threshold_value) : Number(this.state.threshold_value)
                                this.updateThresholdData(attributethresholdData)
                                // this.setState({check_value:null, threshold_value:null})
                            }
                            }
                        >
                            <i className="mr-1">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                />
                            </i>
                            Save

                        </button>
                    </div>
                </Popover.Content>

                {/* </Form>
                    )}
                </Formik> */}


            </Popover>
        )
    }

    closeAlertmodel() {
        this.setState({ showAlertModel: !this.state.showAlertModel })
    }

    goToMetricPage() {
        this.props.closeFullScreen();
    }

    render() {
        let attributeData = this.props.attributeData
        let metricData = this.props.metricData
        let attributethresholdData = {
            tenant_id: Number(localStorage.getItem('team_id')),
            env_id: attributeData.envId,
            integration_id: attributeData.integrationId,
            data_set_id: attributeData.datasetId,
            attribute_id: attributeData.attributeId,
            is_enable: metricData.is_enable,
            metrics_id: metricData.metrics_id,
            update_type: "attribute_threshold",
            threshold_value: metricData.threshold_value,
        }

        if (metricData['payload'] !== undefined) {
            attributethresholdData["check_value"] = metricData["payload"]["check"]
        }

        let checked = false
        let disabled = false
        if (attributethresholdData['is_enable'] === 1) {
            checked = true
        }
        if (attributethresholdData['is_enable'] === "NA") {
            disabled = true
        }
        let className = "metric-toggle-switch__right clickpopover" + attributeData['datasetId'] + "_" + attributethresholdData.attribute_id + "_" + attributethresholdData.metrics_id + "_"
        return (
            <>
                {/* <td className={metricClassMapping[metricData['metrics_name']]['tableClass']}> */}
                <div className="metric-toggle-switch">
                    <>
                        {/* <Form.Check
                                type="switch"
                                className={"custom-switch-sm childOf_" + attributeData['datasetId']} // Needs to add ml-0 for disabled
                                id={"attributeToggle" + attributeData.attributeId + (metricData.metrics_id === undefined ? attributeData.attributeId + metricData.dummyId : metricData.metrics_id)}
                                label=""
                                // defaultChecked={checked}
                                checked={checked}
                                disabled={disabled}
                                onChange={(e) => {

                                    if (this.props.activeMetrics[metricData['metrics_name']] === -1) {
                                        // $(id).prop("checked", !checked);
                                        this.setState({ showAlertModel: !this.state.showAlertModel, confirmationData: attributethresholdData})
                                        return null
                                    }
                                    attributethresholdData["update_type"] = "attribute_status"
                                    attributethresholdData["is_enable"] = checked === true ? 0 : 1
                                    attributethresholdData["checked"] = !checked
                                    this.postToggleAction(attributethresholdData)
                                    this.setState({ check_value: null, threshold_value: null })
                                }}
                            /> */}
                        <div class="custom-control custom-switch custom-switch-sm">
                            <input type="checkbox"
                                // defaultChecked = {checked}
                                checked={this.state.checked === "" ? checked : this.state.checked}
                                disabled={disabled}
                                onChange={(e) => {
                                    if (this.props.activeMetrics[metricData['metrics_name']] === -1) {
                                        // $(id).prop("checked", !checked);
                                        this.setState({ showAlertModel: !this.state.showAlertModel, confirmationData: attributethresholdData })
                                        return null
                                    }
                                    attributethresholdData["update_type"] = "attribute_status"
                                    attributethresholdData["is_enable"] = checked === true ? 0 : 1
                                    attributethresholdData["checked"] = !checked
                                    this.setState({checked:!checked})
                                    this.postToggleAction(attributethresholdData)
                                    this.setState({ check_value: null, threshold_value: null })
                                }}
                                className="custom-control-input " id={"attributeToggle" + attributeData.attributeId + (metricData.metrics_id === undefined ? attributeData.attributeId + metricData.dummyId : metricData.metrics_id)}
                            />
                            <label class="custom-control-label" for={"attributeToggle" + attributeData.attributeId + (metricData.metrics_id === undefined ? attributeData.attributeId + metricData.dummyId : metricData.metrics_id)}>&nbsp;</label>
                        </div>
                        <div className={className}>
                            <OverlayTrigger
                                trigger="click"
                                rootCloseEvent="click"
                                placement='auto'
                                rootClose
                                onToggle={(e) => { this.popoverAction(attributethresholdData) }}
                                overlay={
                                    this.attributePopover(attributeData, attributethresholdData, metricData)
                                }
                            >
                                <RcTooltip
                                    placement="top"
                                    overlay={
                                        <div><span>Edit Metric</span></div>
                                    }
                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                >


                                    <i
                                        onClick={() => { this.handlePopoverAction(attributethresholdData) }}
                                        className={disabled === true || metricData['metrics_name'] === "Unique Values" || this.props.activeMetrics[metricData['metrics_name']] === -1 ? "icon icon-pencil d-none" : "icon icon-pencil"}
                                    ></i>
                                </RcTooltip>
                            </OverlayTrigger>
                        </div>

                        <Modal show={this.state.showAlertModel} centered>
                            <Modal.Body>
                                <div className="modal__alert">
                                    <div className="modal_alert-icon">
                                        <i className="text-danger"><FontAwesomeIcon icon={faToggleOn} /></i>
                                    </div>
                                    <div className="modal_alert-text">
                                        <h4 className="mb-3">Global {this.state.confirmationData['metrics_name']} metric is disabled</h4>
                                        <p>You need to enable the Global  {this.state.confirmationData['metrics_name']} metric before activating it at the attribute level.</p>
                                        {/* <p>Please enable the Global  {this.state.confirmationData['metrics_name']} metric in the default metric page to proceed.</p> */}
                                    </div>
                                    <div className="modal_alert-btn-group border-0 pt-0 pb-4">
                                        <button
                                            type="button"
                                            id="close-btn"
                                            className="btn btn-outline btn-grey btn-circle mr-2"
                                            onClick={() => { this.closeAlertmodel(); }}
                                        >Cancel</button>

                                        <button
                                            type="button"
                                            id="save-btn"
                                            className="btn btn-primary btn-circle mr-2"
                                            onClick={() => { this.goToMetricPage(); }}
                                        >Go Back</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                </div>
                {/* </td> */}
            </>
        );
    }
}
